import React, { useEffect, useState, useRef, useMemo } from "react";
import {
  Stack,
  mergeStyleSets,
  FontIcon,
  DefaultButton,
  TooltipHost,
  Panel,
  Modal,
  PrimaryButton,
} from "@fluentui/react";
import { useId } from "@fluentui/react-hooks";
import {
  COMPARE_HORIZONTAL_CHART_OPTIONS,
  SIMPLE_REPORT_CHART_OPTIONS,
  SUS_REPORT_CHART_COLOR,
  HORIZONTAL_STACKED_BAR_CHART_OPTIONS,
  SCOPE_DESCRIPTION
} from "constants/sustainability";
import ReactEcharts from "components/ReactEcharts";
import BackToTop from "components/BackToTop";
import {
  kNumFormat,
  convertNumberToString,
  isLargeNumber,
  isSmallWidthScreen,
} from "utils/helpers";
import TwoColorCircle from "components/TwoColorCircle";
import MethodologyPage from "components/MethodologyPage";
import ReadMore from "components/ReadMore";
import MobileTooltipAccordion from "./components/MobileTooltipAccordion";
import BackIcon from "assets/back.svg";
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
import "./SustainabilityReport.scss";

const SustainabilityReport = (props) => {
  const { reportData, setShowCompleteReport, productReference, productOptions, chatbotExpanded, productList } = props;
  const [activeMenuItem, setActiveMenuItem] = useState("overall");
  const [showMenu, setShowMenu] = useState(false);
  const [isProductsSame, setIsProductsSame] = useState(false);
  const barChartHeight = reportData.production.rows.length * 6.25;
  const [formattedProductLabels, setFormattedProductLabels] = useState([]);
  const [comparisonsProductLabels, setComparisonsProductLabels] = useState([]);
  
  useEffect(() => {
    let formattedProductList = [];
    let filteredProductList = [];
    let count = 0;
    if(productList && productList[0] && reportData && (Object.keys(reportData).length > 0)){
      productList.forEach((item) => {
        formattedProductList.push(`Country: ${item.location?.text || ""}, Quantity: ${item.quantity || ""}, Years: ${item.years?.text || 3}`);
        if(item.product?.text === reportData.emissionBySurfaceModel?.comparisons[0]?.selectedDetails[count]?.label){
          filteredProductList.push(`Country: ${item.location?.text || ""}, Quantity: ${item.quantity || ""}, Years: ${item.years?.text || 3}`);
          count++;
        }
      });
      setFormattedProductLabels(formattedProductList);
      setComparisonsProductLabels(filteredProductList);
    }
  }, [productList, reportData]);
  

  useEffect(() => {
    if (!isSmallWidthScreen()) {
      const handleScroll = () => {
        reportMenu.forEach((item) => {
          const top = window.scrollY;
          const offset = item.ref.current?.offsetTop || 0;
          const height = item.ref.current?.offsetHeight || 0;
          const viewportHeight = window.innerHeight;
          const position = top - offset - height;
          if (Math.abs(position) < viewportHeight / 2) {
            setActiveMenuItem(item.id);
          }
        });

        const menu = document.querySelector(
          ".sustainability-report-menu-wrapper"
        );
        if(menu){
          const top = Math.round(menu.getBoundingClientRect().top);
          if (top === 0) {
            menu.classList.add("shadow");
          } else {
            menu.classList.remove("shadow");
          }
        }
      };

      window.addEventListener("scroll", handleScroll);
      return () => {
        window.removeEventListener("scroll", handleScroll);
      };
    }
  }, []);

  const reportMenu = [
    {
      id: "overall",
      value: "Overall Summary",
      ref: useRef(null),
    },
    {
      id: "emissions",
      value: "Emission Estimations by Selected...",
      ref: useRef(null),
    },
    {
      id: "production",
      value: "Production Estimates",
      ref: useRef(null),
      boxLabel: "Production",
    },
    {
      id: "packaging",
      value: "Packaging Estimates",
      ref: useRef(null),
      boxLabel: "Packaging",
    },
    {
      id: "transport",
      value: "Transportation Estimates",
      ref: useRef(null),
      boxLabel: "Transportation",
    },
    {
      id: "productUse",
      value: "Product Use Estimates",
      ref: useRef(null),
      boxLabel: "Product Use",
    },
    {
      id: "endOfLife",
      value: "End of Life Estimates",
      ref: useRef(null),
      boxLabel: "End-Of-Life",
    },
    {
      id: "portfolioOverview",
      value: "Portfolio Overview / Total Emission Estimates per Device",
      ref: useRef(null),
    },
    {
      id: "methodology",
      value: "Methodology",
      ref: useRef(null),
    },
  ];

  const getRefProductName = (name) => {
    const key = productOptions.filter(item => item.text === name)[0].key;
    return productReference.filter(item => item.key === key)[0].text;
  }

  const handleMenuItemClick = (item) => {
    const top = document.querySelector(
      ".sustainability-report-menu-wrapper"
    )?.offsetHeight;
    const mobileHeaderHeight = document.querySelector(
      ".sustainability-report-header-wrapper"
    ).offsetHeight;
    let scrollTop;
    if (mobileHeaderHeight) {
      scrollTop = item.current.offsetTop - mobileHeaderHeight;
    } else {
      scrollTop = item.current.offsetTop - top;
    }
    window.scrollTo({ top: scrollTop, behavior: "smooth" });
  };

  const overallSectionStyles = mergeStyleSets({
    sectionWrapper: {
      marginBottom: "1.5rem",
      border: "0.0625rem solid #EDEBE9",
      flexWrap: "wrap",
    },
    totalEmissionWrapper: {
      width: "33.07%",
      height: "23.0625rem",
      alignItems: "center",
      justifyContent: "center",
      borderBottom: "0.0625rem solid #EDEBE9",
      borderRight: "0.0625rem solid #EDEBE9",
    },
    totalEmissionLabel: {
      fontSize: "1.25rem",
      fontWeight: 400,
      lineHeight: "1.5rem",
      marginBottom: "1rem",
      textAlign: "center",
    },
    totalEmissionValue: {
      fontSize: "4rem",
      fontWeight: 600,
      lineHeight: "6rem",
      color: "#0078D4",
    },
    totalEmissionUnit: {
      fontSize: "1.25rem",
      fontWeight: 400,
      lineHeight: "1.5rem",
    },
    totalEmissionSmallUnit: {
      fontSize: "0.875rem",
      fontWeight: 400,
      lineHeight: "1rem",
    },
    overallChart: {
      width: "66.93%",
      height: "23.0625rem",
      borderBottom: "0.0625rem solid #EDEBE9",
      boxSizing: "border-box",
      paddingTop: "1rem",
      alignItems: "center",
    },
    scopeWrapper: {
      width: "33.07%",
      color: "#0078D4",
      alignItems: "center",
      borderRight: "0.0625rem solid #EDEBE9",
    },
    scopeItemWrapper: {
      width: "50%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      padding: "3.6875rem 0 5rem",
    },
    scopeItemValue: {
      fontSize: "2rem",
      fontWeight: 600,
      marginRight: "0.25rem",
    },
    scopeItemUnit: {
      fontSize: "0.875rem",
      fontWeight: 400,
      lineHeight: "1.05rem",
      alignSelf: "flex-end",
    },
    scopeItemLabelWrapper: {
      alignItems: "center",
    },
    scopeItemLabel: {
      fontSize: "1rem",
      fontWeight: 400,
      marginRight: "0.25rem",
    },
    scopeInfoIcon: {
      position: "relative",
      fontSize: "1.25rem",
      lineHeight: "1.25rem",
    },
    equivalentWrapper: {
      width: "66.93%",
      padding: "3rem 0 2.125rem",
      justifyContent: "center",
    },
    equivalentLabel: {
      fontSize: "1.5rem",
      fontWeight: 600,
      lineHeight: "1.5rem",
      marginRight: "1rem",
      color: "#0078D4",
    },
    dataList: {
      alignItems: "center",
      marginBottom: "1rem",
    },
    dataListValue: {
      fontSize: "1.25rem",
      fontWeight: 600,
      marginRight: "1.25rem",
      lineHeight: "1.5rem",
    },
    dataListDes: {
      fontSize: "1rem",
      fontWeight: 400,
      lineHeight: "1.5rem",
      color: "#0078D4",
    },
    divider: {
      borderRight: "0.0625rem solid #EDEBE9",
    },
    moreInfoText: {
      fontSize: "1.25rem",
      lineHeight: "1.5rem",
      fontWeight: 600,
    },
    moreInfoHighlight: {
      textDecoration: "underline",
      fontWeight: 600,
      cursor: "pointer",
    },
  });

  const iconStyles = mergeStyleSets({
    img: {
      width: "1.125rem",
      marginRight: "1.4375rem",
    },
    phone: {
      width: "0.75rem",
      marginRight: "1.8125rem",
    },
  });

  const equivalentList = [
    {
      id: "kilometresTraveledByCar",
      icon: (
        <img
          alt="car"
          src="/images/car.svg"
          className={iconStyles.img}
          tabIndex={0}
        />
      ),
      description: "Vehicle kilometres travelled",
    },
    {
      id: "gallonsOfGasoline",
      icon: (
        <img
          alt="gasoline"
          src="/images/gasoline.svg"
          className={iconStyles.img}
          tabIndex={0}
        />
      ),
      description: "Liters of Gasoline",
    },
    {
      id: "barrelsOfOil",
      icon: (
        <img
          alt="oil"
          src="/images/oil.svg"
          className={iconStyles.img}
          tabIndex={0}
        />
      ),
      description: "Barrels of Oil",
    },
  ];

  const compareChartStyles = mergeStyleSets({
    container: {
      gap: (window.innerWidth < 1640 && chatbotExpanded) ? "1rem" : "5.4375rem",
      flexGrow: 0,
    },
    contentWrapper: {
      //border: "0.0625rem solid #EDEBE9",
      borderRadius: "0.25rem",
      maxWidth: "26.3125rem",
    },
    boxWrapper: {
      
      padding: "1.5rem 1rem",
      justifyContent: "center",
      alignItems: "center",
      minWidth: (window.innerWidth < 1440 && chatbotExpanded) ? "fit-content" : "20.625rem",
      maxWidth: "26.3125rem",
      textAlign: "center",
    },
    label: {
      fontSize: "1rem",
    },
    value: {
      fontSize: "2rem",
      fontWeight: 600,
      lineHeight: "5.25rem",
      marginRight: "0.25rem",
      marginBottom: "0",
    },
    unit: {
      fontSize: "1rem",
      alignSelf: "flex-end",
      marginBottom: "1rem",
    },
    chartWrapper: {
      width: "75%",
    },
    epaWrapper: {
      margin: "2.5rem 0",
      justifyContent: "flex-start",
    },
    productionChartWrapper: {
      gap: "2rem",
    },
    countryTable: {
      border: "0.0625rem solid #EDEBE9",
      borderRadius: "0.5rem",
    },
    countryTableTitle: {
      borderBottom: "0.0625rem solid #EDEBE9",
      boxSizing: "border-box",
      padding: "1rem 0",
      width: "100%",
      height: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      textAlign: "center",
    },
    countryTableContent: {
      display: "grid",
      gridTemplateColumns: "16.36% 27.21% 2.8% 18.36% 3.14% 31.39%",
      justifyItems: "center",
      alignItems: "center",
      gridAutoFlow: "row",
    },
    greyBackground: {
      width: "100%",
      height: "100%",
      backgroundColor: "#FAF9F8",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      position: "relative",
      textAlign: "center",
      padding: "1rem 0",
      boxSizing: "border-box",
    },
    withBorder: {
      ":after": {
        content: "''",
        width: "2.5rem",
        height: "5px",
        transform: "scale(1, 0.1)",
        backgroundColor: "#A19F9D",
        position: "absolute",
        bottom: 0,
      },
    },
    green: {
      color: "#107C10",
    },
    multiple: {
      gridColumn: "3",
      gridRow: `2 / ${(reportData?.productUse?.countryRows?.length || 0) + 2}`,
      fontSize: "2rem",
    },
    equal: {
      gridColumn: "5",
      gridRow: `2 / ${(reportData?.productUse?.countryRows?.length || 0) + 2}`,
      fontSize: "2rem",
    },
  });

  const compareChart = (data, id) => {
    const name = reportMenu.find((i) => i.id === id).boxLabel;
    return (
      <Stack
        horizontal
        className={`${compareChartStyles.container} sustainability-report-compare-chart-wrapper`}
      >
        <Stack
          className={`${compareChartStyles.contentWrapper} sustainability-report-compare-chart-left`}
        >
          <Stack className={compareChartStyles.boxWrapper}>
            <Stack
              horizontal
              className={
                isLargeNumber(data.value) ? "two-line-value-and-unit" : ""
              }
            >
              <span
                className={`${compareChartStyles.value} sustainability-report-compare-chart-left-value`}
                tabIndex={0}
              >
                {kNumFormat(data.value, true)}
              </span>
              <span
                className={`${compareChartStyles.unit} sustainability-report-compare-chart-left-unit`}
                tabIndex={0}
              >
                MTCO<sub>2</sub>
              </span>
            </Stack>
            <div
              className={`${compareChartStyles.label} sustainability-report-compare-chart-left-label`}
              tabIndex={0}
            >
              Total Estimated {name} Emissions
            </div>
          </Stack>
          {id === "productUse" ? (
            <Stack className={compareChartStyles.boxWrapper}>
              <Stack>
                <span
                  className={`${compareChartStyles.value} sustainability-report-compare-chart-left-value`}
                  style={{ marginBottom: 0 }}
                  tabIndex={0}
                >
                  {data.annualEnergyConsumption}
                </span>
                <span
                  className={`${compareChartStyles.unit} sustainability-report-compare-chart-left-unit`}
                  style={{ alignSelf: "center" }}
                  tabIndex={0}
                >
                  kWh
                </span>
              </Stack>
              <div
                className={`${compareChartStyles.label} sustainability-report-compare-chart-left-label`}
                tabIndex={0}
              >
                Annual kWh Energy Consumption
              </div>
            </Stack>
          ) : null}
          <Stack className={compareChartStyles.boxWrapper}>
            <span
              className={`${compareChartStyles.value} sustainability-report-compare-chart-left-value`}
              tabIndex={0}
            >
              {data.percentage}%
            </span>
            <div
              className={`${compareChartStyles.label} sustainability-report-compare-chart-left-label`}
              tabIndex={0}
            >
              % of Total Estimated Carbon Emissions
            </div>
          </Stack>
        </Stack>
        <div
          className={`${compareChartStyles.chartWrapper} sustainability-report-compare-chart-right`}
        >
          {id === "production" && reportData.production.rows[0].rows ? (
            <Stack className={compareChartStyles.productionChartWrapper}>
              <ReactEcharts
                options={HORIZONTAL_STACKED_BAR_CHART_OPTIONS(
                  productionData,
                  SUS_REPORT_CHART_COLOR[id]
                )}
                width="100%"
                height={`${barChartHeight}rem`}
              />
              <Stack
                horizontal
                className={`${emissionStyles.legendWrapper} sustainability-report-legend-wrapper`}
              >
                {productionData.map((item, idx) => {
                  return (
                    <Stack
                      horizontal
                      key={idx}
                      className={`${emissionStyles.legendItemWrapper} sustainability-report-legend-item-wrapper`}
                    >
                      <TwoColorCircle
                        fill1={SUS_REPORT_CHART_COLOR[id].bar[idx]}
                      />
                      <span tabIndex={0}>{item.name}</span>
                    </Stack>
                  );
                })}
              </Stack>
            </Stack>
          ) : (
            <>
            <ReactEcharts
              options={COMPARE_HORIZONTAL_CHART_OPTIONS(
                data.rows,
                SUS_REPORT_CHART_COLOR[id],
                id,
                formattedProductLabels,
              )}
              width="100%"
              height={`${
                id === "productUse" ? barChartHeight * 1.3 : barChartHeight
              }rem`}
            />
            </>
          )}

          {id === "productUse" ? (
            <>
              <Stack
                horizontal
                className={`${emissionStyles.legendWrapper} ${compareChartStyles.epaWrapper} sustainability-report-legend-wrapper sustainability-report-epa-legend-wrapper`}
              >
                <Stack
                  horizontal
                  className={`${emissionStyles.legendItemWrapper} sustainability-report-legend-item-wrapper`}
                >
                  <TwoColorCircle fill1={SUS_REPORT_CHART_COLOR[id].bar} />
                  <span tabIndex={0}>Telemetry Estimation</span>
                </Stack>
                <Stack
                  horizontal
                  className={`${emissionStyles.legendItemWrapper} sustainability-report-legend-item-wrapper`}
                >
                  <TwoColorCircle
                    fill1={SUS_REPORT_CHART_COLOR[id].energyStar.bar}
                  />
                  <span tabIndex={0}>EPA Energy Star Estimation</span>
                </Stack>
              </Stack>
              <div
                className={`${compareChartStyles.countryTable} sustainability-report-country-table`}
              >
                <div className={compareChartStyles.countryTableContent}>
                  <span className={compareChartStyles.multiple}>X</span>
                  <span className={compareChartStyles.equal}>=</span>
                  <div
                    className={`${compareChartStyles.greyBackground} ${compareChartStyles.countryTableTitle}`}
                    tabIndex={0}
                  >
                    Country
                  </div>
                  <div
                    tabIndex={0}
                    className={compareChartStyles.countryTableTitle}
                  >
                    Electricity Usage (kWh)
                  </div>
                  <div className={compareChartStyles.countryTableTitle}></div>
                  <div
                    tabIndex={0}
                    className={compareChartStyles.countryTableTitle}
                  >
                    Emission Factor
                  </div>
                  <div className={compareChartStyles.countryTableTitle}></div>
                  <div
                    className={`${compareChartStyles.green} ${compareChartStyles.countryTableTitle}`}
                    tabIndex={0}
                  >
                    <span>
                      Carbon Emissions (MTCO<sub>2</sub>)
                    </span>
                  </div>
                  {data.countryRows.map((item, index) => {
                    return (
                      <React.Fragment key={index}>
                        <div
                          className={`${compareChartStyles.greyBackground} ${
                            index < data.countryRows.length - 1
                              ? `${compareChartStyles.withBorder} country-table-with-border`
                              : ""
                          }`}
                          tabIndex={0}
                        >
                          {item.label}
                        </div>
                        <span tabIndex={0}>
                          {convertNumberToString(item.electicityUsage)}
                        </span>
                        <span tabIndex={0}>{item.emissionFactor}</span>
                        <span className={compareChartStyles.green} tabIndex={0}>
                          {convertNumberToString(item.value/1000, "", 5)}
                        </span>
                      </React.Fragment>
                    );
                  })}
                </div>
              </div>
            </>
          ) : null}
        </div>
      </Stack>
    );
  }

  const completeReportStyles = mergeStyleSets({
    container: {
      // overflow: "clip",
    },
    mobileHeaderWrapper: {
      display: "none",
      position: "sticky",
      top: 0,
      width: "100%",
      height: "4.4106rem",
      backgroundColor: "white",
      zIndex: 1,
      alignItems: "center",
      justifyContent: "space-between",
      cursor: "pointer",
    },
    mobileBackWrapper: {
      paddingLeft: "1.4rem",
      gap: "1.2rem",
      img: {
        width: "0.768rem",
      },
      span: {
        fontSize: "1.6rem",
        lineHeight: "2.4rem",
        fontWeight: 600,
      },
    },
    menuIcon: {
      display: "none",
      fontSize: "2rem",
      marginBottom: "0.25rem",
      marginRight: "2rem",
    },
    reportWrapper: {
      position: "relative",
      padding: "4.4106rem 4.1% 0 5%",
    },
  });

  const reportContentStyles = mergeStyleSets({
    sectionWrapper: {
      marginBottom: "5rem",
    },
    contentWrapper: {
      position: "relative",
      padding: "4.4106rem 3.5rem 0"//"4.4106rem 5% 0",
    },
    titleWrapper: {
      justifyContent: "space-between",
      alignItems: "center",
      marginBottom: "1.5rem",
    },
    title: {
      fontSize: (window.innerWidth < 1540 && chatbotExpanded) ? "2.2rem" : "2.625rem",
      fontWeight: 400,
      lineHeight: "3rem",
      marginBottom: "3.125rem",
      margin: 0,
    },
    backButton: {
      width: "9rem",
      height: "2.5rem",
      fontSize: "1rem",
      fontWeight: 600,
      padding: "0 0.5rem",
    },
    mobileBackButton: {
      fontSize: "1.6rem",
      fontWeight: 600,
      margin: "0 auto",
      marginBottom: "3.4rem",
    },
    infoIcon: {
      fontSize: "1.5rem",
      lineHeight: "1.5rem",
    },
    sectionTitle: {
      fontSize: "1.625rem",
      fontWeight: 400,
      lineHeight: "2rem",
      marginTop: 0,
      marginBottom: "0.5rem",
      display: "flex",
      gap: "1.125rem",
      alignItems: "center",
      textTransform: "capitalize",
    },
    readmore: {
      width: "80%",
    },
    moreMarginTitle: {
      marginBottom: "1.1875rem",
    },
    mobileMenuItem: {
      width: "94%",
      padding: "1rem",
    },
    mobileActiveMenuItem: {
      backgroundColor: "#FAF9F8",
    },
    menuWrapper: {
      position: "sticky",
      top: "0",
      alignItems: "stretch",
      gap: (window.innerWidth < 1540 && chatbotExpanded) ? "1.5rem" : "2.5rem",
      marginBottom: "2.625rem",
      backgroundColor: "#ffffff",
      zIndex: 1,
    },
    menuItem: {
      fontSize: (window.innerWidth < 1540 && chatbotExpanded) ? "0.8rem" : "0.875rem",
      fontWeight: 400,
      lineHeight: "1.25rem",
      cursor: "pointer",
      padding: "0.75rem 0 0.75rem",
      display: "flex",
      alignItems: "center",
    },
    activeMenuItem: {
      fontWeight: 600,
      borderBottom: "0.125rem solid #0078D4",
    },
    portfolioOverviewWrapper: {
      width: (window.innerWidth < 1740 && chatbotExpanded) ? "80%" : "60%",
      height: "100%",
    },
    portfolioOverviewChartWrapper: {
      marginBottom: "2rem",
    },
    learnMoreWrapper: {
      borderTop: "0.0313rem solid #C8C6C4",
      borderBottom: "0.0313rem solid #C8C6C4",
      marginBottom: "5rem",
      marginRight: "3rem",
    },
    learnMoreTitle: {
      fontSize: "3rem",
      fontWeight: 400,
      lineHeight: "4rem",
      alignSelf: "center",
      margin: "4rem 0 2.5rem",
    },
    learnMoreContent: {
      fontSize: "1.25rem",
      fontWeight: 400,
      lineHeight: "2rem",
      marginBottom: "4rem",
      padding: "0 4.125rem",
      textAlign: "center",
    },
    methodologyIntro: {
      display: "block",
      fontSize: "1rem",
      lineHeight: "1.375rem",
      fontStyle: "italic",
      marginBottom: "4.5625rem",
    },
    chatbotButtonContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      minWidth: "max-content",
      // marginRight: "15rem",
      // ">div": {
      //   width: "10%",
      // }
    },
    chatbotButton: {
      padding: "0.5rem 1rem",
      marginRight: chatbotExpanded ? "1rem" : "2rem",
      background: chatbotExpanded ? "#0078d4" : "#fff",
      border: "1px solid #0078d4",
      // borderRadius: "0.5rem",
      color: chatbotExpanded ? "#fff" : "#0078d4",
      fontWeight: 500,
      ":hover": {
        cursor: "pointer",
        background: "#0078d4",
        color: "#fff",
      },
      textAlign: "center",
    },
    marginBottom: {
      marginBottom: "3rem",
    }
  });

  const emissionStyles = mergeStyleSets({
    container: {
      
      gap: (window.innerWidth < 1740 && chatbotExpanded) ? "0rem" : (window.innerWidth < 1840 && chatbotExpanded) ? "2rem" : "5rem",
    },
    left: {
      //border: "0.0625rem solid #EDEBE9",
      borderRadius: "0.25rem",
      maxWidth: "26.3125rem",
    },
    right: {
      width: (window.innerWidth < 1740 && chatbotExpanded) ? "80%" : "70%",
      display: "flex",
      flexDirection: "column",
    },
    box: {
      
      padding: "1.5rem 1rem",
      justifyContent: "center",
      alignItems: "center",
      minWidth: (window.innerWidth < 1740 && chatbotExpanded) ? "fit-content" : "20.625rem",
      maxWidth: "26.3125rem",
    },
    value: {
      fontSize: "2rem",
      fontWeight: 600,
      lineHeight: "5.25rem",
      marginRight: "0.25rem",
      marginBottom: "0",
    },
    unit: {
      fontSize: "1rem",
      alignSelf: "flex-end",
      marginBottom: "0.875rem",
    },
    description: {
      fontSize: "1rem",
      fontWeight: 400,
      lineHeight: "1.3125rem",
      textAlign: "center",
      gap: "0.5rem",
      maxWidth: "17rem",
      alignItems: "center",
    },
    itemWrapper: {
      alignItems: "center",
      gap: "1rem",
    },
    chartWrapper: {
      width: "75%",
    },
    descriptionWrapper: {
      borderLeft: "0.0625rem solid #EDEBE9",
      padding: "0.625rem",
      alignItems: "center",
      color: "#0078D4",
      width: "25%",
    },
    desValue: {
      fontSize: (window.innerWidth < 1740 && chatbotExpanded) ? "1.7rem" : "2rem",
      fontWeight: 600,
      marginRight: "0.5rem",
    },
    desUnit: {
      fontSize: (window.innerWidth < 1740 && chatbotExpanded) ? "0.75rem" : "0.875rem",
      alignSelf: "flex-end",
    },
    desText: {
      fontSize: (window.innerWidth < 1740 && chatbotExpanded) ? "1.5rem" : "1.625rem",
    },
    legendWrapper: {
      alignItems: "center",
      gap: "1rem",
      flexWrap: "wrap",
      marginTop: "1.5rem",
    },
    legendItemWrapper: {
      alignItems: "center",
      gap: "0.875rem",
      fontSize: "1rem",
      lineHeight: "1.5rem",
      fontWeight: 400,
    },
  });

  const emissionChartData = useMemo(() => {
    let chartData = [];
    reportData.emissionBySurfaceModel.comparisons.forEach((item, index) => {
      item.previousDetails.forEach((i, idx) => {
        let currentValue = {
          name: item.label,
          data: [
            {
              name: item.selectedDetails[idx].label,
              value: item.selectedDetails[idx].value,
              itemStyle: {
                color: SUS_REPORT_CHART_COLOR.emissions[1][index],
              },
            },
            {
              name: `{description|Comparison To Previous Generation}\n${i.label}`,
              value: i.value,
              itemStyle: {
                color: SUS_REPORT_CHART_COLOR.emissions[1][index],
              },
            },
          ],
        };
        if (index === 0) {
          chartData[idx] = {
            label: [i.label, item.selectedDetails[idx].label],
            chartData: [currentValue],
          };
        } else {
          chartData[idx].chartData.push(currentValue);
        }
      });
    });

    const getSum = (data, index) => {
      return data.reduce((acc, curr) => {
        let current =
          typeof curr.data[index].value !== "number"
            ? 0
            : curr.data[index].value;
        return acc + current;
      }, 0);
      // return data
      //   .reduce((acc, curr) => {
      //     let current =
      //       typeof curr.value[index].value !== "number"
      //         ? 0
      //         : curr.value[index].value;
      //     current = parseFloat(
      //       (current / 1000).toLocaleString(undefined, {
      //         minimumFractionDigits: 0,
      //         maximumFractionDigits: 2,
      //       })
      //     );
      //     return acc + current;
      //   }, 0).toFixed(2);
    };

    chartData = chartData.map((item) => {
      
      const sum1 = getSum(item.chartData, 0);
      const sum2 = getSum(item.chartData, 1);
            
      return {
        ...item,
        sum: [sum1, sum2],
        savings: sum1 - sum2,
      };    
      
    });

    
    return chartData;
  }, [reportData]);

  const portfolioOverviewData = useMemo(() => {
    const categories = Object.keys(reportData.portfolioOverview);
    const data = [];

    const keyNames = {
      'production': 'Production',
      'endOfLife': 'End of Life',
      'packaging': 'Packaging',
      'transportation': 'Transportation',
      'usage': 'Usage'
    };

    categories.forEach( item => {
      let listItem = {
        name: keyNames[item],
        data: reportData.portfolioOverview[item].map (subItem => {
          return {
            name: subItem.label,
            value: subItem.value,
          }
        })
      }
      data.push(listItem);
    });
    
    return data;
    
    /* reportData.emissionBySurfaceModel.comparisons.map((item) => {
      return {
        name: item.label,
        data: item.selectedDetails.map((i) => {
          return {
            name: i.label,
            value: i.value,
          };
        }),
      };
    }); */
  }, [reportData]);

  const productionData = useMemo(() => {
    if (!reportData.production.rows[0].rows) {
      return {};
    }
    let list = {};
    reportData.production.rows.forEach((item, index) => {
      item.rows.forEach((i) => {
        let current = {
          name: item.label,
          value: i.value,
        }; 
        if (index === 0) {
          list[i.label] = [current];
        } else {
          list[i.label].push(current);
        }
      });
    });
    return Object.keys(list).map((item) => {
      return {
        name: item,
        data: list[item],
      };
    });
  }, [reportData]);

  const getRef = (id) => reportMenu.find((i) => i.id === id)?.ref;
  const tooltipId1 = useId("tooltip");
  const tooltipId2 = useId("tooltip");
  const tooltipId3 = useId("tooltip");
  const calloutProps = { gapSpace: 0 };
  const tooltipStyles = {
    root: {
      display: "inline-flex",
      alignSelf: "flex-end",
    },
  };
  const tooltipProps = {
    styles: {
      content: {
        fontSize: 14,
        lineHeight: 19,
      },
    },
  };

  const handleDownload = () => {
    const element = document.querySelector("#sustainability-report-wrapper");
    // html2canvas(element, {
    //   windowWidth: element.scrollWidth,
    //   windowHeight: element.scrollHeight,
    // }).then((canvas) => {
    //   const imgData = canvas.toDataURL("image/png");
    //   const pdf = new jsPDF("p", "pt", "a4");
    //   const imgWidth = pdf.internal.pageSize.width + 100;
    //   const pageHeight = pdf.internal.pageSize.height;
    //   let position = 0;
    //   const imgHeight = (canvas.height * imgWidth) / canvas.width;
    //   let heightLeft = imgHeight;
    //   pdf.addImage(
    //     imgData,
    //     "PNG",
    //     0,
    //     position,
    //     imgWidth,
    //     imgHeight,
    //     "",
    //     "FAST"
    //   );
    //   heightLeft -= pageHeight;
    //   while (heightLeft >= 0) {
    //     position = heightLeft - imgHeight;
    //     pdf.addPage();
    //     pdf.addImage(
    //       imgData,
    //       "PNG",
    //       0,
    //       position,
    //       imgWidth,
    //       imgHeight,
    //       "",
    //       "FAST"
    //     );
    //     heightLeft -= pageHeight;
    //   }
    //   // pdf.html(document.querySelector(".sustainability-report-learn-more"), {x: 0, y: 0});
    //   pdf.save("download.pdf");
    // });

    // const hhtml = (<div style={{width: `${}`, height: ``}}>
    //     Lorem ipsum dolor sit amet consectetur adipisicing elit. Tempora esse quaerat adipisci mollitia quia saepe magnam cupiditate illum! Voluptate cum repellat error incidunt quaerat consequuntur sed eligendi, unde facere cumque.
    //   </div>);

    html2canvas(element, {
      width: element.scrollWidth,
      height: element.scrollHeight,
    }).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF("p", "pt", [
        element.scrollWidth,
        element.scrollHeight,
      ]);
      pdf.addImage(
        imgData,
        "PNG",
        0,
        0,
        pdf.internal.pageSize.width,
        pdf.internal.pageSize.height,
        "",
        "FAST"
      );
      // pdf.textWithLink("link", 25, 25, {
      //   url: "https://www.microsoft.com/en-us/download/details.aspx?id=11691",
      // });
      pdf.save("sustainability-report.pdf");
    });
  };

  return (
    <>
    <div
      className={`${completeReportStyles.container} sustainability-report-wrapper`}
    >
      <div
        className={`${completeReportStyles.mobileHeaderWrapper} sustainability-report-header-wrapper`}
      >
        <Stack
          horizontal
          className={completeReportStyles.mobileBackWrapper}
          onClick={() => {
            setShowCompleteReport(false)
          }}
        >
          <img alt="back" src={BackIcon} tabIndex={0} />
          <span>Back to Edit</span>
        </Stack>
        <img
          alt="gasoline"
          src="/images/menu.svg"
          className={`${completeReportStyles.menuIcon} sustainability-report-menu-icon`}
          tabIndex={0}
          onClick={() => setShowMenu(true)}
        />
      </div>
      <Panel
        isOpen={showMenu}
        onDismiss={() => setShowMenu(false)}
        // You MUST provide this prop! Otherwise screen readers will just say "button" with no label.
        closeButtonAriaLabel="Close"
        className="sustainability-report-mobile-menu-wrapper"
      >
        {reportMenu.map((item) => (       
          <div
            key={item.id}
            onClick={() => {
              setShowMenu(false);
              handleMenuItemClick(item.ref);
            }}
            className={`${reportContentStyles.mobileMenuItem} ${
              item.id === activeMenuItem
                ? reportContentStyles.mobileActiveMenuItem
                : ""
            }`}
            tabIndex={0}
            onKeyDown={(event) => {
              if (event.key === "Enter") {
                setShowMenu(false);
                handleMenuItemClick(item.ref);
              }
            }}
          >
            {item.value}
          </div>
        ))}
      </Panel>
      <div
        id="sustainability-report-wrapper"
        className={`${reportContentStyles.contentWrapper} sustainability-report-content-wrapper`}
      >
        <Stack
          horizontal
          className={`${reportContentStyles.titleWrapper} sustainability-report-title-wrapper`}
        >
          <h1 className={reportContentStyles.title} tabIndex={0}>
            Microsoft Surface Emissions Estimator Report
          </h1>
          <div className={`${reportContentStyles.chatbotButtonContainer}`}>
            <DefaultButton
              text="Export to PDF"
              onClick={() => {
                setShowCompleteReport(false);
                handleDownload();
              }}
              className={`${reportContentStyles.backButton} mobile-hide`}
              data-html2canvas-ignore="true"
            />
          </div>
        </Stack>
        {/* <Stack
          horizontal
          className={`${reportContentStyles.menuWrapper} sustainability-report-menu-wrapper`}
        >
          {reportMenu.map((item) => (
            <span
              key={item.id}
              className={`${reportContentStyles.menuItem} ${
                item.id === activeMenuItem
                  ? reportContentStyles.activeMenuItem
                  : ""
              }`}
              tabIndex={0}
              onClick={() => handleMenuItemClick(item.ref)}
              onKeyDown={(event) => {
                if (event.key === "Enter") {
                  handleMenuItemClick(item.ref);
                }
              }}
            >
              {item.value}
            </span>
          ))}
        </Stack> */}
        <section className={reportContentStyles.sectionWrapper}>
          <h2
            className={`${reportContentStyles.sectionTitle} ${reportContentStyles.moreMarginTitle} mobile-hide`}
            ref={getRef("overall")}
            tabIndex={0}
          >
            Overall Summary
          </h2>
          <Stack
            horizontal
            className={`${overallSectionStyles.sectionWrapper} sustainability-report-overall-section-wrapper`}
          >
            <Stack
              className={`${overallSectionStyles.totalEmissionWrapper} sustainability-report-overall-total-emission`}
            >
              <span
                className={`${overallSectionStyles.totalEmissionLabel} sustainability-report-overall-total-emission-label`}
                tabIndex={0}
              >
                Total Estimated CO2 Emissions of Your Selections
              </span>
              <span
                className={`${overallSectionStyles.totalEmissionValue} sustainability-report-overall-total-emission-value`}
                tabIndex={0}
              >
                {kNumFormat(reportData.totalCO2Emissions, true)}
              </span>
              <span
                className={`${overallSectionStyles.totalEmissionUnit} sustainability-report-overall-total-emission-unit`}
                tabIndex={0}
              >
                Metric Tons CO<sub>2</sub>
                {` `}
                <span
                  className={`${overallSectionStyles.totalEmissionSmallUnit}`}
                >
                  (MTCO<sub>2</sub>)
                </span>
              </span>
            </Stack>
            <Stack
              className={`${overallSectionStyles.overallChart} sustainability-report-overall-chart`}
            >
              <ReactEcharts
                options={SIMPLE_REPORT_CHART_OPTIONS(reportData)}
                width="36rem"
                height="21.75rem"
                onChartReadyCallback={(chart) => {
                  chart.on("click", function (params) {
                    const ref = reportMenu.find((i) =>
                      i.value.includes(params.name)
                    )?.ref;
                    if (ref) {
                      handleMenuItemClick(ref);
                    }
                  });
                }}
              />
            </Stack>
            <Stack
              horizontal
              className={`${overallSectionStyles.scopeWrapper} sustainability-report-overall-scope-wrapper`}
            >
              <Stack
                className={`${overallSectionStyles.scopeItemWrapper} ${overallSectionStyles.divider}`}
              >
                <Stack
                  horizontal
                  className={
                    isLargeNumber(reportData.scope2)
                      ? "two-line-value-and-unit"
                      : ""
                  }
                >
                  <span
                    className={`${overallSectionStyles.scopeItemValue} sustainability-report-scope-item-value`}
                    tabIndex={0}
                  >
                    {kNumFormat(reportData.scope2, true)}
                  </span>
                  <span
                    className={`${overallSectionStyles.scopeItemUnit} sustainability-report-scope-item-unit`}
                    tabIndex={0}
                  >
                    MTCO<sub>2</sub>e
                  </span>
                </Stack>
                <Stack
                  horizontal
                  className={overallSectionStyles.scopeItemLabelWrapper}
                >
                  <span
                    className={`${overallSectionStyles.scopeItemLabel} sustainability-report-scope-item-label`}
                    tabIndex={0}
                  >
                    Scope 2
                  </span>
                  <TooltipHost
                    content={SCOPE_DESCRIPTION.scope2.content}
                    id={tooltipId1}
                    calloutProps={calloutProps}
                    styles={tooltipStyles}
                    hostClassName="mobile-hide"
                    tooltipProps={tooltipProps}
                  >
                    <FontIcon
                      aria-describedby={tooltipId1}
                      iconName="Info"
                      tabIndex={0}
                      className={overallSectionStyles.scopeInfoIcon}
                    />
                  </TooltipHost>
                </Stack>
              </Stack>
              <Stack className={overallSectionStyles.scopeItemWrapper}>
                <Stack
                  horizontal
                  className={
                    isLargeNumber(reportData.scope3)
                      ? "two-line-value-and-unit"
                      : ""
                  }
                >
                  <span
                    className={`${overallSectionStyles.scopeItemValue} sustainability-report-scope-item-value`}
                    tabIndex={0}
                  >
                    {kNumFormat(reportData.scope3, true)}
                  </span>
                  <span
                    className={`${overallSectionStyles.scopeItemUnit} sustainability-report-scope-item-unit`}
                    tabIndex={0}
                  >
                    MTCO<sub>2</sub>e
                  </span>
                </Stack>
                <Stack
                  horizontal
                  className={overallSectionStyles.scopeItemLabelWrapper}
                >
                  <span
                    className={`${overallSectionStyles.scopeItemLabel} sustainability-report-scope-item-label`}
                    tabIndex={0}
                  >
                    Scope 3
                  </span>
                  <TooltipHost
                    content={SCOPE_DESCRIPTION.scope3.content}
                    id={tooltipId2}
                    calloutProps={calloutProps}
                    styles={tooltipStyles}
                    hostClassName="mobile-hide"
                    tooltipProps={tooltipProps}
                  >
                    <FontIcon
                      aria-describedby={tooltipId2}
                      iconName="Info"
                      tabIndex={0}
                      className={overallSectionStyles.scopeInfoIcon}
                    />
                  </TooltipHost>
                </Stack>
              </Stack>
            </Stack>
            <Stack
              horizontal
              className={`${overallSectionStyles.equivalentWrapper} sustainability-report-overall-equivalent-wrapper`}
            >
              <span
                className={`${overallSectionStyles.equivalentLabel} sustainability-report-equivalent-label`}
                tabIndex={0}
              >
                Equivalent to
              </span>
              <div>
                {equivalentList.map((item) => (
                  <Stack
                    horizontal
                    key={item.id}
                    className={`${overallSectionStyles.dataList} sustainability-report-equivalent-data-list`}
                  >
                    {item.icon}
                    <span
                      className={`${overallSectionStyles.dataListValue} sustainability-report-equivalent-value`}
                      tabIndex={0}
                    >
                      {convertNumberToString(reportData.equivalentTo[item.id])}
                    </span>
                    <span
                      className={`${overallSectionStyles.dataListDes} sustainability-report-equivalent-description`}
                      tabIndex={0}
                    >
                      {item.description}
                    </span>
                  </Stack>
                ))}
              </div>
            </Stack>
          </Stack>
          <div
            className={`${overallSectionStyles.moreInfoText} sustainability-report-more-info-text`}
          >
            <p>
              The Surface Emissions Estimator provides you with a report that
              includes estimates of carbon emissions. These estimates are based
              on a Life Cycle Assessment (LCA) in accordance with ISO 14040 and
              ISO 14044. The Life Cycle Inventory (LCI) data is based on
              Microsoft's own measurements, collected from suppliers, along with
              industry standard and internationally available LCI databases.
            </p>
            <p>
              The Emissions Estimator report provided to you is for
              informational purposes only. You should not interpret the report
              you receive to be a commitment on the part of Microsoft; actual
              emissions may vary based on your location, purchase method, usage,
              and other factors. Microsoft does not make any representations or
              warranties, express or implied, as to the information within this
              website and the generated report.
            </p>
          </div>
          <MobileTooltipAccordion
            scope2={kNumFormat(reportData.scope2, true)}
            scope3={kNumFormat(reportData.scope3, true)}
          />
        </section>
        {
          (!reportData.emissionBySurfaceModel.selectedTotal && !reportData.emissionBySurfaceModel.previousTotal && !reportData.emissionBySurfaceModel.savings) ?
          <span  ref={getRef("emissions")}> </span> :  
          <section className={reportContentStyles.sectionWrapper}>
            <h2
              className={reportContentStyles.sectionTitle}
              ref={getRef("emissions")}
              tabIndex={0}
            >
              Emission Estimations by Selected Surface Models vs. Previous
              Generation Devices
            </h2>
            {/* <ReadMore
              className={`${reportContentStyles.readmore} sustainability-report-readmore`}
            > */}
              <p>
                We are committed to reducing the carbon intensity of our devices
                and have made significant investments to expand our data-driven
                lifecycle assessment (LCA) and telemetry approach to better
                measure, inform, and prioritize top reduction opportunities to
                reduce our device carbon intensity across the full lifecycle of
                our devices.
              </p>
              <p>
                These efforts include carbon-conscious design, reducing supply
                chain emissions, innovating energy-efficient hardware and software
                in use, and enabling product repairability, reusability, and
                recyclability. This allows us to deliver the best product
                experience with lower carbon intensity, while also partnering with
                our users to minimize carbon footprint when using our products.
              </p>
              <p className={reportContentStyles.marginBottom}>
                Actual emissions may vary from estimates. For more information,
                please see the methodology section.
              </p>
            {/* </ReadMore> */}
            <Stack
              horizontal
              className={`${emissionStyles.container} sustainability-report-emissions-wrapper`}
            >
              <div
                className={`${emissionStyles.left} sustainability-report-emissions-left`}
              >
                <Stack className={emissionStyles.box}>
                  <Stack
                    horizontal
                    className={
                      isLargeNumber(
                        reportData.emissionBySurfaceModel.selectedTotal
                      )
                        ? "two-line-value-and-unit"
                        : ""
                    }
                  >
                    <span
                      className={`${emissionStyles.value} sustainability-report-emissions-left-value`}
                      tabIndex={0}
                    >
                      {kNumFormat(
                        reportData.emissionBySurfaceModel.selectedTotal,
                        true
                      )}
                    </span>
                    <span
                      className={`${emissionStyles.unit} sustainability-report-emissions-left-unit`}
                      tabIndex={0}
                    >
                      MTCO<sub>2</sub>
                    </span>
                  </Stack>
                  <span
                    className={`${emissionStyles.description} sustainability-report-emissions-left-description`}
                    tabIndex={0}
                  >
                    Estimated Emissions From Your Selected Devices
                  </span>
                </Stack>
                <Stack className={emissionStyles.box}>
                  <Stack
                    horizontal
                    className={
                      isLargeNumber(
                        reportData.emissionBySurfaceModel.previousTotal
                      )
                        ? "two-line-value-and-unit"
                        : ""
                    }
                  >
                    <span
                      className={`${emissionStyles.value} sustainability-report-emissions-left-value`}
                      tabIndex={0}
                    >
                      {kNumFormat(
                        reportData.emissionBySurfaceModel.previousTotal,
                        true
                      )}
                    </span>
                    <span
                      className={`${emissionStyles.unit} sustainability-report-emissions-left-unit`}
                      tabIndex={0}
                    >
                      MTCO<sub>2</sub>
                    </span>
                  </Stack>
                  <span
                    className={`${emissionStyles.description} sustainability-report-emissions-left-description`}
                    tabIndex={0}
                  >
                    Estimated Emissions from Comparable Earlier Generation Devices
                  </span>
                </Stack>
                <Stack className={emissionStyles.box}>
                  <Stack
                    horizontal
                    className={
                      isLargeNumber(reportData.emissionBySurfaceModel.savings)
                        ? "two-line-value-and-unit"
                        : ""
                    }
                  >
                    <span
                      className={`${emissionStyles.value} sustainability-report-emissions-left-value`}
                      tabIndex={0}
                    >
                      {kNumFormat(reportData.emissionBySurfaceModel.savings, true)}
                    </span>
                    <span
                      className={`${emissionStyles.unit} sustainability-report-emissions-left-unit`}
                      tabIndex={0}
                    >
                      MTCO<sub>2</sub>
                    </span>
                  </Stack>
                  <Stack
                    horizontal
                    className={`${emissionStyles.description} sustainability-report-emissions-left-description`}
                  >
                    <span tabIndex={0}>Estimated Emissions Reduced</span>
                    <TooltipHost
                      content="Metric Tonnes CO2 in Total Emissions Saved"
                      id={tooltipId3}
                      calloutProps={calloutProps}
                      styles={tooltipStyles}
                      tooltipProps={tooltipProps}
                    >
                      <FontIcon
                        aria-describedby={tooltipId3}
                        iconName="Info"
                        tabIndex={0}
                        className={overallSectionStyles.scopeInfoIcon}
                      />
                    </TooltipHost>
                  </Stack>
                </Stack>
              </div>
              <div
                className={`${emissionStyles.right} sustainability-report-emissions-right`}
              >
                {emissionChartData.map((item, index) => {
                  
                  return (
                    
                    <Stack
                      horizontal
                      className={`${emissionStyles.itemWrapper} sustainability-report-emissions-item-wrapper`}
                      key={index}
                    >
                      <div
                        className={`${emissionStyles.chartWrapper} sustainability-report-emissions-chart-wrapper`}
                      >
                        <ReactEcharts
                          options={HORIZONTAL_STACKED_BAR_CHART_OPTIONS(
                            item.chartData, null, null, chatbotExpanded, [comparisonsProductLabels[index], comparisonsProductLabels[index]]
                          )}
                          width="100%"
                          height={`${6.25 * 2}rem`}
                        />
                      </div>
                      <Stack
                        className={`${emissionStyles.descriptionWrapper} sustainability-report-emissions-chart-description`}
                      >
                        <Stack
                          horizontal
                          className={
                            isLargeNumber(item.savings)
                              ? "two-line-value-and-unit"
                              : ""
                          }
                        >
                          <span className={emissionStyles.desValue} tabIndex={0}>
                            {`${item.savings > 0 ? "+" : ""}${kNumFormat(
                              item.savings, true
                            )}`}
                          </span>
                          <span
                            className={`${emissionStyles.desUnit} sustainability-report-emissions-chart-des-unit`}
                            tabIndex={0}
                          >
                            MTCO<sub>2</sub>
                          </span>
                        </Stack>
                        <span className={emissionStyles.desText} tabIndex={0}>
                          {item.savings < 0 ? "Reductions" : "Increase"}
                        </span>
                      </Stack>
                    </Stack>
                  );
                })}
                <Stack
                  horizontal
                  className={`${emissionStyles.legendWrapper} sustainability-report-legend-wrapper`}
                >
                  {reportData.emissionBySurfaceModel.comparisons.map(
                    (item, idx) => {
                      return (
                        <Stack
                          horizontal
                          key={idx}
                          className={`${emissionStyles.legendItemWrapper} sustainability-report-legend-item-wrapper`}
                        >
                          <TwoColorCircle
                            fill1={SUS_REPORT_CHART_COLOR.emissions[1][idx]}
                          />
                          <span tabIndex={0}>{item.label}</span>
                        </Stack>
                      );
                    }
                  )}
                </Stack>
              </div>
            </Stack>
          </section>
        }
        <section className={reportContentStyles.sectionWrapper}>
          <h2
            className={reportContentStyles.sectionTitle}
            ref={getRef("production")}
            tabIndex={0}
          >
            Production Estimates
            <TooltipHost
              content="This calculator classifies Production Emissions as Scope 3. Scope 3 emissions are all indirect emissions (not included in scope 2) that occur in the value chain of the reporting company, including both upstream and downstream emissions."
              id="production-tooltip"
              calloutProps={calloutProps}
              styles={tooltipStyles}
              tooltipProps={tooltipProps}
            >
              <FontIcon
                aria-describedby="production-tooltip"
                iconName="Info"
                tabIndex={0}
                className={reportContentStyles.infoIcon}
              />
            </TooltipHost>
          </h2>
          {/* <ReadMore
            className={`${reportContentStyles.readmore} sustainability-report-readmore`}
          > */}
            <p>
              We estimate carbon emissions from materials and production based
              on a Lifecycle Assessment, the system boundaries for which include
              extraction of raw materials, upstream materials preparation and
              packaging, electronic component manufacturing and packaging,
              subassembly manufacturing and assembly, and final assembly.
            </p>
            <p className={`${reportContentStyles.marginBottom}`}>
              Actual emissions may vary from estimates. For more information,
              please see the methodology section.
            </p>
          {/* </ReadMore> */}
          {compareChart(reportData.production, "production")}
        </section>
        <section className={reportContentStyles.sectionWrapper}>
          <h2
            className={reportContentStyles.sectionTitle}
            ref={getRef("packaging")}
            tabIndex={0}
          >
            Packaging Estimates
            <TooltipHost
              content="This calculator classifies Packaging Emissions as Scope 3. Scope 3 emissions are all indirect emissions (not included in scope 2) that occur in the value chain of the reporting company, including both upstream and downstream emissions."
              id="packaging-tooltip"
              calloutProps={calloutProps}
              styles={tooltipStyles}
              tooltipProps={tooltipProps}
            >
              <FontIcon
                aria-describedby="packaging-tooltip"
                iconName="Info"
                tabIndex={0}
                className={reportContentStyles.infoIcon}
              />
            </TooltipHost>
          </h2>
          {/* <ReadMore
            className={`${reportContentStyles.readmore} sustainability-report-readmore`}
          > */}
            <p>
              We estimate carbon emissions from packaging based on the specially
              designed packaging for commercial customers. First rolled out in
              fiscal year 2020, this commercial packaging platform reduced
              weight and greenhouse gas emissions (GHG) while increasing
              recycled content and recyclability.
            </p>
            <p className={`${reportContentStyles.marginBottom}`}>
              Actual emissions may vary from estimates. For more information,
              please see the methodology section.
            </p>
          {/* </ReadMore> */}
          {compareChart(reportData.packaging, "packaging")}
        </section>
        <section className={reportContentStyles.sectionWrapper}>
          <h2
            className={reportContentStyles.sectionTitle}
            ref={getRef("transport")}
            tabIndex={0}
          >
            Transportation Estimates
            <TooltipHost
              content="This calculator classifies Transportation Emissions as Scope 3. Scope 3 emissions are all indirect emissions (not included in scope 2) that occur in the value chain of the reporting company, including both upstream and downstream emissions."
              id="transport-tooltip"
              calloutProps={calloutProps}
              styles={tooltipStyles}
              tooltipProps={tooltipProps}
            >
              <FontIcon
                aria-describedby="transport-tooltip"
                iconName="Info"
                tabIndex={0}
                className={reportContentStyles.infoIcon}
              />
            </TooltipHost>
          </h2>
          {/* <ReadMore
            className={`${reportContentStyles.readmore} sustainability-report-readmore`}
          > */}
            <p>
              We estimate carbon emissions from transportation based on the
              user's input of device and country location by averaging
              historical data that considers shipment weight, transportation
              pathway distance, transport modalities, and defined emissions
              factors. These calculations are based on the Global Logistics
              Emissions Council (GLEC) framework.
            </p>
            <p className={`${reportContentStyles.marginBottom}`}>
              Actual emissions may vary from estimates. For more information,
              please see the methodology section.
            </p>
          {/* </ReadMore> */}
          {compareChart(reportData.transport, "transport")}
        </section>
        <section className={reportContentStyles.sectionWrapper}>
          <h2
            className={reportContentStyles.sectionTitle}
            ref={getRef("productUse")}
            tabIndex={0}
          >
            Product Use Estimates
            <TooltipHost
              content="This calculator classifies Product Usage Emissions as Scope 3. Scope 2 emissions are indirect emissions from the generation of purchased energy."
              id="product-use-tooltip"
              calloutProps={calloutProps}
              styles={tooltipStyles}
              tooltipProps={tooltipProps}
            >
              <FontIcon
                aria-describedby="product-use-tooltip"
                iconName="Info"
                tabIndex={0}
                className={reportContentStyles.infoIcon}
              />
            </TooltipHost>
          </h2>
          {/* <ReadMore
            className={`${reportContentStyles.readmore} sustainability-report-readmore`}
          > */}
          <p>
            We estimate carbon emissions associated with product usage based on
            two elements:
          </p>
            <ul>
              <li style={{ marginBottom: "1rem" }}>
                Product Energy Use is based on usage data. We analyze the usage
                data to construct average or typical use profiles.{" "}
                <i>
                  For comparison, we also provide ENERGY STAR test and max limit
                  values alongside data from Windows users. These values are
                  based on the United States' Environmental Protection Agency's
                  Energy Star standards.
                </i>
              </li>
              <li>
                Country-based factors for energy source and grids, based on
                publicly available and reported data. These grid factors and the
                calculations are provided below.
              </li>
            </ul>
            <p className={`${reportContentStyles.marginBottom}`}>
              Actual emissions may vary from estimates. For more information,
              please see the methodology section.
            </p>
          {/* </ReadMore> */}
          {compareChart(reportData.productUse, "productUse")}
        </section>
        <section className={reportContentStyles.sectionWrapper}>
          <h2
            className={reportContentStyles.sectionTitle}
            ref={getRef("endOfLife")}
            tabIndex={0}
          >
            End of Life Estimates
            <TooltipHost
              content="This calculator classifies End of Life Emissions as Scope 3. Scope 3 emissions are all indirect emissions (not included in scope 2) that occur in the value chain of the reporting company, including both upstream and downstream emissions."
              id="end-of-life-tooltip"
              calloutProps={calloutProps}
              styles={tooltipStyles}
              tooltipProps={tooltipProps}
            >
              <FontIcon
                aria-describedby="end-of-life-tooltip"
                iconName="Info"
                tabIndex={0}
                className={reportContentStyles.infoIcon}
              />
            </TooltipHost>
          </h2>
          {/* <ReadMore
            className={`${reportContentStyles.readmore} sustainability-report-readmore`}
          > */}
            <p>
              <i>
                We estimate carbon emissions associated with disposal of devices
              </i>
              {` `}
              based on publicly available waste statistics from EuroStat (for
              devices used in Europe) and the United States Environmental
              Protection Agency (for devices used in the United States and the
              rest of the world).
            </p>
            <p>
              <i>
                Surface is investing in repairability and serviceability to
                reduce waste.
              </i>
              {` `}
              In 2021, our Design for Repair engineering program helped launch
              the Surface Pro 8, the Surface Laptop Studio, and Surface Laptop
              SE, which are considered to be some of the most repairable devices
              in their product lines with replaceable displays, batteries,
              keyboards, and more.
            </p>
            <p className={`${reportContentStyles.marginBottom}`}>
              Actual emissions may vary from estimates. For more information,
              please see the methodology section.
            </p>
          {/* </ReadMore> */}
          {compareChart(reportData.endOfLife, "endOfLife")}
        </section>
        <section className={reportContentStyles.sectionWrapper}>
          <h2
            className={`${reportContentStyles.sectionTitle} ${reportContentStyles.moreMarginTitle}`}
            ref={getRef("portfolioOverview")}
            tabIndex={0}
          >
            <Stack>
              Portfolio Overview / Total Emission Estimates per Device
            </Stack>
            {/* <TooltipHost
              content="This calculator assumes a device usage of 3 years."
              id="portfolio-overview-tooltip"
              calloutProps={calloutProps}
              styles={tooltipStyles}
              tooltipProps={tooltipProps}
            >
              <FontIcon
                aria-describedby="portfolio-overview-tooltip"
                iconName="Info"
                tabIndex={0}
                className={reportContentStyles.infoIcon}
              />
            </TooltipHost> */}
          </h2>
          <div
            className={`${reportContentStyles.portfolioOverviewWrapper} sustainability-report-portfolio-overview-wrapper`}
          >
            <div className={reportContentStyles.portfolioOverviewChartWrapper}>
              <ReactEcharts
                options={HORIZONTAL_STACKED_BAR_CHART_OPTIONS(
                  portfolioOverviewData,
                  SUS_REPORT_CHART_COLOR.portfolioOverview,
                  true, false, formattedProductLabels
                )}
                width="100%"
                height={`${barChartHeight}rem`}
              />
            </div>
            <Stack
              horizontal
              className={`${emissionStyles.legendWrapper} sustainability-report-legend-wrapper`}
            >
              {reportData.emissionBySurfaceModel.comparisons.map(
                (item, idx) => {
                  return (
                    <Stack
                      horizontal
                      key={idx}
                      className={`${emissionStyles.legendItemWrapper} sustainability-report-legend-item-wrapper`}
                    >
                      <TwoColorCircle
                        fill1={
                          SUS_REPORT_CHART_COLOR.portfolioOverview.bar[idx]
                        }
                      />
                      <span tabIndex={0}>{item.label}</span>
                    </Stack>
                  );
                }
              )}
            </Stack>
          </div>
        </section>
        <Stack
          className={`${reportContentStyles.learnMoreWrapper} sustainability-report-learn-more`}
        >
          <h3 className={reportContentStyles.learnMoreTitle} tabIndex={0}>
            Learn More
          </h3>
          <div className={reportContentStyles.learnMoreContent} tabIndex={0}>
            For more information, please review our{" "}
            <a
              href="https://www.microsoft.com/en-us/download/details.aspx?id=55974 "
              rel="noreferrer"
              target="_blank"
            >
              Surface Eco Profiles
            </a>{" "}
            or go to our{" "}
            <a
              href="https://www.microsoft.com/en-us/sustainability/surface"
              rel="noreferrer"
              target="_blank"
            >
              Sustainability Page
            </a>{" "}
            to learn more.
          </div>
        </Stack>
        <section className={reportContentStyles.sectionWrapper}>
          <h2
            className={reportContentStyles.sectionTitle}
            ref={getRef("methodology")}
            tabIndex={0}
          >
            Methodology
          </h2>
          <span
            className={`${reportContentStyles.methodologyIntro} sustainability-report-methodology-intro`}
          >
            Microsoft is currently working to update the methodology it uses to
            conduct Lifecycle Assessments in order to incorporate more specific
            data from its suppliers. The estimates provided by this tool may
            change as a result.
          </span>
          <MethodologyPage />
        </section>
        <DefaultButton
          text="Back to edit"
          onClick={() => {
            setShowCompleteReport(false)
          }}
          className={`${reportContentStyles.mobileBackButton} mobile-display-only`}
          data-html2canvas-ignore="true"
        />
        {/* <BackToTop /> */}
      </div>
      {/* <DefaultButton text="Save as PDF" onClick={handleDownload} /> */}
    </div>
    </>
  );
};

export default SustainabilityReport;
