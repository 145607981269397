import React, { useEffect, useState, useContext, useRef, useMemo } from "react";
import {
  Stack,
  Dropdown,
  TextField,
  FontIcon,
  mergeStyleSets,
  TooltipHost,
  PrimaryButton,
  Spinner,
  Modal,
  SpinnerSize,
  ComboBox
} from "@fluentui/react";

import { useId } from "@fluentui/react-hooks";
import { v4 as uuid } from "uuid";
import _ from "lodash";
import ReactEcharts from "components/ReactEcharts";
import { AlertContext } from "components/Alert";
import {
  convertStringToNumber,
  sendRequest,
  isPositiveInteger,
  formatNumber,
  isObjectEmpty,
  kNumFormat,
  isSmallWidthScreen,
  isLargeNumber,
  convertNumberToString,
} from "utils/helpers";
import {
  SIMPLE_REPORT_CHART_OPTIONS,
  SCOPE_DESCRIPTION,
  QUANTITY_LIMIT,
  TOOLTIP_CONTENT,
  COMPARE_HORIZONTAL_CHART_OPTIONS,
  HORIZONTAL_STACKED_BAR_CHART_OPTIONS,
  SUS_REPORT_CHART_COLOR,
} from "constants/sustainability";
import TwoPartLayout from "components/TwoPartLayout";
import SustainabilityReport from "./SustainabilityReport";
import LearnMore from "./components/LearnMore";
import SelectionBoxList from "components/SelectionBoxList";
import laptopIcon from "assets/laptop.svg";
import ChatbotIcon from "assets/chatbot-icon.PNG";
import MobileTooltipAccordion from "./components/MobileTooltipAccordion";
import { useBoolean } from '@fluentui/react-hooks';
import "./SustainabilityCalculator.scss";
import SEEChatbot from "./chatbot/SEEChatbot";
import TwoColorCircle from "components/TwoColorCircle";
import MethodologyPage from "components/MethodologyPage";

const SustainabilityCalculator = () => {
  const [productList, setProductList] = useState([
    { id: uuid() },
    // { id: uuid() },
    // { id: uuid() },
  ]);
  const [errorList, setErrorList] = useState({ initial: true });
  const [simpleReportData, setSimpleReportData] = useState({ key: uuid() });
  const [completeReportData, setCompleteReportData] = useState({ key: uuid() });
  const [countryOptions, setCountryOptions] = useState([]);
  const [productOptions, setProductOptions] = useState([]);
  const [productRefOptions, setProductRefOptions] = useState([]);
  const [showCompleteReport, setShowCompleteReport] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [chartTooltip, setChartTooltip] = useState("");
  const { setAlert } = useContext(AlertContext);
  const [isHideIntroText, setIsHideIntroText] = useState(false);
  const MAX_CARDS = 50;
  const MAX_DEVICES = 8;
  const MAX_COUNTRIES = 10;
  const [isShowMaxCardsModal, setIsShowMaxCardsModal] = useState(false);
  const [isShowMaxDevicesModal, setIsShowMaxDevicesModal] = useState(false);
  const [isShowMaxCountriesModal, setIsShowMaxCountriesModal] = useState(false);
  const MAX_MSG_TITLE = 'Error Message'
  
  const [showMethodology, { toggle: toggleShowMethodology }] = useBoolean(false);
  const [openChatbot, { toggle: toggleOpenChatbot }] = useBoolean(false);
  const [blockChatbotUsage, { toggle: toggleBlockChatbotUsage }] = useBoolean(false);
  const [isShowFirstTimeModalChatbot, setIsShowFirstTimeModalChatbot] = useState(false);
  const [openCompleteReport, setOpenCompleteReport] = useState(false);

  const detailedChartEndRef = useRef(null);

  useEffect(() => {
    document.title = "Microsoft Surface Emissions Estimator";

    sendRequest("POST", `api/sus-config`).then(
      (data) => {
        if (data.error) {
          setAlert("error", data.error.message);
        }
        if (data.value) {
          const { countries, products, frontendProducts } = data.value;
          const countryList = countries
            .map((item) => {
              return {
                key: item.sus_country_code,
                text: item.sus_country_name,
              };
            })
            .sort((a, b) => a.text.localeCompare(b.text));
            if(frontendProducts) {
              const productList = frontendProducts.map((item) => {
                return {
                  key: item.sus_mplid,
                  text: item.sus_product_list,
                };
              });
            } else {
              const productList = [];
            }
          

          const productRefList = products.map((item) => {
            return {
              key: item.sus_mplid,
              text: item.sus_product_list,
            };
          });
          setCountryOptions(countryList);
          setProductOptions(productRefList);
          setProductRefOptions(productRefList);
        }
      }
    );

    //if (sessionStorage.getItem("HideIntroText") === "true") {
      setIsHideIntroText(true);
    //}

    const windowWidth = window.innerWidth;
    const handleResize = () => {
      if (window.innerWidth === windowWidth) return;
      setSimpleReportData((state) => {
        return {
          ...state,
          key: uuid(),
        };
      });
      setCompleteReportData((state) => {
        return {
          ...state,
          key: uuid(),
        };
      });
    };

    const calculatorElement = document.querySelector(
      ".sustainability-calculator-wrapper .sustainability-calculator-input-page"
    );
    const handleClick = (event) => {
      if (event.target.tagName !== "CANVAS") {
        setChartTooltip("");
      }
    };

    window.addEventListener("resize", _.debounce(handleResize, 500));
    if (calculatorElement) {
      calculatorElement.addEventListener("click", handleClick);
    }
    return () => {
      window.removeEventListener("resize", _.debounce(handleResize, 500));
      if (calculatorElement) {
        calculatorElement.removeEventListener("click", handleClick);
      }
    };
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [showCompleteReport]);

  const chartTooltipItems = ["Production", "Product Use", "Transport", "Packaging", "End of Life"];

  useEffect(() => {
    if(chartTooltipItems.includes(chartTooltip)){
      // detailedChartEndRef.current?.scrollIntoView({ behavior: 'smooth', block: 'end' });
      const element = document.getElementById("scroll-id");
      element.scrollIntoView({ behavior: 'smooth', block: 'end' });
    }
  }, [chartTooltip]);

  const bottomPartStyles = mergeStyleSets({
    button: {
      alignSelf: "flex-end",
      margin: "1.5rem 2.75rem",
      width: "12rem",
      height: "3.25rem",
      fontSize: "1rem",
      fontWeight: 600,
      lineHeight: "1.25rem",
      padding: 0,
      minHeight: "3.125rem",
    },
  });

  const bottomPart = (
    <PrimaryButton
      className={bottomPartStyles.button}
      onClick={() => {
        if (!isLoading) {
          getReport(productList, true);
        }
      }}
      disabled={
        !productList.filter((i) => i.product).length ||
        Object.values(errorList).filter((i) => {
          if (typeof i === "object") {
            return !isObjectEmpty(i);
          }
          return i;
        }).length > 0
      }
    >
      {isLoading ? (
        <Spinner size={SpinnerSize.small} />
      ) : (
        <span>View Full Report</span>
      )}
    </PrimaryButton>
  );

  const leftPartStyles = mergeStyleSets({
    container: {
      overflow: "auto",
    },
    title: {
      fontWeight: 400,
      fontSize: "2rem",
      lineHeight: "2.6875rem",
      margin: "1rem 3.5625rem 1.375rem 3.5625rem",
      textAlign: "center",
    },
    introText: {
      margin: "0 3.5625rem 1.5625rem",
      fontSize: "1rem",
      lineHeight: "1.5rem",
      fontStyle: "italic",
      position: "relative",
    },
    introTextCancelIcon: {
      position: "absolute",
      top: 0,
      right: "-1rem",
      fontSize: "0.9688rem",
      color: "#212121",
      cursor: "pointer",
    },
    contentWrapper: {
      padding: "0 2.5625rem",
      // height: "calc(100vh - 8rem)",
    },
    keyValueWrapper: {
      border: "1px solid #EDEBE9",
      borderRadius: "0.25rem",
      padding: "0.875rem 3.4rem 0.3562rem",
      textAlign: "center",
      marginBottom: "0.625rem",
    },
    key: {
      fontSize: "1.25rem",
      fontWeight: 400,
      lineHeight: "1.5rem",
      color: "#201F1E",
      marginBottom: "0.5625rem",
    },
    value: {
      fontSize: "3.3125rem",
      fontWeight: 600,
      lineHeight: "3.3125rem",
      color: "#0078D4",
      marginBottom: "0.5rem",
    },
    unit: {
      fontSize: "0.875rem",
      lineHeight: "1rem",
    },
    chartWrapper: {
      position: "relative",
      width: "100%",
      alignItems: "center",
    },
    scopeWrapper: {
      position: "absolute",
      top: 0,
      right: 0,
      border: "0.0625rem solid #EDEBE9",
      borderRadius: "0.25rem",
      padding: "0.6875rem 0.5rem",
      gap: "0.5rem",
      zIndex: 1,
    },
    scopeItemWrapper: {
      gap: "0.5rem",
      justifyContent: "space-between",
      alignItems: "center",
    },
    scopeItemName: {
      fontSize: "0.875rem",
      fontWeight: 400,
      alignItems: "flex-end",
      "> span": {
        marginRight: "0.5rem",
      },
    },
    scopeItemValue: {
      fontSize: "1rem",
      fontWeight: 600,
    },
    tooltipHost: {
      display: "flex",
      alignItems: "center",
    },
    infoIcon: {
      fontSize: "1rem",
    },
    chartTooltip: {
      margin: "2.5rem 0 3.75rem",
      fontSize: "0.875rem",
      lineHeight: "1.3125rem",
      backgroundColor: "#ffffff",
      padding: "0.5rem",
      borderRadius: "0.125rem",
      boxShadow: "0px 1.2px 3.6px rgba(0, 0, 0, 0.1)",
    },
    chartTooltipNew: {
      fontSize: "0.9rem",
      lineHeight: "1.3125rem",
      padding: "1rem",
      marginBottom: "2rem",
    },
    mobileTooltipAccordionWrapper: {
      alignSelf: "flex-start",
      width: "100%",
    },
    mobileTooltipAccordionTitle: {
      backgroundColor: "#ffffff",
      padding: "1.3rem 1rem",
    },
    mobileTooltipAccordionContent: {
      backgroundColor: "#ffffff",
      padding: " 0 1.6rem 1.6rem",
      fontSize: "1.4rem",
      lineHeight: "2.1rem",
    },
    moreDetailsContainer: {
      width: "100%",
      padding: "1.5rem",
      marginBottom: "1rem",
      background: "#ffffff",
      boxShadow: "0px 1.2px 3.6px rgba(0, 0, 0, 0.1)",
      boxSizing: "border-box",
    },
    moreDetailsTitle: {
      fontSize: "1.5rem",
      padding: "0 1rem",
      fontWeight: 500,
    }
  });

  const tooltipId1 = useId("tooltip1");
  const tooltipId2 = useId("tooltip2");
  const calloutProps = { gapSpace: 0 };
  const tooltipProps = {
    styles: {
      content: {
        fontSize: 14,
        lineHeight: 19,
      },
    },
  };



  // CODE FROM FULL REPORT. CAN BE OPTIMIZED AND RE USED. START

  const [formattedProductLabels, setFormattedProductLabels] = useState([]);

  useEffect(() => {
    let formattedProductList = [];
    let filteredProductList = [];
    let count = 0;
    if(productList && productList[0] && completeReportData && (Object.keys(completeReportData).length > 0)){
      productList.forEach((item) => {
        formattedProductList.push(`Country: ${item.location?.text || ""}, Quantity: ${item.quantity || ""}, Years: ${item.years?.text || 3}`);
        if(item.product?.text === completeReportData.emissionBySurfaceModel?.comparisons[0]?.selectedDetails[count]?.label){
          filteredProductList.push(`Country: ${item.location?.text || ""}, Quantity: ${item.quantity || ""}, Years: ${item.years?.text || 3}`);
          count++;
        }
      });
      setFormattedProductLabels(formattedProductList);
    }
  }, [productList, completeReportData]);

  const barChartHeight = completeReportData.production?.rows.length * 4.5 || 4.5;

  const reportMenu = [
    {
      id: "overall",
      value: "Overall Summary",
      ref: useRef(null),
    },
    {
      id: "emissions",
      value: "Emission Estimations by Selected...",
      ref: useRef(null),
    },
    {
      id: "production",
      value: "Production Estimates",
      ref: useRef(null),
      boxLabel: "Production",
    },
    {
      id: "packaging",
      value: "Packaging Estimates",
      ref: useRef(null),
      boxLabel: "Packaging",
    },
    {
      id: "transport",
      value: "Transportation Estimates",
      ref: useRef(null),
      boxLabel: "Transportation",
    },
    {
      id: "productUse",
      value: "Product Use Estimates",
      ref: useRef(null),
      boxLabel: "Product Use",
    },
    {
      id: "endOfLife",
      value: "End of Life Estimates",
      ref: useRef(null),
      boxLabel: "End-Of-Life",
    },
    {
      id: "portfolioOverview",
      value: "Portfolio Overview / Total Emission Estimates per Device",
      ref: useRef(null),
    },
    {
      id: "methodology",
      value: "Methodology",
      ref: useRef(null),
    },
  ];

  const productionData = useMemo(() => {
    if(!(Object.keys(completeReportData).length > 1)){
      return {};
    }
    if (!completeReportData.production.rows[0].rows) {
      return {};
    }
    let list = {};
    completeReportData.production.rows.forEach((item, index) => {
      item.rows.forEach((i) => {
        let current = {
          name: item.label,
          value: i.value,
        }; 
        if (index === 0) {
          list[i.label] = [current];
        } else {
          list[i.label].push(current);
        }
      });
    });
    return Object.keys(list).map((item) => {
      return {
        name: item,
        data: list[item],
      };
    });
  }, [completeReportData]);

  const compareChartStyles = mergeStyleSets({
    container: {
      gap: "1rem",
      flexGrow: 0,
      maxWidth: "99%",
      marginBottom: "1rem",
      paddingLeft: "3rem",
    },
    contentWrapper: {
      //border: "0.0625rem solid #EDEBE9",
      borderRadius: "0.25rem",
      maxWidth: "26.3125rem",
    },
    boxWrapper: {
      padding: "0 1rem 1rem 0",
      justifyContent: "center",
      alignItems: "center",
      minWidth: "16.625rem",
      maxWidth: "26.3125rem",
      textAlign: "center",
      "&.extra-padding": {
        padding: "1rem 1rem 2rem 0",
      }
    },
    label: {
      fontSize: "1rem",
    },
    value: {
      fontSize: "1.8rem",
      fontWeight: 600,
      lineHeight: "4.25rem",
      marginRight: "0.25rem",
      marginBottom: "0",
    },
    unit: {
      fontSize: "1rem",
      alignSelf: "flex-end",
      marginBottom: "0.7rem",
    },
    chartWrapper: {
      width: "75%",
      paddingLeft: "1rem",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
    },
    epaWrapper: {
      margin: "2.5rem 0 1.5rem",
      justifyContent: "flex-start",
    },
    productionChartWrapper: {
      gap: "2rem",
    },
    countryTable: {
      border: "0.0625rem solid #EDEBE9",
      borderRadius: "0.5rem",
    },
    countryTableTitle: {
      borderBottom: "0.0625rem solid #EDEBE9",
      boxSizing: "border-box",
      padding: "1rem 0",
      width: "100%",
      height: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      textAlign: "center",
    },
    countryTableContent: {
      display: "grid",
      gridTemplateColumns: "16.36% 27.21% 2.8% 18.36% 3.14% 31.39%",
      justifyItems: "center",
      alignItems: "center",
      gridAutoFlow: "row",
      fontSize: "0.85rem",
    },
    greyBackground: {
      width: "100%",
      height: "100%",
      backgroundColor: "#FAF9F8",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      position: "relative",
      textAlign: "center",
      padding: "1rem 0",
      boxSizing: "border-box",
    },
    withBorder: {
      ":after": {
        content: "''",
        width: "2.5rem",
        height: "5px",
        transform: "scale(1, 0.1)",
        backgroundColor: "#A19F9D",
        position: "absolute",
        bottom: 0,
      },
    },
    green: {
      color: "#107C10",
    },
    multiple: {
      gridColumn: "3",
      gridRow: `2 / ${(completeReportData?.productUse?.countryRows?.length || 0) + 2}`,
      fontSize: "1.1rem",
    },
    equal: {
      gridColumn: "5",
      gridRow: `2 / ${(completeReportData?.productUse?.countryRows?.length || 0) + 2}`,
      fontSize: "1.6rem",
    },
    horizontalStack: {
      flexDirection: "row",
      ">span:last-child": {
        marginBottom: "0",
        marginTop: "0.6rem",
        // alignSelf: "flex-end !important",
      }
    }
  });

  const emissionStyles = mergeStyleSets({
    legendWrapper: {
      alignItems: "center",
      gap: "1rem",
      flexWrap: "wrap",
      marginTop: "1.5rem",
    },
    legendItemWrapper: {
      alignItems: "center",
      gap: "0.875rem",
      fontSize: "0.9rem",
      lineHeight: "1.5rem",
      fontWeight: 400,
    },
  });

  const compareChart = (data, id) => {
    const name = reportMenu.find((i) => i.id === id).boxLabel;
    return (
      <Stack
        horizontal
        className={`${compareChartStyles.container} sustainability-report-compare-chart-wrapper`}
      >
        <Stack
          className={`${compareChartStyles.contentWrapper} sustainability-report-compare-chart-left`}
        >
          <Stack className={compareChartStyles.boxWrapper}>
            <Stack
              horizontal
              className={
                isLargeNumber(data.value) ? "two-line-value-and-unit" : ""
              }
            >
              <span
                className={`${compareChartStyles.value} sustainability-report-compare-chart-left-value`}
                tabIndex={0}
              >
                {kNumFormat(data.value, true)}
              </span>
              <span
                className={`${compareChartStyles.unit} sustainability-report-compare-chart-left-unit`}
                tabIndex={0}
              >
                MTCO<sub>2</sub>
              </span>
            </Stack>
            <div
              className={`${compareChartStyles.label} sustainability-report-compare-chart-left-label`}
              tabIndex={0}
            >
              Total Estimated {name} Emissions
            </div>
          </Stack>
          {id === "productUse" ? (
            <Stack className={`${compareChartStyles.boxWrapper} extra-padding`}>
              <Stack className={compareChartStyles.horizontalStack}>
                <span
                  className={`${compareChartStyles.value} sustainability-report-compare-chart-left-value`}
                  style={{ marginBottom: 0 }}
                  tabIndex={0}
                >
                  {data.annualEnergyConsumption}
                </span>
                <span
                  className={`${compareChartStyles.unit} sustainability-report-compare-chart-left-unit`}
                  style={{ alignSelf: "center" }}
                  tabIndex={0}
                >
                  kWh
                </span>
              </Stack>
              <div
                className={`${compareChartStyles.label} sustainability-report-compare-chart-left-label`}
                tabIndex={0}
              >
                Annual kWh Energy Consumption
              </div>
            </Stack>
          ) : null}
          <Stack className={compareChartStyles.boxWrapper}>
            <span
              className={`${compareChartStyles.value} sustainability-report-compare-chart-left-value`}
              tabIndex={0}
            >
              {data.percentage}%
            </span>
            <div
              className={`${compareChartStyles.label} sustainability-report-compare-chart-left-label`}
              tabIndex={0}
            >
              % of Total Estimated Carbon Emissions
            </div>
          </Stack>
        </Stack>
        <div
          className={`${compareChartStyles.chartWrapper} sustainability-report-compare-chart-right`}
        >
          {id === "production" && completeReportData.production.rows[0].rows ? (
            <Stack className={compareChartStyles.productionChartWrapper}>
              <ReactEcharts
                options={HORIZONTAL_STACKED_BAR_CHART_OPTIONS(
                  productionData,
                  SUS_REPORT_CHART_COLOR[id]
                )}
                width="100%"
                height={`${barChartHeight}rem`}
              />
              <Stack
                horizontal
                className={`${emissionStyles.legendWrapper} sustainability-report-legend-wrapper`}
              >
                {productionData.map((item, idx) => {
                  return (
                    <Stack
                      horizontal
                      key={idx}
                      className={`${emissionStyles.legendItemWrapper} sustainability-report-legend-item-wrapper`}
                    >
                      <TwoColorCircle
                        fill1={SUS_REPORT_CHART_COLOR[id].bar[idx]}
                      />
                      <span tabIndex={0}>{item.name}</span>
                    </Stack>
                  );
                })}
              </Stack>
            </Stack>
          ) : (
            <>
              <ReactEcharts
                options={COMPARE_HORIZONTAL_CHART_OPTIONS(
                  data.rows,
                  SUS_REPORT_CHART_COLOR[id],
                  id,
                  formattedProductLabels,
                  true
                )}
                width="100%"
                height={`${
                  id === "productUse" ? barChartHeight * 1.5 : barChartHeight
                }rem`}
              />
            </>
          )}

          {id === "productUse" ? (
            <>
              <Stack
                horizontal
                className={`${emissionStyles.legendWrapper} ${compareChartStyles.epaWrapper} sustainability-report-legend-wrapper sustainability-report-epa-legend-wrapper`}
              >
                <Stack
                  horizontal
                  className={`${emissionStyles.legendItemWrapper} sustainability-report-legend-item-wrapper`}
                >
                  <TwoColorCircle fill1={SUS_REPORT_CHART_COLOR[id].bar} />
                  <span tabIndex={0}>Telemetry Estimation</span>
                </Stack>
                <Stack
                  horizontal
                  className={`${emissionStyles.legendItemWrapper} sustainability-report-legend-item-wrapper`}
                >
                  <TwoColorCircle
                    fill1={SUS_REPORT_CHART_COLOR[id].energyStar.bar}
                  />
                  <span tabIndex={0}>EPA Energy Star Estimation</span>
                </Stack>
              </Stack>
              <div
                className={`${compareChartStyles.countryTable} sustainability-report-country-table`}
              >
                <div className={compareChartStyles.countryTableContent}>
                  <span className={compareChartStyles.multiple}>X</span>
                  <span className={compareChartStyles.equal}>=</span>
                  <div
                    className={`${compareChartStyles.greyBackground} ${compareChartStyles.countryTableTitle}`}
                    tabIndex={0}
                  >
                    Country
                  </div>
                  <div
                    tabIndex={0}
                    className={compareChartStyles.countryTableTitle}
                  >
                    Electricity Usage (kWh)
                  </div>
                  <div className={compareChartStyles.countryTableTitle}></div>
                  <div
                    tabIndex={0}
                    className={compareChartStyles.countryTableTitle}
                  >
                    Emission Factor
                  </div>
                  <div className={compareChartStyles.countryTableTitle}></div>
                  <div
                    className={`${compareChartStyles.green} ${compareChartStyles.countryTableTitle}`}
                    tabIndex={0}
                  >
                    <span>
                      Carbon Emissions (MTCO<sub>2</sub>)
                    </span>
                  </div>
                  {data.countryRows.map((item, index) => {
                    return (
                      <React.Fragment key={index}>
                        <div
                          className={`${compareChartStyles.greyBackground} ${
                            index < data.countryRows.length - 1
                              ? `${compareChartStyles.withBorder} country-table-with-border`
                              : ""
                          }`}
                          tabIndex={0}
                        >
                          {item.label}
                        </div>
                        <span tabIndex={0}>
                          {convertNumberToString(item.electicityUsage)}
                        </span>
                        <span tabIndex={0}>{item.emissionFactor}</span>
                        <span className={compareChartStyles.green} tabIndex={0}>
                          {convertNumberToString(item.value/1000, "", 5)}
                        </span>
                      </React.Fragment>
                    );
                  })}
                </div>
              </div>
            </>
          ) : null}
        </div>
      </Stack>
    );
  }

  // CODE FROM FULL REPORT. CAN BE OPTIMIZED AND RE USED. END



  const leftPartChart = (
    <Stack className={leftPartStyles.chartWrapper}>
      <Stack
        className={`${leftPartStyles.scopeWrapper} sustainability-left-scope-wrapper`}
      >
        <Stack horizontal className={leftPartStyles.scopeItemWrapper}>
          <Stack
            horizontal
            className={leftPartStyles.scopeItemName}
            tabIndex={0}
          >
            <span>Scope 2</span>
            <TooltipHost
              content={SCOPE_DESCRIPTION.scope2.content}
              id={tooltipId1}
              calloutProps={calloutProps}
              hostClassName={`${leftPartStyles.tooltipHost} mobile-hide`}
              tooltipProps={tooltipProps}
            >
              <FontIcon
                className={leftPartStyles.infoIcon}
                aria-describedby={tooltipId1}
                iconName="Unknown"
                tabIndex={0}
              />
            </TooltipHost>
          </Stack>
          <span className={leftPartStyles.scopeItemValue} tabIndex={0}>
            {kNumFormat(simpleReportData.scope2, true)}
          </span>
        </Stack>
        <Stack horizontal className={leftPartStyles.scopeItemWrapper}>
          <Stack
            horizontal
            className={leftPartStyles.scopeItemName}
            tabIndex={0}
          >
            <span>Scope 3</span>
            <TooltipHost
              content={SCOPE_DESCRIPTION.scope3.content}
              id={tooltipId2}
              calloutProps={calloutProps}
              hostClassName={`${leftPartStyles.tooltipHost} mobile-hide`}
              tooltipProps={tooltipProps}
            >
              <FontIcon
                className={leftPartStyles.infoIcon}
                aria-describedby={tooltipId2}
                iconName="Unknown"
                tabIndex={0}
              />
            </TooltipHost>
          </Stack>
          <span className={leftPartStyles.scopeItemValue} tabIndex={0}>
            {kNumFormat(simpleReportData.scope3, true)}
          </span>
        </Stack>
      </Stack>
      <ReactEcharts
        options={SIMPLE_REPORT_CHART_OPTIONS(simpleReportData, true)}
        width="36rem"
        height="26rem"
        onChartReadyCallback={(chart) => {
          chart.on("click", (params) => {
            if (!isSmallWidthScreen()) {
              setChartTooltip((state) => {
                if (state === params.name) {
                  return "";
                }
                return params.name;
              });
            }
          });
        }}
      />
      {/* {chartTooltip && !isSmallWidthScreen() ? (
        <div
          className={leftPartStyles.chartTooltip}
          dangerouslySetInnerHTML={{
            __html: TOOLTIP_CONTENT.find((item) => item.id === chartTooltip)
              ?.text,
          }}
        />
      ) : null} */}

      {chartTooltip && (Object.keys(completeReportData).length > 1) && !isSmallWidthScreen() ? (
        <div className={leftPartStyles.moreDetailsContainer}>
          <div className={leftPartStyles.moreDetailsTitle}>{chartTooltip}</div>
          <div
            className={leftPartStyles.chartTooltipNew}
            dangerouslySetInnerHTML={{
              __html: TOOLTIP_CONTENT.find((item) => item.id === chartTooltip)
                ?.text,
            }}
          />
          {
            chartTooltip === "Production" ?
              compareChart(completeReportData.production, "production")
            :
            chartTooltip === "Packaging" ?
              compareChart(completeReportData.packaging, "packaging")
            :
            chartTooltip === "Transport" ?
              compareChart(completeReportData.transport, "transport")
            :
            chartTooltip === "Product Use" ?
              compareChart(completeReportData.productUse, "productUse")
            :
            chartTooltip === "End of Life" ?
              compareChart(completeReportData.endOfLife, "endOfLife")
            :
              null
          }
        </div>
      ) : null}




      <div className="mobile-display-only mobile-view-report-wrapper">
        {bottomPart}
      </div>
      <MobileTooltipAccordion
        scope2={kNumFormat(simpleReportData.scope2)}
        scope3={kNumFormat(simpleReportData.scope3)}
      />
    </Stack>
  );

  const handleClick = () => {
    sessionStorage.setItem("HideIntroText", "true");
    setIsHideIntroText(true);
  };

  const introText = (
    <>
      {isHideIntroText ? null : (
        <div
          className={`${leftPartStyles.introText} sustainability-left-part-intro-text`}
        >
          Microsoft is currently working to update the methodology it uses to
          conduct Lifecycle Assessments in order to incorporate more specific
          data from its suppliers. The estimates provided by this tool may
          change as a result
          <FontIcon
            className={leftPartStyles.introTextCancelIcon}
            iconName="Cancel"
            onClick={handleClick}
            tabIndex={0}
            onKeyDown={(event) => {
              if (event.key === "Enter") {
                handleClick();
              }
            }}
          />
        </div>
      )}
    </>
  );

  const leftPart = (
    <div className={leftPartStyles.container}>
      <h1 className={`${leftPartStyles.title} mobile-hide`} tabIndex={0}>
        Microsoft Surface Emissions Estimator
      </h1>
      <div className="mobile-hide">{introText}</div>
      <div
        className={`${leftPartStyles.contentWrapper} sustainability-left-content-wrapper`}
      >
        <Stack
          className={`${leftPartStyles.keyValueWrapper} sustainability-left-key-value-wrapper`}
        >
          <span
            className={`${leftPartStyles.key} sustainability-left-key`}
            tabIndex={0}
          >
            Total Estimated CO2 Emissions of Your Selections
          </span>
          <span
            className={`${leftPartStyles.value} sustainability-left-value`}
            tabIndex={0}
          >
            {kNumFormat(simpleReportData.totalCO2Emissions)}
          </span>
          <span
            className={`${leftPartStyles.key} sustainability-left-key`}
            tabIndex={0}
          >
            Metric Tons CO<sub>2</sub>
            {` `}
            <span className={`${leftPartStyles.unit} sustainability-left-unit`}>
              (MTCO<sub>2</sub>)
            </span>
          </span>
        </Stack>
        <div className="mobile-display-only">{introText}</div>
        <div>{leftPartChart}</div>
      </div>
      {/* <div ref={detailedChartEndRef} /> */}
      <div id="scroll-id"></div>
    </div>
  );

  const productSelectionBoxStyles = mergeStyleSets({
    selectionWrapper: {
      marginBottom: 16,
    },
    yearsWrapper: {
      display: "flex",
      alignItems: "center",
      // justifyContent: "space-between",
      marginBottom: 16,
      "> div:nth-child(1)": {
        width: "60%",
      },
      "> div:nth-child(2)": {
        width: "40%",
      },
    }
  });

  const comboBoxStyles = {
    optionsContainerWrapper: {
      width: document.querySelector(".ms-ComboBox-container")?.offsetWidth,
    },
  };

  const validateProductSelection = (updatedList) => {
    var prodCount = {};
    var countryCount = {};

    updatedList.forEach((item) => {
      if(item.product && item.product.text)
      {
        const prod = item.product.text;
        if(prod in prodCount)
        {
          prodCount[prod] += 1;
        } else {
          prodCount[prod] = 1;
        }
      }

      if(item.location) {
        const country = item['location']['text'];
        if(country in countryCount)
        {
          countryCount[country] += 1;
        } else {
          countryCount[country] = 1;
        }
      }
    });

    if(Object.keys(prodCount).length > MAX_DEVICES)
    {
      setIsShowMaxDevicesModal(true);
      return false;
    }

    if(Object.keys(countryCount).length > MAX_COUNTRIES)
    {
      setIsShowMaxCountriesModal(true);
      return false;
    }

    return true;
  }

  const handleChange = (event, value, id) => {
    if (event.target.ariaLabel === "location" && !value) {
      return;
    }
    if (value !== "" && event.target.ariaLabel === "quantity") {
      if (!isPositiveInteger(value)) {
        return;
      }
      if (convertStringToNumber(value) > QUANTITY_LIMIT) {
        return;
      }
      value = formatNumber(value);
    }
    let updatedList = productList.map((item) => {
      if (item.id === id) {
        let updatedItem = Object.assign({}, item);
        updatedItem[event.target.ariaLabel] = value;
        return updatedItem;
      }
      return item;
    });

    if(validateProductSelection(updatedList))
    {
      setProductList(updatedList);
      
      if (event.type !== "input") {
        getReport(updatedList);
        getReport(updatedList, true);
      }
    } else {
      return;
    }
  };

  const handleValidation = (values) => {
    let error = {};
    let hasError = false;
    values.forEach((item) => {
      error[item.id] = {};
      if (!item.product && !item.quantity && !item.location) {
        return;
      }
      if (!item.product) {
        hasError = true;
        error[item.id].product = "required";
      }
      if (!item.quantity) {
        hasError = true;
        error[item.id].quantity = "required";
      }
      if (!item.location) {
        hasError = true;
        error[item.id].location = "required";
      }
    });
    setErrorList(error);
    return hasError;
  };

  const getRefProductName = (key) => {
    return productRefOptions.filter(item => item.key === key)[0].text;
  }

  const getReport = (values, isCompleteReport) => {
    if (handleValidation(values)) {
      return;
    }
    if (isCompleteReport) {
      setIsLoading(true);
    };
    const list = values.filter((i) => i.product);
    if (!list.length) return;
    const deviceMixPortfolio = list.map((item, index) => {
      return {
        seqNo: index + 1,
        productId: item.product.key,
        product: getRefProductName(item.product.key),
        countryCode: item.location.key,
        country: item.location.text,
        quantity: convertStringToNumber(item.quantity),
        years: item.years? Number(item.years.key) : 3,
      };
    });
    const requestBody = {
      deviceMixPortfolio,
    };
    // const url = `${process.env.REACT_APP_API_URL}/${
    //   isCompleteReport ? "sus-report-complete" : "sus-report"
    // }`;
    const url = isCompleteReport ? "api/sus-report-complete" : "api/sus-report";
    sendRequest("POST", url, requestBody).then((data) => {
      if (data.error) {
        setAlert("error", data.error.message);
      }
      if (data.value) {
        if (isCompleteReport) {
          setCompleteReportData(data.value);
          setShowCompleteReport(true);
          setIsLoading(false);
        } else {
          setSimpleReportData(data.value);
        }
      }
    });
  };

  const rightPartStyles = mergeStyleSets({
    container: {
      padding: "3.5rem 2.75rem 6.3rem",
    },
    mobileCompleteButton: {
      margin: "5.6rem auto 0",
      width: "13.6rem",
      height: "3.8rem",
    },
    firstTimeModal: {
      display: "flex",
      flexFlow: "column nowrap",
      width: "35rem",
      height: "100%",
      padding: "1rem 1.5rem 1.5rem 1.5rem",
      button: {
        alignSelf: "flex-end",
      },
    },
    firstTimeTitleWrapper: {
      marginBottom: "1.25rem",
      alignItems: "center",
      justifyContent: "space-between",
    },
    firstTimeTitle: {
      fontSize: "1.25rem",
      lineHeight: "1.75rem",
      fontWeight: 600,
      color: "#201F1E",
    },
    firstTimeCancelIcon: {
      color: "#201F1E",
      fontSize: 16,
      cursor: "pointer",
      zIndex: 3,
    },
    firstTimeContent: {
      fontSize: "0.875rem",
      lineHeight: "1.25rem",
      marginBottom: "1rem",
      color: "#605E5C",
    },
  });

  const dropdownStyles = {
    dropdownOptionText: { overflow: 'visible', whiteSpace: 'normal' },
    dropdownItem: { height: 'auto', margin: '2px 0' },
    dropdownItemSelected:  { height: 'auto', margin: '2px 0' },
  };

  const onRenderTitle = (options) => {
    return <div>Number of years ({options[0]["key"]})</div>
  }

  const yearsList = [{key: "1", text: "1"}, {key: "2", text: "2"}, {key: "3", text: "3"}, {key: "4", text: "4"}];

  const ProductBoxContent = (product) => (
    <>
      <Dropdown
        ariaLabel="product"
        placeholder="Select Device"
        options={productOptions}
        styles={dropdownStyles}
        className={productSelectionBoxStyles.selectionWrapper}
        selectedKey={
          productList.find((i) => i.id === product.id)?.product
            ? productList.find((i) => i.id === product.id).product.key
            : null
        }
        onChange={(event, val) => handleChange(event, val, product.id)}
        errorMessage={errorList[product.id]?.product}
        calloutProps={{ calloutMaxHeight: "40vh" }}
      />
      <TextField
        ariaLabel="quantity"
        placeholder="Quantity (Max: 100,000)"
        className={productSelectionBoxStyles.selectionWrapper}
        onChange={(event, val) => handleChange(event, val, product.id)}
        onBlur={() => {
            getReport(productList);
            getReport(productList, true);
        }}
        value={productList.find((i) => i.id === product.id)?.quantity || ""}
        errorMessage={errorList[product.id]?.quantity}
      />
      <ComboBox
        styles={comboBoxStyles}
        className={productSelectionBoxStyles.selectionWrapper}
        placeholder="Location"
        options={countryOptions}
        selectedKey={
          productList.find((i) => i.id === product.id)?.location
            ? productList.find((i) => i.id === product.id).location.key
            : null
        }
        allowFreeform={true}
        autoComplete="on"
        onChange={(event, val) =>
          handleChange(
            {
              ...event,
              target: {
                ...event.target,
                ariaLabel: "location",
              },
            },
            val,
            product.id
          )
        }
        errorMessage={errorList[product.id]?.location}
      />
      {/* <div className={productSelectionBoxStyles.yearsWrapper}> */}
        {/* <div>Number of Years</div> */}
        <Dropdown
          title="Number of years"
          ariaLabel="years"
          placeholder="Number of years (3)"
          options={yearsList}
          styles={dropdownStyles}
          className={productSelectionBoxStyles.selectionWrapper}
          selectedKey={
            productList.find((i) => i.id === product.id)?.years
              ? productList.find((i) => i.id === product.id).years.key
              : null
          }
          onChange={(event, val) => handleChange(event, val, product.id)}
          // errorMessage={errorList[product.id]?.product}
          calloutProps={{ calloutMaxHeight: "40vh" }}
          onRenderTitle={onRenderTitle}
        />
      {/* </div> */}

    </>
  );

  const rightPart = (
    <div className={rightPartStyles.container}>
      <h1 className="mobile-display-only mobile-page-title" tabIndex={0}>
        Microsoft Surface Emissions Estimator
      </h1>
      <SelectionBoxList
        title="Select Your Device(s):"
        selectionName="Product"
        max={MAX_CARDS+1}
        min={1}
        list={productList}
        updateList={setProductList}
        boxContent={ProductBoxContent}
        onDelete={(updatedList, id) => {
          if (updatedList.every((i) => Object.keys(i).length < 4)) {
            setSimpleReportData({ key: uuid() });
            setCompleteReportData({ key: uuid() });
            setChartTooltip("");
          } else {
            getReport(updatedList);
            getReport(updatedList, true);
          }
        }}
        onAdd={(updatedList) => {
          if(updatedList.length > MAX_CARDS)
          {
            setIsShowMaxCardsModal(true);
            return false;
          }

          return true;
        }}
        icon={laptopIcon}
        message=""
      />
     <Modal
          isOpen={isShowMaxDevicesModal}
          onDismiss={() => setIsShowMaxDevicesModal(false)}
          scrollableContentClassName={`${rightPartStyles.firstTimeModal} sus-first-time-modal-wrapper`}
        >
          <Stack horizontal className={rightPartStyles.firstTimeTitleWrapper}>
            <div className={`${rightPartStyles.firstTimeTitle} sus-first-time-modal-title`}
            >
              {MAX_MSG_TITLE}
            </div>
            <FontIcon
              className={rightPartStyles.firstTimeCancelIcon}
              iconName="Cancel"
              onClick={() => setIsShowMaxDevicesModal(false)}
              onKeyDown={(event) => {
                if (event.key === "Enter") {
                  setIsShowMaxDevicesModal(false);
                }
              }}
            />
          </Stack>
          <div  className={`${rightPartStyles.firstTimeContent} sus-first-time-modal-content`}
          >
           This tool only estimates emissions for up to {MAX_DEVICES} unique products at one time. Please divide larger entries into multiple submissions.
          </div>
          <PrimaryButton
            text="Okay"
            onClick={() => setIsShowMaxDevicesModal(false)}
          />
     </Modal>
     <Modal
          isOpen={isShowMaxCountriesModal}
          onDismiss={() => setIsShowMaxCountriesModal(false)}
          scrollableContentClassName={`${rightPartStyles.firstTimeModal} sus-first-time-modal-wrapper`}
        >
          <Stack horizontal className={rightPartStyles.firstTimeTitleWrapper}>
            <div className={`${rightPartStyles.firstTimeTitle} sus-first-time-modal-title`}
            >
              {MAX_MSG_TITLE}
            </div>
            <FontIcon
              className={rightPartStyles.firstTimeCancelIcon}
              iconName="Cancel"
              onClick={() => setIsShowMaxCountriesModal(false)}
              onKeyDown={(event) => {
                if (event.key === "Enter") {
                  setIsShowMaxCountriesModal(false);
                }
              }}
            />
          </Stack>
          <div  className={`${rightPartStyles.firstTimeContent} sus-first-time-modal-content`}
          >
           This tool only estimates emissions for up to {MAX_COUNTRIES} unique countries at one time. Please divide entries spanning more than {MAX_COUNTRIES} country locations into multiple submissions.
          </div>
          <PrimaryButton
            text="Okay"
            onClick={() => setIsShowMaxCountriesModal(false)}
          />
      </Modal>
      <Modal
          isOpen={isShowMaxCardsModal}
          onDismiss={() => setIsShowMaxCardsModal(false)}
          scrollableContentClassName={`${rightPartStyles.firstTimeModal} sus-first-time-modal-wrapper`}
        >
          <Stack horizontal className={rightPartStyles.firstTimeTitleWrapper}>
            <div className={`${rightPartStyles.firstTimeTitle} sus-first-time-modal-title`}
            >
              {MAX_MSG_TITLE}
            </div>
            <FontIcon
              className={rightPartStyles.firstTimeCancelIcon}
              iconName="Cancel"
              onClick={() => setIsShowMaxCardsModal(false)}
              onKeyDown={(event) => {
                if (event.key === "Enter") {
                  setIsShowMaxCardsModal(false);
                }
              }}
            />
          </Stack>
          <div  className={`${rightPartStyles.firstTimeContent} sus-first-time-modal-content`}
          >
           This tool only estimates emissions for up to {MAX_CARDS} location+device entries at one time. Please divide larger entries into multiple submissions.
          </div>
          <PrimaryButton
            text="Okay"
            onClick={() => setIsShowMaxCardsModal(false)}
          />
        </Modal>
    </div>
  );

  const learnMoreStyles = mergeStyleSets({
    inputPage: {
      maxWidth: 1440,
      left: "50%",
      transform: "translate(-50%, 0)",
      button: {
        marginRight: "2.75rem",
      },
    },
    reportPage: {
      right: "3.5rem",//"5%",
    },
  });

  const containerStyles = mergeStyleSets({
    rootContainer: {
      width: "100%",
      display: "flex",
    },
    assumptionsContainer: {
      width: "100%",
      transition: "all 0.3s ease",
    },
    assumptionsContainerExpanded: {
      width: "70%",
      marginLeft: "30%",
      transition: "all 0.3s ease",
    },
    chatbotContainer: {
      display: "none",
      width: "0",
      transition: "all 1s ease",
    },
    chatbotContainerExpanded: {
      display: "block",
      width: "30%",
      height: "100vh",
      position: "fixed",
      left: 0,
      top: 0,
      transition: "all 1s ease",
      background: "linear-gradient(316deg, #FFF 0%, #F0F0F0 57.36%)",
      boxShadow: "0px 0px 1.5399999618530273px 0px rgba(0, 0, 0, 0.12), 0px 0.7699999809265137px 1.5399999618530273px 0px rgba(0, 0, 0, 0.14)",
    },
    chatIcon: {
      width: "3rem",
      height: "3rem",
      position: "fixed",
      bottom: "15rem",
      left: "1rem",
      cursor: "pointer",
    },
  });

  const headerHeight = document.querySelector("header").offsetHeight;

  const LayoutStyles = mergeStyleSets({
    mainContainer: {
      maxWidth: 1640,
      margin: '0 auto',
    },
    innerContainer: {
        // background: 'orange',
        display: 'flex',
        height: `calc(100vh - ${headerHeight}px)`,
    },
    left: {
        // background: 'blue',
        width: '30%',
        overflowY: 'auto',
        // background: "linear-gradient(316deg, #FFF 0%, #F0F0F0 57.36%)",
    },
    right: {
        // background: 'pink',
        width: '70%',
    },
    chartContainer: {
        height: `calc(100vh - ${headerHeight}px - 2.5rem)`,
        overflowY: 'auto',
        // background: "#faf9f8",
        // "-ms-overflow-style": "none",
        // scrollbarWidth: "none",
        // "::-webkit-scrollbar": {
        //     display: "none",
        // },
    },
    optionsContainer: {
        // background: 'yellow',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        gap: '1rem',
        height: "2.5rem", //Also change in chartContainer if you change the height here.
        paddingRight: `calc(3.5625rem + 5px)`,
    },
    option: {
        border: '1px solid #106ebe',
        borderRadius: "0 0 0.5rem 0.5rem",
        color: "#106ebe",
        padding: "0.3rem 0",
        width: "9rem",
        textAlign: 'center',
        ":hover": {
            cursor: "pointer",
            background: "#106ebe",
            color: "#fff",
        },
        "&.background-fill-blue": {
            background: "#106ebe",
            color: "#fff",
        }
    },
    showContent: {
      display: "block",
      height: "99%",
    },
    hideContent: {
      display: "none",
    }
  });

  const reportModalStyles = mergeStyleSets({
    firstTimeModal: {
      display: "flex",
      flexFlow: "column nowrap",
      width: "35rem",
      height: "100%",
      padding: "1rem 1.5rem 1.5rem 1.5rem",
      button: {
        alignSelf: "flex-end",
      },
    },
    firstTimeTitleWrapper: {
      marginBottom: "1.25rem",
      alignItems: "center",
      justifyContent: "space-between",
    },
    firstTimeTitle: {
      fontSize: "1.25rem",
      lineHeight: "1.75rem",
      fontWeight: 600,
      color: "#201F1E",
    },
    firstTimeCancelIcon: {
      color: "#201F1E",
      fontSize: 16,
      cursor: "pointer",
      zIndex: 3,
    },
    firstTimeContent: {
      fontSize: "0.875rem",
      lineHeight: "1.25rem",
      marginBottom: "1rem",
      color: "#605E5C",
    },
    firstTimeContentDuplicate: {
      fontSize: "1.1rem",
      lineHeight: "1.25rem",
      marginBottom: "1rem",
      color: "#605E5C",
    },



    contentWrapper: {
      display: "flex",
      flexFlow: "column nowrap",
      // width: openChatbot ? "100%" : "85vw",
      width: "85vw",
      height: "85vh",
    },
    actualModal: {
      // width: openChatbot ? "70%" : "100%",
      width: "100%",
      // marginTop: openChatbot ? `${headerHeight}px` : '0',
      right: "0",
    },
    cancelIcon: {
      color: "#474747",
      alignSelf: "flex-end",
      fontSize: "22.5px",
      cursor: "pointer",
      position: "sticky",
      top: "1.4844rem",
      marginRight: "2.2344rem",
      zIndex: 3,
    },
  });

  const reportContentStyles = mergeStyleSets({
    sectionWrapper: {
      marginBottom: "5rem",
      padding: "3rem 3.5rem 0",
    },
    sectionTitle: {
      fontSize: "1.75rem",
      fontWeight: 500,
      lineHeight: "2rem",
      marginTop: 0,
      marginBottom: "1rem",
      display: "flex",
      gap: "1.125rem",
      alignItems: "center",
      textTransform: "capitalize",
    },
    methodologyIntro: {
      display: "block",
      fontSize: "1rem",
      lineHeight: "1.375rem",
      fontStyle: "italic",
      marginBottom: "4.5625rem",
    },
  });

  const handleMethodologyClick = () => {
    toggleShowMethodology();
  }

  const handleFullReportClick = () => {
    const list = productList.filter((i) => i.product);
    if((Object.keys(completeReportData).length > 1) && (list.length)){
      setOpenCompleteReport(current => !current);
    } else {
      toggleBlockChatbotUsage();
    }
  }

  const firstTimeCheckChatbot = () => {
    if (!sessionStorage.getItem("see-chatbot-first-click")) {
      sessionStorage.setItem("see-chatbot-first-click", "true");
      setIsShowFirstTimeModalChatbot(true);
    }
  };

  const handleChatButtonClick = () => {
    const list = productList.filter((i) => i.product);
    if((Object.keys(completeReportData).length > 1) && (list.length)) {
      firstTimeCheckChatbot();
      toggleOpenChatbot();
    } else {
      toggleBlockChatbotUsage();
    }
  }

  return (
    <div className={`${LayoutStyles.mainContainer}`}>
        <div className={`${LayoutStyles.innerContainer}`} >
            <div className={`${LayoutStyles.left} overflow-scroll`}>
                {
                    openChatbot ?
                        <SEEChatbot 
                            toggleChatbotWindow={toggleOpenChatbot} 
                            reportData={completeReportData}
                            productList={productList}
                            productRefOptions={productRefOptions}
                        />
                    :
                        rightPart
                }
            </div>
            <div className={`${LayoutStyles.right}`}>
                <div className={`${LayoutStyles.optionsContainer}`} >
                    <div className={`${LayoutStyles.option}`} onClick={handleMethodologyClick}>Methodology</div>
                    <div className={`${LayoutStyles.option}`} onClick={handleFullReportClick}>Full Report (PDF)</div>
                    {process.env.REACT_APP_CHATBOT_ENABLED === "true" && <div className={`${LayoutStyles.option} ${openChatbot ? 'background-fill-blue' : ''}`} onClick={handleChatButtonClick}>AI Chat (GPT)</div>}
                </div>
                <div className={`${LayoutStyles.chartContainer} overflow-scroll`}>{leftPart}</div>
            </div>
        </div>

        <Modal
          isOpen={showMethodology}
          onDismiss={() => toggleShowMethodology()}
          className={`${reportModalStyles.actualModal}`}
          scrollableContentClassName={`${reportModalStyles.contentWrapper} tco-report-modal-wrapper`}
          // focusTrapZoneProps={{
          //   disabled: true,
          // }}
        >
          <FontIcon
            className={reportModalStyles.cancelIcon}
            iconName="Cancel"
            onClick={() => toggleShowMethodology()}
            tabIndex={0}
            onKeyDown={(event) => {
              if (event.key === "Enter") {
                toggleShowMethodology();
              }
            }}
          />
          <section className={reportContentStyles.sectionWrapper}>
            <h2
              className={reportContentStyles.sectionTitle}
              tabIndex={0}
            >
              Methodology
            </h2>
            <span
              className={`${reportContentStyles.methodologyIntro} sustainability-report-methodology-intro`}
            >
              Microsoft is currently working to update the methodology it uses to
              conduct Lifecycle Assessments in order to incorporate more specific
              data from its suppliers. The estimates provided by this tool may
              change as a result.
            </span>
            <MethodologyPage />
          </section>
        </Modal>

        <Modal
            isOpen={isShowFirstTimeModalChatbot}
            onDismiss={() => setIsShowFirstTimeModalChatbot(false)}
            scrollableContentClassName={`${reportModalStyles.firstTimeModal} tco-assumption-first-time-modal-wrapper`}
        >
            <Stack horizontal className={reportModalStyles.firstTimeTitleWrapper}>
                <div
                    className={`${reportModalStyles.firstTimeTitle} tco-assumption-first-time-modal-title`}
                >
                    Preview feature disclaimer
                </div>
                <FontIcon
                    className={reportModalStyles.firstTimeCancelIcon}
                    iconName="Cancel"
                    onClick={() => setIsShowFirstTimeModalChatbot(false)}
                    onKeyDown={(event) => {
                        if (event.key === "Enter") {
                            setIsShowFirstTimeModalChatbot(false);
                        }
                    }}
                />
            </Stack>
            <div
                className={`${reportModalStyles.firstTimeContent} tco-assumption-first-time-modal-content`}
            >
                This AI assistant is an experimental feature which is still in preview. Please use with caution and be aware that it may not always provide accurate or complete information. We appreciate your understanding and feedback as we work to improve this cutting-edge technology.
            </div>
            <PrimaryButton
                text="Okay"
                onClick={() => setIsShowFirstTimeModalChatbot(false)}
            />
        </Modal>

        <Modal
          isOpen={blockChatbotUsage}
          onDismiss={() => toggleBlockChatbotUsage()}
          scrollableContentClassName={`${reportModalStyles.firstTimeModal} tco-assumption-first-time-modal-wrapper`}
        >
            <Stack horizontal className={reportModalStyles.firstTimeTitleWrapper}>
                {/* <div
                    className={`${reportModalStyles.firstTimeTitle} tco-assumption-first-time-modal-title`}
                >
                    Preview feature disclaimer
                </div> */}
                {/* <FontIcon
                    className={reportModalStyles.firstTimeCancelIcon}
                    iconName="Cancel"
                    onClick={() => toggleBlockChatbotUsage()}
                    onKeyDown={(event) => {
                        if (event.key === "Enter") {
                          toggleBlockChatbotUsage();
                        }
                    }}
                /> */}
            </Stack>
            <div
                className={`${reportModalStyles.firstTimeContentDuplicate} tco-assumption-first-time-modal-content`}
            >
              Please select a product first.
            </div>
            <PrimaryButton
                text="Okay"
                onClick={() => toggleBlockChatbotUsage()}
            />
        </Modal>

        <Modal
          isOpen={openCompleteReport}
          onDismiss={() => setOpenCompleteReport(false)}
          className={`${reportModalStyles.actualModal}`}
          scrollableContentClassName={`${reportModalStyles.contentWrapper} tco-report-modal-wrapper`}
          // focusTrapZoneProps={{
          //   disabled: true,
          // }}
        >
          <FontIcon
            className={reportModalStyles.cancelIcon}
            iconName="Cancel"
            onClick={() => setOpenCompleteReport(false)}
            tabIndex={0}
            onKeyDown={(event) => {
              if (event.key === "Enter") {
                setOpenCompleteReport(false);
              }
            }}
          />
            <SustainabilityReport
              reportData={completeReportData}
              productReference= {productRefOptions}
              productOptions= {productOptions}
              setShowCompleteReport={setShowCompleteReport}
              chatbotExpanded={openChatbot}
              productList={productList}
            />
        </Modal>
    </div>
  );
};

export default SustainabilityCalculator;