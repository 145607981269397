import { useEffect, useState, useContext, useMemo } from "react";
import {
  Stack,
  mergeStyleSets,
  DefaultButton,
  PrimaryButton,
  FontIcon,
  Pivot,
  PivotItem,
  Callout,
  Modal,
  DirectionalHint,
  Spinner,
  SpinnerSize,
  CompoundButton,
  Dropdown,
  mergeStyles,
  TextField,
  CommandBarButton
} from "@fluentui/react";
import { v4 as uuid } from "uuid";
import _ from "lodash";
import TwoPartLayout from "components/TwoPartLayout";
import Accordion from "components/Accordion";
import CommonForm from "components/CommonForm/CommonForm";
import { useForm } from "components/CommonForm/formHooks";
import { AlertContext } from "components/Alert";
import {
  sendRequest,
  convertStringToNumber,
  convertNumberToString,
  isObjectEmpty,
  formatNumberWithUnit,
  isSmallWidthScreen,
  calculateNPVAndBPB,
  formatCurrency,
  generateOnePager,
  isPositiveNumber,
  formatNumber,
  trackGAEvent,
} from "utils/helpers";
import {
  YEAR_AMOUNT,
  CURRENCY,
  TCO_SIMPLE_REPORT_CHART_COLOR,
  ASSUMPTION_ADVANCED_LIST,
  BAR_COMPARISON_CHART_OPTIONS,
} from "constants/tco";
import ReactEcharts from "components/ReactEcharts";
import TcoCompleteReport from "./TcoCompleteReport";
import AssumptionsInputItem from "./AssumptionsInputItem";
import SummaryIcon from "assets/summary.svg";
import LearnMoreText from "./LearnMoreText";
import FocusableCheckbox from "components/FocusableCheckbox";
import ChartDisclaimer from "./ChartDisclaimer";
import "./TcoAssumptions.scss";
import { DelayedRender, Text } from '@fluentui/react';
import { useBoolean, useId } from '@fluentui/react-hooks';

import tooltipData from "../../assets/tooltipData.json";
import Excel from 'exceljs';
import { saveAs } from 'file-saver';
import AdvancedAssumptionsInputItem from "./AdvancedAssumptionsInputItem";
import CommonFormAssumptions from "components/CommonForm/CommonFormAssumptions";
import { Link } from "react-router-dom";
import AssumptionsInputItemSus from "./AssumptionsInputItemSus";

const TcoAssumptions = (props) => {
  const { userInput, setUserInput, setShowAssumptions, currencySymbol, retainedYears, setRetainedYears, chatbotExpanded, setChatbotExpanded, setActiveViewForChatbot, toggleChatbotWindow, openFullReport, setOpenFullReport, defaultAssumptions} = props;
  const { setAlert } = useContext(AlertContext);
  const [simpleReport, setSimpleReport] = useState({ key: uuid() });
  const [completeReport, setCompleteReport] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [showCompleteReport, setShowCompleteReport] = useState(false);
  const [advancedInput, setAdvancedInput] = useState({});
  const [initialAdvancedInput, setInitialAdvancedInput] = useState({});
  const [updateInitialAdvancedInput, setUpdateInitialAdvancedInput] = useState(false);
  const [showSummary, setShowSummary] = useState(false);
  const [showMobileSummary, setShowMobileSummary] = useState(false);
  const [isShowFirstTimeModal, setIsShowFirstTimeModal] = useState(false);
  const [isShowFurtherEditingModal, setIsShowFurtherEditingModal] =
    useState(false);
  const [isShowFirstTimeModalChatbot, setIsShowFirstTimeModalChatbot] = useState(false);
  const [activeView, setActiveView] = useState("Standard");
  const [reportPageData, setReportPageData] = useState({});
  const [isFullReportLoading, setIsFullReportLoading] = useState(false);
  const headerHeight = document.querySelector("header").offsetHeight;
  const [savings, setSavings] = useState(["0\nSavings", "0\nSavings", "0\nSavings"]); 


  const [isAccessoriesCalloutVisible, { toggle: toggleIsAccessoriesCalloutVisible }] = useBoolean(false);
  const [isAccessoriesCalloutVisibleC, { toggle: toggleIsAccessoriesCalloutVisibleC }] = useBoolean(false);
  const [isPaybackCalloutVisible, { toggle: toggleIsPaybackCalloutVisible }] = useBoolean(false);
  const [isAdvPaybackCalloutVisible, { toggle: toggleIsAdvPaybackCalloutVisible }] = useBoolean(false);
  const [isAdvPaybackMobileCalloutVisible, { toggle: toggleIsAdvPaybackMobileCalloutVisible }] = useBoolean(false);
  const [isNPVCalloutVisible, { toggle: toggleIsNPVCalloutVisible }] = useBoolean(false);
  const [isAdvNPVCalloutVisible, { toggle: toggleIsAdvNPVCalloutVisible }] = useBoolean(false);
  const [isAdvNPVMobileCalloutVisible, { toggle: toggleIsAdvNPVMobileCalloutVisible }] = useBoolean(false);
  const [isheading1CalloutVisible, { toggle: heading1CalloutVisible }] = useBoolean(false);
  const [isheading2CalloutVisible, { toggle: heading2CalloutVisible }] = useBoolean(false);
  const [isDiscountRate1CalloutVisible, { toggle: toggleIsDiscountRate1CalloutVisible }] = useBoolean(false);
  const [isDiscountRate2CalloutVisible, { toggle: toggleIsDiscountRate2CalloutVisible }] = useBoolean(false);
  const [isSEE1CalloutVisible, { toggle: toggleIsSEE1CalloutVisible }] = useBoolean(false);
  const [isSEE2CalloutVisible, { toggle: toggleIsSEE2CalloutVisible }] = useBoolean(false);
  const calloutId = useId('accessories-id');
  const summaryPaybackId = useId('payback-id');
  const summaryAdvPaybackId = useId('adv-payback');
  const summaryNPVId = useId('npv-id');
  const summaryAdvNPVId = useId('adv-npv');
  const discoutRateCalloutId1 = useId('dr-pbp-id');
  const discoutRateCalloutId2 = useId('dr-npv-id');
  const seeCalloutId1 = useId('see-energy-cost-id');
  const seeCalloutId2 = useId('see-co2-cost-id');


  const [isDeviceCalloutVisible, { toggle: toggleDeviceCalloutVisible }] = useBoolean(false);
  const [isITCalloutVisible, { toggle: toggleITCalloutVisible }] = useBoolean(false);
  const [isEmployeeCalloutVisible, { toggle: toggleEmployeeCalloutVisible }] = useBoolean(false);
  const [selectedRadioButton, setSelectedRadioButton] = useState("Per Device");
  const [assChoice, setAssChoice] = useState(true);
  const [isDefaultAssum, setDefaultAssum] = useState(true);
  const initialAccValueList = {
    isCamera: true,
    isLight: true,
    isHeadset: true,
    isDock: true,
    isPen: false,
    isKeyboard: false,
    isPowerSupply: false
  };
  let customValuesList = [
    {label: 'Camera', key: 'isCamera', surfaceValue: 0, otherPCVal: 0},
    {label: 'Light', key: 'isLight', surfaceValue: 0, otherPCVal: 0},
    {label: 'Headset', key: 'isHeadset', surfaceValue: 0, otherPCVal: 0},
    {label: 'Dock', key: 'isDock', surfaceValue: 0, otherPCVal: 0},
    {label: 'Pen', key: 'isPen', surfaceValue: 0, otherPCVal: 0},
    {label: 'Keyboard', key: 'isKeyboard', surfaceValue: 0, otherPCVal: 0},
    {label: 'Power supply', key: 'isPowerSupply', surfaceValue: 0, otherPCVal: 0}
  ]
  const [defaultValues, setdefaultValues] = useState(initialAccValueList);
  const [customValues, setCustomValues] = useState(customValuesList);
  const [isSameCost, setIsSameCost] = useState(false);

  const [totalValues, setTotalValues] = useState({
    totalCosts: "0",
    deviceSavings: "0",
    itSimplification: "0",
    employeeEmpowerment: "0",
    sustainability: "0",
  });

  const [discountRate, setDiscountRate] = useState({
    pbp: 6.2,
    npv: 6.2,
  });

  const [sustainabilitySavings, setSustainabilitySavings] = useState({});

  const [reportPageDataPerDevice, setReportPageDataPerDevice] = useState({});

  let assumptionsWithCurrency = ["annualEmployeeSalary", "annualITStaffSalary", "averageCostToHire", "averagePCCost", "cameraCost", "dockCost", "extraPowerSupplyCost", "headsetCost", "lightsCost", "pcWarrantyAndMaintenanceCost", "penCost", "typeCoverCost", "PCCameraCost", "PCDockCost", "PCHeadsetCost", "PCLightsCost", "PCPenCost", "PCPowerSupplyCost", "PCTypeCoverCost", "SurfaceCameraCost", "SurfaceDockCost", "SurfaceHeadsetCost", "SurfaceLightsCost", "SurfacePenCost",  "SurfacePowerSupplyCost", "SurfaceTypeCoverCost"];
  const fieldsWithOnlyCost = ["Reduced Support", "Device Consolidation", "Reduced Support", ];
  const fieldsWithOnlyHours = ["Reduced Helpdesk Support", "Reduced Ongoing Maintenance", "Reduced Time for Security Incident Response", "Reduced Employee Time Lost to Helpdesk", "Meeting Time Saved", "Reduced Employee Time Lost to Security Incidents", "Faster Surface Deployment to Employees", "Faster Bootup Time"];
  const fieldsWithBothCostAndHours = ["Reduced IT Deployment Costs", "Reduced IT Staff Time for Ongoing Security", "IT Training Costs"];
  const allfieldsWithHours = ["helpdeskSupport", "ongoingMaintenance", "securityIncidentResponse", "reducedITDeploymentCosts", "ongoingSecurity", "itTraining", "productivityGains", "employeeTimeLosttoHelpdesk", "meetingTime", "employeeTimeLostToSecurityIncidents", "surfaceDeployment", "bootupTime"];

  //If this has to be removed, then make both getReport API calls in getData, getDataClone, handleYearsChange, Standard-Advanced Pivot linkClick
  useEffect(() => {
    handleChange(null, userInput.assumptions || {});
  }, [userInput.assumptions]);

  //For chatbot responses learnmore option
  useEffect(() => {
    if(openFullReport === true){
      getReportPageData(userInput);
      setShowCompleteReport(true);
      setOpenFullReport(false);
    }
  }, [openFullReport]);

  const handleCustomOptions = (item) => {
    const id = item.key;
    const value = defaultValues[id];
    if(value){
      setCustomValues(
        customValues.map((i) => {
          if(i.key === id){
            return {
              ...i,
              surfaceValue: 0, 
              otherPCVal: 0,
            }
          }
          return i;
        })
      );
    }
    const updatedValues = {
      ...defaultValues,
      [id]: !value,
    };
    setdefaultValues(updatedValues);
  }

  const handleNoAccessories = (flag) => {
    setAssChoice(flag);    
    // userInput.options["wantAccessories"] = flag;
    // if (!flag) {
    // delete userInput.advancedInput;
    //   getReport(userInput, true);
    //   getReport(userInput);
    // }


    setUserInput((state) => {
      let updatedList = Object.assign({}, state);
      updatedList.options["wantAccessories"] = flag;
      delete updatedList.advancedInput;

      getReport(updatedList, true);
      getReport(updatedList);
      
      return updatedList;
    });
  }

  const getData = () => {

    setUserInput({
      ...userInput,
      options: {
        ...userInput.options,
        includeCameraPeripheralSavings: defaultValues["isCamera"],
        includeLightsPeripheralSavings: defaultValues["isLight"],
        includeHeadsetPeripheralSavings: defaultValues["isHeadset"],
        includeDockCost: defaultValues["isDock"],
        includePenCost: defaultValues["isPen"],
        includeTypeCoverCost: defaultValues["isKeyboard"],
        includeExtraPowerSupplyCost: defaultValues["isPowerSupply"],
      },
      assumptions: {
        ...userInput.assumptions,
        PCCameraCost: customValues[0]["otherPCVal"],
        PCDockCost: customValues[3]["otherPCVal"],
        PCHeadsetCost: customValues[2]["otherPCVal"],
        PCLightsCost: customValues[1]["otherPCVal"],
        PCPenCost: customValues[4]["otherPCVal"],
        PCPowerSupplyCost: customValues[6]["otherPCVal"],
        PCTypeCoverCost: customValues[5]["otherPCVal"],
        SurfaceCameraCost: customValues[0]["surfaceValue"],
        SurfaceDockCost: customValues[3]["surfaceValue"],
        SurfaceHeadsetCost: customValues[2]["surfaceValue"],
        SurfaceLightsCost: customValues[1]["surfaceValue"],
        SurfacePenCost: customValues[4]["surfaceValue"],
        SurfacePowerSupplyCost: customValues[6]["surfaceValue"],
        SurfaceTypeCoverCost: customValues[5]["surfaceValue"],
      }
    });

    const requestData = {
      ...userInput,
      options: {
        ...userInput.options,
        includeCameraPeripheralSavings: defaultValues["isCamera"],
        includeLightsPeripheralSavings: defaultValues["isLight"],
        includeHeadsetPeripheralSavings: defaultValues["isHeadset"],
        includeDockCost: defaultValues["isDock"],
        includePenCost: defaultValues["isPen"],
        includeTypeCoverCost: defaultValues["isKeyboard"],
        includeExtraPowerSupplyCost: defaultValues["isPowerSupply"],
      },
      assumptions: {
        ...userInput.assumptions,
        PCCameraCost: customValues[0]["otherPCVal"],
        PCDockCost: customValues[3]["otherPCVal"],
        PCHeadsetCost: customValues[2]["otherPCVal"],
        PCLightsCost: customValues[1]["otherPCVal"],
        PCPenCost: customValues[4]["otherPCVal"],
        PCPowerSupplyCost: customValues[6]["otherPCVal"],
        PCTypeCoverCost: customValues[5]["otherPCVal"],
        SurfaceCameraCost: customValues[0]["surfaceValue"],
        SurfaceDockCost: customValues[3]["surfaceValue"],
        SurfaceHeadsetCost: customValues[2]["surfaceValue"],
        SurfaceLightsCost: customValues[1]["surfaceValue"],
        SurfacePenCost: customValues[4]["surfaceValue"],
        SurfacePowerSupplyCost: customValues[6]["surfaceValue"],
        SurfaceTypeCoverCost: customValues[5]["surfaceValue"],
      }
    }

    //As long as you have an useEffect with [userInput.assumptions] as dependencies array, you don't have to make this API call.
    // getReport(requestData, true);
    // getReport(requestData);
  }

  const getDataClone = (updatedValues) => {

    setUserInput({
      ...userInput,
      options: {
        ...userInput.options,
        includeCameraPeripheralSavings: updatedValues["isCamera"],
        includeLightsPeripheralSavings: updatedValues["isLight"],
        includeHeadsetPeripheralSavings: updatedValues["isHeadset"],
        includeDockCost: updatedValues["isDock"],
        includePenCost: updatedValues["isPen"],
        includeTypeCoverCost: updatedValues["isKeyboard"],
        includeExtraPowerSupplyCost: updatedValues["isPowerSupply"],
      },
      assumptions: {
        ...userInput.assumptions,
        PCCameraCost: customValues[0]["otherPCVal"],
        PCDockCost: customValues[3]["otherPCVal"],
        PCHeadsetCost: customValues[2]["otherPCVal"],
        PCLightsCost: customValues[1]["otherPCVal"],
        PCPenCost: customValues[4]["otherPCVal"],
        PCPowerSupplyCost: customValues[6]["otherPCVal"],
        PCTypeCoverCost: customValues[5]["otherPCVal"],
        SurfaceCameraCost: customValues[0]["surfaceValue"],
        SurfaceDockCost: customValues[3]["surfaceValue"],
        SurfaceHeadsetCost: customValues[2]["surfaceValue"],
        SurfaceLightsCost: customValues[1]["surfaceValue"],
        SurfacePenCost: customValues[4]["surfaceValue"],
        SurfacePowerSupplyCost: customValues[6]["surfaceValue"],
        SurfaceTypeCoverCost: customValues[5]["surfaceValue"],
      }
    });

    const requestData = {
      ...userInput,
      options: {
        ...userInput.options,
        includeCameraPeripheralSavings: updatedValues["isCamera"],
        includeLightsPeripheralSavings: updatedValues["isLight"],
        includeHeadsetPeripheralSavings: updatedValues["isHeadset"],
        includeDockCost: updatedValues["isDock"],
        includePenCost: updatedValues["isPen"],
        includeTypeCoverCost: updatedValues["isKeyboard"],
        includeExtraPowerSupplyCost: updatedValues["isPowerSupply"],
      },
      assumptions: {
        ...userInput.assumptions,
        PCCameraCost: customValues[0]["otherPCVal"],
        PCDockCost: customValues[3]["otherPCVal"],
        PCHeadsetCost: customValues[2]["otherPCVal"],
        PCLightsCost: customValues[1]["otherPCVal"],
        PCPenCost: customValues[4]["otherPCVal"],
        PCPowerSupplyCost: customValues[6]["otherPCVal"],
        PCTypeCoverCost: customValues[5]["otherPCVal"],
        SurfaceCameraCost: customValues[0]["surfaceValue"],
        SurfaceDockCost: customValues[3]["surfaceValue"],
        SurfaceHeadsetCost: customValues[2]["surfaceValue"],
        SurfaceLightsCost: customValues[1]["surfaceValue"],
        SurfacePenCost: customValues[4]["surfaceValue"],
        SurfacePowerSupplyCost: customValues[6]["surfaceValue"],
        SurfaceTypeCoverCost: customValues[5]["surfaceValue"],
      }
    }

    //As long as you have an useEffect with [userInput.assumptions] as dependencies array, you don't have to make this API call.
    // getReport(requestData, true);
    // getReport(requestData);
  }

  const handleCostChange = () =>{
    if(!isSameCost){
      setCustomValues(
        customValues.map((item) => {
          return {
            ...item,
            otherPCVal: item.surfaceValue,
          }
        })
      );
    }
    setIsSameCost(!isSameCost);
  }

  const handleAssumChange = (flag) => {
    setDefaultAssum(flag);
    userInput.options["default"] = flag;
    userInput.options["custom"] = !flag;
    if (flag) {
      setdefaultValues(initialAccValueList);
      getDataClone(initialAccValueList);
    } else {
      setCustomValues(customValuesList);
      setIsSameCost(false);
        const values = {
          ...Object.keys(initialAccValueList).reduce((reduced, key) => ({ ...reduced, [key]: false }), {})
        }
        setdefaultValues(values);
    }
  }

  const handleDefaultChange = (event, value) => {
    const id = event.target.ariaLabel;
    const updatedValues = {
      ...defaultValues,
      [id]: value,
    };
    setdefaultValues(updatedValues);
    getDataClone(updatedValues);
  }

  const handleSurfaceValueChange = (event) => {
    setCustomValues(
      customValues.map((item) => {
        if(item.key === event.target.name){
          if(isSameCost){
            return {
              ...item,
              surfaceValue: event.target.value,
              otherPCVal: event.target.value,
            }
          }
          return {
            ...item,
            surfaceValue: event.target.value,
          }
        } else {
          return item
        }
      })
    );    
  }

  const handleSurfaceValueFocus = (event) => {
    if(event.target.value == 0){
      setCustomValues(
        customValues.map((item) => {
          if(item.key === event.target.name){
            if(isSameCost){
              return {
                ...item,
                surfaceValue: "",
                otherPCVal: "",
              }
            }
            return {
              ...item,
              surfaceValue: "",
            }
          } else {
            return item
          }
        })
      );
    }
  }

  const handleSurfaceValueBlur = (event) => {
    if(!event.target.value){
      setCustomValues(
        customValues.map((item) => {
          if(item.key === event.target.name){
            if(isSameCost){
              return {
                ...item,
                surfaceValue: 0,
                otherPCVal: 0,
              }
            }
            return {
              ...item,
              surfaceValue: 0,
            }
          } else {
            return item
          }
        })
      );    
    }
  }

  const handleOtherPCValueChange = (event) => {
    setCustomValues(
      customValues.map((item) => {
        if(item.key === event.target.name){
          return {
            ...item,
            otherPCVal: event.target.value,
          }
        } else {
          return item
        }
      })
    );
  }

  const handleOtherPCValueFocus = (event) => {
    if(event.target.value == 0){
      setCustomValues(
        customValues.map((item) => {
          if(item.key === event.target.name){
            return {
              ...item,
              otherPCVal: "",
            }
          } else {
            return item
          }
        })
      );
    }
  }

  const handleOtherPCValueBlur = (event) => {
    if(!event.target.value){
      setCustomValues(
        customValues.map((item) => {
          if(item.key === event.target.name){
            return {
              ...item,
              otherPCVal: 0,
            }
          } else {
            return item
          }
        })
      );
    }
  }

  const firstTimeCheck = () => {
    if (!sessionStorage.getItem("customization")) {
      sessionStorage.setItem("customization", "true");
      setIsShowFirstTimeModal(true);
    }
  };

  const firstTimeCheckChatbot = () => {
    if (!sessionStorage.getItem("chatbot-first-click")) {
      sessionStorage.setItem("chatbot-first-click", "true");
      setIsShowFirstTimeModalChatbot(true);
    }
  };

  useEffect(() => {
    if (Object.keys(completeReport).length > 1) {
      let updatedData = {};
      Object.keys(ASSUMPTION_ADVANCED_LIST).forEach((key) => {
        ASSUMPTION_ADVANCED_LIST[key].forEach((item) => {
          const valueKey = key === "totalCosts" ? "surfaceValue" : "savings";
          const reportItem = completeReport[key].rows.find(
            (i) => i.label === item.reportLabel
          );
          if (reportItem) {
            if(reportItem["pcHours"] !== undefined){
              updatedData[item.id] = {
                value: convertNumberToString(key === "totalCosts" ? reportItem["surfaceValue"] : reportItem["savings"] , 0, 0),
                pcValue: convertNumberToString(reportItem["pcValue"], 0, 0),
                surfaceValue: convertNumberToString(reportItem["surfaceValue"], 0, 0),
                pcHours: convertNumberToString(reportItem["pcHours"]),
                checked: true,
              };
              if(reportItem.isSurfaceInput){
                updatedData[item.id].isSurfaceValueChanged = true;
              }
              if(reportItem.isOtherPcInput){
                updatedData[item.id].isOtherPCValueChanged = true;
              }
              if(reportItem.isOtherPcHoursInput){
                updatedData[item.id].isHoursChanged = true;
              }
            } else {
              updatedData[item.id] = {
                value: convertNumberToString(key === "totalCosts" ? reportItem["surfaceValue"] : reportItem["savings"] , 0, 0),
                pcValue: convertNumberToString(reportItem["pcValue"], 0, 0),
                surfaceValue: convertNumberToString(reportItem["surfaceValue"], 0, 0),
                checked: true,
              };
              if(reportItem.isSurfaceInput){
                updatedData[item.id].isSurfaceValueChanged = true;
              }
              if(reportItem.isOtherPcInput){
                updatedData[item.id].isOtherPCValueChanged = true;
              }
            }
          } else {
            updatedData[item.id] = {
              value: 0,
              pcValue: 0,
              surfaceValue: 0,
              checked: false,
              // isSurfaceValueChanged: true,
              // isOtherPCValueChanged: true,
            };
            if(allfieldsWithHours.includes(item.id)){
              updatedData[item.id].pcHours = 0;
              // updatedData[item.id].isHoursChanged = true;
            }
          }
        });
      });
      setAdvancedInput(updatedData);
      if(Object.keys(initialAdvancedInput).length === 0 || updateInitialAdvancedInput){
        setInitialAdvancedInput(updatedData);
        setUpdateInitialAdvancedInput(false);
      }
    }
  }, [completeReport]);

  useEffect(() => {   
    
    scrollToSummary();

    const handleResize = () => {
      setSimpleReport((state) => {
        return {
          ...state,
          key: uuid(),
        };
      });
    };

    window.addEventListener("resize", _.debounce(handleResize, 500));
    return () => {
      window.removeEventListener("resize", _.debounce(handleResize, 500));
    };
    
    
  }, []);

  useEffect(() => {
    if (!isObjectEmpty(advancedInput)) {
      let diff = {};
      for (let key in advancedInput) {
        // if(key === "accessoriesSavings"){
        //   continue;
        // }
        let object1 = initialAdvancedInput[key];
        let object2 = advancedInput[key];
        for(let key2 in object1){
          if(key2 && (object1[key2] !== object2[key2])){

            // if (JSON.stringify(advancedInput[key]) !== JSON.stringify(initialAdvancedInput[key])) {
              //check which field is changed and then accordingly set isValueChanged, isHoursChanged
              if(advancedInput[key].pcHours !== undefined){
                diff[key] = {
                  ...advancedInput[key],
                  value: convertStringToNumber(advancedInput[key].value),
                  pcValue: convertStringToNumber(advancedInput[key].pcValue),
                  surfaceValue: convertStringToNumber(advancedInput[key].surfaceValue),
                  pcHours: convertStringToNumber(advancedInput[key].pcHours),
                  checked: advancedInput[key].checked,
                };
              } else {
                diff[key] = {
                  ...advancedInput[key],
                  value: convertStringToNumber(advancedInput[key].value),
                  pcValue: convertStringToNumber(advancedInput[key].pcValue),
                  surfaceValue: convertStringToNumber(advancedInput[key].surfaceValue),
                  checked: advancedInput[key].checked,
                };
              }
            // }
          }
        }
      }
      setUserInput((state) => {
        let updatedState = Object.assign({}, state);
        if (isObjectEmpty(diff)){
          delete updatedState.advancedInput;
        } else {
          updatedState = {
            ...updatedState,
            advancedInput: diff,
          };
        }
        return updatedState;
      });
    }
  }, [advancedInput]);

  useEffect(() => {
    const savingsData = [];
    simpleReport.details?.forEach(i => {
      savingsData.push(formatCurrency(i.savings, currencySymbol, 0) + "\nSavings");
    });
    setSavings(savingsData);
  }, [simpleReport]);

  const costBarChartLabel = [
    {
      id: "Device",
      label: simpleReport.details ? savings[0] : "0\nSavings",
      tooltip: "Device Costs",
    },
    {
      id: "IT",
      label: simpleReport.details ? savings[1] : "0\nSavings",
      tooltip: "IT Efficiency",
    },
    {
      id: "Employee",
      label: simpleReport.details ? savings[2] : "0\nSavings",
      tooltip: "Employee Experience",
    },
  ];

  const costBarChartData = useMemo(() => {
    let surfaceData = {
      name: "Surface",
      value: [],
    };
    let pcData = {
      name: "Other PC",
      value: [],
    };
    simpleReport.details?.forEach((item) => {
      const currentLabel = costBarChartLabel.find((i) => {
        return item.label.includes(i.id);
      })?.tooltip;
      surfaceData.value.push({
        name: currentLabel,
        value: item.surfaceValue,
      });
      pcData.value.push({
        name: currentLabel,
        value: item.pcValue,
      });
    });
    return {
      chartData: [surfaceData, pcData],
      label: costBarChartLabel.map((i) => i.label),
      currencySymbol: currencySymbol,
    };
  }, [simpleReport, savings]);

  const getMobileCostBarChartData = (item) => {
    const currentLabel = costBarChartLabel.find((i) => {
      return item.label.includes(i.id);
    })?.tooltip;
    return {
      chartData: [
        {
          name: "Surface",
          value: [{ name: currentLabel, value: item.surfaceValue }],
        },
        {
          name: "Other PC",
          value: [{ name: currentLabel, value: item.pcValue }],
        },
      ],
      label: [currentLabel],
      currencySymbol: currencySymbol,
    };
  };

  const standardStyles = mergeStyleSets({
    container: {
      paddingRight: "2rem",
    },
    section: {
      paddingTop: "1.214rem"
    },
    sectiontitle: {
      textTransform: "capitalize",
      fontSize: "1.25rem",
      fontWeight: 600,
      lineHeight: "1.25rem",
      marginBottom: "1.625rem",
    },
    topSectionWrapper: {
      margin: "5vh 0",
      gap: "2rem",
    },
    topSectionLeft: {
      padding: "1.5rem 0",
      "> div:first-child": {
        marginBottom: "2rem",
      },
    },
    topSectionLeftRow: {
      alignItems: "center",
      color: "#0078D4",
    },
    topSectionLeftLabel: {
      width: "12rem",
      fontSize: "1rem",
      lineHeight: "1.25rem",
    },
    topSectionValue: {
      fontSize: "2rem",
      fontWeight: 600,
      lineHeight: "3rem",
    },
    chartRowWrapper: {
      marginLeft: chatbotExpanded ? "8%" : "4%",
      textAlign: "center",
      justifyContent: "flex-end",
      alignItems: "center",
      "> div:nth-child(1)": {
        width: "32%",
      },
      "> div:nth-child(2)": {
        width: "30%",
      },
      "> div:nth-child(3)": {
        width: "32%",
      },
    },
    xaxisLabelWrapper: {
      "> div:nth-child(1)": {
        width: "30%",
      },
      "> div:nth-child(2)": {
        width: "32.5%",
      },
      "> div:nth-child(3)": {
        width: "30%",
      },
    },
    sumValueWrapper: {
      // marginBottom: "0.5rem",
    },
    xaxisLabel: {
      fontSize: "0.75rem",
      lineHeight: "0.875rem",
      gap: "1.8rem",
      justifyContent: "center",
    },
    topSectionRight: {
      padding: "1.5rem",
      color: "#107C10",
      gap: "2rem",
    },
    mobileChartWrapper: {
      marginBottom: "1rem",
    },
    mobileChartValue: {
      fontSize: "2rem",
      fontWeight: 600,
      lineHeight: "1.6rem",
      color: "#0078D4",
      textAlign: "center",
      marginBottom: "1rem",
      marginLeft: "2rem",
    },
    summaryItem: {
      textAlign: "center",
      height: "fit-content",
      "h2": {
        color: "#0078D4",
        marginTop: "0",
        marginBottom: "0",
        fontSize: "2.14rem",
        fontWeight: 600,
      },
      "p": {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        textTransform: "uppercase",
        color: "#0078D4",
        fontSize: "1.28rem",
        fontWeight: 600,
        marginTop: "0",
        marginBottom: "0",
      },
      "span": {
        textTransform: "uppercase",
        color: "#0078D4",
        display: "block",
        fontSize: "0.857rem",
        fontWeight: 400,
      },
    },
    summaryContainer: {
      display: "flex",
      width: "100%",
      justifyContent: "space-between",
      paddingBottom: "0.5rem",
      "div:nth-child(-n+2) p": {
        fontWeight: 600,
      },
    },
    summaryWrapper: {
      width: "100%",
      paddingTop: "1.428rem",
    },
    summaryTitle: {
      textTransform: "capitalize",
      fontSize: "1.428rem",
      fontWeight: 600,
      color: "#0078D4",
      lineHeight: "0.1rem",
      marginBottom: "1.495rem",
      textAlign: "center", 
    },
    titleWrapper: {
      display: "flex",
      gap: "0.625rem",
      justifyContent: "center",
      alignItems: "center",
      fontSize: chatbotExpanded ? "0.8rem" : "1rem",
      fontWeight: 600,
      lineHeight: "1.25rem",
      color: "#0078D4",
      textTransform: "uppercase",
    },
    infoIcon: {
      cursor: "pointer",
      fontSize: "1.25rem",
      color: "#201F1E",
    },
    titleWrapperMobile: {
      marginBottom: "1rem",
    },
    summaryLinesWrapper: {
      display: "flex",
      "> div:nth-child(1)": {
        flex: 1,
      },
      "> div:nth-child(2)": {
        width: "22%",
      },
      "> div:nth-child(3)": {
        flex: 1,
      },
    },
    summaryLine: {
      height: "1px",
      borderBottom: "1px solid #0078D4",
    },
    infoIconUpdated: {
      cursor: "pointer",
      fontSize: "1.25rem",
      color: "#0078D4",
      paddingLeft: "0.5rem",
    },
  });

  const calloutStyles = mergeStyleSets({
    callout: {
      padding: '0.7rem 1.5rem',
      width: 380,
      maxWidth: '90%',
      "span": {
        display: "block",
        paddingBottom: "0.7rem",
      }
    },
    calloutAlternative: {
      padding: '0rem 1.5rem',
      width: 150,
      maxWidth: '90%',
    },
    calloutForSummaryItems: {
      padding: '1rem 1.5rem',
      width: 500,
      maxWidth: '90%',
      "span": {
        display: "block",
        paddingBottom: "0.7rem",
      }
    },
    tooltipAlign: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    }
  });

  const scrollToSummary = () => {
    let el = document.getElementById('summary1');
    // el.scrollIntoView({behavior:"smooth"});
  }

  const summary = (
    <div className={`${standardStyles.summaryWrapper} summary-wrapper`}>
      <div className={`${standardStyles.summaryLinesWrapper} ${chatbotExpanded ? 'summary-lines-wrapper-reduced' : 'summary-lines-wrapper'}`}>
        <div className={`${standardStyles.summaryLine}`}></div>
        <div className={`${standardStyles.summaryTitle} tco-standard-view-summary-title`}>Summary</div>
        <div className={`${standardStyles.summaryLine}`}></div>
      </div>
      <div className={`${standardStyles.summaryContainer} ${chatbotExpanded ? 'summary-container-reduced' : 'summary-container'}`}>
        <div className={`${standardStyles.summaryItem} ${chatbotExpanded ? 'summary-item-reduced' : 'summary-item'}`}>
          <h2>{formatCurrency(simpleReport.costs, currencySymbol, 0)}</h2>
          <p>Total cost</p>
        </div>
        <div className={`${standardStyles.summaryItem} ${chatbotExpanded ? 'summary-item-reduced' : 'summary-item'}`}>
          <h2>{formatCurrency(simpleReport.savings, currencySymbol, 0)}</h2>
          <p>Total savings</p>
        </div>
        <div className={`${standardStyles.summaryItem} ${chatbotExpanded ? 'summary-item-reduced' : 'summary-item'}`}>
          <h2 className="capitalize">{simpleReport.costs ? calculateNPVAndBPB(simpleReport.costs, simpleReport.savings, 'PBP', "Standard", discountRate.pbp) : "0 Months"}</h2>
          <p><div>Payback period</div>
          <FontIcon
            id={summaryPaybackId}
            iconName="Info"
            tabIndex={0}
            className={`${standardStyles.infoIconUpdated} info-icon-updated tco-assumptions-right-part-title-icon`}
            onClick={toggleIsPaybackCalloutVisible}
            directionalHint={DirectionalHint.rightCenter}
          />
          {isPaybackCalloutVisible && (
            <Callout 
              className={calloutStyles.calloutForSummaryItems} 
              target={`#${summaryPaybackId}`} 
              onDismiss={toggleIsPaybackCalloutVisible} 
              role="alert"
              gapSpace={0}
            >
              <Text>
                PBP = (Total Savings / ( 1 + Discount Rate*) ^ No.of years* ) / Total Cost
              </Text>
              <Text>
                *Discount Rate = 6.20%
              </Text>
              <Text>
                *No.of years = {sessionStorage.getItem("years")}
              </Text>
            </Callout>
          )}
          </p>
        </div>
        <div className={`${standardStyles.summaryItem} ${chatbotExpanded ? 'summary-item-reduced' : 'summary-item'}`}>
          <h2>{formatCurrency(calculateNPVAndBPB(simpleReport.costs, simpleReport.savings, 'NPV', "Standard", discountRate.npv), currencySymbol, 0)}</h2>
          <p><div>Net Present Value</div>
          <FontIcon
            id={summaryNPVId}
            iconName="Info"
            tabIndex={0}
            className={`${standardStyles.infoIconUpdated} info-icon-updated tco-assumptions-right-part-title-icon`}
            onClick={toggleIsNPVCalloutVisible}
            directionalHint={DirectionalHint.rightCenter}
          />
          {isNPVCalloutVisible && (
            <Callout 
              className={calloutStyles.calloutForSummaryItems} 
              target={`#${summaryNPVId}`} 
              onDismiss={toggleIsNPVCalloutVisible} 
              role="alert"
              gapSpace={0}
            >
              <Text>
                NPV = (Total Savings / ( 1 + Discount Rate*) ^ No.of years* ) - Total Cost
              </Text>
              <Text>
                *Discount Rate = 6.20%
              </Text>
              <Text>
                *No.of years = {sessionStorage.getItem("years")}
              </Text>
            </Callout>
          )}
          </p>           
        </div>
      </div>
    </div>
  );

  const standardView = (
    <Stack
      className={`${standardStyles.container} tco-standard-view-content-wrapper`}
    >
      <div className={`${standardStyles.section} tco-standard-view-first-section`}>
        {isSmallWidthScreen() ? (
          <div className={standardStyles.mobileChartWrapper}>
            {simpleReport.details?.map((item, index) => {
              const chartTitleList = [
                {id: "device-definition", title: "Device", def: tooltipData["deviceDefinition"], callOut: toggleDeviceCalloutVisible, callOutVisibility: isDeviceCalloutVisible},
                {id: "it-definition", title: "IT Efficiency", def: tooltipData["itEfficiencyDefinition"], callOut: toggleITCalloutVisible, callOutVisibility: isITCalloutVisible},
                {id: "employee-definition", title: "Employee Experience", def: tooltipData["employeeExperienceDefinition"], callOut: toggleEmployeeCalloutVisible, callOutVisibility: isEmployeeCalloutVisible},
              ];
              return (
                <div key={index} className={`${standardStyles.mobileChartWrapper} tco-mobile-chart-wrapper`}>
                  <div className={`${standardStyles.titleWrapper} ${standardStyles.titleWrapperMobile} tco-chart-title-wrapper`} >
                    <span>{chartTitleList[index]["title"]}</span>
                    <FontIcon
                      // aria-describedby="assumptions-tooltip"
                      id={chartTitleList[index]["id"]}
                      iconName="Info"
                      tabIndex={0}
                      className={`${standardStyles.infoIcon} tco-assumptions-right-part-title-icon`}
                      onClick={chartTitleList[index]["callOut"]}
                      directionalHint={DirectionalHint.topCenter}
                    />
                    {chartTitleList[index]["callOutVisibility"] && (
                      <Callout className={calloutStyles.callout} target={`#${chartTitleList[index]["id"]}`} onDismiss={chartTitleList[index]["callOut"]} role="alert">
                        <DelayedRender>
                          <Text variant="small">
                            {chartTitleList[index]["def"]}
                          </Text>
                        </DelayedRender>
                      </Callout>
                    )}
                  </div>
                  <div className={`${standardStyles.mobileChartValue} mobile-chart-value`}>
                    {`${formatNumberWithUnit(item.savings, currencySymbol)} Savings`}
                  </div>
                  <ReactEcharts
                    options={BAR_COMPARISON_CHART_OPTIONS(
                      getMobileCostBarChartData(item),
                      TCO_SIMPLE_REPORT_CHART_COLOR.cost
                    )}
                    width="100%"
                    height="17rem"
                  />
                </div>
              );
            })}
          </div>
        ) : (
          <>
            <Stack
              horizontal
              className={`${standardStyles.sumValueWrapper} ${standardStyles.chartRowWrapper} tco-standard-view-sum-value-wrapper`}
            >
              <div className={`${standardStyles.titleWrapper}`} >
                <span>Device</span>
                <FontIcon
                  // aria-describedby="assumptions-tooltip"
                  id="device-definition"
                  iconName="Info"
                  tabIndex={0}
                  className={`${standardStyles.infoIcon} tco-assumptions-right-part-title-icon`}
                  onClick={toggleDeviceCalloutVisible}
                  directionalHint={DirectionalHint.rightCenter}
                />
                {isDeviceCalloutVisible && (
                  <Callout className={calloutStyles.callout} target={`#device-definition`} onDismiss={toggleDeviceCalloutVisible} role="alert">
                    <DelayedRender>
                      <Text variant="small">
                        {tooltipData["deviceDefinition"]}
                      </Text>
                    </DelayedRender>
                  </Callout>
                )}
              </div>
              <div className={`${standardStyles.titleWrapper}`}>
                <span>IT Efficiency</span>
                <FontIcon
                  // aria-describedby="assumptions-tooltip"
                  id="it-definition"
                  iconName="Info"
                  tabIndex={0}
                  className={`${standardStyles.infoIcon} tooltip tco-assumptions-right-part-title-icon`}
                  onClick={toggleITCalloutVisible}
                  directionalHint={DirectionalHint.rightCenter}
                />
                {isITCalloutVisible && (
                  <Callout className={calloutStyles.callout} target={`#it-definition`} onDismiss={toggleITCalloutVisible} role="alert">
                    <DelayedRender>
                      <Text variant="small">
                        {tooltipData["itEfficiencyDefinition"]}
                      </Text>
                    </DelayedRender>
                  </Callout>
                )}
              </div>
              <div className={`${standardStyles.titleWrapper}`} >
                <span>Employee Experience</span>
                <FontIcon
                  // aria-describedby="assumptions-tooltip"
                  id="employee-definition"
                  iconName="Info"
                  tabIndex={0}
                  className={`${standardStyles.infoIcon} tco-assumptions-right-part-title-icon`}
                  onClick={toggleEmployeeCalloutVisible}
                  directionalHint={DirectionalHint.rightCenter}
                />
                {isEmployeeCalloutVisible && (
                  <Callout className={calloutStyles.callout} target={`#employee-definition`} onDismiss={toggleEmployeeCalloutVisible} role="alert">
                    <DelayedRender>
                      <Text variant="small">
                        {tooltipData["employeeExperienceDefinition"]}
                      </Text>
                    </DelayedRender>
                  </Callout>
                )}
              </div>
            </Stack>
            <ReactEcharts
              options={BAR_COMPARISON_CHART_OPTIONS(
                costBarChartData,
                TCO_SIMPLE_REPORT_CHART_COLOR.cost,
                chatbotExpanded
              )}
              width="100%"
              height="24rem"
            />
          </>
        )}
        <ChartDisclaimer />
      </div>
      {summary}
    </Stack>
  );

  const advancedStyles = mergeStyleSets({
    container: {
      marginTop: "1rem",
      justifyContent: "space-between",
      height: `calc(100vh - ${headerHeight}px - 44px - 2rem)`,
    },
    inputWrapper: {
      padding: "0 0.2rem 0 0",
      marginRight: "1.8rem",
      display: "flex",
      flexDirection: "column",
      overflowY: "auto",
      boxSizing: "border-box",
      // gap: "4.84%",
      // justifyContent: "center",
      // flexWrap: "wrap",
      // alignItems: "flex-start",
      ">div": {
        width: "100%",
      },
    },
    checkBoxWrapper: {
      backgroundColor: "#FFFFFF",
      padding: "1rem 2rem 0.2rem 1rem",
      marginBottom: "1.43rem",
      boxSizing: "border-box",
    },
    selectAll: {
      marginBottom: "1.5rem",
    },
    sectionTitle: {
      fontSize: "1.428rem",
      fontWeight: 600,
      lineHeight: "1.9rem",
      alignItems: "center",
      justifyContent: "space-between",
      gap: "1rem",
      marginBottom: "1rem",
      paddingBottom: "0.5rem",
      span: {
        display: "block",
        textTransform: "uppercase",
        fontSize: "1.142rem",
        fontWeight: 400,
      }
    },
    titleWithMargin: {
      marginTop: "1.25rem",
    },
    summaryButtonWrapper: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      paddingRight: "2rem",
      width: "100%",
      boxSizing: "border-box",
    },
    summaryButton: {
      margin: "0",
      color: "#191919",
      width: "max-content",
      padding: "0",
      height: "2.25rem",
      ":hover": {
        cursor: "pointer",
      },
    },
    summaryButtonTextWrapper: {
      gap: "0.5rem",
      alignItems: "center",
      span: {
        fontSize: "1.428rem",
        fontWeight: 600,
        color: "#0078D4",
      },
    },
    summaryWrapper: {
      ".ms-Callout-beak": {
        backgroundColor: "#0078D4",
      },
    },
    cancelIcon: {
      color: "#ffffff",
      fontSize: 16,
      cursor: "pointer",
      position: "absolute",
      top: "1rem",
      right: "1rem",
      zIndex: 3,
    },
    summaryContentWrapper: {
      padding: "1.375rem 4rem 0 1.5rem",
      width: "100%",
      backgroundColor: "#0078D4",
    },
    summaryLabel: {
      fontSize: "1rem",
      fontWeight: 600,
      lineHeight: "1rem",
      marginBottom: "0.5rem",
      color: "#ffffff",
    },
    summaryValue: {
      fontSize: "2.5rem",
      fontWeight: 600,
      lineHeight: "2.5rem",
      marginBottom: "2rem",
      color: "#ffffff",
    },
    okayButton: {
      borderColor: "#ffffff",
      borderRadius: "0.125rem",
      color: "#0078D4",
      alignSelf: "flex-end",
      marginBottom: "1.6875rem",
      marginRight: "-2.5rem",
      ":hover": {
        backgroundColor: "#ffffff",
        color: "#0078D4",
      },
      ":active": {
        backgroundColor: "#ffffff",
        color: "#0078D4",
      },
    },
    totalValueWrapper: {
      margin: "0",
      padding: "0 2rem 1.428rem 0rem",
      display: "flex",
      justifyContent: "space-around",
      boxSizing: "border-box",
      ">div": {
        textAlign: "center",
      },
    },
    totalValueLabel: {
      color: "#000000",
      fontSize: "1.25rem",
      fontWeight: 400,
      lineHeight: "1.6875rem",
      marginBottom: "0.25rem",
    },
    totalValue: {
      fontSize: "2.5rem",
      fontWeight: 700,
      lineHeight: "3rem",
      color: "#0078D4",
    },
    spinner: {
      height: "calc(100vh - 44px - 3rem)",
    },
    infoIcon: {
      fontSize: "1.25rem",
      color: "#201F1E",
    },
    infoIconSummary: {
      fontSize: "1.25rem",
      color: "#0078D4",
    },
    totalFieldWrapper: {
      "> label": {
        textTransform: "uppercase",
        fontSize: "1rem",
        fontWeight: 400,
        color: "#201F1E",
        marginRight: "1.428rem",
      },
      "> input": {
        width: "8.84rem",
        maxWidth: "8.84rem",
        minWidth: "8.84rem",
        padding: "0.428rem 0.571rem",
        boxSizing: "border-box",
        border: "1px solid #0078D4",
        borderRadius: "2px",
      },
    },
    yearsSelection: {
      width: "100%",
      margin: "0 auto",
    },
    // yearsFields: {
    //   display: "flex",
    //   boxSizing: "border-box",
    //   alignItems: "center",
    //   padding: "1rem 2.5rem 0.2rem 1rem",
    //   marginRight: "1.8rem",
    //   marginBottom: "1rem",
    //   "> div:first-child": {
    //     width: "calc(100% - 8.84rem)",
    //     textAlign: "right",
    //     marginRight: "1.428rem",
    //   },
    //   "> div:nth-child(2)": {
    //     width: "30%",
    //     width: "8.84rem",
    //     maxWidth: "8.84rem",
    //     minWidth: "8.84rem",
    //   }
    // }
    yearsFields: {
      display: "flex",
      boxSizing: "border-box",
      alignItems: "center",
      padding: "1rem 2rem 1rem 1rem",
      "> div:first-child": {
        width: "calc(100% - 8.84rem)",
        textAlign: "right",
        marginRight: "1.428rem",
      },
      "> div:nth-child(2)": {
        width: "30%",
        width: "8.84rem",
        maxWidth: "8.84rem",
        minWidth: "8.84rem",
      }
    }
  });

  const handleToggleChange = (val, key) => {
    let updatedData = Object.assign({}, advancedInput);
    ASSUMPTION_ADVANCED_LIST[key].forEach((item) => {
      updatedData[item.id] = {
        ...updatedData[item.id],
        // value: updatedData[item.id].value,
        // pcValue: updatedData[item.id].pcValue,
        checked: val,
      };
    });
    setAdvancedInput(updatedData);
    sessionStorage.setItem("FurtherEditing", "false");
    fetchReportDataClone(updatedData);
  };

  const handleToggleChangeSustainability = async (val) => {
    let updatedUserInput = userInput;
    await setUserInput((currentState) => {
      updatedUserInput = {
        ...currentState,
        sustainability: {
          ...currentState.sustainability,
          Energy: {
            ...currentState.sustainability.Energy,
            checked: val,
          },
          CarbonEmissions: {
            ...currentState.sustainability.CarbonEmissions,
            checked: val,
          }
        }
      }
      return updatedUserInput;
    });
    getReportPageData(updatedUserInput);
    sessionStorage.setItem("FurtherEditing", "false");
  };

  const isSelectedAll = useMemo(() => {
    let result = {};
    Object.keys(ASSUMPTION_ADVANCED_LIST).forEach((key) => {
      result[key] = ASSUMPTION_ADVANCED_LIST[key].every(
        (i) => advancedInput[i.id]?.checked === true
      );
    })
    return result;
  }, [advancedInput]);

  const advancedData = useMemo(() => {
    let result = {};
    const getSum = (key) => {
      return ASSUMPTION_ADVANCED_LIST[key].reduce(
        (acc, curr) => {
          let result = acc;
          if (advancedInput[curr.id] && advancedInput[curr.id].checked) {
            result = acc + convertStringToNumber(advancedInput[curr.id].value);
          }
          return result;
        },
        0
      );
    }

    result.sustainability = convertStringToNumber(sustainabilitySavings.totalSavings || 0);

    if (!isObjectEmpty(advancedInput)) {
      result.totalCosts = getSum("totalCosts");
      result.deviceSavings = getSum("totalSavings");
      result.itSimplification = getSum("itSimplification");
      result.employeeEmpowerment = getSum("employeeEmpowerment");
      result.totalSavings =
        result.deviceSavings +
        result.itSimplification +
        result.employeeEmpowerment +
        result.sustainability;
      result.roi =
        result.totalCosts === 0 ? 0 : (result.totalSavings / result.totalCosts) * 100;
    }
    
    setTotalValues({
      totalCosts: convertNumberToString(result.totalCosts),
      deviceSavings: convertNumberToString(result.deviceSavings),
      itSimplification: convertNumberToString(result.itSimplification),
      employeeEmpowerment: convertNumberToString(result.employeeEmpowerment),
      sustainability: convertNumberToString(result.sustainability),
    });
    return result;
  }, [advancedInput, sustainabilitySavings]);

  const toggleSummary = (id, flag) => {
    if (id === "mobile") {
      if (flag === undefined || flag === null) {
        setShowMobileSummary(!showMobileSummary);
      } else {
        setShowMobileSummary(flag);
      }
    } else {
      if (flag === undefined || flag === null) {
        setShowSummary(!showSummary);
      } else {
        setShowSummary(flag);
      }
    }
  };

  const isShowSummary = (id) => {
    if (id === "mobile") {
      return showMobileSummary;
    }
    return showSummary;
  }

  const yearsList = [{key: "1", text: "1"}, {key: "2", text: "2"}, {key: "3", text: "3"}, {key: "4", text: "4"}];

  const handleYearsChange = (val) => {
    const previousYears = sessionStorage.getItem("years");
    sessionStorage.setItem("years", val.years);
    setUserInput((state) => {
      // getReport(state, true);
      return {
        ...state,
        assumptions: {
          ...state.assumptions,
          warrantyAndMaintenanceCost: convertNumberToString(Number(sessionStorage.getItem("years")) * convertStringToNumber(state.assumptions.warrantyAndMaintenanceCost) / Number(previousYears)),
          pcWarrantyAndMaintenanceCost: convertNumberToString(Number(sessionStorage.getItem("years")) * convertStringToNumber(state.assumptions.pcWarrantyAndMaintenanceCost) / Number(previousYears)),
        }
      };
    });
  }

  const yearFields = useMemo(
    () => [
      {
        id: "years",
        type: "dropdown",
        placeholder: "",
        className: advancedStyles.yearsSelection,
        options: yearsList,
        onChange: (event, val) => handleYearsChange(val),
      },
    ],
    []
  );

  const yearsForm = useForm(
    yearFields,
    retainedYears
  );

  const updateUserInput = async (advancedInputCopy) => {
    if(!advancedInputCopy){
      advancedInputCopy = Object.assign({}, advancedInput);
    } else if(isObjectEmpty(advancedInputCopy)){
      advancedInputCopy = Object.assign({}, advancedInput);
    }
    if (!isObjectEmpty(advancedInputCopy)) {
      let diff = {};
      for (let key in advancedInputCopy) {
        let object1 = initialAdvancedInput[key];
        let object2 = advancedInputCopy[key];
        for(let key2 in object1){
          if(key2 && (object1[key2] !== object2[key2])){
              if(advancedInputCopy[key].pcHours !== undefined){
                diff[key] = {
                  ...advancedInputCopy[key],
                  value: convertStringToNumber(advancedInputCopy[key].value),
                  pcValue: convertStringToNumber(advancedInputCopy[key].pcValue),
                  surfaceValue: convertStringToNumber(advancedInputCopy[key].surfaceValue),
                  pcHours: convertStringToNumber(advancedInputCopy[key].pcHours),
                  checked: advancedInputCopy[key].checked,
                };
              } else {
                diff[key] = {
                  ...advancedInputCopy[key],
                  value: convertStringToNumber(advancedInputCopy[key].value),
                  pcValue: convertStringToNumber(advancedInputCopy[key].pcValue),
                  surfaceValue: convertStringToNumber(advancedInputCopy[key].surfaceValue),
                  checked: advancedInputCopy[key].checked,
                };
              }
          }
        }
      }
      let updatedState = Object.assign({}, userInput);
      await setUserInput((state) => {
        updatedState = Object.assign({}, state);
        if (isObjectEmpty(diff)){
          delete updatedState.advancedInput;
        } else {
          updatedState = {
            ...updatedState,
            advancedInput: diff,
          };
        }
        return updatedState;
      });
      return updatedState;
    }
    return userInput;
  }

  const fetchReportData = async () => {
    let updatedUserInput = await updateUserInput();
    getReportPageData(updatedUserInput);
    getReport(updatedUserInput);
  }

  const fetchReportDataClone = async (advancedInputCopy) => {
    let updatedUserInput = await updateUserInput(advancedInputCopy);
    getReportPageData(updatedUserInput);
    getReport(updatedUserInput);
  }

  const advancedView = (id) => (
    <Stack
      className={`${advancedStyles.container} tco-assumptions-advanced-wrapper`}
    >
      <div className="total-summary-wrapper">
      <div className={`${advancedStyles.summaryButtonWrapper} summary-button-wrapper`}>
        <div className={`${standardStyles.summaryLine}`}></div>
        <div
          id={`callout-button-${id}`}
          className={`${advancedStyles.summaryButton} tco-assumptions-advanced-summary-button`}
          onClick={() => toggleSummary(id)}
        >
          <Stack horizontal className={advancedStyles.summaryButtonTextWrapper}>
            <span>Summary</span>
            <FontIcon
              aria-describedby="assumptions-tooltip"
              iconName="Info"
              tabIndex={0}
              className={`${advancedStyles.infoIconSummary} tco-assumptions-right-part-title-icon`}
            />
          </Stack>
        </div>
        <div className={`${standardStyles.summaryLine}`}></div>
        {isShowSummary(id) ? (
          <Callout
            className={advancedStyles.summaryWrapper}
            role="dialog"
            gapSpace={0}
            target={`#callout-button-${id}`}
            isBeakVisible={true}
            onDismiss={() => toggleSummary(id, false)}
            // setInitialFocus
            directionalHint={DirectionalHint.rightCenter}
          >
            <FontIcon
              className={advancedStyles.cancelIcon}
              iconName="Cancel"
              onClick={() => toggleSummary(id, false)}
              tabIndex={0}
              onKeyDown={(event) => {
                if (event.key === "Enter") {
                  toggleSummary(id, false);
                }
              }}
            />
            <Stack className={advancedStyles.summaryContentWrapper}>
              <div
                className={`${advancedStyles.summaryLabel} tco-assumptions-advanced-summary-label`}
              >
                Total Savings and Benefits
              </div>
              <div
                className={`${advancedStyles.summaryValue} tco-assumptions-advanced-summary-value`}
              >
                {formatCurrency(advancedData.totalSavings, currencySymbol, 0)}
              </div>
              <div
                className={`${advancedStyles.summaryLabel} tco-assumptions-advanced-summary-label`}
              >
                Return on Investment
              </div>
              <div
                className={`${advancedStyles.summaryValue} tco-assumptions-advanced-summary-value`}
              >
                {`${formatNumberWithUnit(advancedData.roi)}%`}
              </div>
              <div
                className={`${advancedStyles.summaryLabel} tco-assumptions-advanced-summary-label`}
              >
                Total Direct Savings over {sessionStorage.getItem("years")} Years
              </div>
              <div
                className={`${advancedStyles.summaryValue} tco-assumptions-advanced-summary-value`}
              >
                {formatCurrency(advancedData.deviceSavings, currencySymbol, 0)}
              </div>
              <div
                className={`${advancedStyles.summaryLabel} tco-assumptions-advanced-summary-label`}
              >
                Total IT Efficiency Benefits over {sessionStorage.getItem("years")} Years
              </div>
              <div
                className={`${advancedStyles.summaryValue} tco-assumptions-advanced-summary-value`}
              >
                {formatCurrency(advancedData.itSimplification, currencySymbol,0)}
              </div>
              <div
                className={`${advancedStyles.summaryLabel} tco-assumptions-advanced-summary-label`}
              >
                Total Employee Experience Benefits over {sessionStorage.getItem("years")} Years
              </div>
              <div
                className={`${advancedStyles.summaryValue} tco-assumptions-advanced-summary-value`}
              >
                {formatCurrency(advancedData.employeeEmpowerment, currencySymbol, 0)}
              </div>
              <DefaultButton
                text="Okay"
                className={advancedStyles.okayButton}
                onClick={() => toggleSummary(id, false)}
              />
            </Stack>
          </Callout>
        ) : null}
      </div>
      <Stack
        horizontal
        className={`${advancedStyles.totalValueWrapper} tco-assumptions-advanced-total-value-wrapper`}
      >
        <div className={`${standardStyles.summaryContainer} ${chatbotExpanded ? 'summary-container-reduced' : 'summary-container'}`}>
          <div className={`${standardStyles.summaryItem} ${chatbotExpanded ? 'summary-item-reduced' : 'summary-item'}`}>
            <h2>{formatCurrency(advancedData.totalCosts, currencySymbol, 0)}</h2>
            <p>Total cost</p>
          </div>
          <div className={`${standardStyles.summaryItem} ${chatbotExpanded ? 'summary-item-reduced' : 'summary-item'}`}>
            <h2>{formatCurrency(advancedData.totalSavings, currencySymbol, 0)}</h2>
            <p>Total savings</p>
          </div>
          <div className={`${standardStyles.summaryItem} ${chatbotExpanded ? 'summary-item-reduced' : 'summary-item'}`}>
            <h2 className="capitalize">{calculateNPVAndBPB(advancedData.totalCosts, advancedData.totalSavings, 'PBP', "Advanced", discountRate.pbp)}</h2>
            <p>Payback period
            {
              (id === "desktop") ?
              <>
                <FontIcon
                  id={`${summaryAdvPaybackId}-${id}`}
                  iconName="Info"
                  tabIndex={0}
                  className={`${standardStyles.infoIconUpdated} info-icon-updated tco-assumptions-right-part-title-icon`}
                  onClick={toggleIsAdvPaybackCalloutVisible}
                  directionalHint={DirectionalHint.rightCenter}
                />
                {isAdvPaybackCalloutVisible && (
                  <Callout 
                    className={`${calloutStyles.calloutForSummaryItems} mobile-hide`} 
                    target={`#${summaryAdvPaybackId}-${id}`} 
                    onDismiss={toggleIsAdvPaybackCalloutVisible} 
                    role="alert"
                    gapSpace={0}
                  >
                    <Text>
                      PBP = (Total Savings / ( 1 + Discount Rate*) ^ No.of years* ) / Total Cost
                    </Text>
                    <Text>
                      *Discount Rate = 6.20%
                    </Text>
                    <Text>
                      *No.of years = {sessionStorage.getItem("years")}
                    </Text>
                  </Callout>
                )}
              </>
              :
              <>
                <FontIcon
                  id={`${summaryAdvPaybackId}-${id}`}
                  iconName="Info"
                  tabIndex={0}
                  className={`${standardStyles.infoIconUpdated} tco-assumptions-right-part-title-icon`}
                  onClick={toggleIsAdvPaybackMobileCalloutVisible}
                  directionalHint={DirectionalHint.rightCenter}
                />
                {isAdvPaybackMobileCalloutVisible && (
                  <Callout 
                    className={`${calloutStyles.calloutForSummaryItems} mobile-display-only`} 
                    target={`#${summaryAdvPaybackId}-${id}`} 
                    onDismiss={toggleIsAdvPaybackMobileCalloutVisible} 
                    role="alert"
                    gapSpace={0}
                  >
                    <Text>
                      PBP = (Total Savings / ( 1 + Discount Rate*) ^ No.of years* ) / Total Cost
                    </Text>
                    <Text>
                      *Discount Rate = 6.20%
                    </Text>
                    <Text>
                      *No.of years = {sessionStorage.getItem("years")}
                    </Text>
                  </Callout>
                )}
              </>
            }
            </p>
          </div>
          <div className={`${standardStyles.summaryItem} ${chatbotExpanded ? 'summary-item-reduced' : 'summary-item'}`}>
            <h2>{formatCurrency(calculateNPVAndBPB(advancedData.totalCosts, advancedData.totalSavings, 'NPV', "Advanced", discountRate.npv), currencySymbol, 0)}</h2>
            <p>Net Present Value
              {
                (id === "desktop") ?
                  <>
                    <FontIcon
                      id={`${summaryAdvNPVId}-${id}`}
                      iconName="Info"
                      tabIndex={0}
                      className={`${standardStyles.infoIconUpdated} info-icon-updated tco-assumptions-right-part-title-icon`}
                      onClick={toggleIsAdvNPVCalloutVisible}
                      directionalHint={DirectionalHint.rightCenter}
                    />
                    {isAdvNPVCalloutVisible && (
                      <Callout 
                        className={`${calloutStyles.calloutForSummaryItems} mobile-hide`} 
                        target={`#${summaryAdvNPVId}-${id}`} 
                        onDismiss={toggleIsAdvNPVCalloutVisible} 
                        role="alert"
                        gapSpace={0}
                      >
                        <Text>
                          NPV = (Total Savings / ( 1 + Discount Rate*) ^ No.of years* ) - Total Cost
                        </Text>
                        <Text>
                          *Discount Rate = 6.20%
                        </Text>
                        <Text>
                          *No.of years = {sessionStorage.getItem("years")}
                        </Text>
                      </Callout>
                    )}
                  </>
                :
                  <>
                    <FontIcon
                      id={`${summaryAdvNPVId}-${id}`}
                      iconName="Info"
                      tabIndex={0}
                      className={`${standardStyles.infoIconUpdated} tco-assumptions-right-part-title-icon`}
                      onClick={toggleIsAdvNPVMobileCalloutVisible}
                      directionalHint={DirectionalHint.rightCenter}
                    />
                    {isAdvNPVMobileCalloutVisible && (
                      <Callout 
                        className={`${calloutStyles.calloutForSummaryItems} mobile-display-only`} 
                        target={`#${summaryAdvNPVId}-${id}`} 
                        onDismiss={toggleIsAdvNPVMobileCalloutVisible} 
                        role="alert"
                        gapSpace={0}
                      >
                        <Text>
                          NPV = (Total Savings / ( 1 + Discount Rate*) ^ No.of years* ) - Total Cost
                        </Text>
                        <Text>
                          *Discount Rate = 6.20%
                        </Text>
                        <Text>
                          *No.of years = {sessionStorage.getItem("years")}
                        </Text>
                      </Callout>
                    )}
                  </>
              }
            </p>
            
          </div>
        </div>
      </Stack>
      </div>
      <Stack
        horizontal
        className={`${advancedStyles.inputWrapper} tco-assumptions-advanced-input-wrapper`}
      >
        <div className={`${advancedStyles.checkBoxWrapper} ${advancedStyles.yearsFields} advanced-checkbox-wrapper`}>
          <div>Number of years</div>
          <CommonForm form={yearsForm} />        
        </div>
        <div className={`${advancedStyles.checkBoxWrapper} advanced-checkbox-wrapper`}>
          <Stack
            horizontal
            className={`${advancedStyles.sectionTitle} tco-assumptions-advanced-input-section-title`}
          >
            <div>Device Costs <span>over {sessionStorage.getItem("years")} Years</span></div>
            <div className={`${advancedStyles.totalFieldWrapper} ${chatbotExpanded ? 'total-field-wrapper-reduced' : ''} mobile-hide`}>
              <label htmlFor="total-costs">Total</label>
              <input id="total-costs" type="text" value={`${currencySymbol} ${totalValues.totalCosts}`} readOnly></input>
            </div>
          </Stack>
          <FocusableCheckbox
            className={`${advancedStyles.selectAll} tco-assumptions-advanced-select-all`}
            label="Select All"
            checked={isSelectedAll.totalCosts}
            onChange={(e, val) => handleToggleChange(val, "totalCosts")}
          />
          <div className="tco-assumptions-advanced-input-section">
            {ASSUMPTION_ADVANCED_LIST.totalCosts.map((item) => (
              <AssumptionsInputItem
                key={item.id}
                item={item}
                advancedInput={advancedInput}
                setAdvancedInput={setAdvancedInput}
                inputType="positiveNumber"
                currencySymbol={currencySymbol}
                chatbotExpanded={chatbotExpanded}
                fetchReportDataClone={fetchReportDataClone}
              />
            ))}
          </div>
          <div className={`${advancedStyles.totalFieldWrapper} mobile-display-only total-field-wrapper`}>
              <label htmlFor="total-costs">Total</label>
              <input id="total-costs" type="text" value={`${currencySymbol} ${totalValues.totalCosts}`} readOnly></input>
          </div>
        </div>
        <div className={`${advancedStyles.checkBoxWrapper} advanced-checkbox-wrapper`}>
          <Stack
            horizontal
            className={`${advancedStyles.sectionTitle} tco-assumptions-advanced-input-section-title`}
          >
            <div>Device Savings <span>over {sessionStorage.getItem("years")} years</span></div>
            <div className={`${advancedStyles.totalFieldWrapper} ${chatbotExpanded ? 'total-field-wrapper-reduced' : ''} mobile-hide`}>
              <label htmlFor="total-savings">Total</label>
              <input id="total-savings" type="text" value={`${currencySymbol} ${totalValues.deviceSavings}`} readOnly></input>
            </div>
          </Stack>
          <FocusableCheckbox
            className={`${advancedStyles.selectAll} tco-assumptions-advanced-select-all`}
            checked={isSelectedAll.totalSavings}
            label="Select All"
            onChange={(e, val) => handleToggleChange(val, "totalSavings")}
          />
          <div className="tco-assumptions-advanced-input-section">
            {ASSUMPTION_ADVANCED_LIST.totalSavings.map((item) => (
              <AssumptionsInputItem
                key={item.id}
                item={item}
                advancedInput={advancedInput}
                setAdvancedInput={setAdvancedInput}
                currencySymbol={currencySymbol}
                chatbotExpanded={chatbotExpanded}
                fetchReportDataClone={fetchReportDataClone}
              />
            ))}
          </div>
          <div className={`${advancedStyles.totalFieldWrapper} mobile-display-only total-field-wrapper`}>
              <label htmlFor="total-savings">Total</label>
              <input id="total-savings" type="text" value={`${currencySymbol} ${totalValues.deviceSavings}`} readOnly></input>
          </div>
        </div>
        <div className={`${advancedStyles.checkBoxWrapper} advanced-checkbox-wrapper`}>
          <Stack
            horizontal
            className={`${advancedStyles.sectionTitle} tco-assumptions-advanced-input-section-title`}
          >
            <div>IT Efficiency <span>savings over {sessionStorage.getItem("years")} Years</span></div>
            <div className={`${advancedStyles.totalFieldWrapper} ${chatbotExpanded ? 'total-field-wrapper-reduced' : ''} mobile-hide`}>
              <label htmlFor="total-it-savings">Total</label>
              <input id="total-it-savings" type="text" value={`${currencySymbol} ${totalValues.itSimplification}`} readOnly></input>
            </div>
          </Stack>
          <FocusableCheckbox
            className={`${advancedStyles.selectAll} tco-assumptions-advanced-select-all`}
            checked={isSelectedAll.itSimplification}
            label="Select All"
            onChange={(e, val) => handleToggleChange(val, "itSimplification")}
          />
          <div className="tco-assumptions-advanced-input-section">
            {ASSUMPTION_ADVANCED_LIST.itSimplification.map((item) => (
              <AssumptionsInputItem
                key={item.id}
                item={item}
                advancedInput={advancedInput}
                setAdvancedInput={setAdvancedInput}
                currencySymbol={currencySymbol}
                chatbotExpanded={chatbotExpanded}
                fetchReportDataClone={fetchReportDataClone}
              />
            ))}
          </div>
          <div className={`${advancedStyles.totalFieldWrapper} mobile-display-only total-field-wrapper`}>
            <label htmlFor="total-it-savings">Total</label>
            <input id="total-it-savings" type="text" value={`${currencySymbol} ${totalValues.itSimplification}`} readOnly></input>
          </div>
        </div>
        <div className={`${advancedStyles.checkBoxWrapper} advanced-checkbox-wrapper`}>
          <Stack
            horizontal
            className={`${advancedStyles.sectionTitle} tco-assumptions-advanced-input-section-title`}
          >
            <div>Employee Experience <span>savings over {sessionStorage.getItem("years")} Years</span></div>
            <div className={`${advancedStyles.totalFieldWrapper} ${chatbotExpanded ? 'total-field-wrapper-reduced' : ''} mobile-hide`}>
              <label htmlFor="total-emp">Total</label>
              <input id="total-emp" type="text" value={`${currencySymbol} ${totalValues.employeeEmpowerment}`} readOnly></input>
            </div>
          </Stack>
          <FocusableCheckbox
            className={`${advancedStyles.selectAll} tco-assumptions-advanced-select-all`}
            checked={isSelectedAll.employeeEmpowerment}
            label="Select All"
            onChange={(e, val) =>
              handleToggleChange(val, "employeeEmpowerment")
            }
          />
          <div className="tco-assumptions-advanced-input-section">
            {ASSUMPTION_ADVANCED_LIST.employeeEmpowerment.map((item) => (
              <AssumptionsInputItem
                key={item.id}
                item={item}
                advancedInput={advancedInput}
                setAdvancedInput={setAdvancedInput}
                currencySymbol={currencySymbol}
                chatbotExpanded={chatbotExpanded}
                fetchReportDataClone={fetchReportDataClone}
              />
            ))}
          </div>
          <div className={`${advancedStyles.totalFieldWrapper} mobile-display-only total-field-wrapper`}>
            <label htmlFor="total-emp">Total</label>
            <input id="total-emp" type="text" value={`${currencySymbol} ${totalValues.employeeEmpowerment}`} readOnly></input>
          </div>
        </div>





        <div className={`${advancedStyles.checkBoxWrapper} advanced-checkbox-wrapper`}>
          <Stack
            horizontal
            className={`${advancedStyles.sectionTitle} tco-assumptions-advanced-input-section-title`}
          >
            <div>Sustainability <span>savings over {sessionStorage.getItem("years")} Years</span></div>
            <div className={`${advancedStyles.totalFieldWrapper} ${chatbotExpanded ? 'total-field-wrapper-reduced' : ''} mobile-hide`}>
              <label htmlFor="total-eco">Total</label>
              <input id="total-eco" type="text" value={`${currencySymbol} ${formatNumber(totalValues.sustainability)}`} readOnly></input>
            </div>
          </Stack>
          <FocusableCheckbox
            className={`${advancedStyles.selectAll} tco-assumptions-advanced-select-all`}
            checked={userInput.sustainability.Energy.checked && userInput.sustainability.CarbonEmissions.checked}
            label="Select All"
            onChange={(e, val) => handleToggleChangeSustainability(val)}
          />
          <div className="tco-assumptions-advanced-input-section">
            {[{id: "Energy", reportLabel: "Total Energy Consumption Savings", fieldLabel: "Total Energy Consumption Savings"},
            {id: "CarbonEmissions", reportLabel: "Total Carbon Emissions Savings", fieldLabel: "Total Carbon Emissions Savings"}].map((item) => (
              <AssumptionsInputItemSus
                key={item.id}
                item={item}
                userInput={userInput}
                setUserInput={setUserInput}
                currencySymbol={currencySymbol}
                chatbotExpanded={chatbotExpanded}
                getReportPageData={getReportPageData}
                sustainabilitySavings={sustainabilitySavings}
              />
            ))}
          </div>
          <div className={`${advancedStyles.totalFieldWrapper} mobile-display-only total-field-wrapper`}>
            <label htmlFor="total-eco">Total</label>
            <input id="total-eco" type="text" value={`${currencySymbol} ${formatNumber(totalValues.sustainability)}`} readOnly></input>
          </div>
        </div>








      </Stack>
    </Stack>
  );

  const getFullResultsMenuItems = () => [
    {key: "one-pager-pdf", text: "One Pager (PDF)"},
    {key: "full-report", text: "Full Report"},
    {key: "excel", text: "Excel"},
  ];

  const menuItems = getFullResultsMenuItems();

  const handleMenuItemClick = (e, item) => {
    if(item.key === "one-pager-pdf"){
      getReportPageData(userInput, true);
      trackGAEvent("Export Results", "One pager export", "Export results as One pager in TCO Results page");
    } else if(item.key === "excel"){
      exportToExcel();
      trackGAEvent("Export Results", "Excel file export", "Export results to Excel file in TCO Results page");
    } else if(item.key === "full-report") {
      getReportPageData(userInput);
      setShowCompleteReport(true);
      trackGAEvent("Results View", "Full report view", "View full report popup in TCO Results page");
    }
  }

  const bottomPartStyles = mergeStyleSets({
    container: {
      backgroundColor: "#ffffff",
    },
    buttonContainer: {
      justifyContent: "flex-end",
      gap: chatbotExpanded ? "1rem" : "1rem",
      padding: "0rem 0rem 1.25rem",
      button: {
        width: "29%",
        height: "3.275rem",
        fontSize: chatbotExpanded ? "0.9rem" : "1rem",
      },
      ".ms-Dropdown-container": {
        width: "49%",
        height: "3.275rem",
        ".ms-Dropdown:focus::after": {
          border: "none",
        },
        ".ms-Dropdown-title": {
          background: "#191919",
          fontSize: chatbotExpanded ? "0.9rem" : "1rem",
          fontWeight: 600,
          color: "white",
          border: "1px solid #191919",
          height: "3.275rem",
          lineHeight: "3.275rem",
          textAlign: "center",
        },
        ".ms-Dropdown-caretDownWrapper": {
          paddingTop: "0.1rem",
        },
        ".ms-Dropdown-caretDown": {
          background: "#191919",
          fontSize: "0.7rem",
          fontWeight: 600,
          color: "white",
          height: "3.175rem",
          lineHeight: "3.275rem",
          paddingRight: "5px",
        },
      },
    },
    buttonContainerMobile: {
      justifyContent: "center",
      gap: "1rem",
      padding: "0rem 0rem 1.25rem",
      button: {
        width: "48%",
        height: "3.275rem",
        fontSize: "1rem",
      },
      ".ms-Dropdown-container": {
        width: "49%",
        height: "3.275rem",
        ".ms-Dropdown:focus::after": {
          border: "none",
        },
        ".ms-Dropdown-title": {
          background: "#191919",
          fontSize: "1rem",
          fontWeight: 600,
          color: "white",
          border: "1px solid #191919",
          height: "3.275rem",
          lineHeight: "3.275rem",
          textAlign: "center",
        },
        ".ms-Dropdown-caretDownWrapper": {
          paddingTop: "0.1rem",
        },
        ".ms-Dropdown-caretDown": {
          background: "#191919",
          fontSize: "0.7rem",
          fontWeight: 600,
          color: "white",
          height: "3.175rem",
          lineHeight: "3.275rem",
          paddingRight: "5px",
        },
      },
    },
    primaryButton: {
      background: "#191919",
      fontSize: "1rem",
      border: "1px solid #191919",
      padding: "0 12px",
      ":hover": {
        background: "#292929",
        border: "1px solid #292929",
      }
    },
    viewFullResultsButton: {
      background: "#0078d4",
      color: "#ffffff",
      fontSize: "1rem",
      border: "1px solid #0078d4",
      // padding: "0 12px",
      ":hover": {
        background: "rgba(0, 120, 212, 0.9)",
        color: "#ffffff",
        ".ms-Button-menuIcon": {
          background: "transparent",
          color: "#ffffff",
        }
      },
      ":active": {
        background: "rgba(0, 120, 212, 0.9)",
        color: "#ffffff",
        ".ms-Button-menuIcon": {
          background: "transparent",
          color: "#ffffff",
        }
      },
      "span": {
      fontWeight: 600,
      },
      ".ms-Button-menuIcon": {
        color: "#ffffff",
      }
    }
  });

  const handleBackToPortfolio = () => {
    sessionStorage.setItem("showPerDevice", "false");
    setShowAssumptions(false);
    setUserInput({
      ...userInput,
      options: {
        includeCameraPeripheralSavings: true,
        includeLightsPeripheralSavings: true,
        includeHeadsetPeripheralSavings: true,
        includeDockCost: true,
        includeTypeCoverCost: false,
        includePenCost: false,
        includeExtraPowerSupplyCost: false,
        includeDeviceConsolidationSavings: true,
        includeResidualValueSavings: true,
        include3rdPartyServicesSavings: true,
        wantAccessories: true,
        default: true,
        custom: false
      },
      sustainability: {
        Energy: {
          checked: false,
          unitPrice: 0,
          surfaceValue: 0,
          otherPcValue: 0
        },
        CarbonEmissions: {
          checked: false,
          unitPrice: 0,
          surfaceValue: 0,
          otherPcValue: 0
        }
      }
    });
    setInitialAdvancedInput({});
    setSustainabilitySavings({});

    setChatbotExpanded(false);
    const header = document.querySelector('header');
    const footer = document.querySelector('footer');
    header.style.width = '100%';
    header.style.marginLeft = '0%';
    footer.style.width = '100%';
    footer.style.marginLeft = '0%';
    trackGAEvent("Results View", "Back to Portfolio button click", "Back to Portfolio Button in TCO Results page");
  }

 const bottomPart = (
    <div className={bottomPartStyles.container}>
      <Stack horizontal className={bottomPartStyles.buttonContainerMobile}>
        <DefaultButton
          text="Back to Portfolio"
          onClick={handleBackToPortfolio}
        />

      <CommandBarButton 
        id="view-full-results-button-id-mobile"
        className={bottomPartStyles.viewFullResultsButton}
          text="View Full Results"
          menuProps={{
            items: menuItems,
            calloutProps: {
              styles: {
                calloutMain: {
                  width: document.getElementById("view-full-results-button-id-mobile") ? `${document.getElementById("view-full-results-button-id-mobile").offsetWidth - 12}px` : 'auto'
                },
              }
            },
            onItemClick: handleMenuItemClick,
          }}
        />
      </Stack>
      <LearnMoreText />
    </div>
  );

  const mobileAccordionStyles = mergeStyleSets({
    accordionHeader: {
      padding: "2.4rem 1.6rem",
      border: "1px solid #E1DFDD",
      backgroundColor: "#FAF9F8",
      marginTop: "2.3rem",
      justifyContent: "flex-start",
      alignItems: "center",
      gap: "1.8rem",
      span: {
        color: "#212121",
        order: 2,
        fontSize: "1.6rem",
        fontWeight: 600,
        lineHeight: "2.2rem",
      },
      i: {
        color: "#212121",
        order: 1,
        fontSize: "1.6rem",
        marginLeft: 0,
      },
    },
  });

  const rightPartStyles = mergeStyleSets({
    container: {
      padding: "2.5rem 2rem 12.5rem 3.5rem",
      h1: {
        margin: 0,
        fontSize: "1.875rem",
        fontWeight: 600,
        lineHeight: "2.5rem",
        marginBottom: "2.25rem",
      },
    },
    titleAccordionHeader: {
      marginBottom: "1.125rem",
    },
    titleWrapper: {
      gap: "0.625rem",
      alignItems: "center",
      fontSize: "1.25rem",
      fontWeight: 600,
      lineHeight: "1.5rem",
      color: "#201F1E",
    },
    infoIcon: {
      fontSize: "1.2rem",
      color: "#201F1E",
      paddingTop: "0.15rem",
    },
    infoIconUpdated: {
      cursor: "pointer",
      fontSize: "1.25rem",
      color: "#201F1E",
      paddingLeft: "0.5rem",
    },
    infoText: {
      fontSize: "1rem",
      lineHeight: "1.5rem",
      marginBottom: "2.0625rem",
    },
    accordionBorder: {
      border: "1px solid #D6D6D6",
      padding: "0.8rem",
      marginBottom: "1.42rem !important",
    },
    accordionContentWrapper: {
      paddingTop: "1rem",
    },
    inputAccordionText: {
      display: "block",
      marginBottom: "2.5rem",
      fontSize: "1rem",
      lineHeight: "1.5rem",
    },
    marginTop: {
      marginTop: "2rem",
    }
  });

  const inputStyles = mergeStyleSets({
    container: {
      marginBottom: "1.5rem",
      label: {
        fontSize: "1.125rem",
        fontWeight: 400,
        color: "#201F1E",
        marginBottom: "1rem",
        display: "block"
      },
      
    },
    checkbox:{
      label:{
        display: "flex",
        marginBottom: "10px",
        span: {
          marginBottom: 0,
          fontWeight: 400
        }
        
      }
    },
    span: {
      fontWeight: 600,
      marginLeft: "5px"
    },
    assumptionContainer: {
      margin: "0 25px",
      p: {
        margin: "5px 0"
      },
      span:{
        fontWeight: 600,
        marginBottom: "15px"
      }
    },
    buttonWrapper: {
      gap: "1rem",
      marginBottom: "1.5rem",
      button: {
        borderRadius: 4,
        width: "50%",
        height: "45px", //"4rem",
        minHeight: "unset",
        ".ms-Button-flexContainer": {
          alignItems: "center",
          ".ms-Button-textContainer": {
            textAlign: "center",
            ".ms-Button-label": {
              margin: 0,
              fontSize: "1rem",
              fontWeight: 400,
              lineHeight: "1.25rem",
            },
          },
        },
      },
    },
    paragraphWithTooltip: {
      display: "flex",
      alignItems: "center",
    },
    radioWrapper: {
      display: "flex",
      alignItems: "center",
    }
  });

  //Should userInput.advancedInput get deleted?
  const handleChange = (event, val) => {
    setUpdateInitialAdvancedInput(true);
    setUserInput((state) => {
      let updatedList = Object.assign({}, state);
      if (!updatedList.options) {
        updatedList.options = {};
      }
      if (!updatedList.assumptions) {
        updatedList.assumptions = {};
      }
      Object.keys(val).forEach((item) => {
        if (typeof val[item] === "boolean") {
          updatedList.options[item] = val[item];
        } else {
          updatedList.assumptions[item] = val[item];
        }
      });
      if (!event || event.type !== "input") {
        let updatedState = {
          ...state,
          ...updatedList,
        };
        if (updatedState.advancedInput) {
          delete updatedState.advancedInput;
        }
        getReport(updatedState, true);
        getReport(updatedState);
      }
      return {
        ...state,
        ...updatedList,
      };
    });
  };

  const handleBlur = () => {
    setUserInput((state) => {
      let updatedState = Object.assign({}, state);
      if (updatedState.advancedInput) {
        delete updatedState.advancedInput;
      }
      getReport(updatedState, true);
      getReport(updatedState);
      return state;
    });
  };

  const preventEvent = (type) => {
    // prevent user input if user has made changes in advanced section
    if (sessionStorage.getItem("FurtherEditing") === "false") {
      setIsShowFurtherEditingModal(true);
      if (type !== "input") {
        sessionStorage.setItem("FurtherEditing", "true");
      }
      return true;
    }
    return false;
  }

  const assumptionFields = useMemo(
    () => [
      {
        type: "positiveNumber",
        id: "annualITStaffSalary",
        label: "What is the average yearly salary of your IT staff?",
        prefix: currencySymbol,
        onChange: handleChange,
        onBlur: handleBlur,
        preventEvent,
      },
      {
        type: "positiveNumber",
        id: "annualEmployeeSalary",
        label: "What is the average yearly salary of the end-user?",
        prefix: currencySymbol,
        onChange: handleChange,
        onBlur: handleBlur,
        preventEvent,
      },
      {
        type: "positiveInteger",
        id: "hoursWorkedPerYear",
        label: "How many hours does your average employee work per year?",
        onChange: handleChange,
        onBlur: handleBlur,
        preventEvent,
      },
      {
        type: "positiveNumber",
        id: "averageCostToHire",
        label: "What is your average cost to hire and onboard a new employee?",
        prefix: currencySymbol,
        onChange: handleChange,
        onBlur: handleBlur,
        preventEvent,
      },
    ],
    []
  );

  const devicesFields = useMemo(
    () => [
      {
        type: "positiveNumber",
        id: "averagePCCost",
        label:
          "What is the average cost of an alternative device you’re considering?",
        prefix: currencySymbol,
        onChange: handleChange,
        onBlur: handleBlur,
        preventEvent,
      },
      {
        type: "yesorno",
        id: "includeDeviceConsolidationSavings",
        question:
          "Do you expect to consolidate devices with your Surface device purchase?",
        tooltip: tooltipData.deviceConsolidation,
        tooltipId: "includeDeviceConsolidationSavings",
        onChange: handleChange,
        preventEvent,
        childFields: [
          {
            type: "positiveNumber",
            id: "numberOfDevicesConsolidated",
            label: "How many?",
            onChange: handleChange,
            onBlur: handleBlur,
            preventEvent,
          },
        ],
        childInitialValues: userInput.assumptions,
      },
      {
        type: "yesorno",
        id: "includeResidualValueSavings",
        question: "Do you sell your devices when you no longer need them?",
        tooltip: tooltipData.increasedResidualValue,
        tooltipId: "includeResidualValueSavings",
        onChange: handleChange,
        preventEvent,
      },
      {
        type: "yesorno",
        id: "include3rdPartyServicesSavings",
        question:
          "Do you expect to purchase fewer 3rd party support and maintenance services?",
        tooltip: tooltipData.reducedSupport,
        tooltipId: "include3rdPartyServicesSavings",
        onChange: handleChange,
        preventEvent,
      },
    ],
    [userInput.assumption]
  );

  const initialValues = useMemo(() => {
    return {
      ...userInput.assumptions,
      ...userInput.options,
    };
  }, [userInput.assumptions, userInput.options]);

  const warrantyFields = useMemo(
    () => [
      {
        type: "positiveNumber",
        id: "pcWarrantyAndMaintenanceCost",
        label: `How much will your Extended Warranty and Maintenance Plan for the alternative device cost over ${activeView === "Advanced" && sessionStorage.getItem("years") ? sessionStorage.getItem("years") : 3} years?`,
        prefix: currencySymbol,
        onChange: handleChange,
        onBlur: handleBlur,
        preventEvent,
      },
      {
        type: "positiveNumber",
        id: "warrantyAndMaintenanceCost",
        label: `How much will your Extended Warranty and Maintenance Plan for your Surface devices cost over ${activeView === "Advanced" && sessionStorage.getItem("years") ? sessionStorage.getItem("years") : 3} years?`,
        prefix: currencySymbol,
        onChange: handleChange,
        onBlur: handleBlur,
        preventEvent,
      },
    ],
    [userInput.assumptions]
  );

  const assumptionForm = useForm(assumptionFields, userInput.assumptions);
  const warrantyForm = useForm(warrantyFields, initialValues);
  const devicesForm = useForm(devicesFields, initialValues);

  const getReport = (values, isCompleteReport) => {
    if (isCompleteReport) {
      setIsLoading(true);
    }

    const url = `api/${
      isCompleteReport ? "tco-report-complete" : "tco-report"
    }`;
    let requestData = _.cloneDeep(values);
    const currencyConversionMultipleToUSD = requestData.currencyConversionMultipleToUSD;
    const currencyConversionMultipleFromUSD = requestData.currencyConversionMultipleFromUSD;
    requestData.deviceMixPortfolio = requestData.deviceMixPortfolio
      .filter((i) => Object.keys(i).length > 1)
      .map((item, index) => {
        return {
          seqNo: index + 1,
          product: item.product,
          quantity: sessionStorage.getItem("showPerDevice") === "true" ? 1 : convertStringToNumber(item.quantity),
          microsoft365Subscription: item.microsoft365Subscription,
          msrp: (item.msrp * currencyConversionMultipleToUSD).toFixed(2),
        };
      });

    let updatedAssumptions = {};
    Object.keys(requestData.assumptions).forEach((key) => {
      if (key === "warrantyAndMaintenanceCost" || key === "pcWarrantyAndMaintenanceCost") {
        if(sessionStorage.getItem("activeView") === "Advanced"){
          updatedAssumptions[key] = (convertStringToNumber(requestData.assumptions[key]) * currencyConversionMultipleToUSD / sessionStorage.getItem("years")).toFixed(2);
        } else {
          updatedAssumptions[key] = (convertStringToNumber(requestData.assumptions[key]) * currencyConversionMultipleToUSD / 3).toFixed(2);
        }
      } else if(assumptionsWithCurrency.includes(key)) {
        updatedAssumptions[key] = (convertStringToNumber(requestData.assumptions[key]) * currencyConversionMultipleToUSD).toFixed(2);
      } else {
        updatedAssumptions[key] = convertStringToNumber(requestData.assumptions[key]);
      }
    });
    requestData.assumptions = updatedAssumptions;

    // if(isCompleteReport && requestData.advancedInput){
    // added this because results editor is introduced to standard view and Standard view uses this api data for graphs.
    // either user tco-complete-report for graphs or remove advanced input when something in assumptions editor is changed.

    if(requestData.advancedInput){
      let updatedAdvancedInput = {};
      Object.keys(requestData.advancedInput).forEach((key) => {
        const currentValueObject = requestData.advancedInput[key];
        updatedAdvancedInput[key] = {
          ...currentValueObject,
          value: (currentValueObject["value"] * currencyConversionMultipleToUSD).toFixed(2),
          surfaceValue: (currentValueObject["surfaceValue"] * currencyConversionMultipleToUSD).toFixed(2),
          pcValue: (currentValueObject["pcValue"] * currencyConversionMultipleToUSD).toFixed(2),
        };
      });
      requestData.advancedInput = updatedAdvancedInput;
    }

    let formattedSustainability = {};
    Object.keys(requestData.sustainability).forEach((key) => {
      const currentValueSusObject = requestData.sustainability[key];
      formattedSustainability[key] = {
        ...currentValueSusObject,
        unitPrice: convertStringToNumber(currentValueSusObject["unitPrice"]) * currencyConversionMultipleToUSD,
        surfaceValue: convertStringToNumber(currentValueSusObject["surfaceValue"]),
        otherPcValue: convertStringToNumber(currentValueSusObject["otherPcValue"]),
      }
    })
    requestData.sustainability = formattedSustainability;
    
    sendRequest("POST", url, {
      country: "US",
      industry: "default",
      currency: CURRENCY.unit,
      years: sessionStorage.getItem("years") && isCompleteReport ? Number(sessionStorage.getItem("years")) : 3,
      ...requestData,
    }).then((data) => {
      if (data.value) {
        if (isCompleteReport) {
          let apiData = data.value;

          const convertRowsArray = (rowsArray) => {
            return rowsArray.map((i) => {
              return {
                ...i,
                pcValue: i.pcValue * currencyConversionMultipleFromUSD,
                savings: i.savings * currencyConversionMultipleFromUSD,
                surfaceValue: i.surfaceValue * currencyConversionMultipleFromUSD,
              }
            });
          }

          const convertCategoryObject = (category) => {
            return {
              ...category, 
              pcValue: category.pcValue * currencyConversionMultipleFromUSD,
              surfaceValue: category.surfaceValue * currencyConversionMultipleFromUSD,
              savings: category.savings * currencyConversionMultipleFromUSD,
              rows: convertRowsArray(category.rows),
            }
          }

          apiData = {
            ...apiData,
            costs: apiData.costs * currencyConversionMultipleFromUSD,
            savings: apiData.savings * currencyConversionMultipleFromUSD,
            employeeEmpowerment: convertCategoryObject(apiData.employeeEmpowerment),
            itSimplification: convertCategoryObject(apiData.itSimplification),
            totalCosts: convertCategoryObject(apiData.totalCosts),
            totalSavings: convertCategoryObject(apiData.totalSavings),
            sustainability: convertCategoryObject(apiData.sustainability),
          }

          let susSavings = {};
          apiData.sustainability.rows.forEach(row => {
            susSavings[row.label] = {
              surfaceValue: Math.round(row.surfaceValue), 
              pcValue: Math.round(row.pcValue), 
              savings: Math.round(row.savings),
            }
          })
          susSavings['totalSavings'] = Math.round(apiData.sustainability.savings);
          setSustainabilitySavings(susSavings);
          setCompleteReport(apiData);
          setIsLoading(false);
        } else {
          let apiData = data.value;
          let detailsArray = apiData.details.map((i) => {
            return {
              ...i,
              pcValue: i.pcValue * currencyConversionMultipleFromUSD,
              savings: i.savings * currencyConversionMultipleFromUSD,
              surfaceValue: i.surfaceValue * currencyConversionMultipleFromUSD,
            }
          })
          apiData = {
            ...apiData, 
            costs: apiData.costs * currencyConversionMultipleFromUSD,
            savings: apiData.savings * currencyConversionMultipleFromUSD,
            details: detailsArray,
          }
          setSimpleReport(apiData);
          // setSimpleReport(data.value);
        }
      } else {
        setAlert("error", data.error.message);
      }
    });
  };

  const getReportPageData = (values, isOnePager) => {
    setIsFullReportLoading(true);
    
    const url = "api/tco-report-complete";
    let requestData = _.cloneDeep(values);
    const currencyConversionMultipleToUSD = requestData.currencyConversionMultipleToUSD;
    const currencyConversionMultipleFromUSD = requestData.currencyConversionMultipleFromUSD;
    requestData.deviceMixPortfolio = requestData.deviceMixPortfolio
      .filter((i) => Object.keys(i).length > 1)
      .map((item, index) => {
        return {
          seqNo: index + 1,
          product: item.product,
          quantity: sessionStorage.getItem("showPerDevice") === "true" ? 1 : convertStringToNumber(item.quantity),
          microsoft365Subscription: item.microsoft365Subscription,
          msrp: (item.msrp * currencyConversionMultipleToUSD).toFixed(2),
        };
      });

    let updatedAssumptions = {};
    Object.keys(requestData.assumptions).forEach((key) => {
      if (key === "warrantyAndMaintenanceCost" || key === "pcWarrantyAndMaintenanceCost") {
        if(sessionStorage.getItem("activeView") === "Advanced"){
          updatedAssumptions[key] = (convertStringToNumber(requestData.assumptions[key]) * currencyConversionMultipleToUSD / sessionStorage.getItem("years")).toFixed(2);
        } else {
          updatedAssumptions[key] = (convertStringToNumber(requestData.assumptions[key]) * currencyConversionMultipleToUSD / 3).toFixed(2);
        }
      } else if(assumptionsWithCurrency.includes(key)) {
        updatedAssumptions[key] = (convertStringToNumber(requestData.assumptions[key]) * currencyConversionMultipleToUSD).toFixed(2);
      } else {
        updatedAssumptions[key] = convertStringToNumber(requestData.assumptions[key]);
      }
    });
    requestData.assumptions = updatedAssumptions;

    if(requestData.advancedInput){
      let updatedAdvancedInput = {};
      Object.keys(requestData.advancedInput).forEach((key) => {
        const currentValueObject = requestData.advancedInput[key];
        updatedAdvancedInput[key] = {
          ...currentValueObject,
          value: (currentValueObject["value"] * currencyConversionMultipleToUSD).toFixed(2),
          surfaceValue: (currentValueObject["surfaceValue"] * currencyConversionMultipleToUSD).toFixed(2),
          pcValue: (currentValueObject["pcValue"] * currencyConversionMultipleToUSD).toFixed(2),
        };
      });
      requestData.advancedInput = updatedAdvancedInput;
    }

    let formattedSustainability = {};
    Object.keys(requestData.sustainability).forEach((key) => {
      const currentValueSusObject = requestData.sustainability[key];
      formattedSustainability[key] = {
        ...currentValueSusObject,
        unitPrice: convertStringToNumber(currentValueSusObject["unitPrice"]) * currencyConversionMultipleToUSD,
        surfaceValue: convertStringToNumber(currentValueSusObject["surfaceValue"]),
        otherPcValue: convertStringToNumber(currentValueSusObject["otherPcValue"]),
      }
    })
    requestData.sustainability = formattedSustainability;


    sendRequest("POST", url, {
      country: "US",
      industry: "default",
      currency: CURRENCY.unit,
      years: activeView === "Advanced" && sessionStorage.getItem("years") ? Number(sessionStorage.getItem("years")) : 3,
      ...requestData,
    }).then((data) => {
      let perDeviceData;
      if (data.value) {
        let apiData = data.value;

        const convertRowsArray = (rowsArray) => {
          return rowsArray.map((i) => {
            return {
              ...i,
              pcValue: i.pcValue * currencyConversionMultipleFromUSD,
              savings: i.savings * currencyConversionMultipleFromUSD,
              surfaceValue: i.surfaceValue * currencyConversionMultipleFromUSD,
            }
          });
        }

        const convertCategoryObject = (category) => {
          return {
            ...category, 
            pcValue: category.pcValue * currencyConversionMultipleFromUSD,
            surfaceValue: category.surfaceValue * currencyConversionMultipleFromUSD,
            savings: category.savings * currencyConversionMultipleFromUSD,
            rows: convertRowsArray(category.rows),
          }
        }

        apiData = {
          ...apiData,
          costs: apiData.costs * currencyConversionMultipleFromUSD,
          savings: apiData.savings * currencyConversionMultipleFromUSD,
          employeeEmpowerment: convertCategoryObject(apiData.employeeEmpowerment),
          itSimplification: convertCategoryObject(apiData.itSimplification),
          totalCosts: convertCategoryObject(apiData.totalCosts),
          totalSavings: convertCategoryObject(apiData.totalSavings),
          sustainability: convertCategoryObject(apiData.sustainability),
        }

        let susSavings = {};
        apiData.sustainability.rows.forEach(row => {
          susSavings[row.label] = {
            surfaceValue: Math.round(row.surfaceValue), 
            pcValue: Math.round(row.pcValue), 
            savings: Math.round(row.savings),
          }
        })
        susSavings['totalSavings'] = Math.round(apiData.sustainability.savings);
        setSustainabilitySavings(susSavings);
        setReportPageData(apiData);
        setCompleteReport(apiData);

        const deviceQuantity = requestData.deviceMixPortfolio.reduce((q, item) => q + item.quantity, 0);
        
        if ( sessionStorage.getItem("showPerDevice")  === "true" || deviceQuantity === requestData.deviceMixPortfolio.length) {
          setReportPageDataPerDevice(apiData);
          perDeviceData = apiData;
          if (isOnePager) {
            generateOnePager(perDeviceData, currencySymbol, sessionStorage.getItem("activeView") === "Advanced" ? Number(sessionStorage.getItem("years")) : 3);
          }
        } else {
          if(requestData.advancedInput){
            let updatedAdvancedInput = {};
            let totalQuantity = convertStringToNumber(requestData.deviceMixPortfolio[0]?.quantity || 1);
            Object.keys(requestData.advancedInput).forEach((key) => {
              const currentValueObject = requestData.advancedInput[key];
              for(const objKey in currentValueObject){
                if(typeof currentValueObject[objKey] !== 'boolean') {
                  currentValueObject[objKey] = convertStringToNumber(currentValueObject[objKey]) / totalQuantity;
                }
              }
              updatedAdvancedInput[key] = currentValueObject;
            });
            requestData.advancedInput = updatedAdvancedInput;
          }

          requestData.deviceMixPortfolio = requestData.deviceMixPortfolio.map(item => {item.quantity = 1; return item;});

          sendRequest("POST", url, {
            country: "US",
            industry: "default",
            currency: CURRENCY.unit,
            years: YEAR_AMOUNT,
            ...requestData,
          }).then((data) => {
            let apiDataPerDevice = data.value;
            const apiPData = {
              ...apiDataPerDevice,
              costs: apiDataPerDevice.costs * currencyConversionMultipleFromUSD,
              savings: apiDataPerDevice.savings * currencyConversionMultipleFromUSD,
              employeeEmpowerment: convertCategoryObject(apiDataPerDevice.employeeEmpowerment),
              itSimplification: convertCategoryObject(apiDataPerDevice.itSimplification),
              totalCosts: convertCategoryObject(apiDataPerDevice.totalCosts),
              totalSavings: convertCategoryObject(apiDataPerDevice.totalSavings),
              sustainability: convertCategoryObject(apiDataPerDevice.sustainability),
            }
            perDeviceData = apiPData;
            setReportPageDataPerDevice(apiPData);
            if (isOnePager) {
              generateOnePager(perDeviceData, currencySymbol, sessionStorage.getItem("activeView") === "Advanced" ? Number(sessionStorage.getItem("years")) : 3);
            }
          });
          
        }

        setIsFullReportLoading(false);
      } else {
        setAlert("error", data.error.message);
      }

      
    });
  };

  const reportModalStyles = mergeStyleSets({
    contentWrapper: {
      display: "flex",
      flexFlow: "column nowrap",
      width: chatbotExpanded ? "100%" : "85vw",
      height: "85vh",
    },
    actualModal: {
      width: chatbotExpanded ? "70%" : "100%",
      right: "0",
    },
    cancelIcon: {
      color: "#474747",
      alignSelf: "flex-end",
      fontSize: "22.5px",
      cursor: "pointer",
      position: "sticky",
      top: "1.4844rem",
      marginRight: "2.2344rem",
      zIndex: 3,
    },
    firstTimeModal: {
      display: "flex",
      flexFlow: "column nowrap",
      width: "35rem",
      height: "100%",
      padding: "1rem 1.5rem 1.5rem 1.5rem",
      button: {
        alignSelf: "flex-end",
      },
    },
    firstTimeTitleWrapper: {
      marginBottom: "1.25rem",
      alignItems: "center",
      justifyContent: "space-between",
    },
    firstTimeTitle: {
      fontSize: "1.25rem",
      lineHeight: "1.75rem",
      fontWeight: 600,
      color: "#201F1E",
    },
    firstTimeCancelIcon: {
      color: "#201F1E",
      fontSize: 16,
      cursor: "pointer",
      zIndex: 3,
    },
    firstTimeContent: {
      fontSize: "0.875rem",
      lineHeight: "1.25rem",
      marginBottom: "1rem",
      color: "#605E5C",
    },
  });

  const layoutStyles = mergeStyleSets({
    commonContainer: {
      // background: "yellow",
      margin: "0.5rem 0 0 3.4rem",
      borderBottom: "2px solid #E1DFDD",
      paddingRight: "2rem",

      display: "flex",
      justifyContent: "space-between",
      "> h1": {
        margin: 0,
        fontSize: "2.143rem",
        fontWeight: 600,
        lineHeight: "2.5rem",
        "> span": {
          display: "block",
          textTransform: "uppercase",
          fontSize: "1.428rem",
          fontWeight: 300,
          letterSpacing: "0.15rem",
        }
      },
      "> div": {
        display: "flex",
        alignItems: "center",
      }
    },
    layoutContainer: {
      display: "flex",
      boxSizing: "border-box",
      "> div:first-child": {
        width: "48.4%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between"

      },
      "> div:last-child": {
        width: "51.6%",
      }
    },
    layoutRight: {
      paddingLeft: "3.07rem",
      marginRight: "0",
    },
    leftTop: {
      padding: "1.485rem 2.3rem 0rem 3.5rem",
      position: "relative",
      "> h1": {
        margin: 0,
        fontSize: "1.875rem",
        fontWeight: 600,
        lineHeight: "2.5rem",
        marginBottom: "1.25rem",
        borderBottom: "2px solid #E1DFDD",
        paddingBottom: "0.8rem",
      },
    },
    leftBottom: {
      // backgroundColor: "#ffffff",
      /* position: "absolute",
      bottom: 0,
      width: "inherit",
      maxWidth: 1440, */
      padding: "0 2.3rem 0 3.5rem",
    },
    mobileButtonWrapper: {
      display: "none !important",
    },
    chatbotButton: {
      padding: "0.35rem 1rem",
      background: chatbotExpanded ? "#0078d4" : "#fff",
      border: "1px solid #0078d4",
      borderRadius: "0.5rem",
      color: chatbotExpanded ? "#fff" : "#0078d4",
      fontWeight: 500,
      ":hover": {
        cursor: "pointer",
        background: "#0078d4",
        color: "#fff",
      }
    }
  });

  const layoutRightStyles = mergeStyleSets({
    pivotWrapper: {
      position: "relative",
    },
    assumptionsHeaderWrapper: {
      // background: "yellow",
      position: "absolute",
      top: "0.3rem",
      left: "2.71rem",
    },
    commonRightHeader: {
      textTransform: "uppercase",
      fontSize: "1.25rem",
      fontWeight: 400,
      color: "#191919",
      letterSpacing: "0.15rem",
    },
    perDeviceWrapper: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      paddingTop: "0.8rem",
      paddingRight: "2rem",
      boxSizing: "border-box",
    },
  });

  const accessoriesPart = (
    <div className={rightPartStyles.accordionContentWrapper}>
      <div className={`${inputStyles.container} input-styles-container`}>
          <div>
              <label>Would you like to purchase accessories with your device?</label>
              <Stack horizontal className={inputStyles.buttonWrapper}>
                <CompoundButton
                  text="Yes"
                  onClick={() => handleNoAccessories(true)}
                  primary={assChoice === true}
                  className={inputStyles.button}
                />
                <CompoundButton
                  text="No"
                  onClick={() => handleNoAccessories(false)}
                  primary={assChoice === false}
                  className={inputStyles.button}
                />
              </Stack>
          </div>
          {
            assChoice ? 
              <div >
                  <div class="mb-15">
                    <div className={inputStyles.radioWrapper}>
                      <input type="radio" name="assumptions" checked={isDefaultAssum} onChange={() => handleAssumChange(true)}/> 
                      <span className={inputStyles.span}>Default Assumptions</span>
                      <FontIcon
                        id={calloutId}
                        iconName="Info"
                        tabIndex={0}
                        className={`${rightPartStyles.infoIconUpdated} tco-assumptions-right-part-title-icon`}
                        onClick={toggleIsAccessoriesCalloutVisible}
                        directionalHint={DirectionalHint.rightCenter}
                      />
                      {isAccessoriesCalloutVisible && (
                        <Callout 
                          className={calloutStyles.callout} 
                          target={`#${calloutId}`} 
                          onDismiss={toggleIsAccessoriesCalloutVisible} 
                          role="alert"
                          gapSpace={0}
                        >
                          <Text>
                              Based on IDC's study, "<a href="https://aka.ms/SurfaceIDCWhitepaper" rel="noreferrer" target="_blank">Evaluating the Business Case of Microsoft Surface</a>", commissioned by Microsoft.
                          </Text>
                          <Text>
                            {`The average organization spends ${formatCurrency(344 * userInput.currencyConversionMultipleFromUSD, currencySymbol, 0)} in accessories per device.`}
                          </Text>
                          <Text>
                            {"Default assumptions include the camera, lights, headset and dock."}
                          </Text>
                        </Callout>
                      )}
                    </div>
                      {
                        isDefaultAssum ? 
                        <div className={`${inputStyles.assumptionContainer} input-styles-assumption-container`}>
                            <div>
                                <p>{`The average organization with Surface, realizes up to ${formatCurrency(119.95 * userInput.currencyConversionMultipleFromUSD, currencySymbol, 0)} in accessories savings per device based on the following.`}</p>
                            </div>
                            <div>
                              <span className={ 'd-block'} >Unselect any if not appropriate: </span>
                              <div>
                                <FocusableCheckbox
                                  className={inputStyles.checkbox}
                                  ariaLabel="isCamera"
                                  label={`${formatCurrency(63 * userInput.currencyConversionMultipleFromUSD, currencySymbol, 0)} savings per device on peripheral cameras`}
                                  onChange={handleDefaultChange}
                                  checked={defaultValues.isCamera}
                                />
                                <FocusableCheckbox
                                  className={inputStyles.checkbox}
                                  ariaLabel="isLight"
                                  label={`${formatCurrency(30 * userInput.currencyConversionMultipleFromUSD, currencySymbol, 0)} savings per device on peripheral lights for meetings`}
                                  onChange={handleDefaultChange}
                                  checked={defaultValues.isLight}
                                />
                                <FocusableCheckbox
                                  className={inputStyles.checkbox}
                                  ariaLabel="isHeadset"
                                  label={`${formatCurrency(27 * userInput.currencyConversionMultipleFromUSD, currencySymbol, 0)} savings per device on a headset`}
                                  onChange={handleDefaultChange}
                                  checked={defaultValues.isHeadset}
                                />
                                <FocusableCheckbox
                                  className={inputStyles.checkbox}
                                  ariaLabel="isDock"
                                  label={`${formatCurrency(0, currencySymbol, 0)} savings per device on a dock`}
                                  onChange={handleDefaultChange}
                                  checked={defaultValues.isDock}
                                />
                              </div>
                            </div>
                        </div>
                        : ``
                      }
                  </div>
                  <div>
                    <div className={inputStyles.radioWrapper}>
                      <input type="radio" name="assumptions"  checked={!isDefaultAssum} onChange={() => handleAssumChange(false)}/> 
                      <span className={inputStyles.span}>Custom Assumptions</span>
                      <FontIcon
                        id={calloutId + 1}
                        iconName="Info"
                        tabIndex={0}
                        className={`${rightPartStyles.infoIconUpdated} tco-assumptions-right-part-title-icon`}
                        onClick={toggleIsAccessoriesCalloutVisibleC}
                        directionalHint={DirectionalHint.rightCenter}
                      />
                      {isAccessoriesCalloutVisibleC && (
                        <Callout 
                          className={calloutStyles.callout} 
                          target={`#${calloutId + 1}`} 
                          onDismiss={toggleIsAccessoriesCalloutVisibleC} 
                          role="alert"
                          gapSpace={0}
                        >
                          <Text>
                            Based on IDC's study, "<a href="https://aka.ms/SurfaceIDCWhitepaper" rel="noreferrer" target="_blank">Evaluating the Business Case of Microsoft Surface</a>", commissioned by Microsoft.
                          </Text>
                          <Text>
                            Pick your own accessories, and input the corresponding costs for Surface and the alternative PC.
                          </Text>
                        </Callout>
                      )}
                    </div>
                        
                      {
                        !isDefaultAssum ? 
                        <div className={`${inputStyles.assumptionContainer} input-styles-assumption-container`}>
                          
                          <div>
                            <p className={inputStyles.question}>What accessories do you need to purchase with your devices and what is their estimated cost?</p> <br></br>
                            <FocusableCheckbox
                                  className={inputStyles.checkbox}
                                  label="Select if accessories costs are the same for both Surface and the Alternative PC"
                                  onChange={handleCostChange}
                            />
                            <div>
                                <ul className="custom-option-list">
                                    <li className="head">
                                        <div></div>
                                        <div>Accessories</div>
                                        <div className={calloutStyles.tooltipAlign}> 
                                          Surface 
                                          <FontIcon
                                            id="heading1"
                                            iconName="Info"
                                            tabIndex={0}
                                            className={`${rightPartStyles.infoIconUpdated} tco-assumptions-right-part-title-icon`}
                                            onClick={heading1CalloutVisible}
                                            directionalHint={DirectionalHint.rightCenter}
                                          />
                                          {isheading1CalloutVisible && (
                                            <Callout 
                                              className={calloutStyles.calloutAlternative} 
                                              target="#heading1"
                                              onDismiss={heading1CalloutVisible} 
                                              role="alert"
                                              gapSpace={0}
                                            >                                                          
                                              <p>Per device</p>
                                            </Callout>
                                          )}
                                        </div>                                                    
                                        <div className={calloutStyles.tooltipAlign}>
                                          Alternative Device
                                          <FontIcon
                                            id="heading2"
                                            iconName="Info"
                                            tabIndex={0}
                                            className={`${rightPartStyles.infoIconUpdated} tco-assumptions-right-part-title-icon`}
                                            onClick={heading2CalloutVisible}
                                            directionalHint={DirectionalHint.rightCenter}
                                          />
                                          {isheading2CalloutVisible && (
                                            <Callout 
                                              className={calloutStyles.calloutAlternative} 
                                              target="#heading2"
                                              onDismiss={heading2CalloutVisible} 
                                              role="alert"
                                              gapSpace={0}
                                            >                                                          
                                                  <p> Per device</p>
                                              
                                            </Callout>
                                          )}
                                        </div>
                                    </li>
                                    {
                                      customValues.map(item => <li>
                                        <div>
                                          <FocusableCheckbox                                                            
                                            label=""
                                            onChange={() => handleCustomOptions(item)}
                                          />
                                        </div>
                                        <div>{item.label}</div>
                                        <div class="curr-field"> <span>{currencySymbol}</span><input type="number" value={item.surfaceValue}  name={item.key} onFocus={handleSurfaceValueFocus} onChange={handleSurfaceValueChange} onBlur={handleSurfaceValueBlur} disabled={!defaultValues[item.key]} /> </div>
                                        {isSameCost ?
                                          <div class="curr-field"> <span>{currencySymbol}</span><input type="number"  name={item.key} onChange={handleOtherPCValueChange} disabled={true} value={item.otherPCVal}/> </div>
                                        :
                                          <div class="curr-field"> <span>{currencySymbol}</span><input type="number" value={item.otherPCVal} name={item.key} onFocus={handleOtherPCValueFocus} onChange={handleOtherPCValueChange} onBlur={handleOtherPCValueBlur} disabled={!defaultValues[item.key]}/> </div>
                                        }
                                      </li>)
                                    }
                                </ul>
                            </div>
                          </div>
                        </div>
                        : ``
                      }
                  </div>
                  <div className="update-btn">
                      {/* <button onClick={getData}>
                        Update
                      </button> */}
                      
                      <PrimaryButton
                        className={bottomPartStyles.primaryButton}
                        text="Update"
                        onClick={getData}
                      />
                  </div>
              </div>
            : 
            ``
          }
      </div>
      {/* {<CommonForm form={warrantyForm} />} */}
      {<CommonFormAssumptions form={warrantyForm} defaultAssumptions={defaultAssumptions} />}
    </div>
  );

  const advancedAssumptionsEditorStyles = mergeStyleSets({
    inputFieldWrapper: {
      marginBottom: "1.5rem",
    },
    singleTextFieldContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      marginTop: "0.6rem",
      "> div": {
        width: "100%",
      }
    },
    inputLabelWrapper: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        fontSize: "1.125rem",
        ">div:first-child": {
          display: "flex",
          alignItems: "center",
        }
    },
    infoIcon: {
        cursor: "pointer",
        fontSize: "1.2rem",
        color: "#201F1E",
        paddingLeft: "0.5rem",
        paddingTop: "0.1rem",
    },
    callout: {
        width: 460,
        padding: '0.7rem 1.5rem',
        fontSize: "1rem",
        "span": {
          display: "block",
          lineHeight: "1.5rem",
        }
    },
    sectionInfo: {
      marginBottom: "1.5rem",
    },
    resetButton: {
      padding: "0.1rem 1rem 0.2rem",
      background: "#fff",
      border: "1px solid #0078d4",
      borderRadius: "0.2rem",
      color: "#0078d4",
      // fontWeight: 500,
      ":hover": {
          cursor: "pointer",
          background: "#0078d4",
          color: "#fff",
      }
    },
    checkBoxFieldContainer: {
      display: "flex",
      alignItems: "center",
      marginBottom: "1rem",
      ">div:nth-child(1)": {
        width: chatbotExpanded ? "12%" : "8%",
      },
      ">div:nth-child(2)": {
        width: chatbotExpanded ? "88%" : "92%",
        display: "flex",
        alignItems: "center",
      },
    },
    checkBoxInputField: {
      fontSize: "1rem",
      width: chatbotExpanded ? "100%" : "48%",
      display: "flex",
      alignItems: "center",
      ">div:nth-child(1)": {
        width: "33%",
      },
      ">div:nth-child(2)": {
        width: "67%",
      },
    },
    ecoFieldsContainer: {
        display: "flex",
        alignItems: "center",
        marginBottom: "1.5rem",
      ">div:nth-child(1)": {
        width: chatbotExpanded ? "12%" : "8%",
      },
      ">div:nth-child(2)": {
        width: chatbotExpanded ? "88%" : "92%",
        display: "flex",
        alignItems: chatbotExpanded ? "flex-start" : "center",
        justifyContent: "space-between",
        flexDirection: chatbotExpanded ? "column" : "row",
      },
    },
    ecoFieldContainer: {
      fontSize: "1rem",
      marginBottom: chatbotExpanded ? "1rem" : "0",
      width: chatbotExpanded ? "100%" : "48%",
      display: "flex",
      alignItems: "center",
      ">div:nth-child(1)": {
        width: "33%",
      },
      ">div:nth-child(2)": {
        width: "67%",
      },
    },
    ecoFieldsWrapper: {
      paddingTop: "1rem",
      marginBottom: "1.5rem",
    },
    ecoFieldsLabelWrapper: {
      display: "flex",
      alignItems: "center",
      marginBottom: "0.8rem",
      fontSize: "1rem",
    }
  });

  const discountRateFields = (
    <div>
      <div className={`${advancedAssumptionsEditorStyles.inputFieldWrapper}`}>
        <div className={`${advancedAssumptionsEditorStyles.inputLabelWrapper}`}>
          <div>
            <div className="mobile-reults-editor-label">{`What is your discount rate for the Payback Period?`}</div>
              <FontIcon
                id={discoutRateCalloutId1}
                iconName="Info"
                tabIndex={0}
                className={`${advancedAssumptionsEditorStyles.infoIcon}`}
                onClick={toggleIsDiscountRate1CalloutVisible}
                directionalHint={DirectionalHint.rightCenter}
              />
              {isDiscountRate1CalloutVisible && (
                <Callout 
                  className={advancedAssumptionsEditorStyles.callout} 
                  target={`#${discoutRateCalloutId1}`} 
                  onDismiss={toggleIsDiscountRate1CalloutVisible} 
                  role="alert"
                  gapSpace={0}
                >
                  <Text>
                    PBP = (Total Savings / ( 1 + Discount Rate*) ^ No.of years* ) / Total Cost
                  </Text>
                  <Text>
                    *Discount Rate = {`${discountRate.pbp}%`}
                  </Text>
                  <Text>
                    *No.of years = {sessionStorage.getItem("years")}
                  </Text>
                </Callout>
              )}
          </div>
          {
            convertStringToNumber(discountRate.pbp) !== 6.2 ?
              <div onClick={() => setDiscountRate({...discountRate, pbp: 6.2})} className={`${advancedAssumptionsEditorStyles.resetButton}`}>Reset</div>
            :
              <></>
          }
        </div>

        <div className={`${advancedAssumptionsEditorStyles.singleTextFieldContainer}`}>
          <TextField 
            suffix={"percentage"} 
            value={discountRate.pbp} 
            onChange={(e, val) => {
              if(!isPositiveNumber(val)){
                if(val === ""){
                } else {
                  return;
                }
              }
              setDiscountRate({...discountRate, pbp: val});
            }}
            onBlur={(e) => {
              if(e.target.value === ""){
                setDiscountRate({...discountRate, pbp: 6.2});
              }
            }}
          />
        </div>
      </div>

      <div className={`${advancedAssumptionsEditorStyles.inputFieldWrapper}`}>
        <div className={`${advancedAssumptionsEditorStyles.inputLabelWrapper}`}>
          <div>
            <div className="mobile-reults-editor-label">{`What is your discount rate for the Net Present Value?`}</div>
              <FontIcon
                id={discoutRateCalloutId2}
                iconName="Info"
                tabIndex={0}
                className={`${advancedAssumptionsEditorStyles.infoIcon}`}
                onClick={toggleIsDiscountRate2CalloutVisible}
                directionalHint={DirectionalHint.rightCenter}
              />
              {isDiscountRate2CalloutVisible && (
                <Callout 
                  className={advancedAssumptionsEditorStyles.callout} 
                  target={`#${discoutRateCalloutId2}`} 
                  onDismiss={toggleIsDiscountRate2CalloutVisible} 
                  role="alert"
                  gapSpace={0}
                >
                  <Text>
                    NPV = (Total Savings / ( 1 + Discount Rate*) ^ No.of years* ) - Total Cost
                  </Text>
                  <Text>
                    *Discount Rate = {`${discountRate.npv}%`}
                  </Text>
                  <Text>
                    *No.of years = {sessionStorage.getItem("years")}
                  </Text>
                </Callout>
              )}
          </div>
          {
            convertStringToNumber(discountRate.npv) !== 6.2 ?
              <div onClick={() => setDiscountRate({...discountRate, npv: 6.2})} className={`${advancedAssumptionsEditorStyles.resetButton}`}>Reset</div>
            :
              <></>
          }
        </div>

        <div className={`${advancedAssumptionsEditorStyles.singleTextFieldContainer}`}>
          <TextField 
            suffix={"percentage"} 
            value={discountRate.npv} 
            onChange={(e, val) => {
              if(!isPositiveNumber(val)){
                if(val === ""){
                } else {
                  return;
                }
              }
              setDiscountRate({...discountRate, npv: val});
            }}
            onBlur={(e) => {
              if(e.target.value === ""){
                setDiscountRate({...discountRate, npv: 6.2});
              }
            }}
          />
        </div>
      </div>
    </div>
  );

  const deviceCostsFields = (
    <div className={rightPartStyles.accordionContentWrapper}>
      <div className={`${advancedAssumptionsEditorStyles.sectionInfo}`}>The initial purchase price of the hardware and any additional accessories, warranty and the M365 license.</div>
      {
        ASSUMPTION_ADVANCED_LIST.totalCosts.map((item, index) => <AdvancedAssumptionsInputItem item={item} currencySymbol={currencySymbol} advancedInput={advancedInput} setAdvancedInput={setAdvancedInput} fetchReportData={fetchReportData} userInput={userInput} initialAdvancedInput={initialAdvancedInput} />)
      }
    </div>
  );

  const deviceSavingsFields = (
    <div className={rightPartStyles.accordionContentWrapper}>
      <div className={`${advancedAssumptionsEditorStyles.sectionInfo}`}>This represents the amount of money saved by using a more efficient or cost-effective device, reflecting reductions in operational or maintenance expenses.</div>
      {
        ASSUMPTION_ADVANCED_LIST.totalSavings.map((item, index) => <AdvancedAssumptionsInputItem item={item} currencySymbol={currencySymbol} advancedInput={advancedInput} setAdvancedInput={setAdvancedInput} fetchReportData={fetchReportData} userInput={userInput} initialAdvancedInput={initialAdvancedInput} />)
      }
    </div>
  );

  const itEfficiencyFields = (
    <div className={rightPartStyles.accordionContentWrapper}>
      <div className={`${advancedAssumptionsEditorStyles.sectionInfo}`}>IT efficiency refers to the ability of an organization's IT department to optimize their processes and resources to reduce costs, improve productivity, and enhance employee satisfaction.</div>
      {
        ASSUMPTION_ADVANCED_LIST.itSimplification.map((item, index) => <AdvancedAssumptionsInputItem item={item} currencySymbol={currencySymbol} advancedInput={advancedInput} setAdvancedInput={setAdvancedInput} fetchReportData={fetchReportData} userInput={userInput} initialAdvancedInput={initialAdvancedInput} />)
      }
    </div>
  );

  const employeeExperienceFields = (
    <div className={rightPartStyles.accordionContentWrapper}>
      <div className={`${advancedAssumptionsEditorStyles.sectionInfo}`}> Employee experience encompasses factors such as device performance, mobility, usability, and security, that impact employee productivity and satisfaction.</div>
      {
        ASSUMPTION_ADVANCED_LIST.employeeEmpowerment.map((item, index) => <AdvancedAssumptionsInputItem item={item} currencySymbol={currencySymbol} advancedInput={advancedInput} setAdvancedInput={setAdvancedInput} fetchReportData={fetchReportData} userInput={userInput} initialAdvancedInput={initialAdvancedInput} />)
      }
    </div>
  );

  const sustainabilityFields = (
    <div className={`${advancedAssumptionsEditorStyles.ecoFieldsWrapper}`}>
      <div className={`${advancedAssumptionsEditorStyles.ecoFieldsLabelWrapper}`}>
        <div className="mobile-reults-editor-label">{`What is the average energy cost per Kwh and annual energy usage per device?`}</div>
        <FontIcon
          id={seeCalloutId1}
          iconName="Info"
          tabIndex={0}
          className={`${advancedAssumptionsEditorStyles.infoIcon}`}
          onClick={toggleIsSEE1CalloutVisible}
          directionalHint={DirectionalHint.rightCenter}
        />
        {isSEE1CalloutVisible && (
          <Callout 
            className={advancedAssumptionsEditorStyles.callout} 
            target={`#${seeCalloutId1}`} 
            onDismiss={toggleIsSEE1CalloutVisible} 
            role="alert"
            gapSpace={0}
          >
            <Text>
              Surface can help with savings through sustainability. Surface devices are designed for low energy usage which translates to cost savings as well as lower environmental impact. To account for this in your TCO, please enter your energy usage per device (Kwh) by visiting the <Link to="/sustainability/calculator" target="_blank">Surface Emissions Estimator</Link> or visit EnergyStar.
            </Text>
          </Callout>
        )}
      </div>
      <div className={`${advancedAssumptionsEditorStyles.checkBoxFieldContainer}`}>
        <FocusableCheckbox
          checked={userInput.sustainability.Energy.checked}
          onChange={(e, val) => {
            let updatedUserInput = {
              ...userInput,
              sustainability: {
                ...userInput.sustainability,
                Energy: {
                  ...userInput.sustainability.Energy,
                  checked: val,
                }
              },
            }
            setUserInput(updatedUserInput);
            getReport(updatedUserInput);
            getReportPageData(updatedUserInput);
          }}
        />
        <div>
          <div className={`${advancedAssumptionsEditorStyles.checkBoxInputField}`}>
            <div>Kwh price</div>
            <TextField 
              prefix={currencySymbol} 
              value={userInput.sustainability.Energy.unitPrice} 
              disabled={!userInput.sustainability.Energy.checked}
              onChange={(e, val) => {
                if(!isPositiveNumber(val)){
                  if(val === ""){
                  } else {
                      return;
                  }
                }
                setUserInput((currentState) => {
                  return {
                    ...currentState,
                    sustainability: {
                      ...currentState.sustainability,
                      Energy: {
                        ...currentState.sustainability.Energy,
                        unitPrice: formatNumber(val),
                      }
                    },
                  }
                })
              }}
              onBlur={(e) => {
                let updatedUserInput = userInput;

                if(e.target.value === "") {
                  updatedUserInput = {
                    ...userInput,
                    sustainability: {
                      ...userInput.sustainability,
                      Energy: {
                        ...userInput.sustainability.Energy,
                        unitPrice: 0,
                      }
                    },
                  };
                  setUserInput(updatedUserInput);
                }

                getReport(updatedUserInput);
                getReportPageData(updatedUserInput);
              }}
            />
          </div>
        </div>
      </div>
      <div className={`${advancedAssumptionsEditorStyles.ecoFieldsContainer}`}>
        <div></div>
        <div>
          <div className={`${advancedAssumptionsEditorStyles.ecoFieldContainer}`}>
            <div>Surface</div>
            <TextField 
              suffix="Kwh"
              value={userInput.sustainability.Energy.surfaceValue} 
              disabled={!userInput.sustainability.Energy.checked}
              onChange={(e, val) => {
                if(!isPositiveNumber(val)){
                  if(val === ""){
                  } else {
                      return;
                  }
                }
                setUserInput((currentState) => {
                  return {
                    ...currentState,
                    sustainability: {
                      ...currentState.sustainability,
                      Energy: {
                        ...currentState.sustainability.Energy,
                        surfaceValue: formatNumber(val),
                      }
                    },
                  }
                })
              }}
              onBlur={(e) => {
                let updatedUserInput = userInput;

                if(e.target.value === "") {
                  updatedUserInput = {
                    ...userInput,
                    sustainability: {
                      ...userInput.sustainability,
                      Energy: {
                        ...userInput.sustainability.Energy,
                        surfaceValue: 0,
                      }
                    },
                  }
                  setUserInput(updatedUserInput);
                }

                getReport(updatedUserInput);
                getReportPageData(updatedUserInput);
              }}
            />
          </div>
          <div className={`${advancedAssumptionsEditorStyles.ecoFieldContainer}`}>
            <div>Other PC</div>
            <TextField 
              suffix="Kwh"
              value={userInput.sustainability.Energy.otherPcValue}
              disabled={!userInput.sustainability.Energy.checked}
              onChange={(e, val) => {
                if(!isPositiveNumber(val)){
                  if(val === ""){
                  } else {
                      return;
                  }
                }
                setUserInput((currentState) => {
                  return {
                    ...currentState,
                    sustainability: {
                      ...currentState.sustainability,
                      Energy: {
                        ...currentState.sustainability.Energy,
                        otherPcValue: formatNumber(val),
                      }
                    },
                  }
                })
              }}
              onBlur={(e) => {
                let updatedUserInput = userInput;

                if(e.target.value === "") {
                  updatedUserInput = {
                    ...userInput,
                    sustainability: {
                      ...userInput.sustainability,
                      Energy: {
                        ...userInput.sustainability.Energy,
                        otherPcValue: 0,
                      }
                    },
                  }
                  setUserInput(updatedUserInput);
                }

                getReport(updatedUserInput);
                getReportPageData(updatedUserInput);
              }}
            />
          </div>
        </div>
      </div>


      <div className={`${advancedAssumptionsEditorStyles.ecoFieldsLabelWrapper}`}>
        <div className="mobile-reults-editor-label">{`What is your cost per Metric ton of Co2 and Co2 value per device?`}</div>
        <FontIcon
          id={seeCalloutId2}
          iconName="Info"
          tabIndex={0}
          className={`${advancedAssumptionsEditorStyles.infoIcon}`}
          onClick={toggleIsSEE2CalloutVisible}
          directionalHint={DirectionalHint.rightCenter}
        />
        {isSEE2CalloutVisible && (
          <Callout 
            className={advancedAssumptionsEditorStyles.callout} 
            target={`#${seeCalloutId2}`} 
            onDismiss={toggleIsSEE2CalloutVisible} 
            role="alert"
            gapSpace={0}
          >
            <Text>
              Surface can help with savings through sustainability. Surface devices are designed for low carbon impact which translates to cost savings as well as lower environmental impact. To account for this in your TCO, please enter your Co2 per device by visiting the <Link to="/sustainability/calculator" target="_blank">Surface Emissions Estimator</Link> or visit your current device manufacturer website to find this information.
            </Text>
          </Callout>
        )}
      </div>
      <div className={`${advancedAssumptionsEditorStyles.checkBoxFieldContainer}`}>
        <FocusableCheckbox
          checked={userInput.sustainability.CarbonEmissions.checked}
          onChange={(e, val) => {
            let updatedUserInput = {
              ...userInput,
              sustainability: {
                ...userInput.sustainability,
                CarbonEmissions: {
                  ...userInput.sustainability.CarbonEmissions,
                  checked: val,
                }
              },
            };
            setUserInput(updatedUserInput);
            getReport(updatedUserInput);
            getReportPageData(updatedUserInput);
          }}
        />
        <div>
          <div className={`${advancedAssumptionsEditorStyles.checkBoxInputField}`}>
            <div>Co2 price</div>
            <TextField 
              prefix={currencySymbol} 
              value={userInput.sustainability.CarbonEmissions.unitPrice}
              disabled={!userInput.sustainability.CarbonEmissions.checked}
              onChange={(e, val) => {
                if(!isPositiveNumber(val)){
                  if(val === ""){
                  } else {
                      return;
                  }
                }
                setUserInput((currentState) => {
                  return {
                    ...currentState,
                    sustainability: {
                      ...currentState.sustainability,
                      CarbonEmissions: {
                        ...currentState.sustainability.CarbonEmissions,
                        unitPrice: formatNumber(val),
                      }
                    },
                  }
                })
              }}
              onBlur={(e) => {
                let updatedUserInput = userInput;

                if(e.target.value === "") {
                  updatedUserInput = {
                    ...userInput,
                    sustainability: {
                      ...userInput.sustainability,
                      CarbonEmissions: {
                        ...userInput.sustainability.CarbonEmissions,
                        unitPrice: 0,
                      }
                    },
                  }
                  setUserInput(updatedUserInput);
                }

                getReport(updatedUserInput);
                getReportPageData(updatedUserInput);
              }}
            />
          </div>
        </div>
      </div>
      <div className={`${advancedAssumptionsEditorStyles.ecoFieldsContainer}`}>
        <div></div>
        <div>
          <div className={`${advancedAssumptionsEditorStyles.ecoFieldContainer}`}>
            <div>Surface</div>
            <TextField 
              suffix="MTCO2"
              value={userInput.sustainability.CarbonEmissions.surfaceValue} 
              disabled={!userInput.sustainability.CarbonEmissions.checked}
              onChange={(e, val) => {
                if(!isPositiveNumber(val)){
                  if(val === ""){
                  } else {
                      return;
                  }
                }
                setUserInput((currentState) => {
                  return {
                    ...currentState,
                    sustainability: {
                      ...currentState.sustainability,
                      CarbonEmissions: {
                        ...currentState.sustainability.CarbonEmissions,
                        surfaceValue: formatNumber(val),
                      }
                    },
                  }
                })
              }}
              onBlur={(e) => {
                let updatedUserInput = userInput;

                if(e.target.value === "") {
                  updatedUserInput = {
                    ...userInput,
                    sustainability: {
                      ...userInput.sustainability,
                      CarbonEmissions: {
                        ...userInput.sustainability.CarbonEmissions,
                        surfaceValue: 0,
                      }
                    },
                  };

                  setUserInput(updatedUserInput)
                }

                getReport(updatedUserInput);
                getReportPageData(updatedUserInput);
              }}
            />
          </div>
          <div className={`${advancedAssumptionsEditorStyles.ecoFieldContainer}`}>
            <div>Other PC</div>
            <TextField 
              suffix="MTCO2"
              value={userInput.sustainability.CarbonEmissions.otherPcValue} 
              disabled={!userInput.sustainability.CarbonEmissions.checked}
              onChange={(e, val) => {
                if(!isPositiveNumber(val)){
                  if(val === ""){
                  } else {
                      return;
                  }
                }
                setUserInput((currentState) => {
                  return {
                    ...currentState,
                    sustainability: {
                      ...currentState.sustainability,
                      CarbonEmissions: {
                        ...currentState.sustainability.CarbonEmissions,
                        otherPcValue: formatNumber(val),
                      }
                    },
                  }
                })
              }}
              onBlur={(e) => {
                let updatedUserInput = userInput;

                if(e.target.value === "") {
                  updatedUserInput = {
                    ...userInput,
                    sustainability: {
                      ...userInput.sustainability,
                      CarbonEmissions: {
                        ...userInput.sustainability.CarbonEmissions,
                        otherPcValue: 0,
                      }
                    },
                  }
                  setUserInput(updatedUserInput);
                }

                getReport(updatedUserInput);
                getReportPageData(updatedUserInput);
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );

  const getDeviceName = (dObj) => {
    let deviceName = `
      ${dObj.product.split('-')[1]} 
      ${dObj.processor.split('-')[1]}
      ${dObj.ram.split('-')[1]}
      ${dObj.driveSize.split('-')[1]}
    `;
    return deviceName;
  }

  const exportToExcel = () => {
    let requestData = _.cloneDeep(userInput);
    const currencyConversionMultipleToUSD = requestData.currencyConversionMultipleToUSD;
    const currencyConversionMultipleFromUSD = requestData.currencyConversionMultipleFromUSD;    

    let updatedAssumptions = {};
    Object.keys(requestData.assumptions).forEach((key) => {
      if (key === "warrantyAndMaintenanceCost" || key === "pcWarrantyAndMaintenanceCost") {
        if(sessionStorage.getItem("activeView") === "Advanced"){
          updatedAssumptions[key] = (convertStringToNumber(requestData.assumptions[key]) * currencyConversionMultipleToUSD / sessionStorage.getItem("years")).toFixed(2);
        } else {
          updatedAssumptions[key] = (convertStringToNumber(requestData.assumptions[key]) * currencyConversionMultipleToUSD / 3).toFixed(2);
        }
      } else if(assumptionsWithCurrency.includes(key)) {
        updatedAssumptions[key] = (convertStringToNumber(requestData.assumptions[key]) * currencyConversionMultipleToUSD).toFixed(2);
      } else {
        updatedAssumptions[key] = convertStringToNumber(requestData.assumptions[key]);
      }
    });
    requestData.assumptions = updatedAssumptions;

    if(requestData.advancedInput){
      let updatedAdvancedInput = {};
      let totalQuantity = convertStringToNumber(requestData.deviceMixPortfolio[0]?.quantity || 1);
      if(sessionStorage.getItem("showPerDevice") === "false"){
        Object.keys(requestData.advancedInput).forEach((key) => {
          const currentValueObject = requestData.advancedInput[key];

          for(const objKey in currentValueObject){
            if(typeof currentValueObject[objKey] !== 'boolean') {
              currentValueObject[objKey] = convertStringToNumber(currentValueObject[objKey]) / totalQuantity;
            }
          }

          updatedAdvancedInput[key] = {
            ...currentValueObject,
            value: (currentValueObject["value"] * currencyConversionMultipleToUSD).toFixed(2),
            surfaceValue: (currentValueObject["surfaceValue"] * currencyConversionMultipleToUSD).toFixed(2),
            pcValue: (currentValueObject["pcValue"] * currencyConversionMultipleToUSD).toFixed(2),
          };
        });
      } else {
        Object.keys(requestData.advancedInput).forEach((key) => {
          const currentValueObject = requestData.advancedInput[key];
          updatedAdvancedInput[key] = {
            ...currentValueObject,
            value: (currentValueObject["value"] * currencyConversionMultipleToUSD).toFixed(2),
            surfaceValue: (currentValueObject["surfaceValue"] * currencyConversionMultipleToUSD).toFixed(2),
            pcValue: (currentValueObject["pcValue"] * currencyConversionMultipleToUSD).toFixed(2),
          };
        });
      }
      requestData.advancedInput = updatedAdvancedInput;
    }

    let formattedSustainability = {};
    Object.keys(requestData.sustainability).forEach((key) => {
      const currentValueSusObject = requestData.sustainability[key];
      formattedSustainability[key] = {
        ...currentValueSusObject,
        unitPrice: convertStringToNumber(currentValueSusObject["unitPrice"]) * currencyConversionMultipleToUSD,
        surfaceValue: convertStringToNumber(currentValueSusObject["surfaceValue"]),
        otherPcValue: convertStringToNumber(currentValueSusObject["otherPcValue"]),
      }
    })
    requestData.sustainability = formattedSustainability;

    const deviceData = requestData.deviceMixPortfolio
      .filter((i) => Object.keys(i).length > 1)
      .map((item) => {
        return {
          seqNo: 1,
          product: item.product,
          quantity: 1,
          microsoft365Subscription: item.microsoft365Subscription,
          msrp: (item.msrp * currencyConversionMultipleToUSD).toFixed(2),
        };
    });

    let requestList = [];
    deviceData.forEach(dd => {
      requestData.deviceMixPortfolio = [];
      requestData.deviceMixPortfolio.push(dd);

      const request = sendRequest("POST", "api/tco-report-complete", {
        country: "US",
        industry: "default",
        currency: CURRENCY.unit,
        years: activeView === "Advanced" && sessionStorage.getItem("years") ? Number(sessionStorage.getItem("years")) : 3,
        ...requestData,
      });

      requestList.push(request)
    });
   
    Promise.all(requestList).then((reponseList) => {
      const workbook = new Excel.Workbook();
    
      const columns = [
        { header: 'Category', key: 'sec' },
        { header: 'Type', key: 'type' },
        { header: 'Item', key: 'item' },
        { header: `Value (in ${currencySymbol})`, key: 'value' }
      ];

      const rowData = [
        {title:  `Total Savings (per Device)`, key: 'Savings'},
        {title:  `Total Cost (per Device)`, key:'Cost'}
      ];

      reponseList.forEach((response, ind) => {
        const eData = createExcelData(response.value, currencyConversionMultipleFromUSD);
        const dataRows = [eData['savings'].length, (eData['savings'].length + eData['cost'].length)];
        const data = [...eData['savings'], ...eData['cost']];
        let worksheet = workbook.addWorksheet(`Device ${ind + 1}`); //getDeviceName(userInput.deviceMixPortfolio[ind])
        worksheet.columns = columns;
        const wrd1 = data.reduce((a, b) => a.type.length < b.type.length ? b : a);
        const wrd2 = data.reduce((a, b) => a.item.length < b.item.length ? b : a);

        worksheet.columns.forEach((column, index) => {
          switch(index){
            case 0: column.width = 15; break;
            case 1: column.width = wrd1.type.length + 5; break;
            case 2: column.width = wrd2.item.length + 5; break;
            default: column.width = column.header.length + 5; break;
          }          
          column.alignment = { horizontal: 'center', vertical: 'middle' };
        });

        data.forEach(singleData => {
          worksheet.addRow(singleData);
        });

        worksheet.eachRow({ includeEmpty: false }, (row, rowNumber) => {      

          const currentCell = row._cells;        
          currentCell.forEach(singleCell => {
            
            const cellAddress = singleCell._address;        
            worksheet.getCell(cellAddress).border = {
              top: { style: 'thin' },
              left: { style: 'thin' },
              bottom: { style: 'thin' },
              right: { style: 'thin' }
            };
    
            if(rowNumber === 1) {
              worksheet.getCell(cellAddress).fill = {
                type: 'pattern',
                pattern:'solid',
                fgColor:{argb:'FF00008B'},
              }
    
              worksheet.getCell(cellAddress).font = {
                'bold': true,
                'color': {'argb': 'FFFFFFFF'},
              }
            }       
          });          
        });        
        worksheet.autoFilter = 'A1:D1';

        //Summary Table
        const summaryRows = [ dataRows[1] + 6 , dataRows[1] + 11]; 
        summaryRows.forEach( (row, index) => {
          const currCell = `C${row - 2}`;
          worksheet.getCell(currCell).value = `Summary (${rowData[index].key} in ${currencySymbol})`;
          worksheet.getCell(currCell).fill = {
            type: 'pattern',
            pattern:'solid',
            fgColor:{argb:'FF00008B'},
          }
          worksheet.getCell(currCell).font = {
            'bold': true,
            'color': {'argb': 'FFFFFFFF'},
          }
          worksheet.mergeCells(`C${row - 2}:D${row - 2}`);
          worksheet.getCell(currCell).alignment = { horizontal: 'center' };
          const sumDataRows = [ row - 1, row, row + 1];
          worksheet.getCell(`C${sumDataRows[0]}`).value = rowData[index].title;
          const sIndex = index === 0 ? [2, dataRows[0] + 1] : [dataRows[0] + 2, dataRows[1] + 1];
          worksheet.getCell(`D${sumDataRows[0]}`).value = { formula: `SUM(D${sIndex[0]}:D${sIndex[1]})`};
          worksheet.getCell(`C${sumDataRows[1]}`).value = 'Device Quantity';
          worksheet.getCell(`D${sumDataRows[1]}`).value = 1;
          worksheet.getCell(`C${sumDataRows[2]}`).value = `Total ${rowData[index].key}`;
          worksheet.getCell(`D${sumDataRows[2]}`).value = { formula: `D${sumDataRows[0]}*D${sumDataRows[1]}` };
          [row - 2, ...sumDataRows].forEach(cell => {
            worksheet.getCell('C' + cell).border = {
              top: { style: 'thin' },
              left: { style: 'thin' },
              bottom: { style: 'thin' },
              right: { style: 'thin' }
            };
            worksheet.getCell('D' + cell).border = {
              top: { style: 'thin' },
              left: { style: 'thin' },
              bottom: { style: 'thin' },
              right: { style: 'thin' }
            };
          });   
        });
      });
    
      let worksheet = workbook.addWorksheet(`Inputs`);
      worksheet.addTable({
        name: 'Inputs',
        ref: 'A1',
        headerRow: true,
        style: {
          theme: 'TableStyleMedium3',
          //showRowStripes: true,
        },
        columns: [
          {name: 'Product', filterButton: true},
          {name: 'M365 Subscription',  filterButton: true},
        ],
        rows: userInput.deviceMixPortfolio.map(item => [getDeviceName(item), item.microsoft365Subscription])
      });

      worksheet.getColumn('A').width = 50;
      worksheet.getColumn('B').width = 20;
      createDownloadExcel(workbook)
    
    });
  }

  const createDownloadExcel = async (workbook) =>{
    // write the content using writeBuffer
    const buf = await workbook.xlsx.writeBuffer();

    saveAs(new Blob([buf]), `TCO_Calculations_Over3Years.xlsx`);
    // workbook.xlsx.writeBuffer().then((data) => {
      // const blob = new Blob([data], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
      // const url = URL.createObjectURL(blob);
      // const a = document.createElement('a');
      // a.href = url;
      // a.target = '_blank';
      // a.download = `TCO_Calculations_Over3Years.xlsx`;
      // a.click();
      
    // })
  }

  const createExcelData = (iData, currencyConversionMultipleFromUSD) => {
    const data = {
      'savings': [],
      'cost': []
    };

    const savings = [
      {'type': 'Device', 'field': 'totalSavings'},
      {'type': 'Sustainability', 'field': 'sustainability'},
      {'type': 'IT Efficiency', 'field': 'itSimplification'},
      {'type': 'Employee Experience', 'field': 'employeeEmpowerment'},
    ];

    savings.forEach( item => {
      const objData = iData[item.field];

      objData.rows.forEach(obj => {
        let listItem = {
          'sec': 'Savings',
          'type': item.type,
          'item': obj.label,
          'value': +(obj.savings * currencyConversionMultipleFromUSD).toFixed(2)
        };

        data.savings.push(listItem);
      });
    });

    const costObjData = iData.totalCosts.rows;
    costObjData.forEach(obj => {
      let listItem = {
        'sec': 'Cost',
        'type': 'Device',
        'item': obj.label,
        'value': +(obj.surfaceValue * currencyConversionMultipleFromUSD).toFixed(2)
      };

      data.cost.push(listItem);
    });

    return data;
  }


  return (
    <>
      <div className={`${layoutStyles.commonContainer} common-container`}>
        <h1 className="mobile-title">Total Cost of Ownership (TCO) <span>Calculator</span></h1>
        <div className={`${layoutStyles.mobileButtonWrapper} mobile-button-wrapper`}>
          {bottomPart}
        </div>
        <div className="input-sec">
          <div className="chatbot-toggle-container mobile-hide" >
            {process.env.REACT_APP_CHATBOT_ENABLED === "true"  && 
            <div 
              className={`${layoutStyles.chatbotButton}`} 
              onClick={() => {
                toggleChatbotWindow();
                firstTimeCheckChatbot();
                trackGAEvent("Chatbot", "AI Chat button click", "AI Chat (GPT) button click in TCO Results page");
              }} 
            >AI Chat (GPT)
            </div>}
          </div>
          <Pivot
            onLinkClick={(item) => {
              // sessionStorage.setItem("showPerDevice", "false");
              let selectedView = "";
              if(item.props.headerText === "Standard"){
                selectedView = "Standard";
                trackGAEvent("Results View", "Standard view/report click", "Standard View button click in TCO Results page");
              } else {
                selectedView = "Advanced";
                trackGAEvent("Results View", "Custom view/report click", "Custom View button click in TCO Results page");
              }

              if (selectedView === "Advanced") {
                let updatedValue = Object.assign({}, userInput);
                updatedValue = {
                  ...updatedValue,
                  assumptions: {
                    ...updatedValue.assumptions,
                    warrantyAndMaintenanceCost: convertNumberToString(Number(sessionStorage.getItem("years")) * convertStringToNumber(updatedValue.assumptions.warrantyAndMaintenanceCost) / 3),
                    pcWarrantyAndMaintenanceCost: convertNumberToString(Number(sessionStorage.getItem("years")) * convertStringToNumber(updatedValue.assumptions.pcWarrantyAndMaintenanceCost) / 3),
                  }
                };

                delete updatedValue.advancedInput;
                setUserInput(updatedValue);
                // getReport(userInput, true);
                firstTimeCheck();
              } else {
                let updatedValue = Object.assign({}, userInput);
                updatedValue = {
                  ...updatedValue,
                  assumptions: {
                    ...updatedValue.assumptions,
                    warrantyAndMaintenanceCost: convertNumberToString(3 * convertStringToNumber(updatedValue.assumptions.warrantyAndMaintenanceCost) / Number(sessionStorage.getItem("years"))),
                    pcWarrantyAndMaintenanceCost: convertNumberToString(3 * convertStringToNumber(updatedValue.assumptions.pcWarrantyAndMaintenanceCost) / Number(sessionStorage.getItem("years"))),
                  }
                };
                delete updatedValue.advancedInput;
                setUserInput(updatedValue);
                setInitialAdvancedInput({});
                setAdvancedInput({});
                sessionStorage.removeItem("FurtherEditing");
                setTimeout(() => {
                  scrollToSummary();
                }, 500);
              }
              setActiveView(selectedView);
              setActiveViewForChatbot(selectedView);
              sessionStorage.setItem("activeView", selectedView);
            }}
          >
            <PivotItem
              headerText="Standard"
              itemIcon={activeView === "Standard" ? "RadioBtnOn" : "LocationCircle"}
            >
            </PivotItem>
            <PivotItem
              headerText="Custom"
              itemIcon={activeView === "Advanced" ? "RadioBtnOn" : "LocationCircle"}
            >
            </PivotItem>
          </Pivot>
        </div>
      </div>

      <div className={`${layoutStyles.layoutContainer} layout-container`}>
        <div className={`layout-left mobile-hide`}>
          <div className={`${layoutStyles.leftTop} left-top`}>
            <div className="assumptionsEditor mobile-hide">
              <Accordion
                title={
                  <Stack
                    horizontal
                    className={`${rightPartStyles.titleWrapper} tco-assumptions-right-part-title`}
                  >
                    <div>Assumptions Editor</div>
                    <FontIcon
                      aria-describedby="assumptions-tooltip"
                      iconName="Info"
                      tabIndex={0}
                      className={`${rightPartStyles.infoIcon} tco-assumptions-right-part-title-icon`}
                    />
                  </Stack>
                }
                className="tco-assumption-right-title-accordion"
                headerClassName={rightPartStyles.titleAccordionHeader}
              >
                <span>
                  Telling us this additional information will help your calculations be
                  more accurate and specific to you.
                </span>
              </Accordion>
              <div
                className={`${rightPartStyles.infoText} tco-assumptions-right-part-info-text`}
              >
                Customize with your own information or accept default assumptions based
                on a study conducted by IDC and commissioned by Microsoft in 2022.
              </div>
              <Accordion title="Employee Assumptions" className={rightPartStyles.accordionBorder}>
                <div className={rightPartStyles.accordionContentWrapper}>
                  {/* <CommonForm form={assumptionForm} /> */}
                  <CommonFormAssumptions form={assumptionForm} defaultAssumptions={defaultAssumptions}/>
                  {discountRateFields}
                </div>
              </Accordion>
              <Accordion title="Accessories And Extended Warranty" className={rightPartStyles.accordionBorder}>
                {accessoriesPart}
              </Accordion>
              <Accordion title="Surface And Other PC Devices" className={`${rightPartStyles.accordionBorder}`}>
                <div className={rightPartStyles.accordionContentWrapper}>
                  {/* <CommonForm form={devicesForm} /> */}
                  <CommonFormAssumptions form={devicesForm} defaultAssumptions={defaultAssumptions} />
                </div>
              </Accordion>
              <div className={`${rightPartStyles.marginTop}`}></div>
              <Accordion
                title={
                  <Stack
                    horizontal
                    className={`${rightPartStyles.titleWrapper} tco-assumptions-right-part-title`}
                  >
                    <div>Optional</div>
                    <FontIcon
                      aria-describedby="assumptions-tooltip"
                      iconName="Info"
                      tabIndex={0}
                      className={`${rightPartStyles.infoIcon} tco-assumptions-right-part-title-icon`}
                    />
                  </Stack>
                }
                className="tco-assumption-right-title-accordion"
                headerClassName={rightPartStyles.titleAccordionHeader}
              >
                <span>
                  This data is not included in the standard TCO calculation, but you can enter the data and it will be factored into your results.
                </span>
              </Accordion>
              <Accordion title="Sustainability" className={rightPartStyles.accordionBorder}>
                {sustainabilityFields}
              </Accordion>
              <div className={`${rightPartStyles.marginTop}`}></div>
              <Accordion
                title={
                  <Stack
                    horizontal
                    className={`${rightPartStyles.titleWrapper} tco-assumptions-right-part-title`}
                  >
                    <div>Results Editor</div>
                    <FontIcon
                      aria-describedby="assumptions-tooltip"
                      iconName="Info"
                      tabIndex={0}
                      className={`${rightPartStyles.infoIcon} tco-assumptions-right-part-title-icon`}
                    />
                  </Stack>
                }
                className="tco-assumption-right-title-accordion"
                headerClassName={rightPartStyles.titleAccordionHeader}
              >
                <span>
                  Telling us this additional information will help your calculations be
                  more accurate and specific to you.
                </span>
              </Accordion>
              <div
                className={`${rightPartStyles.infoText} tco-assumptions-right-part-info-text`}
              >
                Adjust your cost savings across Device, IT Efficiency and/or Employee Experience.
              </div>
              <Accordion title="Device Costs" className={rightPartStyles.accordionBorder}>
                {deviceCostsFields}
              </Accordion>
              <Accordion title="Device Savings" className={rightPartStyles.accordionBorder}>
                {deviceSavingsFields}
              </Accordion>
              <Accordion title="IT Efficiency" className={rightPartStyles.accordionBorder}>
                {itEfficiencyFields}
              </Accordion>
              <Accordion title="Employee Experience" className={rightPartStyles.accordionBorder}>
                {employeeExperienceFields}
              </Accordion>
            </div>
          </div>
          <div className={`${layoutStyles.leftBottom} left-bottom mobile-hide`}>
            <LearnMoreText />
            <Stack horizontal className={bottomPartStyles.buttonContainer}>
              <DefaultButton
                text="Back to Portfolio"
                onClick={handleBackToPortfolio}
              />
              <CommandBarButton 
              id="view-full-results-button-id"
              className={bottomPartStyles.viewFullResultsButton}
                text="View Full Results"
                menuProps={{
                  items: menuItems,
                  calloutProps: {
                    styles: {
                      calloutMain: {
                        width: document.getElementById("view-full-results-button-id") ? `${document.getElementById("view-full-results-button-id").offsetWidth - 12}px` : 'auto'
                      },
                    }
                  },
                  onItemClick: handleMenuItemClick,
                }}
              />
            </Stack>
          </div>
        </div>
        <div className={`${layoutStyles.layoutRight} tco-assumptions-right-part`}>
            <div className={`${layoutRightStyles.perDeviceWrapper} per-device-wrapper`}>
              {
                activeView === "Standard" ?
                  <div id="summary1" className={`${layoutRightStyles.commonRightHeader} common-right-header`}>
                    Costs & Savings
                  </div>
                :
                  <div id="summary1" className={`${layoutRightStyles.commonRightHeader} common-right-header mobile-hide`}>
                    Costs & Savings
                  </div>
              }
              <Pivot
                onLinkClick={(item) => {
                  if (item.props.headerText === "Total") {
                    sessionStorage.setItem("showPerDevice", "false");
                    trackGAEvent("Results View", "Total results option click", "Total button click in TCO Results page");
                  } else {
                    sessionStorage.setItem("showPerDevice", "true");
                    trackGAEvent("Results View", "Per Device results option click", "Per Device button click in TCO Results page");
                  }
                  let updatedUserInput = userInput;
                  if(userInput.advancedInput){
                    updatedUserInput.advancedInput = {}; 
                    setUserInput({
                      ...userInput,
                      advancedInput: {},
                    });
                  }
                  setInitialAdvancedInput({});
                  getReport(updatedUserInput, true);
                  getReport(updatedUserInput);
                  setSelectedRadioButton(item.props.headerText);
                }}
              >
                <PivotItem headerText="Per Device"
                  itemIcon={selectedRadioButton === "Per Device" ? "RadioBtnOn" : "LocationCircle"}
                >
                </PivotItem>
                <PivotItem headerText="Total"
                  itemIcon={selectedRadioButton === "Total" ? "RadioBtnOn" : "LocationCircle"}
                >
                </PivotItem>
              </Pivot>
            </div>
            <div className={`${layoutRightStyles.pivotWrapper}`}>
              {
                activeView === "Standard" ?
                  <>{standardView}</>
                :
                  <>
                
                    {isLoading ? (
                      <Spinner
                        className={advancedStyles.spinner}
                        size={SpinnerSize.large}
                      />
                    ) : (
                      <>
                        <Accordion
                          defaultOpen={true}
                          title="Cost & Savings Customization"
                          className="mobile-display-only accordion-header-exception"
                          headerClassName={mobileAccordionStyles.accordionHeader}
                        >
                          {advancedView("mobile")}
                        </Accordion>
                        <div className="mobile-hide">{advancedView("desktop")}</div>
                      </>
                    )}
                  </>
              }
            </div>
            <div className="assumptionsEditor mobile-display-only">
              <Accordion
                title={
                  <Stack
                    horizontal
                    className={`${rightPartStyles.titleWrapper} tco-assumptions-right-part-title`}
                  >
                    <span>Assumptions Editor</span>
                    <FontIcon
                      aria-describedby="assumptions-tooltip"
                      iconName="Info"
                      tabIndex={0}
                      className={`${rightPartStyles.infoIcon} tco-assumptions-right-part-title-icon`}
                    />
                  </Stack>
                }
                className="tco-assumption-right-title-accordion"
                headerClassName={rightPartStyles.titleAccordionHeader}
              >
                <span>
                  Telling us this additional information will help your calculations be
                  more accurate and specific to you.
                </span>
              </Accordion>
              <div
                className={`${rightPartStyles.infoText} tco-assumptions-right-part-info-text`}
              >
                Customize with your own information or accept default assumptions based
                on a study conducted by IDC and commissioned by Microsoft in 2022.
              </div>
              <Accordion title="Employee Assumptions" className={rightPartStyles.accordionBorder}>
                <div className={rightPartStyles.accordionContentWrapper}>
                  {/* <CommonForm form={assumptionForm} /> */}
                  <CommonFormAssumptions form={assumptionForm} defaultAssumptions={defaultAssumptions}/>
                  {discountRateFields}
                </div>
              </Accordion>
              <Accordion title="Accessories And Extended Warranty" className={rightPartStyles.accordionBorder}>
                <div className={rightPartStyles.accordionContentWrapper}>
                  {accessoriesPart}
                </div>
              </Accordion>
              <Accordion title="Surface And Other PC Devices" className={rightPartStyles.accordionBorder}>
                <div className={rightPartStyles.accordionContentWrapper}>
                  {/* <CommonForm form={devicesForm} /> */}
                  <CommonFormAssumptions form={devicesForm} defaultAssumptions={defaultAssumptions} />
                </div>
              </Accordion>

              <div className={`${rightPartStyles.marginTop} mobile-margin-top`}></div>
              <Accordion
                title={
                  <Stack
                    horizontal
                    className={`${rightPartStyles.titleWrapper} tco-assumptions-right-part-title`}
                  >
                    <div>Optional</div>
                    <FontIcon
                      aria-describedby="assumptions-tooltip"
                      iconName="Info"
                      tabIndex={0}
                      className={`${rightPartStyles.infoIcon} tco-assumptions-right-part-title-icon`}
                    />
                  </Stack>
                }
                className="tco-assumption-right-title-accordion"
                headerClassName={rightPartStyles.titleAccordionHeader}
              >
                <span>
                  This data is not included in the standard TCO calculation, but you can enter the data and it will be factored into your results.
                </span>
              </Accordion>
              <Accordion title="Sustainability" className={rightPartStyles.accordionBorder}>
                {sustainabilityFields}
              </Accordion>

              <div className={`${rightPartStyles.marginTop} mobile-margin-top`}></div>
              <Accordion
                title={
                  <Stack
                    horizontal
                    className={`${rightPartStyles.titleWrapper} tco-assumptions-right-part-title`}
                  >
                    <span>Results Editor</span>
                    <FontIcon
                      aria-describedby="assumptions-tooltip"
                      iconName="Info"
                      tabIndex={0}
                      className={`${rightPartStyles.infoIcon} tco-assumptions-right-part-title-icon`}
                    />
                  </Stack>
                }
                className="tco-assumption-right-title-accordion"
                headerClassName={rightPartStyles.titleAccordionHeader}
              >
                <span>
                  Telling us this additional information will help your calculations be
                  more accurate and specific to you.
                </span>
              </Accordion>
              <div
                className={`${rightPartStyles.infoText} tco-assumptions-right-part-info-text`}
              >
                Adjust your cost savings across Device, IT Efficiency and/or Employee Experience.
              </div>
              <Accordion title="Device Costs" className={rightPartStyles.accordionBorder}>
                {deviceCostsFields}
              </Accordion>
              <Accordion title="Device Savings" className={rightPartStyles.accordionBorder}>
                {deviceSavingsFields}
              </Accordion>
              <Accordion title="IT Efficiency" className={rightPartStyles.accordionBorder}>
                {itEfficiencyFields}
              </Accordion>
              <Accordion title="Employee Experience" className={rightPartStyles.accordionBorder}>
                {employeeExperienceFields}
              </Accordion>
            </div>
        </div>
      </div>


      <Modal
        isOpen={showCompleteReport}
        onDismiss={() => setShowCompleteReport(false)}
        className={`${reportModalStyles.actualModal}`}
        scrollableContentClassName={`${reportModalStyles.contentWrapper} tco-report-modal-wrapper`}
        focusTrapZoneProps={{
          disabled: true,
        }}
      >
        <FontIcon
          className={reportModalStyles.cancelIcon}
          iconName="Cancel"
          onClick={() => setShowCompleteReport(false)}
          tabIndex={0}
          onKeyDown={(event) => {
            if (event.key === "Enter") {
              setShowCompleteReport(false);
            }
          }}
        />
        <TcoCompleteReport
          data={reportPageData}
          dataPerDevice={reportPageDataPerDevice}
          isLoading={isFullReportLoading}
          advancedInput={advancedInput}
          currencySymbol={currencySymbol}
          userInput={userInput}
        />
      </Modal>
      <Modal
        isOpen={isShowFirstTimeModal}
        onDismiss={() => setIsShowFirstTimeModal(false)}
        scrollableContentClassName={`${reportModalStyles.firstTimeModal} tco-assumption-first-time-modal-wrapper`}
      >
        <Stack horizontal className={reportModalStyles.firstTimeTitleWrapper}>
          <div
            className={`${reportModalStyles.firstTimeTitle} tco-assumption-first-time-modal-title`}
          >
            Customize Costs and Savings Over {sessionStorage.getItem("years")} Years
          </div>
          <FontIcon
            className={reportModalStyles.firstTimeCancelIcon}
            iconName="Cancel"
            onClick={() => setIsShowFirstTimeModal(false)}
            onKeyDown={(event) => {
              if (event.key === "Enter") {
                setIsShowFirstTimeModal(false);
              }
            }}
          />
        </Stack>
        <div
          className={`${reportModalStyles.firstTimeContent} tco-assumption-first-time-modal-content`}
        >
          Customize with your own information or accept default assumptions
          based on a study conducted by IDC and commissioned by Microsoft in
          2022.
        </div>
        <PrimaryButton
          text="Okay"
          onClick={() => setIsShowFirstTimeModal(false)}
        />
      </Modal>
      <Modal
        isOpen={isShowFirstTimeModalChatbot}
        onDismiss={() => setIsShowFirstTimeModalChatbot(false)}
        scrollableContentClassName={`${reportModalStyles.firstTimeModal} tco-assumption-first-time-modal-wrapper`}
      >
        <Stack horizontal className={reportModalStyles.firstTimeTitleWrapper}>
          <div
            className={`${reportModalStyles.firstTimeTitle} tco-assumption-first-time-modal-title`}
          >
            Preview feature disclaimer
          </div>
          <FontIcon
            className={reportModalStyles.firstTimeCancelIcon}
            iconName="Cancel"
            onClick={() => setIsShowFirstTimeModalChatbot(false)}
            onKeyDown={(event) => {
              if (event.key === "Enter") {
                setIsShowFirstTimeModalChatbot(false);
              }
            }}
          />
        </Stack>
        <div
          className={`${reportModalStyles.firstTimeContent} tco-assumption-first-time-modal-content`}
        >
          This AI assistant is an experimental feature which is still in preview. Please use with caution and be aware that it may not always provide accurate or complete information. We appreciate your understanding and feedback as we work to improve this cutting-edge technology.
        </div>
        <PrimaryButton
          text="Okay"
          onClick={() => setIsShowFirstTimeModalChatbot(false)}
        />
      </Modal>
      <Modal
        isOpen={isShowFurtherEditingModal}
        onDismiss={() => setIsShowFurtherEditingModal(false)}
        scrollableContentClassName={`${reportModalStyles.firstTimeModal} tco-assumption-first-time-modal-wrapper`}
      >
        <Stack horizontal className={reportModalStyles.firstTimeTitleWrapper}>
          <div
            className={`${reportModalStyles.firstTimeTitle} tco-assumption-first-time-modal-title`}
          >
            Further Editing
          </div>
          <FontIcon
            className={reportModalStyles.firstTimeCancelIcon}
            iconName="Cancel"
            onClick={() => setIsShowFurtherEditingModal(false)}
            onKeyDown={(event) => {
              if (event.key === "Enter") {
                setIsShowFurtherEditingModal(false);
              }
            }}
          />
        </Stack>
        <div
          className={`${reportModalStyles.firstTimeContent} tco-assumption-first-time-modal-content`}
        >
          Editing assumptions will override any fields you have altered in the
          Custom view.
        </div>
        <PrimaryButton
          text="Okay"
          onClick={() => setIsShowFurtherEditingModal(false)}
        />
      </Modal>
    </>
  );
};

export default TcoAssumptions;
