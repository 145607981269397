import React, { useEffect, useState } from 'react';
import { Callout, Checkbox, TextField, mergeStyleSets } from '@fluentui/react';
import { useBoolean, useId } from '@fluentui/react-hooks';

import ChatbotSend from "assets/chatbot-send.svg";
import ChatbotLike from "assets/chatbot-like.svg";
import ChatbotDislike from "assets/chatbot-dislike.svg";


const SEEChatbotLearnMore = (props) => {

    const {rating, setRating, questionAndAnswer, renderDetailedConversationPrompt, requireDetailedResponse} = props;

    const reportAnswerId = useId("report-answer-id");

    const [isReportMenuVisible, { toggle: toggleIsReportMenuVisible }] = useBoolean(false);

    const [selectedReportAnswerOptions, setSelectedReportAnswerOptions] = useState([]);
    const [reportAnswerText, setReportAnswerText] = useState("");
    const [like, setLike] = useState(false);
    const [dislike, setDislike] = useState(false);

    const reportCorrectAnswerOptions = [
        { key: 'option1', text: 'Accurate' },
        { key: 'option2', text: 'Relevant' },
    ];

    const reportIncorrectAnswerOptions = [
      { key: 'option1', text: 'Inaccurate' },
      { key: 'option2', text: 'Inappropriate' },
      { key: 'option3', text: 'Offensive' },
    ];

    useEffect(() => {
      if(rating === "Positive"){
        setLike(true);
      } else if(rating === "Negative"){
        setDislike(true);
      } else {
        setLike(false);
        setDislike(false);
      }
    }, [rating]);
  

    const SEEChatbotLearnMoreStyles = mergeStyleSets({
        inputBoxForReportAnswer: {
          color: "#A7A7A7",
          borderRadius: "5px",
          border: "1px solid #A7A7A7",
          background: "#FFF",
          padding: "2px",
          ".ms-TextField-suffix": {
            cursor: "pointer",
            background: "#FFFFFF",
          },
          ".ms-TextField-fieldGroup": {
            border: "none",
          },
          ".ms-TextField-fieldGroup::after": {
            border: "none",
          }
        },
        fullWidthHorizontalLine: {
          width: "100%",
          borderBottom: "1px solid #E3E3E3",
          margin: "1rem auto",
        },
        sendButton: {
          width: "1.3rem",
          height: "1.3rem",
        },
        learnMoreContainer: {
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          "> div:first-child": {
            flex: 1,
          },
          "> div:last-child": {
            width: "max-content",
          },
          span: {
            color: "#0078D4",
            textDecoration: "underline",
            cursor: "pointer",
          }
        },
        learnMoreWrapper: {
          display: "flex",
          flexDirection: "column",
        },
        conversationChangePromptContainer: {
          marginTop: "1rem",
          // new styles
          display: "flex",
          alignItems: "center",
          
        },
        promptSelectionWrapper: {
          marginTop: "0.8rem",
          display: "flex",
        },
        suggestedQuestion: {
          borderRadius: "5px",
          border: "1px solid #64BAF6",
          background: "#FFF",
          // margin: "0 0.5rem 0.5rem 0",
          marginLeft: "0.7rem",
          color: "#64BAF6",
          padding: "0.2rem 0.4rem",
          fontSize: "0.9rem",
          ":hover": {
            cursor: "pointer",
            background: "#0078D4",
            border: "1px solid #0078D4",
            color: "#FFF",
          }
        },
        responseFeedbackContainer: {
          display: "flex",
          justifyContent: "space-between",
          // alignSelf: "flex-end",
          "img": {
            cursor: "pointer",
            paddingBottom: "3px",
            borderBottom: "3px solid transparent",
            width: "1.5rem",
          },
          "img:first-child": {
            marginRight: "7px",
          }
        },
        reportAnswerCallout: {
          maxWidth: "20rem",
          border: "1px solid #A7A7A7",
          padding: "1px",
          borderRadius: "5px",
          background: "linear-gradient(218deg, #FFF 0%, #F1F0EF 100%)",
          boxShadow: "0px 0px 1.5399999618530273px 0px rgba(0, 0, 0, 0.12), 0px 0.7699999809265137px 1.5399999618530273px 0px rgba(0, 0, 0, 0.14)",
          ".ms-Callout-main": {
            padding: "0.8rem 0.6rem 0.7rem",
          },
          ".report-answer-title": {
            color: "#1D1D1C",
            fontSize: "1rem",
            fontWeight: 600,
            marginBottom: "0.8rem",
          }
        },
        reportAnswerOptionsCheckbox: {
          marginBottom: "0.7rem",
          span: {
            color: "#1D1D1C",
            fontWeight: 600,
          }
        },
        likeDislikeActive: {
          borderBottom: "3px solid #0078D4 !important",
          borderRadius: "2px",
        },
        submitAnswerContainer: {
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          marginTop: "1.5rem",
          "> div": {
            cursor: "pointer",
            borderRadius: "5px",
            border: "1px solid #0078D4",
            background: "linear-gradient(339deg, #F0F0F0 -4.66%, #FFF 106.3%)",
            padding: "0.5rem 0",
            width: "45%",
            textAlign: "center",
            fontSize: "1rem",
          },
          "> div:last-child": {
            background: "#0078D4",
            color: "#FFFFFF",
          },
        }
    });

    const handleLike = () => {
      if(rating){
        return;
      }
        setSelectedReportAnswerOptions([]);
        if(dislike && isReportMenuVisible){
            setDislike(false);
            toggleIsReportMenuVisible();
        } else if(dislike){
            setDislike(false);
        }

        if(like && isReportMenuVisible){
            //delete from db ? not needed
            toggleIsReportMenuVisible();
        } else if(like){
            //delete from db
            setRating(null);
        } else {
            toggleIsReportMenuVisible();
        }
        setLike(prevState => !prevState);
    }
  
    const handleDislike = () => {
      if(rating){
        return;
      }
      setSelectedReportAnswerOptions([]);
        if(like && isReportMenuVisible){
            setLike(false);
            toggleIsReportMenuVisible();
        } else if(like){
            setLike(false);
        }
  
        if(dislike && isReportMenuVisible){
            //delete from db ? not needed
            toggleIsReportMenuVisible();
        } else if(dislike){
            //delete from db
            setRating(null);
        } else {
            toggleIsReportMenuVisible();
        }
        setDislike(prevState => !prevState);
    }

    const onCheckboxChange = (ev, checked, option) => {
        if (checked) {
            setSelectedReportAnswerOptions((prevOptions) => [...prevOptions, option]);
        } else {
            setSelectedReportAnswerOptions((prevOptions) =>
                prevOptions.filter((item) => item.key !== option.key)
            );
        }
    };

    const handleFeedbackCancel = () => {
      setReportAnswerText('');
      setSelectedReportAnswerOptions([]);
      toggleIsReportMenuVisible();
      if(rating === "Positive"){
        setLike(true);
        setDislike(false);
      } else if(rating === "Negative"){
        setLike(false);
        setDislike(true);
      } else {
        setLike(false);
        setDislike(false);
      }
      // setLike(false);
      // setDislike(false);
      //delete from db
    }

    const handleFeedbackSubmit = async () => {
      let requestPayload = {
        question: questionAndAnswer.question,
        answer: questionAndAnswer.answer,
        rating: like ? "Positive" : "Negative",
        comment: reportAnswerText,
        // options: selectedReportAnswerOptions.map(option => option.text),
      };

      const contextApiUrl = `${process.env.REACT_APP_SEE_RATING_API}?code=${process.env.REACT_APP_API_CODE}`;
      const fetchContextAPIResponse = fetch(contextApiUrl, {
        method: "POST",
        body: JSON.stringify(requestPayload),
        headers: {
          "Content-Type": "application/json",
        },
      }).then(data => data.json())
      .then(response => setRating(like ? "Positive" : "Negative", true))
      .catch(error => setRating(null, false))

      setReportAnswerText('');
      setSelectedReportAnswerOptions([]);
      toggleIsReportMenuVisible();
    };

    return (
        <>
            {
              renderDetailedConversationPrompt ?
                <>
                  <div className={`${SEEChatbotLearnMoreStyles.conversationChangePromptContainer}`}>
                    <div>Would you like more detail?</div>
                    {/* <div className={`${SEEChatbotLearnMoreStyles.promptSelectionWrapper}`}> */}
                      <div className={`${SEEChatbotLearnMoreStyles.suggestedQuestion}`} onClick={() => requireDetailedResponse(questionAndAnswer.question)}>Yes</div>
                      {/* <div className={`${SEEChatbotLearnMoreStyles.suggestedQuestion}`} onClick={() => handleConversationSelection(false)}>No</div> */}
                    {/* </div> */}
                  </div>
                </>
              :
                <></>
            }
            <div className={`${SEEChatbotLearnMoreStyles.fullWidthHorizontalLine}`}></div>
            <div className={`${SEEChatbotLearnMoreStyles.learnMoreContainer}`} >
            <div>Learn more: &nbsp; <a href="https://www.microsoft.com/en-in/download/details.aspx?id=55974" rel="noreferrer" target="_blank">Eco Profiles</a></div>
            {/* <div>Learn more: &nbsp; <span>Eco Profiles</span></div> */}
                <div id={reportAnswerId} className={`${SEEChatbotLearnMoreStyles.responseFeedbackContainer}`}>
                    <img src={ChatbotLike} alt="like" onClick={handleLike} className={like? SEEChatbotLearnMoreStyles.likeDislikeActive :''} />
                    <img src={ChatbotDislike} alt="dislike" onClick={handleDislike} className={dislike? SEEChatbotLearnMoreStyles.likeDislikeActive:''} />
                    {
                        isReportMenuVisible && (
                            <Callout
                                target={`#${reportAnswerId}`}
                                onDismiss={handleFeedbackCancel}
                                setInitialFocus={true}
                                isBeakVisible={false}
                                className={`${SEEChatbotLearnMoreStyles.reportAnswerCallout}`}
                            >
                                <div className={`report-answer-title`}>{like ? "Thanks for the thumbs up! What did you like about the answer?": "Could you please tell why didn't you like the answer?"}</div>
                                {/* <div>
                                    {
                                      like ?
                                        reportCorrectAnswerOptions.map((option) => (
                                          <Checkbox
                                              key={option.key}
                                              label={option.text}
                                              checked={selectedReportAnswerOptions.some((item) => item.key === option.key)}
                                              onChange={(ev, checked) => onCheckboxChange(ev, checked, option)}
                                              className={`${SEEChatbotLearnMoreStyles.reportAnswerOptionsCheckbox}`}
                                          />
                                        ))
                                      :
                                        reportIncorrectAnswerOptions.map((option) => (
                                          <Checkbox
                                              key={option.key}
                                              label={option.text}
                                              checked={selectedReportAnswerOptions.some((item) => item.key === option.key)}
                                              onChange={(ev, checked) => onCheckboxChange(ev, checked, option)}
                                              className={`${SEEChatbotLearnMoreStyles.reportAnswerOptionsCheckbox}`}
                                          />
                                          ))
                                    }
                                </div> */}
                                <div>
                                    <TextField 
                                        placeholder="Add more details here..." 
                                        value={reportAnswerText}
                                        onKeyDown={(event) => {
                                            if (event.key === 'Enter') {
                                                event.preventDefault();
                                                handleFeedbackSubmit();
                                                event.target.value = '';
                                            }
                                        }}
                                        onChange={(event) => setReportAnswerText(event.target.value)}
                                        multiline={true}
                                        rows={1} 
                                        resizable={false} 
                                        className={`${SEEChatbotLearnMoreStyles.inputBoxForReportAnswer}`}
                                        // suffix={<img src={ChatbotSend} alt="send" className={SEEChatbotLearnMoreStyles.sendButton} 
                                        // onClick={() => {
                                        //     handleFeedbackSubmit(reportAnswerText);
                                        //     setReportAnswerText('');
                                        // }}/>}
                                    />
                                </div>
                                <div className={`${SEEChatbotLearnMoreStyles.submitAnswerContainer}`}>
                                  <div onClick={handleFeedbackCancel} >Cancel</div>
                                  <div onClick={handleFeedbackSubmit}>Submit</div>
                                </div>
                            </Callout>
                        )
                    }
                </div>
            </div>
            {/* {
              renderDetailedConversationPrompt ?
                <>
                  <div className={`${SEEChatbotLearnMoreStyles.conversationChangePromptContainer}`}>
                    <div>Would you like to have more detailed answers?</div>
                    <div className={`${SEEChatbotLearnMoreStyles.promptSelectionWrapper}`}>
                      <div className={`${SEEChatbotLearnMoreStyles.suggestedQuestion}`} onClick={() => console.log("yes")}>Yes</div>
                      <div className={`${SEEChatbotLearnMoreStyles.suggestedQuestion}`} onClick={() => console.log("no")}>No</div>
                    </div>
                  </div>
                </>
              :
                <></>
            } */}
        </>
    )
}

export default SEEChatbotLearnMore;