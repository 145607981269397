import React, { useEffect } from 'react';
import { mergeStyleSets } from '@fluentui/react';

import tcoLandingPageLaptopCropped from "assets/tco-landing-page-laptop-cropped.png";
import tcoLandingPageLaptop from "assets/tco-landing-page-laptop.png";
import tcoCalculatorImage from "assets/tco-calculator.svg";
import tcoReportImage from "assets/tco-report.svg";
import { useNavigate } from 'react-router-dom';
import "./TcoHome.scss";
import { trackGAEvent } from 'utils/helpers';

const TcoHome = () => {

    const navigate = useNavigate();

    useEffect(() => {
        const header = document.querySelector('header');
        const footer = document.querySelector('footer');
        header.style.width = '100%';
        header.style.marginLeft = '0%';
        footer.style.width = '100%';
        footer.style.marginLeft = '0%';
    }, [])
    

    const tcoHomeStyles = mergeStyleSets({
        container: {
            display: "flex",
            alignItems: "stretch",
            height: "85vh",
        },
        firstElementWrapper: {
            flex: "28%",
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
        },
        secondElementWrapper: {
            flex: "72%",
            backgroundImage: `url(${window.innerWidth > 1640 ? tcoLandingPageLaptop : tcoLandingPageLaptopCropped})`,
            backgroundSize: "contain",
            backgroundRepeat: "no-repeat",
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
        },
        firstElement: {
            width: "70%",
            color: "#0070C0",
            fontSize: "1.5rem",
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            justifyContent: "center",
            "img": {
                width: "5rem",
                cursor: "pointer",
            },
            "p": {
                cursor: "pointer",
            },
            textAlign: "center",
        },
        secondElement: {
            color: "#0070C0",
            fontSize: "1.5rem",
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            justifyContent: "center",
            "p": {
                width: "70%",
                cursor: "pointer",
            },
            "img": {
                width: "5rem",
                cursor: "pointer",
            },
            textAlign: "center",
        }
    });

  return (
    <div>
        <div className={`${tcoHomeStyles.container} mobile-container`}>
            <div className={`${tcoHomeStyles.firstElementWrapper} mobile-first-element-wrapper`}>
                <div className={`${tcoHomeStyles.firstElement}`}>
                    <img src={tcoReportImage} alt="idc-report" onClick={() => {navigate("/idc"); trackGAEvent("Navigation", "landing page understand TCO button", "Understand TCO option in TCO landing page")}} />
                    <p onClick={() => {navigate("/idc"); trackGAEvent("Navigation", "landing page understand TCO button", "Understand TCO option in TCO landing page")}}>Understanding Total Cost of Ownership (TCO)</p>
                </div>
            </div>
            <div className={`${tcoHomeStyles.secondElementWrapper} mobile-second-element-wrapper`} >
                <div className={`${tcoHomeStyles.secondElement}`}>
                    <img src={tcoCalculatorImage} alt="calculator" onClick={() => {navigate("/calculator"); trackGAEvent("Navigation", "landing page TCO calculator button", "TCO Calculator option in TCO landing page")}} />
                    <p onClick={() => {navigate("/calculator"); trackGAEvent("Navigation", "landing page TCO calculator button", "TCO Calculator option in TCO landing page")}}>Total Cost of Ownership (TCO) Calculator</p>
                </div>
            </div>
        </div>
    </div>
  )
}

export default TcoHome;