import {
  kNumFormat,
  responsiveSize,
  isSmallWidthScreen,
  convertNumberToString
} from "utils/helpers";

export const LARGE_NUMBER = 1000000000;
export const QUANTITY_LIMIT = 100000;

export const TOOLTIP_CONTENT = [
  {
    id: "Production",
    text: "We estimate carbon emissions from materials and production based on a Lifecycle Assessment, the system boundaries for which include extraction of raw materials, upstream materials preparation and packaging, electronic component manufacturing and packaging, subassembly manufacturing and assembly, and final assembly. ",
  },
  {
    id: "Product Use",
    text: "We estimate carbon emissions associated with product usage based on two elements: <ul><li>Product Energy Use, based on telemetry data from Windows users who have agreed to and participate in programs for sharing their data with us. We analyze these data to construct average or typical use profiles. For comparison, we also provide ENERGY STAR test and max limit values alongside data from Windows users. These values are based on the United States' Environmental Protection Agency's Energy Star standards.</li><li>Country-based factors for energy source and grids, based on publicly available and reported data. These grid factors and the calculations are provided below. </li></ul>",
  },

  {
    id: "Transport",
    text: "We estimate carbon emissions from transportation based on the user's input of device and country location by averaging historical data that considers shipment weight, transportation pathway distance, transport modalities, and defined emissions factors. These calculations are based on the Global Logistics Emissions Council (GLEC) framework.",
  },
  {
    id: "Packaging",
    text: "We estimate carbon emissions from packaging based on the specially designed packaging for commercial customers. First rolled out in fiscal year 2020, this commercial packaging platform reduced weight and greenhouse gas emissions (GHG) while increasing recycled content and recyclability.",
  },
  {
    id: "End of Life",
    text: "We estimate carbon emissions associated with end of life based on publicly available waste statistics from EuroStat (for devices used in Europe) and the United States Environmental Protection Agency (for devices used in the United States and the rest of the world). <br />Repairability and serviceability can extend the lifespan of our devices. In 2021, our Design for Repair engineering program helped launch the Surface Pro 8, the Surface Laptop Studio, and Surface Laptop SE, which are considered to be some of the most repairable devices in their product lines with replaceable displays, batteries, keyboards, and more.",
  },
];

export const SCOPE_DESCRIPTION = {
  scope2: {
    label: "Scope 2",
    content:
      "Scope 2 emissions are indirect GHG emissions associated with the purchase of electricity, steam, heat, or cooling.",
  },
  scope3: {
    label: "Scope 3",
    content:
      "Scope 3 Emissions - Indirect emissions from sources that are not owned or directly controlled by an organization but related to it activities.",
  },
};

export const SIMPLE_REPORT_CHART_OPTIONS = (data, hideTooltip) => {
  return {
    textStyle: {
      fontFamily:
        '"Segoe UI", "Segoe UI Web (West European)", "Segoe UI", -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif',
    },
    color: ["#106EBE", "#2484CE", "#61A7DD", "#ABD1EE", "#D1E6F6"],
    tooltip: {
      show: isSmallWidthScreen() || hideTooltip ? false : true,
      trigger: "item",
      formatter: (params) => {
        return TOOLTIP_CONTENT.find((i) => i.id === params.data.name)?.text;
      },
      position: function (point, params, dom, rect, size) {
        return { left: 0, top: size.viewSize[1] };
      },
      extraCssText: "width:auto; white-space:pre-wrap;",
    },
    legend: {
      show: false,
    },
    grid: {
      containLabel: true,
    },
    series: [
      {
        type: "pie",
        radius: ["55%", "69%"],
        avoidLabelOverlap: true,
        // startAngle: 290,
        label: {
          show: true,
          position: "outside",
          alignTo: "edge",
          edgeDistance: 0,
          width: responsiveSize(150),
          formatter: (params) => {
            // return `{name|${params.dataIndex + 1}. ${params.name}}\n{value|${kNumFormat(
            //   params.value
            // )}}{unit|MTCO\u2082}`;
            return `{name|${params.name}}\n{value|${kNumFormat(
              params.value
            )}}{unit|MTCO\u2082}`;
          },
          rich: {
            name: {
              fontSize: responsiveSize(16),
              fontWeight: 400,
              lineHeight: responsiveSize(16),
              verticalAlign: "middle",
              padding: [responsiveSize(16), 0, 0, 0],
              color: "#0078d4",
              // borderColor: 'red',
              // borderWidth: "1",
            },
            value: {
              fontSize: responsiveSize(24),
              fontWeight: 400,
              lineHeight: responsiveSize(30),
              verticalAlign: "middle",
              padding: [responsiveSize(14), 0, 0, 0],
            },
            unit: {
              fontSize: responsiveSize(14),
              lineHeight: responsiveSize(38),
              verticalAlign: "bottom",
              padding: [0, 0, 0, responsiveSize(4)],
            },
          },
        },
        labelLayout: (params) => {
          const isLeft = params.align === "left";
          const points = params.labelLinePoints;
          if (points) {
            // Update the end point.
            points[2][0] = isLeft
              ? params.labelRect.x
              : params.labelRect.x + params.labelRect.width;
          }
          return {
            labelLinePoints: points,
          };
        },
        emphasis: {
          disabled: isSmallWidthScreen() ? true : false,
          label: {
            show: true,
            formatter: (params) => {
              // return `{name|${params.dataIndex + 1}. ${params.name}}\n{value|${kNumFormat(
              return `{name|${params.name}}\n{value|${kNumFormat(
                params.value,
                true
              )}}{unit|MTCO\u2082}`;
            },
            rich: {
              name: {
                fontSize: responsiveSize(16),
                fontWeight: 600,
                lineHeight: responsiveSize(16),
                verticalAlign: "middle",
                padding: [responsiveSize(16), 0, 0, 0],
                color: "#0078d4",
              },
              value: {
                fontSize: responsiveSize(24),
                fontWeight: 600,
                lineHeight: responsiveSize(30),
                verticalAlign: "middle",
                padding: [responsiveSize(14), 0, 0, 0],
              },
              unit: {
                fontSize: responsiveSize(14),
                lineHeight: responsiveSize(38),
                verticalAlign: "bottom",
                fontWeight: 600,
                padding: [0, 0, 0, responsiveSize(4)],
              },
            },
          },
        },
        markPoint: {
          tooltip: { show: false },
          label: {
            show: true,
            formatter: (props) => {
              return `{value|${props.value}}\n{unit|MTCO\u2082}\n{name|${props.name}}`;
            },
            rich: {
              name: {
                color: "#201F1E",
                fontSize: responsiveSize(20),
                fontWeight: 400,
                align: "center",
                lineHeight: responsiveSize(26),
              },
              unit: {
                color: "#201F1E",
                fontSize: responsiveSize(16),
                fontWeight: 600,
                align: "center",
                lineHeight: responsiveSize(20),
                padding: [0, 0, responsiveSize(8), 0],
              },
              value: {
                color: "#201F1E",
                fontSize: responsiveSize(32),
                fontWeight: 600,
                align: "center",
                lineHeight: responsiveSize(41),
              },
            },
          },
          data: [
            {
              name: "Total Estimated\nEmissions",
              value: kNumFormat(data.totalCO2Emissions, true),
              symbol: "circle",
              itemStyle: { color: "transparent" },
              x: "50%",
              y: "50%",
            },
          ],
        },
        data: data.totalEmissionsDetail?.map((item) => {
          return {
            name: item.label,
            value: item.value,
          };
        }),
      },
    ],
  };
};

export const MINI_SIMPLE_REPORT_CHART_OPTIONS = (data) => {
  return {
    textStyle: {
      fontFamily:
        '"Segoe UI", "Segoe UI Web (West European)", "Segoe UI", -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif',
    },
    color: ["#106EBE", "#2484CE", "#61A7DD", "#ABD1EE", "#D1E6F6"],
    tooltip: {
      show: false,
    },
    legend: {
      show: false,
    },
    series: [
      {
        type: "pie",
        radius: ["68%", "100%"],
        label: {
          show: false,
        },
        emphasis: {
          disabled: true,
        },
        data: data.totalEmissionsDetail?.map((item) => {
          return {
            name: item.label,
            value: item.value,
          };
        }),
      },
    ],
  };
};

export const COMPARE_HORIZONTAL_CHART_OPTIONS = (data, color, name, formattedProductLabels, reduceSize) => {
  const maxTextlength = Math.max(...(data.map(el => el.label.length)));

  let series = [
    {
      type: "bar",
      data: data.map((i, index) => {
        return {
          name: name === 'productUse' ? `Annual kWh Energy Consumption\n${formattedProductLabels[index]}`  : `${i.label}\n${formattedProductLabels[index]}`,
          value: i.value < 0 ? 0 : i.value,
          tooltip: name === 'productUse' ? i.annualEnergyConsumptionByDevice  : (i.value < 0 ? 0 : i.value), //
          itemStyle: {
            color: Array.isArray(color.bar)
              ? color.bar[color.bar.length - 1]
              : color.bar,
          },
        };
      }),
      barWidth: data[0].energyStarValue !== undefined ? "45%" : "60%",
      barGap: "10%",
      label: {
        show: true,
        position: "right",
        distance: responsiveSize(16),
        formatter: (params) => {
          const seperator =
            params.value > LARGE_NUMBER || isSmallWidthScreen() ? "\n" : "";
          return `{value|${kNumFormat(
            params.value, true
          )}}${seperator}{unit|MTCO\u2082}`;
        },
        rich: {
          value: {
            fontSize: responsiveSize(20),
            align: "right",
            padding: [0, responsiveSize(4), 0, 0],
          },
          unit: {
            fontSize: responsiveSize(14),
            verticalAlign: "bottom",
          },
        },
        color: "#201F1E",
      },
      emphasis: {
        disabled: true,
      },
    },
  ];
  if (data[0].energyStarValue !== undefined) {
    series.push({
      //name: "EPA Energy Star Estimation",
      type: "bar",
      barWidth: "30%",
      barGap: "10%",
      data: data.map((i) => {
        
        return {
          name: "Annual kWh Energy Consumption",//i.label,
          value: i.energyStarValue,
          tooltip: i.annualEnergyConsumptionByDeviceEPA,
          itemStyle: {
            color: color.energyStar.bar,
          },
        };
      }),
      label: {
        show: true,
        position: "right",
        distance: responsiveSize(16),
        formatter: (params) => {
          const seperator =
            params.value > LARGE_NUMBER || isSmallWidthScreen() ? "\n" : "";
          return `{value|${kNumFormat(
            params.value, true
          )}}${seperator}{unit|MTCO\u2082}`;
        },
        rich: {
          value: {
            fontSize: responsiveSize(20),
            align: "right",
            padding: [0, responsiveSize(4), 0, 0],
          },
          unit: {
            fontSize: responsiveSize(14),
            verticalAlign: "bottom",
          },
        },
        color: "#201F1E",
      },
      emphasis: {
        disabled: true,
      },
      markPoint: {
        tooltip: { show: false },
        data: data.map((i, idx) => {
          return {
            symbol:
              "path://M6.24929 2.04605C6.54274 1.45145 7.39061 1.45145 7.68407 2.04605L8.79579 4.29866L11.2817 4.65988C11.9379 4.75523 12.1999 5.5616 11.7251 6.02443L9.92625 7.77785L10.3509 10.2537C10.463 10.9072 9.77704 11.4056 9.19014 11.097L6.96668 9.9281L4.74322 11.097C4.15632 11.4056 3.47037 10.9072 3.58246 10.2537L4.0071 7.77784L2.20829 6.02443C1.73347 5.5616 1.99548 4.75523 2.65166 4.65988L5.13756 4.29865L6.24929 2.04605ZM6.96668 2.85201L5.98776 4.83551C5.87123 5.07162 5.64598 5.23528 5.38541 5.27314L3.19649 5.59121L4.78041 7.13515C4.96896 7.31894 5.05499 7.58374 5.01048 7.84326L4.63657 10.0233L6.5944 8.99404C6.82746 8.87152 7.10589 8.87151 7.33895 8.99404L9.29678 10.0233L8.92287 7.84326C8.87836 7.58374 8.9644 7.31894 9.15295 7.13515L10.7369 5.59121L8.54794 5.27314C8.28738 5.23528 8.06212 5.07162 7.94559 4.83551L6.96668 2.85201Z",
            symbolSize: 10,
            itemStyle: { color: "#ffffff" },
            xAxis: i.energyStarValue / 2,
            yAxis: idx,
          };
        }),
      },
    });
  }
  
  return {
    textStyle: {
      fontFamily:
        '"Segoe UI", "Segoe UI Web (West European)", "Segoe UI", -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif',
    },
    legend: { show: false },
    grid: {
      top: 0,
      bottom: 0,
      left: "1%",
      right: "5%",
      width: reduceSize ? `${responsiveSize(65)}%` : `${responsiveSize(75)}%`,
      containLabel: true,
    },
    tooltip: {
      show: true, 
      formatter: (params) => {
        //console.log(params)
        let title = params.name;
        //title = title.replace(/{.+\n.+}\n/, "");
        const content = `<div style="display: flex; flex-direction: row; width: 100%; justify-content: space-between; gap: 1rem;">
              <span>${params.marker}${params.name}</span>
              <span><b>${name === 'productUse' ? convertNumberToString(params.data.tooltip, null, 2) : kNumFormat(params.value, true)}</b></span>
            </div>`;
          
        return `${content}`;
      },     
      //valueFormatter: (value) => kNumFormat(value, null, 2),
      confine: true,
      extraCssText: "width:auto; white-space:pre-wrap;",
    },
    xAxis: {
      type: "value",
      axisLabel: { show: false },
      splitLine: { show: false },
    },
    yAxis: {
      type: "category",
      data: data.map((i) => i.label),
      axisLabel: {
        show: true,
        color: "#201F1E",
        fontSize: reduceSize ? responsiveSize(14) : responsiveSize(16),
        lineHeight: responsiveSize(24),
        fontWeight: 400,
        margin: responsiveSize(20),
        width: reduceSize ? responsiveSize((maxTextlength / 2.7) * 10 ) : responsiveSize((maxTextlength / 2.1) * 10 ),
        overflow: "break",
        verticalAlign: data[0].energyStarValue !== undefined ? "bottom" : null,
      },
      axisLine: { show: true, lineStyle: { color: "#EDEBE9" } },
      axisTick: { show: false },
      inverse: true,
    },
    series,
  };
};

export const HORIZONTAL_STACKED_BAR_CHART_OPTIONS = (data, color, isPercentage, chatbotExpanded, formattedProductLabels) => {
  
  const isLastIndex = (index) => {
    return index === data.length - 1;
  };
  return {
    textStyle: {
      fontFamily:
        '"Segoe UI", "Segoe UI Web (West European)", "Segoe UI", -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif',
    },
    color: color ? color.bar : [],
    tooltip: {
      trigger: "axis",
      axisPointer: {
        type: "none",
      },
      formatter: (params) => {
        let title = `${params[0].name}\n${formattedProductLabels[params[0].dataIndex] || ''}`;
        const sum = params.reduce((acc, curr) => {
          if (!curr.value) {
            return acc;
          }
          const current = curr.value;
          return acc + current;
        }, 0);
        
        title = title.replace(/{.+\n.+}\n/, "");
        const content = params
          .map((item) => {
            return `<div style="display: flex; flex-direction: row; width: 100%; justify-content: space-between; gap: 1rem;">
              <span>${item.marker}${item.seriesName}</span>
              <span><b>${kNumFormat(item.value, true)}</b> ${isPercentage ? `(${ convertNumberToString((( item.value * 100) / sum), null, 2) }%)`  : ``} </span>
            </div>`;
          })
          .join("");
        return `${title}<br />${content}`;
      },
      // valueFormatter: (value) => kNumFormat(value),
      confine: true,
      extraCssText: "width:auto; white-space:pre-wrap;",
    },
    legend: {
      show: false,
    },
    grid: {
      left: (window.innerWidth < 1640 && chatbotExpanded) ? responsiveSize(200) : responsiveSize(250),
      top: 0,
      bottom: 0,
      right: (window.innerWidth > 1440 && window.innerWidth < 1740 && chatbotExpanded) ? "20%" : "30%",
      containLabel: true,
    },
    xAxis: {
      type: "value",
      axisLabel: { show: false },
      splitLine: { show: false },
    },
    yAxis: {
      type: "category",
      data: data[0].data.map((i) => i.name),
      axisLabel: {
        show: true,
        color: "#201F1E",
        inside: true,
        fontSize: (window.innerWidth < 1640 && chatbotExpanded) ? responsiveSize(12) : responsiveSize(16),
        lineHeight: responsiveSize(20),
        fontWeight: 400,
        margin: 0,
        padding: [0, responsiveSize(20), 0, 0],
        align: "right",
        formatter: (val) => {
          
          let c = document.createElement("canvas");
          const ctx = c.getContext("2d");
          ctx.font = `${responsiveSize(16)}px normal`;
          const arr = val.split("\n");
          const leftpart = arr.slice(0, arr.length - 1)
            ? arr.slice(0, arr.length - 1).join("\n")
            : "";
          const rightPart = arr[arr.length - 1].split(" ");
          
          rightPart
            .map((item) => ctx.measureText(item).width)
            .reduce((pre, next, index) => {
              const nLen = pre + next;
              if (nLen > responsiveSize(190)) {
                rightPart[index - 1] += "\n";
                return next;
              } else {
                return nLen;
              }
            });
          c = null;
          return `${leftpart}\n${rightPart.join(" ")}`;
        },
        rich: {
          description: {
            fontSize: (window.innerWidth < 1640 && chatbotExpanded) ? responsiveSize(10) : responsiveSize(12),
            lineHeight: responsiveSize(15),
            padding: [0, 0, responsiveSize(8), 0],
          },
        },
      },
      axisLine: { show: true, lineStyle: { color: "#EDEBE9" } },
      axisTick: { show: false },
      inverse: true,
    },
    series: data.map((item, index) => {
      return {
        name: item.name,
        type: "bar",
        stack: "stack",
        data: item.data,
        emphasis: {
          disabled: true,
        },
        barWidth: "60%",
        label: {
          show: isLastIndex(index) === true,
          position: "right",
          align: "left",
          distance: responsiveSize(16),
          formatter: (params) => {
            const sum = data.reduce((acc, curr) => {
              if (!curr.data[params.dataIndex]) {
                return acc;
              }
              const current = curr.data[params.dataIndex].value;
              return acc + current;
            }, 0);
            const seperator =
              sum > LARGE_NUMBER || isSmallWidthScreen() ? "\n" : "";
            return `{value|${kNumFormat(sum, true)}}${seperator}{unit|MTCO\u2082}`;
          },
          rich: {
            value: {
              fontSize: responsiveSize(20),
              padding: [0, responsiveSize(4), 0, 0],
            },
            unit: {
              fontSize: responsiveSize(14),
              verticalAlign: "bottom",
            },
          },
          color: "#201F1E",
        },
      };
    }),
  };
};

export const SUS_REPORT_CHART_COLOR = {
  production: {
    label: "#107C10",
    bar: ["#D1D9ED", "#A1B3DD", "#6F8BCD", "#4266BC", "#1240AC"],
  },
  packaging: {
    label: "#D29200",
    bar: "#b3a2f0",
  },
  transport: {
    label: "#0078D4",
    bar: "#2B88D8",
  },
  productUse: {
    label: "#107C10",
    bar: "#5FB980",
    energyStar: {
      label: "#0078D4",
      bar: "#00BCF2",
    },
  },
  endOfLife: {
    label: "#008272",
    bar: "#00B294",
  },
  emissions: [
    ["#FFF100", "#FFB900", "#D29200", "#EA4300", "#D13438"],
    ["#EDEBE9", "#BAD80A", "#00B294", "#008272", "#004B50"],
  ],
  portfolioOverview: {
    label: "#0078D4",
    bar: ["#B8E0FF", "#ABD1EE", "#61A7DD", "#2484CE", "#095A97"],
  },
};