import { v4 as uuid } from "uuid";
import { Stack, FontIcon, mergeStyleSets } from "@fluentui/react";
import laptopIcon from "assets/laptop.svg";
import "./SelectionBoxList.scss";

const SelectionBoxList = (props) => {
  const {
    title,
    selectionName,
    max = Number.MAX_SAFE_INTEGER,
    min = 0,
    list,
    updateList,
    boxContent,
    onAdd,
    onDelete,
    icon,
    message,
  } = props;

  const handleAdd = () => {
    let updatedList = [...list];
    updatedList.push({
      id: uuid(),
    });   
    if (onAdd) {
      if(onAdd(updatedList))
      {
        updateList(updatedList);
      }
    } else {
      updateList(updatedList);
    }
  };

  const handleDelete = (id) => {
    const updatedList = list.filter((i) => i.id !== id);
    updateList(updatedList);
    if (onDelete) {
      onDelete(updatedList, id);
    }
  };

  const ItemSelectionBoxStyles = mergeStyleSets({
    container: {
      border: "0.0625rem solid #E1DFDD",
      borderRadius: "1rem",
      width: "100%",
    },
    titleWrapper: {
      justifyContent: "space-between",
      alignItems: "center",
      padding: "1.3281rem 1.1875rem",
      borderBottom: "0.0313rem solid #EDEBE9",
    },
    titleNameWrapper: {
      alignItems: "center",
    },
    itemTitleIcon: {
      marginRight: "0.625rem",
      alignSelf: "center",
      width: "1.25rem",
      height: "1.25rem",
    },
    title: {
      fontSize: "1rem",
      fontWeight: 600,
      lineHeight: "1rem",
      margin: 0,
    },
    deleteButton: {
      cursor: "pointer",
      color: "#0078D4",
      fontSize: "1rem",
    },
    contentWrapper: {
      padding: "1rem 1rem 1.5rem 1.5rem",
    },
  });

  const ItemSelectionBox = (item, index) => (
    <div className={ItemSelectionBoxStyles.container} key={item.id}>
      <Stack horizontal className={ItemSelectionBoxStyles.titleWrapper}>
        <Stack horizontal className={ItemSelectionBoxStyles.titleNameWrapper}>
          <img
            alt="item title icon"
            src={icon || laptopIcon}
            className={`${ItemSelectionBoxStyles.itemTitleIcon} selection-box-title-icon`}
            tabIndex={0}
          />
          <h3 className={ItemSelectionBoxStyles.title} tabIndex={0}>
            {selectionName} Selection {index + 1}
          </h3>
        </Stack>
        {list.length > min ? (
          <FontIcon
            className={`${ItemSelectionBoxStyles.deleteButton} selection-box-delete-button`}
            iconName="Delete"
            tabIndex={0}
            onClick={() => handleDelete(item.id)}
            onKeyDown={(event) => {
              if (event.key === "Enter") {
                handleDelete(item.id);
              }
            }}
          />
        ) : null}
      </Stack>
      <div className={ItemSelectionBoxStyles.contentWrapper}>
        {boxContent(item)}
      </div>
    </div>
  );

  const selectionBoxListStyles = mergeStyleSets({
    titleWrapper: {
      marginBottom: "2.375rem",
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
    },
    titleIcon: {
      marginRight: "1.0625rem",
      width: "1.25rem",
      height: "1.25rem",
    },
    title: {
      fontSize: "1.625rem",
      fontWeight: 600,
      lineHeight: "2.4375rem",
      color: "#201F1E",
      margin: 0,
    },
    maxMsg: {
      fontSize: "1.25rem",
      lineHeight: "1.5rem",
      marginBottom: "2rem",
    },
    contentWrapper: {
      width: "100%",
      gap: "2rem",
      display: "grid",
      gridTemplateColumns: "repeat(1, minmax(0, 1fr))",
    },
    addBox: {
      border: "0.0625rem solid #E1DFDD",
      borderRadius: "1rem",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      // minHeight: "calc(6.4062rem + 128px)",
      minHeight: "3rem",
      cursor: "pointer",
    },
    addButton: {
      cursor: "pointer",
      fontSize: "1.125rem",
      fontWeight: 600,
      lineHeight: "2rem",
      color: "#0078D4",
    },
  });

  return (
    <div className="selection-box-list-wrapper">
      <div
        className={`${selectionBoxListStyles.titleWrapper} selection-box-title-wrapper`}
      >
        <img
          alt="title icon"
          src={laptopIcon}
          className={`${selectionBoxListStyles.titleIcon} selection-box-title-icon`}
          tabIndex={0}
        />
        <h2 className={selectionBoxListStyles.title} tabIndex={0}>
          {title}
        </h2>
      </div>
      {message ? (
        <div className={`${selectionBoxListStyles.maxMsg} maximum-message`}>
          {message}
        </div>
      ) : null}
      <div
        className={`${selectionBoxListStyles.contentWrapper} selection-box-content-wrapper`}
      >
        {list.map((item, index) => (
          <div key={item.id}>{ItemSelectionBox(item, index)}</div>
        ))}
        {list.length < max ? (
          <div
            className={`${selectionBoxListStyles.addBox} selection-box-add-box`}
            onClick={handleAdd}
            onKeyDown={(event) => {
              if (event.key === "Enter") {
                handleAdd();
              }
            }}
            tabIndex={0}
          >
            <div className={selectionBoxListStyles.addButton} tabIndex={0}>
              + Add {selectionName}
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
}

export default SelectionBoxList;