import { Stack, mergeStyleSets } from "@fluentui/react";
import "./MethodologyPage.scss";

const MethodologyPage = () => {
  const pageStyles = mergeStyleSets({
    modelListTitleWrapper: {
      gap: "1rem",
    },
    modelListTitle: {
      fontWeight: 600,
    },
    modelListLeft: {
      width: "30%",
      flexShrink: "0 !important",
    },
    modelListWrapper: {
      lineHeight: "2.5rem",
      gap: "1rem",
    },
  });

  const modelList = [
    {
      type: 'Surface Laptop 5 13" - Metal',
      configuration: "Intel Core i7, 16 GB DRAM, 256 GB SSD",
    },
    {
      type: 'Surface Laptop 5 15" - Metal',
      configuration: "Intel Core i7, 16 GB DRAM, 256 GB SSD",
    },
    {
      type: "Surface Pro 9 Intel",
      configuration: "Intel Core i5, 8 GB DRAM, 256 GB SSD",
    },
    {
      type: "Surface Pro 9 SQ3",
      configuration: "SQ3, 8 GB DRAM, 128 GB SSD",
    },
    {
      type: "Surface Laptop Studio",
      configuration: "Intel i7 16GB 512GB dGPU",
    },
    {
      type: "Surface Laptop Go 2",
      configuration: "Intel i5 8GB 128GB",
    },
    {
      type: "Surface Go3",
      configuration: "Wifi 4GB 64GB",
    },
    {
      type: "Surface Laptop SE",
      configuration: "Wifi 4GB 64GB",
    },
  ];

  return (
    <div className="methodology-page-wrapper">
      <p tabIndex={0}>
        This page describes the methodology that provides the basis for the
        Surface Emissions Estimator. The Estimator is intended to help
        commercial customers understand the estimated carbon emissions footprint
        associated with the life cycle impacts of Microsoft Surface devices that
        they purchase and use.
      </p>
      <p tabIndex={0}>
        The Surface Emissions Estimator methodology is highly dependent on Life
        Cycle Assessments (LCA) that were conducted and which represent the
        estimated environmental impacts resulting from key life cycle stages of
        Microsoft Surface devices. LCAs have been conducted for the following
        products and components: the main device, power supply unit, and any
        packaging. LCAs were not conducted on other accessories, such as the
        Surface Type Cover and Surface Pen. Therefore, the emissions resulting
        from such components are not covered by this Estimator.
      </p>
      <p tabIndex={0}>
        LCA calculations are based on a specific hardware configuration for each
        Surface device model type, according to the following table:
      </p>
      <Stack>
        <Stack horizontal className={pageStyles.modelListTitleWrapper}>
          <span
            className={`${pageStyles.modelListTitle} ${pageStyles.modelListLeft}`}
            tabIndex={0}
          >
            Model Type
          </span>
          <span className={pageStyles.modelListTitle} tabIndex={0}>
            Configuration
          </span>
        </Stack>
        {modelList.map((item, index) => (
          <Stack horizontal key={index} className={pageStyles.modelListWrapper}>
            <span className={pageStyles.modelListLeft} tabIndex={0}>
              {item.type}
            </span>
            <span tabIndex={0}>{item.configuration}</span>
          </Stack>
        ))}
      </Stack>
      <p tabIndex={0}>
        LCA data provide an estimation of carbon footprint on a per device
        basis. The system boundaries for these calculations include extraction
        of raw materials, upstream materials preparation and packaging,
        electronic component manufacturing and packaging, subassembly
        manufacturing and assembly, final assembly, product packaging,
        distribution to customer, product usage, and end-of-life treatment.
        Please note: LCA data provide an estimation only and actual user data
        may vary.
      </p>
      <h3 tabIndex={0}>Carbon Emission Estimations by Device Aspect</h3>
      <h4 tabIndex={0}>Estimated Emissions from Materials and Production</h4>
      <p tabIndex={0}>
        Carbon emissions associated with materials and production of our Surface
        devices are based on an LCA in accordance with ISO 14040 and ISO 14044
        complemented by ICT specific ETSI TS 199 and ITU-T L 1410. The Life
        Cycle Inventory (LCI) data is based on our own measurements, collected
        from suppliers, and internationally available LCI databases.
      </p>
      <p tabIndex={0}>
        We use bill of materials information collected from our suppliers to
        estimate the manufacturing & production carbon footprint for each type
        of Surface device model. Our sustainability teams input these data
        sources into an LCA model consistent with ISO 14040 and ISO 14044, which
        calculates the resulting carbon emissions.
      </p>
      <p tabIndex={0}>
        The system boundaries for these estimations include extraction of raw
        materials, upstream materials preparation and packaging, electronic
        component manufacturing and packaging, subassembly manufacturing and
        assembly, and final assembly.
      </p>
      <h4 tabIndex={0}>Estimated Emissions from Product Packaging</h4>
      <p tabIndex={0}>
        We use bill of materials information for Surface device product
        packaging and input such data into an LCA model consistent with ISO
        14044, which estimates the carbon footprint of the product packaging.
        The Packaging emissions are estimated considering "Small" size as the
        default size for a given Packaging configuration and device.
      </p>
      <p tabIndex={0}>
        Note that we consider and estimate packaging for upstream materials and
        electronic components in the <b>Materials & Production</b> stage.
      </p>
      <h4 tabIndex={0}>Estimated Emissions from Transportation</h4>
      <p tabIndex={0}>
        We consider three components when estimating carbon emissions from
        Surface devices that are associated with the transportation stage:
      </p>
      <ol>
        <li tabIndex={0}>
          Transport of <u>sub-assemblies and components to assembly sites</u>
          <ol>
            <li tabIndex={0}>
              Carbon emissions are calculated using our LCA model and based on
              industry factors. These emissions are reported in the Materials
              and Production lifecycle stage and attributed to the components
              transported.
            </li>
          </ol>
        </li>
        <li tabIndex={0}>
          <u>Assembly to Distribution Centers</u> transport and{" "}
          <u>Distribution to Customer</u>
          <ol>
            <li tabIndex={0}>
              Carbon emissions are estimated using average results from
              calculations based on the Global Logistics Emissions Council
              (GLEC) framework, the leading framework on freight and logistics
              emissions. We provide estimates based on the user’s input of
              device and country location by averaging historical data that
              considers shipment weight, transportation pathway distance,
              transport modalities, and defined emissions factors.
            </li>
          </ol>
        </li>
      </ol>
      <p tabIndex={0}>
        Actual transportation emissions may vary from estimates provided in the
        calculator based on factors specific to the customer’s order, including
        the precise customer location and the shipment modality utilized.
      </p>
      <h4 tabIndex={0}>Estimated Emissions from Product Use</h4>
      <p tabIndex={0}>
        We estimate carbon emissions associated with Surface device product
        usage based on two elements:
      </p>
      <ol>
        <li tabIndex={0}>
          Product Energy Use, based on telemetry data from Windows users who
          have agreed to and participate in programs for sharing their data with
          us. We analyze these data to construct average or typical use
          profiles.
          <p>
            Note that the Surface Carbon Calculator also provides ENERGY STAR
            test and max limit values alongside data from Windows users. These
            values are based on the United States Environmental Protection
            Agency’s ENERGY STAR{" "}
            <a
              href="https://www.energystar.gov/products/office_equipment/computers"
              rel="noreferrer"
              target="_blank"
            >
              Computer Product Specification
            </a>
          </p>
        </li>
        <li tabIndex={0}>
          Country-based factors for energy source and grids, based on publicly
          available and reported data. These grid factors and the calculations
          are provided in the <b>Product Usage</b> tab
        </li>
      </ol>
      <p tabIndex={0}>
        Actual emissions may vary from estimates based on differing product
        usage patterns and differing local energy grid factors.
      </p>
      <p tabIndex={0}>
        The default estimations assume a 3-year useful life for each device. Repairs,
        maintenance, returns, and warranty service are currently not
        incorporated in emissions estimates provided by this tool.
      </p>
      <h4 tabIndex={0}>Estimated Emissions from End of Life</h4>
      <p tabIndex={0}>
        We estimate carbon emissions associated with end of life management of
        Surface devices based on publicly available waste statistics from{" "}
        <a
          href="https://ec.europa.eu/eurostat/home?"
          rel="noreferrer"
          target="_blank"
        >
          EuroStat
        </a>{" "}
        (for devices used in Europe) and the United States{" "}
        <a
          href="https://www.epa.gov/smm-electronics"
          rel="noreferrer"
          target="_blank"
        >
          Environmental Protection Agency
        </a>{" "}
        (for devices used in the United States and the rest of world). These
        information sources are input into an LCA model consistent with ISO
        14040 and ISO 14044 to estimate end of life emissions based on
        industry-specific assumptions.
      </p>
      <p tabIndex={0}>
        Actual emissions may vary from estimates based on the asset recovery and
        recycling suppliers contracted. Microsoft maintains{" "}
        <a
          href="https://www.microsoft.com/en-us/download/details.aspx?id=11691"
          rel="noreferrer"
          target="_blank"
        >
          Supplier Conformance Standards for End-of-Life Management of
          Electrical and Electronic Equipment Waste and Materials
        </a>
        , and encourages customers to use suppliers that meet or exceed the
        Supplier Conformance Standards.
      </p>
      <h4 tabIndex={0}>Estimated Equivalency to other types of Emissions</h4>
      <p tabIndex={0}>
        Equivalency to vehicle distance traveled is based on the United States{" "}
        <a
          href="https://www.epa.gov/greenvehicles/greenhouse-gas-emissions-typical-passenger-vehicle"
          rel="noreferrer"
          target="_blank"
        >
          EPA’s 2018 assumption
        </a>
        that the average gasoline passenger vehicle emits 404g CO2 per mile,
        converted to kilometers. Equivalency to volume of fuel consumed is based
        on the{" "}
        <a
          href="https://www.epa.gov/energy/greenhouse-gases-equivalencies-calculator-calculations-and-references"
          rel="noreferrer"
          target="_blank"
        >
          EPA conversion factor
        </a>{" "}
        of 8,887g CO2 per gallon of gasoline consumed, converted to liters.
        Equivalency to barrels of oil consumed is based on the{" "}
        <a
          href="https://www.epa.gov/energy/greenhouse-gases-equivalencies-calculator-calculations-and-references"
          rel="noreferrer"
          target="_blank"
        >
          EPA’s 2021 calculation
        </a>{" "}
        that the average carbon dioxide coefficient of liquefied petroleum gases
        is 235.7 kg CO2 per 42-gallon barrel.
      </p>
    </div>
  );
};

export default MethodologyPage;
