import { Checkbox } from "@fluentui/react";

const FocusableCheckbox = (props) => {
  return (
    <div
      tabIndex={0}
      onKeyDown={(event) => {
        if (
          event.key === "Enter" &&
          document.activeElement.querySelector("input")
        ) {
          document.activeElement.querySelector("input").click();
        }
      }}
    >
      <Checkbox {...props} />
    </div>
  );
};

export default FocusableCheckbox;