import React, { useEffect } from 'react';
import { mergeStyleSets } from '@fluentui/react';

import susLandingPageLaptopCropped from "assets/sus-landing-page-laptop.png";
import susLandingPageLaptop from "assets/sus-landing-page-laptop.png";
import susCalculatorImage from "assets/tco-calculator.svg";
import susReportImage from "assets/tco-report.svg";
import { useNavigate } from 'react-router-dom';
import "./SustainabilityHome.scss";

const SustainabilityHome = () => {

    const navigate = useNavigate();

    //This can be removed???
    useEffect(() => {
        const header = document.querySelector('header');
        const footer = document.querySelector('footer');
        header.style.width = '100%';
        header.style.marginLeft = '0%';
        footer.style.width = '100%';
        footer.style.marginLeft = '0%';
    }, [])
    

    const susHomeStyles = mergeStyleSets({
        container: {
            display: "flex",
            alignItems: "stretch",
            height: "85vh",
            gap: "6%",
        },
        firstElementWrapper: {
            flex: "28%",
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
        },
        secondElementWrapper: {
            flex: "72%",
            backgroundImage: `url(${window.innerWidth > 1640 ? susLandingPageLaptop : susLandingPageLaptopCropped})`,
            backgroundSize: "contain",
            backgroundRepeat: "no-repeat",
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
        },
        firstElement: {
            width: "70%",
            color: "#0070C0",
            fontSize: "1.5rem",
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            justifyContent: "center",
            "img": {
                width: "5rem",
                cursor: "pointer",
            },
            "p": {
                cursor: "pointer",
            },
            textAlign: "center",
        },
        secondElement: {
            color: "#0070C0",
            fontSize: "1.5rem",
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            justifyContent: "center",
            "p": {
                width: "100%",
                cursor: "pointer",
            },
            "img": {
                width: "5rem",
                cursor: "pointer",
            },
            textAlign: "center",
        }
    });

  return (
    <div>
        <div className={`${susHomeStyles.container} sus-mobile-container`}>
            <div className={`${susHomeStyles.firstElementWrapper} sus-mobile-first-element-wrapper`}>
                <div className={`${susHomeStyles.firstElement}`}>
                    <img src={susReportImage} alt="surface and sustainability" onClick={() => navigate("/sustainability/introduction")} />
                    <p onClick={() => navigate("/sustainability/introduction")}>Surface & Sustainability</p>
                </div>
            </div>
            <div className={`${susHomeStyles.secondElementWrapper} sus-mobile-second-element-wrapper`} >
                <div className={`${susHomeStyles.secondElement}`}>
                    <img src={susCalculatorImage} alt="sustainability calculator" onClick={() => navigate("/sustainability/calculator")} />
                    <p onClick={() => navigate("/sustainability/calculator")}>Surface Emissions Estimator</p>
                </div>
            </div>
        </div>
    </div>
  )
}

export default SustainabilityHome;