import { useState, useEffect, useContext } from "react";
import TcoLanding from "./TcoLanding";
import TcoAssumptions from "./TcoAssumptions";
import { v4 as uuid } from "uuid";
import { AlertContext } from "components/Alert";
import { sendRequest, convertNumberToString } from "utils/helpers";
import { YEAR_AMOUNT } from "constants/tco";

import Chatbot from "./chatbot/Chatbot";
import "./TcoCalculator.scss";
import { merge, mergeStyleSets } from "@fluentui/react";

import ChatbotIcon from "assets/chatbot-icon.PNG";

const TcoCalculator = () => {
  const [showAssumptions, setShowAssumptions] = useState(false);
  const [userInput, setUserInput] = useState({
    deviceMixPortfolio: [{ id: uuid() }],
    options: {
      includeCameraPeripheralSavings: true,
      includeLightsPeripheralSavings: true,
      includeHeadsetPeripheralSavings: true,
      includeDockCost: true,
      includeTypeCoverCost: false,
      includePenCost: false,
      includeExtraPowerSupplyCost: false,
      includeDeviceConsolidationSavings: true,
      includeResidualValueSavings: true,
      include3rdPartyServicesSavings: true,
      wantAccessories: true,
      default: true,
      custom: false
    },
    sustainability: {
      Energy: {
        checked: false,
        unitPrice: 0,
        surfaceValue: 0,
        otherPcValue: 0
      },
      CarbonEmissions: {
        checked: false,
        unitPrice: 0,
        surfaceValue: 0,
        otherPcValue: 0
      }
    }
  });
  const { setAlert } = useContext(AlertContext);
  const [config, setConfig] = useState({});
  
  const [currencySymbol, setCurrencySymbol] = useState("");
  const [currencyList, setCurrencyList] = useState([]);

  const [retainedRegionData, setRetainedRegionData] = useState({});
  const [retainedYears, setRetainedYears] = useState({years: "3"});

  const [expanded, setExpanded] = useState(false);
  const [activeViewForChatbot, setActiveViewForChatbot] = useState("Standard");

  const [openFullReport, setOpenFullReport] = useState(false);
  const [defaultAssumptions, setDefaultAssumptions] = useState({});

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [showAssumptions]);
  
  useEffect(() => {
    document.title = "Microsoft Surface Total Cost of Ownership";

    sendRequest("POST", "api/tco-config").then((data) => {
      if (data.error) {
        setAlert("error", data.error.message);
      }
      if (data) {
        let list = {};
        Object.keys(data).forEach((item) => {
          if(item === "newProducts"){
            list["productsMasterData"] = data[item];

            const allProducts = Array.from(new Set(data[item].map((item) => item.name)));
            list["productNames"] = allProducts.map((i, index) => {
              return {
                key: `${index}-${i}`,
                text: i,
              }
            });

            list["msrp"] = data[item].map((i) => {
              return {
                key: i.code,
                text: i.msrp,
              }
            });
          } else if(item === "currencies") {
            list[item] = data[item].map((i) => {
              return {
                key: i.currencyCode,
                text: i.currencyCode,
                countryCode: i.countryCode,
                toUSD: i.usdPerCurrency,
                fromUSD: i.currencyPerUSD,
              };
            });
          } else {
            list[item] = data[item].map((i) => {
              return {
                key: i.code,
                text: i.name,
              };
            });
          }
        });
        setConfig(list);
      }
    });

    sendRequest("POST", "api/tco-assumptions").then((data) => {
      if (!data.value) {
        setAlert("error", data.error.message);
        return;
      }
      if (data.value) {
        const { assumptions } = data.value;
        let updatedValues = {};
        assumptions.forEach((i) => {
          if (i.tco_code === "warrantyAndMaintenanceCost" || i.tco_code === "pcWarrantyAndMaintenanceCost") {
            updatedValues[i.tco_code] = convertNumberToString(
              i.tco_default * YEAR_AMOUNT
            );
          } else {
            updatedValues[i.tco_code] = convertNumberToString(i.tco_default);
          }
        });
        setUserInput({
          ...userInput,
          assumptions: {
            ...userInput.assumptions,
            ...updatedValues,
          },
        });
      }
    });
  }, []);

  const toggleChatbotWindow = () => {
    const header = document.querySelector('header');
    const footer = document.querySelector('footer');

    if(expanded){
      header.style.width = '100%';
      header.style.marginLeft = '0%';
      footer.style.width = '100%';
      footer.style.marginLeft = '0%';
    } else {
      header.style.width = '70%';
      header.style.marginLeft = '30%';
      footer.style.width = '70%';
      footer.style.marginLeft = '30%';
    }
    
    setExpanded(!expanded);
  }

  const containerStyles = mergeStyleSets({
    rootContainer: {
      width: "100%",
      display: "flex",
    },
    assumptionsContainer: {
      width: "100%",
      transition: "all 0.3s ease",
    },
    assumptionsContainerExpanded: {
      width: "70%",
      marginLeft: "30%",
      transition: "all 0.3s ease",
    },
    chatbotContainer: {
      display: "none",
      width: "0",
      transition: "all 1s ease",
    },
    chatbotContainerExpanded: {
      display: "block",
      width: "30%",
      height: "100vh",
      position: "fixed",
      left: 0,
      top: 0,
      transition: "all 1s ease",
      background: "linear-gradient(316deg, #FFF 0%, #F0F0F0 57.36%)",
      boxShadow: "0px 0px 1.5399999618530273px 0px rgba(0, 0, 0, 0.12), 0px 0.7699999809265137px 1.5399999618530273px 0px rgba(0, 0, 0, 0.14)",
    },
    chatIcon: {
      width: "3rem",
      height: "3rem",
      position: "fixed",
      bottom: "15rem",
      left: "1rem",
      cursor: "pointer",
    }
  })

  return (
    // <div className={`${containerStyles.tcoRootContainer} ${expanded ? 'expanded' : ''}`}>
      // {/* <div className={`${containerStyles.mainContainer}`}> */}
        
      // </div>
    // </div>
    <>
    {showAssumptions ? (
          <div className={`${containerStyles.rootContainer}`}>
            <div className={`${expanded ? containerStyles.chatbotContainerExpanded : containerStyles.chatbotContainer}`}>
              <Chatbot 
                toggleChatbotWindow={toggleChatbotWindow} 
                userInput={userInput}
                activeViewForChatbot={activeViewForChatbot}
                currencySymbol={currencySymbol}
                setOpenFullReport={setOpenFullReport}
              />
            </div>
            <div className={`${expanded ? containerStyles.assumptionsContainerExpanded : containerStyles.assumptionsContainer}`}>
              <TcoAssumptions
                userInput={userInput}
                setUserInput={setUserInput}
                setShowAssumptions={setShowAssumptions}
                currencySymbol={currencySymbol}
                retainedYears={retainedYears}
                setRetainedYears={setRetainedYears}
                chatbotExpanded={expanded}
                setChatbotExpanded={setExpanded}
                setActiveViewForChatbot={setActiveViewForChatbot}
                toggleChatbotWindow={toggleChatbotWindow}
                openFullReport={openFullReport}
                setOpenFullReport={setOpenFullReport}
                defaultAssumptions={defaultAssumptions}
              />
            </div>
          </div>
        ) : (
          <TcoLanding
            userInput={userInput}
            setUserInput={setUserInput}
            setShowAssumptions={setShowAssumptions}
            config={config}
            setDefaultAssumptions={setDefaultAssumptions}
            setCurrencySymbol={setCurrencySymbol}
            currencyList={currencyList}
            setCurrencyList={setCurrencyList}
            retainedRegionData={retainedRegionData}
            setRetainedRegionData={setRetainedRegionData}
          />
        )}
    </>
  );
};

export default TcoCalculator;
