import { useState } from "react";
import { Stack, CompoundButton, mergeStyleSets, FontIcon, Callout, DirectionalHint } from "@fluentui/react";
import CommonForm from "components/CommonForm/CommonForm";
import { useForm } from "components/CommonForm/formHooks";
import "./YesOrNoQuestion.scss";
import { useBoolean } from '@fluentui/react-hooks';

const YesOrNoQuestion = (props) => {
  const {
    question,
    description,
    childFields,
    childInitialValues,
    value,
    className,
    onChange,
    preventEvent,
    tooltip,
    tooltipId
  } = props;
  const [choice, setChoice] = useState(value);
  const [isCalloutVisible, { toggle: toggleCalloutVisible }] = useBoolean(false);
  const childForm = useForm(childFields, childInitialValues);

  const handleClick = (val) => {
    if (preventEvent && preventEvent()) return;
    if (choice === val) {
      return;
    }
    setChoice(val);
    onChange(null, val);
  };

  const yesOrNoQuestionStyles = mergeStyleSets({
    calloutForSummaryItems: {
      padding: '1rem 1.5rem',
      width: 500,
      maxWidth: '90%',
      "span": {
        display: "block",
        paddingBottom: "0.7rem",
      }
    },
    questionWrapper: {
      marginBottom: "1rem",
    },
    question: {
      fontSize: "1.125rem",
      fontWeight: 400,
      lineHeight: "1.6875rem",
      color: "#201F1E",
    },
    description: {
      fontSize: "1rem",
      lineHeight: "1.5rem",
      color: "#0078D4",
    },
    buttonWrapper: {
      gap: "1rem",
      marginBottom: "1.5rem",
      button: {
        borderRadius: 4,
        width: "50%",
        height: "45px", //"4rem",
        minHeight: "unset",
        ".ms-Button-flexContainer": {
          alignItems: "center",
          ".ms-Button-textContainer": {
            textAlign: "center",
            ".ms-Button-label": {
              margin: 0,
              fontSize: "1rem",
              fontWeight: 400,
              lineHeight: "1.25rem",
            },
          },
        },
      },
    },
    followUpQuestion: {
      marginBottom: "2.5rem",
      color: "#201F1E",
      fontSize: "1.125rem",
      fontWeight: 400,
    },
    infoIconUpdated: {
      cursor: "pointer",
      fontSize: "1.25rem",
      paddingLeft: "0.5rem",
    },
  });

  return (
    <div className={`${className ? className : ""} yes-or-no-question-wrapper`}>
      <div className={yesOrNoQuestionStyles.questionWrapper}>
        <div className={`${yesOrNoQuestionStyles.question} yes-or-no-question`}>
          {question}
          {
            tooltip ?
            <span>
            <FontIcon
              id={`icon${tooltipId}`}
              iconName="Info"
              tabIndex={0}
              className={`${yesOrNoQuestionStyles.infoIconUpdated} tco-assumptions-right-part-title-icon`}
              onClick={toggleCalloutVisible}
              directionalHint={DirectionalHint.rightCenter}
            />
            {
              isCalloutVisible ? 
            
            <Callout 
              className={yesOrNoQuestionStyles.calloutForSummaryItems} 
              target={`#icon${tooltipId}`} 
              onDismiss={toggleCalloutVisible} 
              role="alert"
              gapSpace={0}
            >
              {tooltip}
            </Callout> 
            : null
            }
            </span>
            :
            null
          }
        </div>
        {description ? (
          <div
            className={`${yesOrNoQuestionStyles.description} yes-or-no-question-description`}
          >
            {description}
          </div>
        ) : null}
      </div>
      <Stack horizontal className={yesOrNoQuestionStyles.buttonWrapper}>
        <CompoundButton
          text="Yes"
          onClick={() => handleClick(true)}
          primary={choice === true}
          className={yesOrNoQuestionStyles.button}
        />
        <CompoundButton
          text="No"
          onClick={() => handleClick(false)}
          primary={choice === false}
          className={yesOrNoQuestionStyles.button}
        />
      </Stack>
      <div className={yesOrNoQuestionStyles.followUpQuestion}>
        {choice === true && childFields ? (
          <CommonForm form={childForm} />
        ) : null}
      </div>
    </div>
  );
};

export default YesOrNoQuestion;