import { useEffect, useMemo, useState } from "react";
import { Stack, mergeStyleSets, FontIcon, DirectionalHint, Callout, DelayedRender, Text } from "@fluentui/react";
import FocusableCheckbox from "components/FocusableCheckbox";
import CommonForm from "components/CommonForm/CommonForm";
import { useForm } from "components/CommonForm/formHooks";
import { CURRENCY } from "constants/tco";
import { useBoolean, useId } from '@fluentui/react-hooks';
import tooltipData from "../../assets/tooltipData.json";
import "./AssumptionsInputItem.scss";

const AssumptionsInputItem = (props) => {
  const { item, advancedInput, setAdvancedInput, inputType, currencySymbol, chatbotExpanded, fetchReportDataClone } = props;
  const fieldName = item.id;

  const calloutId = useId('callout-id');


  const [isCalloutVisible, { toggle: toggleCalloutVisible }] = useBoolean(false);

  const allfieldsWithHours = ["helpdeskSupport", "ongoingMaintenance", "securityIncidentResponse", "reducedITDeploymentCosts", "ongoingSecurity", "itTraining", "productivityGains", "employeeTimeLosttoHelpdesk", "meetingTime", "employeeTimeLostToSecurityIncidents", "surfaceDeployment", "bootupTime"];

  const disabledFields = [
    "deviceCosts",
    "accessoriesCosts",
    "m365Costs",
    "maintenanceCosts",
    "increasedResidualValue",
    "reducedSupport",
    "accessoriesSavings",
    "deviceConsolidation",
    "helpdeskSupport",
    "ongoingMaintenance",
    "securityIncidentResponse",
    "reducedITDeploymentCosts",
    "ongoingSecurity",
    "itTraining",
    "productivityGains",
    "employeeTimeLosttoHelpdesk",
    "meetingTime",
    "employeeTimeLostToSecurityIncidents",
    "surfaceDeployment",
    "employeeRetention",
    "bootupTime",
  ];

  const field = useMemo(
    () => [
      {
        type: inputType ? inputType : "number",
        id: "value",
        prefix: currencySymbol,
        value: advancedInput[item.id]?.value || 0,
        // disabled: !advancedInput[item.id]?.checked,
        disabled: !advancedInput[item.id]?.checked || disabledFields.includes(item.id),
        // disabled: true,
        onChange: (e, val) => {
          setAdvancedInput((state) => {
            return {
              ...state,
              [item.id]: {
                ...state[item.id],
                value: val.value,
                // pcValue: state[item.id]?.pcValue || 0,
                checked: state[item.id]?.checked || false,
              },
            };
          });
          sessionStorage.setItem("FurtherEditing", "false");
        },
      },
    ],
    [advancedInput]
  );
  const form = useForm(field);

  const itemStyles = mergeStyleSets({
    container: {
      alignItems: "center",
      // marginBottom: "1.375rem",
      marginBottom: "1.7rem",
      width: "100%",
    },
    name: {
      width: "70%",
      fontSize: "0.875rem",
      lineHeight: "1.25rem",
      flexShrink: 0,
      marginRight: "1rem",
      display: "flex",
      alignItems: "center",
    },
    inputWrapper: {
      display: "flex",
      flexShrink: 0,
      width: "20%",
      maxWidth: "8.84rem",
      minWidth: "8.84rem",
      borderRadius: 2,
      justifyContent: "space-between",
      // color: "#A19F9D",
      img: {
        cursor: "pointer",
      },
      "&.disabled": {
        color: "#999999",
      }
    },
    inputValue: {
      width: "100%",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
    checkbox: {
      paddingRight: "0.3rem",
      width: "10%",
    },
    modalWrapper: {
      display: "flex",
      flexFlow: "column nowrap",
      padding: "1rem",
      height: "100%",
      boxSizing: "border-box",
    },
    modalTitle: {
      fontSize: "1.25rem",
      fontWeight: 600,
      lineHeight: "1.75rem",
      marginBottom: "1rem",
    },
    cancelIcon: {
      color: "#201F1E",
      fontSize: "1rem",
      cursor: "pointer",
      position: "absolute",
      right: "1.4375rem",
      top: "1.375rem",
      zIndex: 1,
    },
    input: {
      marginBottom: "1rem",
    },
    buttonWrapper: {
      justifyContent: "flex-end",
      gap: "0.5rem",
    },
    infoIcon: {
      cursor: "pointer",
      fontSize: "1.25rem",
      color: "#201F1E",
      paddingLeft: "0.5rem",
    },
    textAndInputWrapper: {
      width: "100%",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    }
  });

  const calloutStyles = mergeStyleSets({
    callout: {
      width: 320,
      padding: '0.7rem 1.5rem',
      fontSize: "1rem",
    },
  });

  return (
    <Stack
      horizontal
      className={`${itemStyles.container} assumption-input-item-wrapper`}
    >
      <FocusableCheckbox
        className={itemStyles.checkbox}
        checked={advancedInput[item.id]?.checked ?? true}
        onChange={async (e, val) => {
          let advancedInputCopy;

          if(item.id === "accessoriesCosts"){
            await setAdvancedInput((state) => {
              advancedInputCopy = {
                ...state,
                [item.id]: {
                  ...state[item.id],
                  checked: val,
                  value: 0,
                  pcValue: 0,
                },
                accessoriesSavings: {
                  ...state['accessoriesSavings'],
                  checked: val,
                  value: 0,
                  pcValue: 0,
                },
              };
              return advancedInputCopy;
            });
          } else {
            await setAdvancedInput((state) => {
              if(allfieldsWithHours.includes(item.id)){
                advancedInputCopy = {
                  ...state,
                  [item.id]: {
                    ...state[item.id],
                    checked: val,
                    value: 0,
                    pcValue: 0,
                    pcHours: 0,
                  },
                };
              } else {
                advancedInputCopy = {
                  ...state,
                  [item.id]: {
                    ...state[item.id],
                    checked: val,
                    value: 0,
                    pcValue: 0,
                  },
                };
              }
              return advancedInputCopy;
            });
          }

          sessionStorage.setItem("FurtherEditing", "false");
          if(val){
            fetchReportDataClone(advancedInputCopy);
          }
        }}
      />
      <div className={`${itemStyles.textAndInputWrapper}`}>
        <Stack.Item className={`${itemStyles.name} ${chatbotExpanded ? 'assumption-input-label-reduced' : 'assumption-input-label'}`}>
          <div>{item.reportLabel}</div>
          <FontIcon
            id={calloutId}
            iconName="Info"
            tabIndex={0}
            className={`${itemStyles.infoIcon} tco-assumptions-right-part-title-icon`}
            onClick={toggleCalloutVisible}
            directionalHint={DirectionalHint.rightCenter}
          />
          {isCalloutVisible && (
            <Callout 
              className={calloutStyles.callout} 
              target={`#${calloutId}`} 
              onDismiss={toggleCalloutVisible} 
              role="alert"
              gapSpace={0}
            >
              <DelayedRender>
                <Text variant="small">
                  {tooltipData[`${fieldName}`]}
                </Text>
              </DelayedRender>
            </Callout>
          )}
        </Stack.Item>
        <Stack.Item
          horizontal
          className={`
            ${itemStyles.inputWrapper} 
            assumption-input-wrapper 
            ${chatbotExpanded ? 'assumption-input-wrapper-reduced' : ''} 
            ${!advancedInput[item.id]?.checked ? "disabled" : ""}
            ${disabledFields.includes(item.id) ? "only-disable-input-box" : ""}
          `}
        >
          {/* <CommonForm form={form} /> */}
          <div className={`${!advancedInput[item.id]?.checked ? "disabled" : ""}`}>{`${currencySymbol} ${advancedInput[item.id]?.value || 0}`}</div>
        </Stack.Item>
      </div>
    </Stack>
  );
};

export default AssumptionsInputItem;
