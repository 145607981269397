import { Stack, mergeStyleSets, FontIcon, DirectionalHint, Callout, DelayedRender, Text } from "@fluentui/react";
import FocusableCheckbox from "components/FocusableCheckbox";
import { useBoolean, useId } from '@fluentui/react-hooks';
import tooltipData from "../../assets/tooltipData.json";
import "./AssumptionsInputItem.scss";
import { convertNumberToString, formatNumber } from "utils/helpers";
import { Link } from "react-router-dom";

const AssumptionsInputItemSus = (props) => {
  const { item, userInput, setUserInput, currencySymbol, chatbotExpanded, getReportPageData, sustainabilitySavings } = props;
  const fieldName = item.id;
  const calloutId = useId('callout-id-eco-fields');
  const [isCalloutVisible, { toggle: toggleCalloutVisible }] = useBoolean(false);
  const disabledFields = ["Energy", "CarbonEmissions"];

  const itemStyles = mergeStyleSets({
    container: {
      alignItems: "center",
      marginBottom: "1.7rem",
      width: "100%",
    },
    name: {
      width: "70%",
      fontSize: "0.875rem",
      lineHeight: "1.25rem",
      flexShrink: 0,
      marginRight: "1rem",
      display: "flex",
      alignItems: "center",
    },
    inputWrapper: {
      display: "flex",
      flexShrink: 0,
      width: "20%",
      maxWidth: "8.84rem",
      minWidth: "8.84rem",
      borderRadius: 2,
      justifyContent: "space-between",
      img: {
        cursor: "pointer",
      },
      "&.disabled": {
        color: "#999999",
      }
    },
    checkbox: {
      paddingRight: "0.3rem",
      width: "10%",
    },
    infoIcon: {
      cursor: "pointer",
      fontSize: "1.25rem",
      color: "#201F1E",
      paddingLeft: "0.5rem",
    },
    textAndInputWrapper: {
      width: "100%",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    }
  });

  const calloutStyles = mergeStyleSets({
    callout: {
      width: 320,
      padding: '0.7rem 1.5rem',
      fontSize: "1rem",
    },
  });

  return (
    <Stack
      horizontal
      className={`${itemStyles.container} assumption-input-item-wrapper`}
    >
      <FocusableCheckbox
        className={itemStyles.checkbox}
        checked={userInput.sustainability[fieldName].checked}
        onChange={async (e, val) => {
          let updatedUserInput = userInput;
          await setUserInput((currentState) => {
            updatedUserInput = {
                ...currentState,
                sustainability: {
                  ...currentState.sustainability,
                  [fieldName]: {
                    ...currentState.sustainability[fieldName],
                    checked: val,
                  },
                }
            }
            return updatedUserInput;
          });
          sessionStorage.setItem("FurtherEditing", "false");
          getReportPageData(updatedUserInput);
        }}
      />
      <div className={`${itemStyles.textAndInputWrapper}`}>
        <Stack.Item className={`${itemStyles.name} ${chatbotExpanded ? 'assumption-input-label-reduced' : 'assumption-input-label'}`}>
          <div>{item.reportLabel}</div>
          <FontIcon
            id={calloutId}
            iconName="Info"
            tabIndex={0}
            className={`${itemStyles.infoIcon} tco-assumptions-right-part-title-icon`}
            onClick={toggleCalloutVisible}
            directionalHint={DirectionalHint.rightCenter}
          />
          {isCalloutVisible && (
            <Callout 
              className={calloutStyles.callout} 
              target={`#${calloutId}`} 
              onDismiss={toggleCalloutVisible} 
              role="alert"
              gapSpace={0}
            >
              <DelayedRender>
                {
                  fieldName === "Energy" ?
                    <Text variant="small">
                      Surface can help with savings through sustainability. Surface devices are designed for low energy usage which translates to cost savings as well as lower environmental impact. To account for this in your TCO, please enter your energy usage per device (Kwh) by visiting the <Link to="/sustainability/calculator" target="_blank">Surface Emissions Estimator</Link> or visit EnergyStar.
                    </Text>
                  :
                    <Text variant="small">
                      Surface can help with savings through sustainability. Surface devices are designed for low carbon impact which translates to cost savings as well as lower environmental impact. To account for this in your TCO, please enter your Co2 per device by visiting the <Link to="/sustainability/calculator" target="_blank">Surface Emissions Estimator</Link> or visit your current device manufacturer website to find this information.
                    </Text>
                }
              </DelayedRender>
            </Callout>
          )}
        </Stack.Item>
        <Stack.Item
          horizontal
          className={`
            ${itemStyles.inputWrapper} 
            assumption-input-wrapper 
            ${chatbotExpanded ? 'assumption-input-wrapper-reduced' : ''} 
            ${!userInput.sustainability[fieldName]?.checked ? "disabled" : ""}
            ${disabledFields.includes(item.id) ? "only-disable-input-box" : ""}
          `}
        >
          <div className={`${!userInput.sustainability[fieldName]?.checked ? "disabled" : ""}`}>{`${currencySymbol} ${sustainabilitySavings[item.fieldLabel] ? formatNumber(String(sustainabilitySavings[item.fieldLabel].savings)) : 0}`}</div>
        </Stack.Item>
      </div>
    </Stack>
  );
};

export default AssumptionsInputItemSus;
