import React, { useState } from 'react';
import { Callout, Text, mergeStyleSets } from '@fluentui/react';
import { useId, useBoolean } from '@fluentui/react-hooks';

import UnderstandSustainabilityLaptopImage1 from "assets/understand-sus-laptop-1.png";
import UnderstandSustainabilityLaptopImage2 from "assets/understand-sus-laptop-2.png";
import SusLearnMoreImage1 from "assets/sus-learnmore-plant.png";
import SusLearnMoreImage2 from "assets/sus-learnmore-report.svg";
import SusLearnMoreImage3 from "assets/sus-learnmore-laptop.svg";
import SusLearnMoreImage4 from "assets/sus-learnmore-graph.svg";
import ReduceCarbonImpactImage from "assets/sus-down-arrow.svg";
import CircularImage from "assets/sus-recycle.svg";
import IntegrityImage from "assets/sus-check.svg";
import { useNavigate } from 'react-router-dom';
import directSavingsImage from "assets/tco-direct-savings.svg";
import itEfficiencyImage from "assets/tco-it-efficiency.svg";
import employeeExperienceImage from "assets/tco-employee-experience.svg";
import "./SustainabilityUnderstand.scss";

const SustainabilityUnderstand = () => {

    const navigate = useNavigate();

    const [activeItem, setActiveItem] = useState(1);
    const [isFootnoteCalloutVisible, { toggle: toggleIsFootnoteCalloutVisible }] = useBoolean(false);
    const [footnoteCalloutId, setFootnoteCalloutId] = useState(null);


    const understandSustainabilityStyles = mergeStyleSets({
        rootContainer: {
            display: "flex",
            flexDirection: "column",
        },
        container: {
            display: "flex",
            justifyContent: "space-between",
            minHeight: "80vh",
        },
        left: {
            width: "25%",
            // width: "40%",
            display: "flex",
            flexDirection: "column",
        },
        right: {
            width: "73%",
            // width: "57%",
        },
        leftElement: {
            flex: 1,
            borderRight: "1px solid #bfbfbf",
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            color: "#bfbfbf",
            // fontSize: "3.1rem",
            fontSize: "2.1rem",
            fontWeight: 340,
            textAlign: "right",
            paddingLeft: "2rem",
            paddingRight: "3rem",
            "p": {
                cursor: "pointer",
                strong: {
                    fontWeight: 500,
                }
            },
            "&.active": {
                color: "#4E95D9",
                borderRight: "1px solid #0070C0",
            },
            position: "relative",
        },
        emptyElement: {
            padding: "0.5rem 0",
        },
        triangle: {
            content: '""',
            position: "absolute",
            top: "50%",
            right: "-2rem",
            transform: "translateY(-50%)",
            borderLeft: "1rem solid #0070C0",
            borderTop: "1rem solid transparent",
            borderBottom: "1rem solid transparent",
            borderRight: "1rem solid transparent",
        },
        topRightButton: {
            marginRight: window.innerWidth > 1640 ? "4.6rem" : "4rem",
            border: "1px solid #4E95D9",
            color: "#4E95D9",
            borderRadius: "0.5rem",
            alignSelf: "flex-end",
            padding: "0.4rem 1.5rem",
            fontWeight: 500,
            ":hover": {
                background: "#4E95D9",
                color: "#ffffff",
                cursor: "pointer",
            }
        }
    });

    const itemOneStyles = mergeStyleSets({
        container: {
            padding: "0 4rem 0 2rem",
            // border: "1px solid red",
        },
        textContainer: {
            // border: "1px solid green",
            color: "#575757",
            marginTop: "4rem",//font-size-revert
            fontSize: "1.2rem",//font-size-revert
            lineHeight: "1.7rem",
            p: {//font-size-revert
                padding: "0.4rem 0",
            }
        },
        togglesContainer: {
            // border: "1px solid blue",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "16rem",
        },
        toggleElement: {
            flex: 1,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
        },
        flipCardFront: {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
            img: {
                width: "4rem",
                paddingTop: "1rem",
            },
            p: {
                fontSize: "1.2rem",
                color: "#575757",
                fontWeight: 500,
            },
        },
        flipCardBack: {
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            color: "#575757",
            p: {
                padding: "0 1rem 0.5rem",
                margin: "0",
            }
        },
        bold: {
            color: "#0070C0",
            fontWeight: 500,
        },
    });

    const itemTwoStyles = mergeStyleSets({
        container: {
            padding: "0 4rem 0 2rem",
            // border: "1px solid red",
        },
        topContainer: {
            display: "flex",
            // alignItems: "center",
            justifyContent: "center",
            margin: "2rem auto",
            "> div": {
                width: "70%",
                paddingRight: "2rem",
            },
            "> img": {
                width: "30%",
                objectFit: "contain",
            },
        },
        bottomContainer: {
            display: "flex",
            // alignItems: "center",
            justifyContent: "center",
            "> div": {
                width: "70%",
                paddingLeft: "2rem",
            },
            "> img": {
                width: "30%",
                objectFit: "contain",
            },
        },
        topTextWrapper: {
            ">p:last-child": {
                color: "#575757",
            }
        },
        bottomTextWrapper: {
            ul: {
                paddingLeft: "2rem",
            },
            li: {
                marginBottom: "1rem",
                sup: {
                    cursor: "pointer",
                    color: "#0078d4",
                    fontWeight: 500,
                    textDecoration: "underline",
                }
            },
            span: {
                color: "#0078d4",
                fontWeight: 500,
            },
        },
        title: {
            color: "#0078d4",
            fontSize: "1.4rem",
            fontWeight: 500,
            marginTop: "0",
        },
        calloutForReport: {
            padding: '1.5rem 2rem',
            width: "50%",
            maxWidth: '60%',
            span: {
                display: "block",
                lineHeight: "1.5rem",
                fontSize: "0.9rem",
                marginBottom: "1rem",
            }
        }
    });

    const itemOneContent = (
        <div className={`${itemOneStyles.container} sus-mobile-item-one-container`}>
            <div className={`${itemOneStyles.textContainer} sus-mobile-item-one-text-container`}>
                <p>
                    We believe the needs of our customers can co-exist with the needs of the planet. Surface plays a key role in helping Microsoft achieve its 2030 sustainability commitments, which is why we have set clear strategies and goals for reducing waste and carbon emissions associated with our Devices and our Devices supply chain, making sustainability a core part of our Surface product promise.
                </p>
            </div>
            <div className={`${itemOneStyles.togglesContainer} sus-mobile-toggles-container`}>
                <div className={`${itemOneStyles.toggleElement} sus-mobile-toggle-element`}>
                    <div className="sus-flip-card">
                        <div className="sus-flip-card-inner">
                            <div className={`${itemOneStyles.flipCardFront} sus-flip-card-front`}>
                                <img src={ReduceCarbonImpactImage} alt="down arrow" />
                                <p>Reducing Carbon Impact</p>
                            </div>
                            <div className={`${itemOneStyles.flipCardBack} sus-flip-card-back`}>
                                <p>Designing Out Carbon Emissions</p>
                                <p>Renewable Energy in Supply Chain</p>
                                <p>ENERGY STAR® Efficiency Ratings</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={`${itemOneStyles.toggleElement} sus-mobile-toggle-element`}>
                    <div className="sus-flip-card">
                        <div className="sus-flip-card-inner">
                            <div className={`${itemOneStyles.flipCardFront} sus-flip-card-front`}>
                                <img src={CircularImage} alt="recycle" />
                                <p>Circular by Design</p>
                            </div>
                            <div className={`${itemOneStyles.flipCardBack} sus-flip-card-back`}>
                                <p>Increasing Repairability</p>
                                <p>Innovating New Circular Materials</p>
                                <p>More Sustainable Packaging</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={`${itemOneStyles.toggleElement} sus-mobile-toggle-element`}>
                    <div className="sus-flip-card">
                        <div className="sus-flip-card-inner">
                            <div className={`${itemOneStyles.flipCardFront} sus-flip-card-front`}>
                                <img src={IntegrityImage} alt="checked image" />
                                <p>Integrity Built-In</p>
                            </div>
                            <div className={`${itemOneStyles.flipCardBack} sus-flip-card-back`}>
                                <p>Transparency on Impact</p>
                                <p>EcoProfiles for Devices</p>
                                <p>EPEAT Gold™ Registered PCs</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );

    const handleFootnoteClick = (id) => {
        setFootnoteCalloutId(id);
        toggleIsFootnoteCalloutVisible();
    }

    const itemTwoContent = (
        <div className={`${itemTwoStyles.container} sus-mobile-item-two-container`}>
            <div className={`${itemTwoStyles.topContainer}`}>
                <div className={`${itemTwoStyles.topTextWrapper}`}>
                    <p className={`${itemTwoStyles.title}`}>Windows 11 Update is now carbon aware</p>
                    <p>Starting with Windows 11, version 22H2, Windows Update is now carbon aware*, making it easier for your devices to reduce carbon emissions.</p>
                    <p>When devices are plugged in, turned on, connected to the internet and where regional carbon intensity data is available, Windows Update will schedule installations at specific times of the day (when doing so may result in lower-carbon emissions because a higher proportion of electricity is coming from lower-carbon sources on the electric grid).</p>
                    <p>You can always bypass this function and choose to install updates immediately on the Windows Update page in Settings. Data is from our partners <a href='https://app.electricitymaps.com/map' target='_blank'>electricityMap</a> or <a href='https://www.watttime.org/' target='_blank'>WattTime</a>.</p>
                </div>
                <img src={UnderstandSustainabilityLaptopImage1} alt="surface laptop" />
            </div>
            <div className={`${itemTwoStyles.bottomContainer}`}>
                <img src={UnderstandSustainabilityLaptopImage2} alt="surface laptop" />
                <div className={`${itemTwoStyles.bottomTextWrapper}`}>
                    <p className={`${itemTwoStyles.title}`}>Designed with sustainability in mind</p>
                    <ul>
                        <li>Surface Pro 9 energy consumption is <span>55% less</span> than current ENERGY STAR ® computer specification</li>
                        <li>Surface Laptop 5 13.5" energy consumption is <span>59% less</span> than current ENERGY STAR ® computer specification</li>
                        <li>Major components in Surface Laptop 5 incl. the display module, keyboard assembly, battery, motherboard, and storage are replaceable through various service options. <sup id="sus-footnote-id-1" onClick={() => handleFootnoteClick("sus-footnote-id-1")}>1</sup></li>
                        <li><span>Packaging is ~99% recyclable on average,</span><sup id="sus-footnote-id-2" onClick={() => handleFootnoteClick("sus-footnote-id-2")}>2</sup> with 96% of the packaging material is bio-renewable, post-consumer recycled, or Certified Sustainably Forested.<sup id="sus-footnote-id-3" onClick={() => handleFootnoteClick("sus-footnote-id-3")}>3</sup></li>
                    </ul>
   
                    {isFootnoteCalloutVisible && (
                    <Callout 
                        className={itemTwoStyles.calloutForReport} 
                        target={`#${footnoteCalloutId}`} 
                        onDismiss={toggleIsFootnoteCalloutVisible} 
                        role="alert"
                        gapSpace={0}
                    >
                        <Text>
                            <sup>1</sup> Customer Replaceable Units (CRUs) are components available for purchase through your Surface Commercial Authorized Device Reseller. Components can be replaced on-site by a skilled technician following Microsoft's <a target='_blank' href='https://www.microsoft.com/en-us/download/details.aspx?id=100440'>Service Guide</a>. Opening and/or repairing your device can present electric shock, fire and personal injury risks and other hazards. Use caution if undertaking do-it-yourself repairs. Device damage caused during repair will not be covered under Microsoft's Hardware Warranty or protection plans. Components will be available shortly after initial launch; timing of availability varies by component and market.
                        </Text>
                        <Text>
                            <sup>2</sup> Recyclability is dependent on recycling options in markets where products are discarded. In OECD countries, Microsoft operates recycling programs either independently or through third parties covering Microsoft Devices. Check local recycling programs for availability.
                        </Text>
                        <Text>
                            <sup>3</sup> Packaging suppliers provide documentation for verifications that meets the standards of how they manage forests. Our packaging suppliers substantiate post-consumer recycled content with documentation for the components they provide.
                        </Text>
                    </Callout>
                    )}
                </div>
            </div>
        </div>
    );

    const itemThreeStyles = mergeStyleSets({
        container: {
            // marginTop: "0",
            padding: "2rem 4rem 0 2rem",
        },
        videoResponsive: {
            overflow: "hidden",
            width: "80%",
            margin: "0 auto",
            paddingBottom: "40%",
            position: "relative",
            height: 0,
            "iframe": {
                left: 0,
                top: 0,
                height: "100%",
                width: "100%",
                position: "absolute",
            }
        },
        iconsContainer: {
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            // height: "14rem",
            marginTop: "3rem",
            paddingTop: "2rem",
            borderTop: "1px solid #bfbfbf",
        },
        iconElement: {
            // flex: 1,
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            flexDirection: "column",
            width: "16rem",
            height: "10rem",
            img: {
                height: "40%",
            },
            a: {
                textAlign: "center",
                fontSize: "1rem",
                height: "50%",
                padding: "0 1rem",
            }
        },
    });

    const itemThreeContent = (
        <div className={`${itemThreeStyles.container}`}>
            <div className={`${itemThreeStyles.videoResponsive}`}>
                <iframe
                    // width="500"
                    // height="350"
                    src={`https://www.youtube.com/embed/sLalQgxI8Hk?si=geXrkuJTCMB_g2Y-`}
                    // frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    title="Embedded youtube"
                />
            </div>
            <div className={`${itemThreeStyles.iconsContainer}`}>
                <div className={`${itemThreeStyles.iconElement} mobile-icon-element`}>
                    <img src={SusLearnMoreImage1} alt="plant" />
                    <a target='_blank' href="https://www.microsoft.com/en-us/corporate-responsibility/sustainability/report">Microsoft's Sustainability Report</a>
                </div>
                <div className={`${itemThreeStyles.iconElement} mobile-icon-element`}>
                    <img src={SusLearnMoreImage2} alt="report" />
                    <a target='_blank' href="https://www.microsoft.com/en-us/corporate-responsibility/supply-chain-integrity?activetab=supplypivot%3aprimaryr5">Microsoft Devices Responsible Sourcing Report</a>
                </div>
                <div className={`${itemThreeStyles.iconElement} mobile-icon-element`}>
                    <img src={SusLearnMoreImage3} alt="laptop" />
                    <a target='_blank' href="https://www.microsoft.com/en-us/download/details.aspx?id=55974">Product EcoProfiles</a>
                </div>
                <div className={`${itemThreeStyles.iconElement} mobile-icon-element`}>
                    <img src={SusLearnMoreImage4} alt="graph" />
                    <a target='_blank' href="https://www.microsoft.com/en-us/legal/compliance/devices-safety-and-eco">Devices Environmental Compliance</a>
                </div>
            </div>
        </div>
    );

  return (
    <div className={`${understandSustainabilityStyles.rootContainer}`}>
        <div className={`${understandSustainabilityStyles.topRightButton}`} onClick={() => navigate("/sustainability/calculator")}>Emissions Estimator</div>
        <div className={`${understandSustainabilityStyles.container} mobile-understand-sus-container`}>
            <div className={`${understandSustainabilityStyles.left}`}>
                <div className={`${understandSustainabilityStyles.emptyElement}`}></div>
                <div 
                    className={`${understandSustainabilityStyles.leftElement} ${activeItem === 1 ? "active" : ""} sus-mobile-left-element`}
                    onClick={() => setActiveItem(1)}
                >
                    {
                        activeItem === 1 ?
                        <>
                            <p>Surface and Sustainability</p>
                            <span className={`${understandSustainabilityStyles.triangle}`} ></span>
                        </>
                :
                        <p>Surface and Sustainability</p>
                    }
                </div>
                <div 
                    className={`${understandSustainabilityStyles.leftElement} ${activeItem === 2 ? "active" : ""} sus-mobile-left-element`}
                    onClick={() => setActiveItem(2)}
                >
                    {
                        activeItem === 2 ?
                            <>
                                <p>Surface to Cloud Sustainability</p>
                                <span className={`${understandSustainabilityStyles.triangle}`} ></span>
                            </>
                        :
                            <p>Surface to Cloud Sustainability</p>
                    }
                </div>
                <div 
                    className={`${understandSustainabilityStyles.leftElement} ${activeItem === 3 ? "active" : ""} sus-mobile-left-element`}
                    onClick={() => setActiveItem(3)}
                >
                    {
                        activeItem === 3 ?
                            <>
                                <p>Learn More</p>
                                <span className={`${understandSustainabilityStyles.triangle}`} ></span>
                            </>
                        :
                            <p>Learn More</p>
                    }
                </div>
                <div className={`${understandSustainabilityStyles.emptyElement}`}></div>
            </div>
            <div className={`${understandSustainabilityStyles.right}`}>
                {
                    activeItem === 3 ? 
                        itemThreeContent 
                    :
                    activeItem === 2 ?
                        itemTwoContent
                    :
                        itemOneContent
                }
            </div>
        </div>
    </div>
  )
}

export default SustainabilityUnderstand;