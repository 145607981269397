import React from 'react';

import { Callout, DirectionalHint, FontIcon, Text, TextField, mergeStyleSets } from '@fluentui/react';
import { useBoolean, useId } from '@fluentui/react-hooks';

import { formatNumber, isPositiveNumber } from 'utils/helpers';
import tooltipData from "../../assets/tooltipData.json";

const AdvancedAssumptionsInputItem = (props) => {

    const {item, currencySymbol, advancedInput, setAdvancedInput, fetchReportData, userInput, initialAdvancedInput} = props;

    const calloutId = useId('callout-advanced-assumptions');
    const [isCalloutVisible, { toggle: toggleCalloutVisible }] = useBoolean(false);

    const fieldsWithOnlyCost = ["Device Consolidation", ];
    const fieldsWithOnlyHours = ["Reduced Helpdesk Support", "Reduced Ongoing Maintenance", "Reduced Time for Security Incident Response", "Reduced Employee Time Lost to Helpdesk", "Meeting Time Saved", "Reduced Employee Time Lost to Security Incidents", "Faster Surface Deployment to Employees", "Faster Bootup Time"];
    const fieldsWithBothCostAndHours = ["Reduced IT Deployment Costs", "Reduced IT Staff Time for Ongoing Security", "IT Training Costs"];
    const fieldsWithBothCosts = ["Total Device Costs", "Total Accessories Cost", "Total M365 Licensing Costs", "Total Extended Warranty & Maintenance Costs", "Reduced Support", "Increased Residual Value"];

    const advancedAssumptionsEditorStyles = mergeStyleSets({
        inputFieldWrapper: {
          marginBottom: "1.5rem",
        },
        singleTextFieldContainer: {
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginTop: "0.2rem",
          "> div:last-child": {
            width: "100%",
          }
        },
        dualTextFieldsContainer: {
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          marginTop: "0.2rem",
          "> div": {
            width: "47%",
          }
        },
        inputLabelWrapper: {
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            marginBottom: "0.8rem",
        },
        inputFieldLabel: {
            display: "flex",
            alignItems: "center",
        },
        resetButton: {
            padding: "0.2rem 1rem",
            background: "#fff",
            border: "1px solid #0078d4",
            borderRadius: "0.2rem",
            color: "#0078d4",
            // fontWeight: 500,
            ":hover": {
                cursor: "pointer",
                background: "#0078d4",
                color: "#fff",
            }
        },
        infoIcon: {
            cursor: "pointer",
            fontSize: "1.2rem",
            color: "#201F1E",
            paddingLeft: "0.5rem",
            paddingTop: "0.1rem",
        },
        callout: {
            maxWidth: 460,
            padding: '0.7rem 1.5rem',
            fontSize: "1rem",
            "span": {
                lineHeight: "1.5rem",
            }
        },
        labelWrapper: {
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            "> div": {
                width: "47%",
            }
        },
        labelStyle: {
            fontSize: "0.8rem",
            fontWeight: 500,
            color: "#a7a7a7",
        }
    });

    const handleResetValue = async () => {
        await setAdvancedInput((state) => {
            const newState = {...state};
            if(newState[item.id]){
                delete newState[item.id]['isOtherPCValueChanged']
                delete newState[item.id]['isHoursChanged']
                delete newState[item.id]['isSurfaceValueChanged']
            }
            return newState;
        });
        fetchReportData();
    }

    return <div className={`${advancedAssumptionsEditorStyles.inputFieldWrapper}`}>
        {
        fieldsWithOnlyCost.includes(item.reportLabel) ?
            <>
            <div className={`${advancedAssumptionsEditorStyles.inputLabelWrapper}`}>
                <div className={`${advancedAssumptionsEditorStyles.inputFieldLabel}`}>
                    <div className='mobile-reults-editor-label'>{item.label}</div>
                    <FontIcon
                        id={calloutId}
                        iconName="Info"
                        tabIndex={0}
                        className={`${advancedAssumptionsEditorStyles.infoIcon}`}
                        onClick={toggleCalloutVisible}
                        directionalHint={DirectionalHint.rightCenter}
                    />
                    {isCalloutVisible && (
                        <Callout 
                        className={advancedAssumptionsEditorStyles.callout} 
                        target={`#${calloutId}`} 
                        onDismiss={toggleCalloutVisible} 
                        role="alert"
                        gapSpace={0}
                        >
                            <Text>
                                {tooltipData[`${item.id + "Assumption"}`]}
                            </Text>
                        </Callout>
                    )}
                </div>
                {
                    advancedInput[item.id]?.pcValue !== initialAdvancedInput[item.id]?.pcValue ?
                        <div onClick={handleResetValue} className={`${advancedAssumptionsEditorStyles.resetButton}`}>Reset</div>
                    :
                        <div></div>
                }
            </div> 
                <div className={`${advancedAssumptionsEditorStyles.labelStyle}`}>Other PC Value</div>
                <div className={`${advancedAssumptionsEditorStyles.singleTextFieldContainer}`}>
                    <TextField 
                        prefix={currencySymbol} 
                        value={advancedInput[item.id]?.pcValue} 
                        onChange={(e, val) => {
                        if(!isPositiveNumber(val)){
                            if(val === ""){
                            } else {
                            return;
                            }
                        }
                        setAdvancedInput((state) => {
                            return {
                            ...state,
                            [item.id]: {
                                ...state[item.id],
                                pcValue: formatNumber(val),
                                isOtherPCValueChanged: true,
                            },
                            };
                        });
                        sessionStorage.setItem("FurtherEditing", "false");
                        }}
                        onBlur={(e) => {
                        if(e.target.value === ""){
                            setAdvancedInput((state) => {
                            return {
                                ...state,
                                [item.id]: {
                                    ...state[item.id],
                                    pcValue: 0,
                                    isOtherPCValueChanged: true,
                                },
                            };
                            });
                        }
                        if(initialAdvancedInput[item.id]["pcValue"] === e.target.value){
                            setAdvancedInput((state) => {
                                const newState = {...state};
                                if(newState[item.id]){
                                    delete newState[item.id]['isOtherPCValueChanged']
                                }
                                return newState;
                            });
                        }
                        fetchReportData();
                        }}
                        disabled={advancedInput[item.id]?.checked === false ? true : false}
                    />
                </div>
            </>
        :
        fieldsWithOnlyHours.includes(item.reportLabel) ? 
            <>
            <div className={`${advancedAssumptionsEditorStyles.inputLabelWrapper}`}>
                <div className={`${advancedAssumptionsEditorStyles.inputFieldLabel}`}>
                    <div className='mobile-reults-editor-label'>{item.label}</div>
                    <FontIcon
                        id={calloutId}
                        iconName="Info"
                        tabIndex={0}
                        className={`${advancedAssumptionsEditorStyles.infoIcon}`}
                        onClick={toggleCalloutVisible}
                        directionalHint={DirectionalHint.rightCenter}
                    />
                    {isCalloutVisible && (
                        <Callout 
                        className={advancedAssumptionsEditorStyles.callout} 
                        target={`#${calloutId}`} 
                        onDismiss={toggleCalloutVisible} 
                        role="alert"
                        gapSpace={0}
                        >
                            <Text>
                                {tooltipData[`${item.id + "Assumption"}`]}
                            </Text>
                        </Callout>
                    )}
                </div>
                {
                    advancedInput[item.id]?.pcHours !== initialAdvancedInput[item.id]?.pcHours ?
                        <div onClick={handleResetValue} className={`${advancedAssumptionsEditorStyles.resetButton}`}>Reset</div>
                    :
                        <div></div>
                }
            </div> 
                <div className={`${advancedAssumptionsEditorStyles.labelStyle}`}>Other PC Hours</div>
                <div className={`${advancedAssumptionsEditorStyles.singleTextFieldContainer}`}>
                    <TextField 
                        suffix="hours" 
                        value={advancedInput[item.id]?.pcHours}
                        onChange={(e, val) => {
                        if(!isPositiveNumber(val)){
                            if(val === ""){
                            } else {
                            return;
                            }
                        }
                        setAdvancedInput((state) => {
                            return {
                            ...state,
                            [item.id]: {
                                ...state[item.id],
                                pcHours: formatNumber(val),
                                isHoursChanged: true,
                            },
                            };
                        });
                        sessionStorage.setItem("FurtherEditing", "false");
                        }}
                        onBlur={(e) => {
                        if(e.target.value === ""){
                            setAdvancedInput((state) => {
                            return {
                                ...state,
                                [item.id]: {
                                    ...state[item.id],
                                    pcHours: 0,
                                    isHoursChanged: true,
                                },
                            };
                            });
                        }
                        if(initialAdvancedInput[item.id]["pcHours"] === e.target.value){
                            setAdvancedInput((state) => {
                                const newState = {...state};
                                if(newState[item.id]){
                                    delete newState[item.id]['isHoursChanged']
                                }
                                return newState;
                            });
                        }
                        fetchReportData();
                        }}
                        disabled={advancedInput[item.id]?.checked === false ? true : false}
                    />
                </div>
            </>
        :
        fieldsWithBothCostAndHours.includes(item.reportLabel) ?
            <>
            <div className={`${advancedAssumptionsEditorStyles.inputLabelWrapper}`}>
                <div className={`${advancedAssumptionsEditorStyles.inputFieldLabel}`}>
                    <div className='mobile-reults-editor-label'>{item.label}</div>
                    <FontIcon
                        id={calloutId}
                        iconName="Info"
                        tabIndex={0}
                        className={`${advancedAssumptionsEditorStyles.infoIcon}`}
                        onClick={toggleCalloutVisible}
                        directionalHint={DirectionalHint.rightCenter}
                    />
                    {isCalloutVisible && (
                        <Callout 
                        className={advancedAssumptionsEditorStyles.callout} 
                        target={`#${calloutId}`} 
                        onDismiss={toggleCalloutVisible} 
                        role="alert"
                        gapSpace={0}
                        >
                            <Text>
                                {tooltipData[`${item.id + "Assumption"}`]}
                            </Text>
                        </Callout>
                    )}
                </div>
                {
                    (advancedInput[item.id]?.pcValue !== initialAdvancedInput[item.id]?.pcValue) ||
                    (advancedInput[item.id]?.pcHours !== initialAdvancedInput[item.id]?.pcHours) ?
                        <div onClick={handleResetValue} className={`${advancedAssumptionsEditorStyles.resetButton}`}>Reset</div>
                    :
                        <div></div>
                }
            </div> 
                <div className={`${advancedAssumptionsEditorStyles.labelWrapper}`}>
                    <div className={`${advancedAssumptionsEditorStyles.labelStyle}`}>Other PC Value</div>
                    <div className={`${advancedAssumptionsEditorStyles.labelStyle}`}>Other PC Hours</div>
                </div>
                <div className={`${advancedAssumptionsEditorStyles.dualTextFieldsContainer}`}>
                    <TextField 
                        prefix={currencySymbol} 
                        value={advancedInput[item.id]?.pcValue} 
                        onChange={(e, val) => {
                        if(!isPositiveNumber(val)){
                            if(val === ""){
                            } else {
                            return;
                            }
                        }
                        setAdvancedInput((state) => {
                            return {
                                ...state,
                                [item.id]: {
                                    ...state[item.id],
                                    pcValue: formatNumber(val),
                                    isHoursChanged: false,
                                    isOtherPCValueChanged: true,
                                },
                            };
                        });
                        sessionStorage.setItem("FurtherEditing", "false");
                        }}
                        onBlur={(e) => {
                        if(e.target.value === ""){
                            setAdvancedInput((state) => {
                                return {
                                    ...state,
                                    [item.id]: {
                                        ...state[item.id],
                                        pcValue: 0,
                                        isHoursChanged: false,
                                        isOtherPCValueChanged: true,
                                    },
                                };
                            });
                        }
                        if(initialAdvancedInput[item.id]["pcValue"] === e.target.value){
                            setAdvancedInput((state) => {
                                const newState = {...state};
                                if(newState[item.id]){
                                    delete newState[item.id]['isOtherPCValueChanged']
                                }
                                return newState;
                            });
                        }
                        fetchReportData();
                        }}
                        disabled={advancedInput[item.id]?.checked === false ? true : false}
                    />
                    <TextField 
                        suffix="hours" 
                        value={advancedInput[item.id]?.pcHours}
                        onChange={(e, val) => {
                        if(!isPositiveNumber(val)){
                            if(val === ""){
                            } else {
                            return;
                            }
                        }
                        setAdvancedInput((state) => {
                            return {
                                ...state,
                                [item.id]: {
                                    ...state[item.id],
                                    pcHours: formatNumber(val),
                                    isHoursChanged: true,
                                    isOtherPCValueChanged: false,
                                },
                            };
                        });
                        sessionStorage.setItem("FurtherEditing", "false");
                        }}
                        onBlur={(e) => {
                        if(e.target.value === ""){
                            setAdvancedInput((state) => {
                                return {
                                    ...state,
                                    [item.id]: {
                                        ...state[item.id],
                                        pcHours: 0,
                                        isHoursChanged: true,
                                        isOtherPCValueChanged: false,
                                    },
                                };
                            });
                        }
                        if(initialAdvancedInput[item.id]["pcHours"] === e.target.value){
                            setAdvancedInput((state) => {
                                const newState = {...state};
                                if(newState[item.id]){
                                    delete newState[item.id]['isHoursChanged']
                                }
                                return newState;
                            });
                        }
                        fetchReportData();
                        }}
                        disabled={advancedInput[item.id]?.checked === false ? true : false}
                    />
                </div>
            </>
        :
        fieldsWithBothCosts.includes(item.reportLabel) ?
            <>
            <div className={`${advancedAssumptionsEditorStyles.inputLabelWrapper}`}>
                <div className={`${advancedAssumptionsEditorStyles.inputFieldLabel}`}>
                    <div className='mobile-reults-editor-label'>{item.label}</div>
                    <FontIcon
                        id={calloutId}
                        iconName="Info"
                        tabIndex={0}
                        className={`${advancedAssumptionsEditorStyles.infoIcon}`}
                        onClick={toggleCalloutVisible}
                        directionalHint={DirectionalHint.rightCenter}
                    />
                    {isCalloutVisible && (
                        <Callout 
                        className={advancedAssumptionsEditorStyles.callout} 
                        target={`#${calloutId}`} 
                        onDismiss={toggleCalloutVisible} 
                        role="alert"
                        gapSpace={0}
                        >
                            <Text>
                                {tooltipData[`${item.id + "Assumption"}`]}
                            </Text>
                        </Callout>
                    )}
                </div>
                {
                    (advancedInput[item.id]?.value !== initialAdvancedInput[item.id]?.value) ||
                    (advancedInput[item.id]?.pcValue !== initialAdvancedInput[item.id]?.pcValue) ?
                        <div onClick={handleResetValue} className={`${advancedAssumptionsEditorStyles.resetButton}`}>Reset</div>
                    :
                        <div></div>
                }
            </div> 
                <div className={`${advancedAssumptionsEditorStyles.labelWrapper}`}>
                    <div className={`${advancedAssumptionsEditorStyles.labelStyle}`}>Surface Value</div>
                    <div className={`${advancedAssumptionsEditorStyles.labelStyle}`}>Other PC Value</div>
                </div>
                <div className={`${advancedAssumptionsEditorStyles.dualTextFieldsContainer}`}>
                    <TextField 
                        prefix={currencySymbol} 
                        value={advancedInput[item.id]?.surfaceValue} 
                        onChange={(e, val) => {
                            if(!isPositiveNumber(val)){
                                if(val === ""){
                                } else {
                                    return;
                                }
                            }
                            setAdvancedInput((state) => {
                                return {
                                    ...state,
                                    [item.id]: {
                                        ...state[item.id],
                                        value: formatNumber(val),
                                        surfaceValue: formatNumber(val),
                                        isSurfaceValueChanged: true,
                                    },
                                };
                            });
                            sessionStorage.setItem("FurtherEditing", "false");
                        }}
                        onBlur={(e) => {
                            if(e.target.value === ""){
                                setAdvancedInput((state) => {
                                    return {
                                        ...state,
                                        [item.id]: {
                                            ...state[item.id],
                                            value: 0,
                                            surfaceValue: 0,
                                            isSurfaceValueChanged: true,
                                        },
                                    };
                                });
                            }
                            if(initialAdvancedInput[item.id]["surfaceValue"] === e.target.value){
                                setAdvancedInput((state) => {
                                    const newState = {...state};
                                    if(newState[item.id]){
                                        delete newState[item.id]['isSurfaceValueChanged']
                                    }
                                    return newState;
                                });
                            }
                            fetchReportData();
                        }}
                        disabled={advancedInput[item.id]?.checked === false ? true : false}
                    />
                    <TextField 
                        prefix={currencySymbol} 
                        value={advancedInput[item.id]?.pcValue} 
                        onChange={(e, val) => {
                            if(!isPositiveNumber(val)){
                                if(val === ""){
                                } else {
                                    return;
                                }
                            }
                            setAdvancedInput((state) => {
                                return {
                                    ...state,
                                    [item.id]: {
                                        ...state[item.id],
                                        pcValue: formatNumber(val),
                                        isOtherPCValueChanged: true,
                                    },
                                };
                            });
                            sessionStorage.setItem("FurtherEditing", "false");
                        }}
                        onBlur={(e) => {
                            if(e.target.value === ""){
                                setAdvancedInput((state) => {
                                    return {
                                        ...state,
                                        [item.id]: {
                                            ...state[item.id],
                                            pcValue: 0,
                                            isOtherPCValueChanged: true,
                                        },
                                    };
                                });
                            }
                            if(initialAdvancedInput[item.id]["pcValue"] === e.target.value){
                                setAdvancedInput((state) => {
                                    const newState = {...state};
                                    if(newState[item.id]){
                                        delete newState[item.id]['isOtherPCValueChanged']
                                    }
                                    return newState;
                                });
                            }
                            fetchReportData();
                        }}
                        disabled={advancedInput[item.id]?.checked === false ? true : false}
                    />
                </div>
            </>
        :
        <></>
        }
  </div>
}

export default AdvancedAssumptionsInputItem;