import React from "react";
import { TextField, Dropdown, mergeStyleSets, ComboBox } from "@fluentui/react";
import YesOrNoQuestion from "../YesOrNoQuestionCustom";
import {
  isPositiveInteger,
  formatNumber,
  convertStringToNumber,
  isPositiveNumber,
  isNumber,
} from "utils/helpers";

const fieldMap = {
  text: TextField,
  number: TextField,
  positiveNumber: TextField,
  positiveInteger: TextField,
  dropdown: Dropdown,
  yesorno: YesOrNoQuestion,
  comboBox: ComboBox,
};

const CommonFormAssumptions = (props) => {
  const { form, defaultAssumptions } = props;
//   console.log("form", form);
//   console.log("defaultAssumptions", defaultAssumptions);

  const inputStyles = mergeStyleSets({
    container: {
      marginBottom: "1.5rem",
      label: {
        fontSize: "1.125rem",
        color: "#201F1E",
      },
    },
    labelWrapper: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        marginBottom: "0.8rem",
        ">div:first-child": {
            fontSize: "1.125rem",
        }
    },
    resetButton: {
        padding: "0.2rem 1rem",
        marginLeft: "1rem",
        background: "#fff",
        border: "1px solid #0078d4",
        borderRadius: "0.2rem",
        color: "#0078d4",
        // fontWeight: 500,
        ":hover": {
            cursor: "pointer",
            background: "#0078d4",
            color: "#fff",
        }
    },
  });

  const handleValidation = (value, validation) => {
    if (!validation) return;
    let error = "";
    validation.forEach((fn) => {
      error = fn(value)?.message;
    });
    return error;
  };

  const handleChange = (event, value, item) => {
    if (item.preventEvent) {
      let result = false;
      if (
        item.type === "text" ||
        item.type === "number" ||
        item.type === "positiveNumber" ||
        item.type === "positiveInteger"
      ) {
        result = item.preventEvent("input");
      } else {
        result = item.preventEvent();
      }
      if (result === true) {
        return;
      }
    }
    if (
      item.type === "positiveInteger" &&
      value !== "" &&
      !isPositiveInteger(value)
    ) {
      return;
    }
    if (item.type === "number" && value !== "" && !isNumber(value)) {
      return;
    }
    if (
      item.type === "positiveNumber" &&
      value !== "" &&
      !isPositiveNumber(value)
    ) {
      return;
    }
    if (
      (item.type === "number" && value !== "-") ||
      item.type === "positiveInteger" ||
      item.type === "positiveNumber"
    ) {
      value = formatNumber(value);
    }
    if (item.max && convertStringToNumber(value) > item.max) {
      return;
    }
    if (item.type === "dropdown") {
      value = value.key;
    }
    form.updateValues({ [item.id]: value });
    if (item.onChange) {
      item.onChange(event, { [item.id]: value });
    }
  };

  const handleReset = (event, value, item) => {
    if (item.preventEvent) {
      let result = false;
      result = item.preventEvent();
      if (result === true) {
        return;
      }
    }
    if (
      item.type === "positiveInteger" &&
      value !== "" &&
      !isPositiveInteger(value)
    ) {
      return;
    }
    if (item.type === "number" && value !== "" && !isNumber(value)) {
      return;
    }
    if (
      item.type === "positiveNumber" &&
      value !== "" &&
      !isPositiveNumber(value)
    ) {
      return;
    }
    if (
      (item.type === "number" && value !== "-") ||
      item.type === "positiveInteger" ||
      item.type === "positiveNumber"
    ) {
      value = formatNumber(value);
    }
    if (item.max && convertStringToNumber(value) > item.max) {
      return;
    }
    if (item.type === "dropdown") {
      value = value.key;
    }
    form.updateValues({ [item.id]: value });
    if (item.onChange) {
      item.onChange(event, { [item.id]: value });
    }
  };

  const handleBlur = (item) => {
    if (item.preventEvent && item.preventEvent()) {
      return;
    }
    if (item.onBlur) {
      item.onBlur();
    }
  }

  return (
    <div>
      {form.fields.map((field, index) => {
        const ignoreFields = ["id", "type", "onChange", "validation", "onBlur", "label"];
        let otherProps = {};
        Object.keys(field).forEach((i) => {
          if (!ignoreFields.includes(i)) {
            otherProps[i] = field[i];
          }
        });
        let fieldProps = {
          className: `${inputStyles.container} ${
            field.className ? field.className : ""
          }`,
          onChange: (event, val) => handleChange(event, val, field),
          onGetErrorMessage: (value) =>
            handleValidation(value, field.validation),
          validateOnLoad: false,
          validateOnFocusOut: true,
          onBlur: () => handleBlur(field),
          ...otherProps,
        };
        if (
          (field.type === "text" ||
            field.type === "number" ||
            field.type === "positiveInteger" ||
            field.type === "positiveNumber") &&
          fieldProps.value === undefined
        ) {
          fieldProps.value = "";
        }
        if (field.type === "dropdown") {
          fieldProps.calloutProps = { calloutMaxHeight: "40vh" };
        }
        return (
          <React.Fragment key={`${field.id}_${index}_${form._id}`}>
            <div className={`${inputStyles.labelWrapper} mobile-results-editor-label-wrapper`}>
                <div>{field.label}</div>
                {
                    field.id === "numberOfDevicesConsolidated" ?
                        ((convertStringToNumber(field.value || "") !== 2.32)) ?
                            <div onClick={() => handleReset(null, "2.32", field)} className={`${inputStyles.resetButton}`}>Reset</div>
                        :
                            <></>
                    :
                    (
                        (field.type === "text" || field.type === "number" || field.type === "positiveInteger" || field.type === "positiveNumber") && 
                        (convertStringToNumber(field.value) !== convertStringToNumber(defaultAssumptions[field.id]))) ?
                            <div onClick={() => handleReset(null, defaultAssumptions[field.id], field)} className={`${inputStyles.resetButton}`}>Reset</div>
                        :
                            <></>
                }
            </div>
            {
            React.createElement(fieldMap[field.type], fieldProps)
            }
            
          </React.Fragment>
        );
      })}
    </div>
  );
};

export default CommonFormAssumptions;