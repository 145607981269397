import { Image, Text, mergeStyleSets } from "@fluentui/react";

const BackToTop = (props) => {
  const { handleClick } = props;
  const backToTopStyles = mergeStyleSets({
    container: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      marginBottom: "7.5625rem",
      cursor: "pointer",
    },
    text: {
      fontSize: "2rem",
      fontWeight: 400,
      lineHeight: "3.0625rem",
      textTransform: "capitalize",
      marginBottom: "0.5625rem",
    },
  });
  const onClick = () => {
    if (handleClick) {
      handleClick();
    } else {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    }
  }

  return (
    <div
      className={backToTopStyles.container}
      tabIndex={0}
      onKeyDown={(event) => {
        if (event.key === "Enter") {
          onClick();
        }
      }}
      data-html2canvas-ignore="true"
    >
      <Text block className={backToTopStyles.text}>
        End
      </Text>
      <Image
        alt="up arrow"
        src="/images/upArrow.svg"
        width="2.5rem"
        height="2.5rem"
        onClick={onClick}
      />
    </div>
  );
};

export default BackToTop;