import { mergeStyleSets } from "@fluentui/react";
import "./LearnMoreText.scss";

const LearnMoreText = () => {
  const learnMoreStyles = mergeStyleSets({
    text: {
      textAlign: "left",
      padding: "0 2.3rem 1rem 0",
      fontSize: "1rem",
      lineHeight: "1.4rem",
    },
  });

  return (
    <div className={`${learnMoreStyles.text} tco-learn-more-text`}>
      To learn more about Microsoft Surface and obtain a quote, contact a
      Surface{" "}
      <a
        href="https://www.microsoft.com/en-us/surface/business/where-to-buy-microsoft-surface#DEVICESRESELLERS"
        rel="noreferrer"
        target="_blank"
      >
        Commercial Authorized Reseller
      </a>.
    </div>
  );
};

export default LearnMoreText;