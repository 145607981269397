import { mergeStyleSets } from "@fluentui/react";

const ChartDisclaimer = () => {
  const disclaimerStyles = mergeStyleSets({
    container: {
      marginTop: "1rem",
      fontSize: 14,
      lineHeight: 20,
    },
  });

  return (
    <div className={disclaimerStyles.container}>
      Unless you entered your own values using the Assumptions Editor, the values for Other PC Devices are based on the study “
      <a
        href="https://aka.ms/SurfaceIDCWhitepaper"
        rel="noreferrer"
        target="_blank"
      >
        Microsoft Surface for Business Use Case
      </a>
      ” conducted by IDC, commissioned by Microsoft in 2022.
    </div>
  );
};

export default ChartDisclaimer;