import { useState, useEffect } from "react";
import { v4 as uuid } from "uuid";

export const useForm = (formFields, initialData) => {
  const [_id, setId] = useState(uuid());
  const [_formFields, setFormFields] = useState(formFields);
  const [_values, setValues] = useState(initialData || {});

  const patchInitialFormValues = (fields, initialValues) => {
    return fields.map((item) => {
      if (item.id) {
        if (
          initialValues[item.id] === undefined ||
          initialValues[item.id] === null
        ) {
          return item;
        }
        if (item.type === "dropdown") {
          return {
            ...item,
            selectedKey: initialValues[item.id],
          };
        }
        return {
          ...item,
          value: initialValues[item.id],
        };
      }
      return item;
    });
  };

  useEffect(() => {
    if (initialData && formFields) {
      const updatedForm = patchInitialFormValues(formFields, initialData);
      setFormFields(updatedForm);
      setValues(initialData);
    } else if (formFields) {
      setFormFields(formFields);
    }
  }, [initialData, formFields]);

  const updateValues = (data) => {
    let updatedFormFields = _formFields.map((item) => {
      if (Object.keys(data).includes(item.id)) {
        let updatedItem = Object.assign({}, item);
        if (item.type === "dropdown") {
          updatedItem.selectedKey = data[item.id];
        } else {
          updatedItem.value = data[item.id];
        }
        return updatedItem;
      }
      return item;
    });
    let updatedValues = {
      ..._values,
      ...data,
    };
    setFormFields(updatedFormFields);
    setValues(updatedValues);
  };

  const validate = () => {
    let hasError = false;
    _formFields.forEach((item) => {
      if (item.validation) {
        item.validation.forEach((fn) => {
          if (fn(_values[item.id])) {
            hasError = true;
          }
        });
      }
    });
    return !hasError;
  };

  const resetAllValues = () => {
    let updatedForm = [...formFields];
    if (initialData) {
      updatedForm = patchInitialFormValues(formFields, initialData);
    }
    setId(uuid());
    setFormFields(updatedForm);
    setValues(initialData || {});
  };

  if (!formFields) return;

  return {
    _id,
    fields: _formFields,
    values: _values,
    initialValues: initialData || {},
    updateValues,
    validate,
    reset: resetAllValues,
  };
};
