import { useContext, useEffect, useState } from "react";
import { mergeStyleSets, PrimaryButton } from "@fluentui/react";
import SelectionBoxListTCO from "./SelectionBoxListTCO";
import penIcon from "assets/pen.svg";
//import TwoPartLayout from "components/TwoPartLayout";
import ProductBoxContent from './ProductBoxContent';
import LearnMoreText from "./LearnMoreText";
//import TcoLandingImg from "assets/tcoLanding.jpg";
//import ReadMore from "components/ReadMore";
import "./TcoLanding.scss";
import { convertNumberToString, sendRequest, trackGAEvent } from "utils/helpers";
import { AlertContext } from "components/Alert";
import { YEAR_AMOUNT } from "constants/tco";
import CurrencySymbols from "../../assets/CurrencySymbols.json";
import SurfaceGo from "assets/Surface_Go.png";
import SurfaceLaptopGo from "assets/Surface_Laptop_Go.png";
import SurfaceLaptopStudio from "assets/Surface_Laptop_Studio.png";
import SurfaceLaptop from "assets/Surface_Laptop.png";
import SurfacePro from "assets/Surface_Pro.png";
// import InputPageSurfaceLaptop from "assets/tco-input-page-new-image.PNG";
import InputPageSurfaceLaptop from "assets/Portfolio.png";

const TcoLanding = (props) => {
  const { userInput, setUserInput, setShowAssumptions, config, setCurrencySymbol,
    currencyList, setCurrencyList, retainedRegionData, setRetainedRegionData, setDefaultAssumptions} = props;

  const { setAlert } = useContext(AlertContext);
  const [validationList, setValidationList] = useState({});
  const headerHeight = document.querySelector("header").offsetHeight;

  const [submitDisabled, setSubmitDisabled] = useState(false);

  const tcoStyles = mergeStyleSets({
    left: {
      height: `calc(100vh - ${headerHeight}px)`,
      padding: "0",
      img: {
        width: "100%",
        height: "100%",
        objectFit: "cover",
      },
    },
    right: {
      backgroundColor: "#ffffff",
      padding: "2.22rem 3.5rem",
      position: "relative",
      h1: {
        borderBottom: "2px solid #E1DFDD",
        fontSize: "2.3rem",
        fontWeight: 600,
        lineHeight: "2.5rem",
        margin: "0 0 2rem 0",
        paddingBottom: "1.5rem"
      },
    },
    introText: {
      marginBottom: "6.406rem",
      span: {
        fontSize: "1rem",
        fontWeight: 400,
        lineHeight: "1.5rem",
        marginBottom: "2.5rem",
      },
      p: {
        color: "#201F1E",
      },
    },
    changeAssumptionsButton: {
      background: "#191919",
      border: "1px solid #191919",
      width: "15.4rem",
      height: "3.1rem",
      margin: "0 3.5rem 1.4688rem auto;",
      fontSize: "1rem",
      ":hover": {
        background: "#292929",
        border: "1px solid #292929",
      },
      ":active": {
        background: "#191919",
        border: "1px solid #191919",
      },
      ":disabled": {
        background: "#ebebeb",
        border: "1px solid #ebebeb",
        color: "#b0b0b0",
      }
    },
    wrapper: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      ">div": {
        width: "50%",
      }
    },
    imageWrapper: {
      textAlign: 'center',
      img: {
        width: "75%",
        maxWidth: "50rem",
        height: "100%",
        objectFit: "cover",
      }
    },
    selectionBoxWrapper: {
      display: "flex",
      justifyContent: "center",
    }
  });

  const handleBoxChange = (val, productId) => {
    setUserInput((state) => {
      const updatedProductList = state.deviceMixPortfolio.map((item) => {
        if (item.id === productId) {
          return {
            ...item,
            ...val,
          };
        }
        return item;
      });
      return {
        ...state,
        deviceMixPortfolio: updatedProductList,
      };
    });
  };

  const boxContent = (product) => (
    <ProductBoxContent
      config={config}
      productId={product.id}
      handleBoxChange={handleBoxChange}
      data={userInput.deviceMixPortfolio}
      setValidationList={setValidationList}

      setSubmitDisabled={setSubmitDisabled}
      selectedRegion={retainedRegionData.region}
      selectedCurrency={retainedRegionData.currency}
    />
  );

  /* const leftPart = (
    <div className={`${tcoStyles.left} tco-landing-left-part`}>
      <img
        alt="landing page hero on the left"
        src={TcoLandingImg}
        tabIndex={0}
      />
    </div>
  ); */

  /* const rightPart = (
   
  ); */

  const bottomPartStyles = mergeStyleSets({
    container: {
      width: "100%",
      backgroundColor: "#ffffff",
      display: "flex",
      flexDirection: "column",
      // alignItems: "center",
      marginRight: "auto",
      paddingLeft: "3.5rem",
      boxSizing: "border-box",
    },
    disclaimerWrapper: {
      display: "flex",
      "> div": {
        width: "75%",
        fontSize: "1rem",
        fontWeight: 400,
      },
      "> button": {
        width: "25%",
        maxWidth: "15.4rem",
      }
    }
  });

  const handleNext = async () => {
    sessionStorage.setItem("shouldDoConversion", sessionStorage.getItem("selectedCurrency"));
    sessionStorage.setItem("returningToLandingPage", true);
    sessionStorage.setItem("showPerDevice", "true");
    sessionStorage.setItem("years", 3);
    sessionStorage.setItem("activeView", "Standard");

    const matchedSymbol = CurrencySymbols.currencySymbols.find(i => i.code === sessionStorage.getItem("selectedCurrency"));
    setCurrencySymbol(matchedSymbol["symbol"]);

    const currencyConversionMultipleFromUSD = userInput.currencyConversionMultipleFromUSD;
    let assumptionsWithCurrency = ["annualEmployeeSalary", "annualITStaffSalary", "averageCostToHire", "averagePCCost", "cameraCost", "dockCost", "extraPowerSupplyCost", "headsetCost", "lightsCost", "pcWarrantyAndMaintenanceCost", "penCost", "typeCoverCost"];
    let updatedValues = {};
    await sendRequest("POST", "api/tco-assumptions").then((data) => {
      if (!data.value) {
        setAlert("error", data.error.message);
        return;
      }
      if (data.value) {
        const { assumptions } = data.value;
        assumptions.forEach((i) => {
          if (i.tco_code === "warrantyAndMaintenanceCost" || i.tco_code === "pcWarrantyAndMaintenanceCost") {
            updatedValues[i.tco_code] = convertNumberToString(
              i.tco_default * currencyConversionMultipleFromUSD * YEAR_AMOUNT
            );
          } else if(assumptionsWithCurrency.includes(i.tco_code)) {
            updatedValues[i.tco_code] = convertNumberToString(i.tco_default * currencyConversionMultipleFromUSD);
          } else {
            updatedValues[i.tco_code] = convertNumberToString(i.tco_default);
          }
        });
      }
    });
    await setUserInput({
      ...userInput,
      assumptions: {
        ...userInput.assumptions,
        ...updatedValues,
      },
    });
    setDefaultAssumptions({
      ...updatedValues,
    });
    setShowAssumptions(true);
    trackGAEvent("Results View", "TCO results page visit", "Next Button in TCO input page");
    //Should Standard view/report click be tracked here?
  }

  /* const bottomPart = (
    <div className={bottomPartStyles.container}>
      <PrimaryButton
        className={tcoStyles.changeAssumptionsButton}
        text="Next >"
        onClick={handleNext}
        disabled={(Object.values(validationList).some((i) => i === false) === true) || (submitDisabled === true)}
      />
      <LearnMoreText />
    </div>
  ); */

  return (
    
    <div>
       <div className={`${tcoStyles.right} tco-landing-right-part`}>
        <h1>Total Cost of Ownership (TCO) <span class="nxt-line-title">Calculator</span></h1>
        <div
          className={`${tcoStyles.introText} tco-landing-right-part-intro-text m0`}
        >
          <span>
            <p>
              See how investing in Microsoft Surface and Microsoft 365 can help you
              save by simplifying your IT operations, reducing security risks and
              increase employee productivity over 3 years.
            </p>
            <p>
              The tool provides an estimated total cost of ownership based on the
              study "
              <a
                href="https://aka.ms/SurfaceIDCWhitepaper"
                rel="noreferrer"
                target="_blank"
              >
                Evaluating the Business Case of Microsoft Surface
              </a>
              " provided by IDC, commissioned by Microsoft in 2022, based on
              responses from IT decision-makers in the United States, Australia/New
              Zealand, India, Germany, and the United Kingdom.
            </p>
          </span>
        </div>
        {/* <div className='imageContainer'>
            <div>
               <img alt="Surface Laptop" src={SurfaceLaptop}/>
               <span>Surface Laptop</span>
            </div>
            <div>
               <img alt="Surface Laptop Studio" src={SurfaceLaptopStudio}/>
               <span>Surface Laptop Studio</span>
            </div>
            <div>
               <img alt="Surface Go" src={SurfaceGo}/>
               <span>Surface Go</span>
            </div>
            <div>
               <img alt="Surface Laptop Go" src={SurfaceLaptopGo}/>
               <span>Surface Laptop Go</span>
            </div>
            <div>
               <img alt="Surface Pro" src={SurfacePro}/>
               <span>Surface Pro</span>
            </div>
        </div> */}
        <div className={`${tcoStyles.wrapper} tco-input-mobile-wrapper`}>
          <div className={`${tcoStyles.imageWrapper} mobile-hide`}>
            <img src={InputPageSurfaceLaptop} alt="surface laptop" />
          </div>
          <div className={`${tcoStyles.selectionBoxWrapper}`}>
            <SelectionBoxListTCO
              title="Select your Product"
              selectionName="Microsoft Surface Product"
              max={10}
              min={1}
              list={userInput.deviceMixPortfolio}
              updateList={(val) =>
                setUserInput({
                  ...userInput,
                  deviceMixPortfolio: val,
                })
              }
              onDelete={(updatedList, id) => {
                const notEmptyItem = updatedList.filter(
                  (i) => Object.values(i).filter((x) => x).length > 1
                );
                let updatedObj = {
                  productBox: notEmptyItem.length !== 0,
                };
                Object.keys(validationList).forEach((key) => {
                  if (key !== id && key !== "productBox") {
                    updatedObj[key] = validationList[key];
                  }
                });
                setValidationList(updatedObj);
                let emptyProduct = updatedList.filter((i) => Object.keys(i).length === 1);
                if(!Object.values(updatedObj).some((i) => i === false) && !emptyProduct[0]){
                  setSubmitDisabled(false);
                } else {
                  setSubmitDisabled(true);
                }
              }}
              boxContent={boxContent}
              icon={penIcon}
              config={config}
              selectedCurrency={retainedRegionData.currency}
              setUserInput={setUserInput}
              currencyList={currencyList}
              setCurrencyList={setCurrencyList}
              retainedRegionData={retainedRegionData}
              setRetainedRegionData={setRetainedRegionData}
            />
          </div>
        </div>
      </div>

      <div className={`${bottomPartStyles.container} bottom-part-container`}>
        <div className={`${bottomPartStyles.disclaimerWrapper} disclaimer-wrapper`}>
          <div>The TCO report provided to you is for informational purposes only. You should not interpret the report you receive to be a commitment on the part of Microsoft; actual costs and savings may vary based on your location, purchase method, deployment, usage, and other factors. Neither Microsoft nor IDC makes any representations or warranties, express or implied, as to the information within this website and report.</div>
          <PrimaryButton
            className={tcoStyles.changeAssumptionsButton}
            text="Next >"
            onClick={handleNext}
            disabled={(Object.values(validationList).some((i) => i === false) === true) || (submitDisabled === true)}
          />
        </div>
        <LearnMoreText />
      </div>
    </div>
  );
};

export default TcoLanding;