import { useRef } from "react";
// import the core library.
import ReactEChartsCore from "echarts-for-react/lib/core";
// Import the echarts core module, which provides the necessary interfaces for using echarts.
import * as echarts from "echarts/core";
// Import charts, all with Chart suffix
import {
  LineChart,
  BarChart,
  PieChart,
  // ScatterChart,
  // RadarChart,
  // MapChart,
  // TreeChart,
  // TreemapChart,
  // GraphChart,
  // GaugeChart,
  // FunnelChart,
  // ParallelChart,
  // SankeyChart,
  // BoxplotChart,
  // CandlestickChart,
  // EffectScatterChart,
  // LinesChart,
  // HeatmapChart,
  // PictorialBarChart,
  // ThemeRiverChart,
  // SunburstChart,
  // CustomChart,
} from "echarts/charts";
// import components, all suffixed with Component
import {
  GridSimpleComponent,
  // GridComponent,
  // PolarComponent,
  // RadarComponent,
  // GeoComponent,
  SingleAxisComponent,
  // ParallelComponent,
  // CalendarComponent,
  // GraphicComponent,
  ToolboxComponent,
  TooltipComponent,
  AxisPointerComponent,
  // BrushComponent,
  TitleComponent,
  // TimelineComponent,
  MarkPointComponent,
  MarkLineComponent,
  // MarkAreaComponent,
  LegendComponent,
  // LegendScrollComponent,
  // LegendPlainComponent,
  // DataZoomComponent,
  // DataZoomInsideComponent,
  // DataZoomSliderComponent,
  // VisualMapComponent,
  // VisualMapContinuousComponent,
  // VisualMapPiecewiseComponent,
  // AriaComponent,
  // TransformComponent,
  // DatasetComponent,
} from "echarts/components";
// Import renderer, note that introducing the CanvasRenderer or SVGRenderer is a required step
import {
  CanvasRenderer,
  // SVGRenderer,
} from "echarts/renderers";

// Register the required components
echarts.use([
  PieChart,
  LineChart,
  BarChart,
  // HeatmapChart,
  TitleComponent,
  TooltipComponent,
  ToolboxComponent,
  MarkLineComponent,
  AxisPointerComponent,
  SingleAxisComponent,
  LegendComponent,
  GridSimpleComponent,
  MarkPointComponent,
  // GridComponent,
  // PolarComponent,
  // RadarComponent,
  // GeoComponent,
  // LegendScrollComponent,
  // LegendPlainComponent,
  // DataZoomComponent,
  // DataZoomInsideComponent,
  // DataZoomSliderComponent,
  // VisualMapComponent,
  // VisualMapContinuousComponent,
  // VisualMapPiecewiseComponent,
  // AriaComponent,
  // TransformComponent,
  // DatasetComponent,
  CanvasRenderer,
]);

const ReactEcharts = (props) => {
  const {
    options,
    onChartReadyCallback,
    initOptions,
    // eventHandlers,
    className,
    loading,
    width,
    height,
    id
  } = props;
  const echartRef = useRef(null);

  return (
    <div tabIndex={0} id={id}>
      <ReactEChartsCore
        ref={echartRef}
        showLoading={loading}
        loadingOption={{ type: "default" }}
        className={className}
        echarts={echarts}
        option={options}
        notMerge={true}
        lazyUpdate={false}
        // theme={"theme_name"}
        onChartReady={onChartReadyCallback ? (e) => onChartReadyCallback(e) : null}
        // onEvents={eventHandlers}
        opts={initOptions}
        style={{ height, width }}
      />
    </div>
  );
};

export default ReactEcharts;
