import { useMemo, useEffect, useState } from "react";
import { FontIcon, mergeStyleSets } from "@fluentui/react";
import CommonForm from "components/CommonForm/CommonForm";
import { useForm } from "components/CommonForm/formHooks";
import { required } from "components/CommonForm/validators.js";
import { QUANTITY_LIMIT } from "constants/tco";
import "./ProductBoxContent.scss";

const ProductBoxContent = (props) => {
  const { config, productId, handleBoxChange, data, setValidationList, setSubmitDisabled, 
    selectedRegion, selectedCurrency } = props;
  
  const [msrp, setMSRP] = useState();
  const [isMSRPDisabled, setIsMSRPDisabled] = useState(true);

  const [productOptions, setProductOptions] = useState({
    processorOptions: [],
    ramOptions: [],
    driveSizeOptions: [],
  });
  const [selectedProductOption, setSelectedProductOption] = useState({
    name: "",
    processor: "",
    ram: "",
    driveSize: "",
  });
  const [disableFields, setDisableFields] = useState({
    productDropdown: true,
    processorDropdown: true,
    ramDropdown: true,
    driveSizeDropdown: true,
  });

  useEffect(() => {
    if(!msrp){
      setSubmitDisabled(true);
    } else {
      setSubmitDisabled(false);
    }
  }, [msrp]);


  useEffect(() => {
    if(data[0]["product"]){
      const matchedProduct = data.find((i) => i.id === productId);
      setMSRP((matchedProduct["msrp"]));
    }
  },[data, selectedCurrency]);

  const removePrefix = (value) => {
    const prefixIndex = value.indexOf("-") + 1;
    return value.substring(prefixIndex);
  }

  useEffect(() => {

    if(sessionStorage.getItem("returningToLandingPage") === "true" && data[0]["product"]){
      const matchedProduct = data.find((i) => i.id === productId);
      setProductOptions((state) => {
        return {
          ...state,
          processorOptions: matchedProduct["processorOptions"],
          ramOptions: matchedProduct["ramOptions"],
          driveSizeOptions: matchedProduct["driveSizeOptions"],
        }
      });

      setSelectedProductOption((state) => {
        return {
          ...state,
          name: removePrefix(matchedProduct["product"]),
          processor: removePrefix(matchedProduct["processor"]),
          ram: removePrefix(matchedProduct["ram"]),
          driveSize: removePrefix(matchedProduct["driveSize"]),
        }
      });
      if(!(matchedProduct["processorOptions"].length === 1 &&
          matchedProduct["ramOptions"].length === 1 &&
          matchedProduct["driveSizeOptions"].length === 1)){
          setDisableFields((state) => {
            return {
              ...state,
              processorDropdown: false,
              ramDropdown: false,
              driveSizeDropdown: false,
            }
          });
      }
    }

  }, []);

  useEffect(() => {
    if(selectedRegion && selectedCurrency){
      setDisableFields((state) => {
        return {
          ...state,
          productDropdown: false,
        }
      })
    }
  }, [selectedRegion, selectedCurrency,]);

  const fieldStyles = mergeStyleSets({
    deviceSelection: {
      width: "100%",
      marginBottom: "1rem",
      verticalAlign: "top",
    },
    processor: {
      width: "32%",
      marginBottom: "1rem",
      verticalAlign: "top",
    },
    ram: {
      width: "32%",
      marginBottom: "1rem",
      verticalAlign: "top",
    },
    subscriptionInput: {
      width: "calc(49% - 2px)",
      // marginBottom: "1rem",
      verticalAlign: "top",
    },
    quantityInput: {
      width: "calc(49% - 2px)",
      // marginBottom: "1rem",
      verticalAlign: "top",
    },
  });

  const convertFromUSDToOther = (valueInUSD, currencyCode) => {
    const matchedCurrency = config.currencies.filter((i) => i.key === currencyCode);
    return matchedCurrency[0]["fromUSD"] * valueInUSD;
  }

  const handleProductChange = (val, productId) => {

    const prefixIndex = val.product.indexOf("-") + 1;
    const productName = val.product.substring(prefixIndex);

    const matchedProducts = config.productsMasterData.filter((i) => i.name === productName);

    if(matchedProducts.length === 1){

      const onlyOption = {
        processor: [{
          key: `${0}-${matchedProducts[0]["processor"]}`,
          text: matchedProducts[0]["processor"],
        }],
        ram: [{
          key: `${0}-${matchedProducts[0]["ram"]}`,
          text: matchedProducts[0]["ram"],
        }],
        driveSize: [{
          key: `${0}-${matchedProducts[0]["driveSize"]}`,
          text: matchedProducts[0]["driveSize"],
        }],
      };

      setSelectedProductOption((state) => {
        return {
          ...state,
          name: productName,
          processor: matchedProducts[0]["processor"],
          ram: matchedProducts[0]["ram"],
          driveSize: matchedProducts[0]["driveSize"],
        }
      });

      setProductOptions((state) => {
        return {
          ...state,
          processorOptions: onlyOption.processor,
          ramOptions: onlyOption.ram,
          driveSizeOptions: onlyOption.driveSize,
        }
      });

      setDisableFields((state) => {
        return {
          ...state,
          processorDropdown: true,
          ramDropdown: true,
          driveSizeDropdown: true,
        }
      });

      const convertedMSRP = convertFromUSDToOther(matchedProducts[0]["msrp"], sessionStorage.getItem("selectedCurrency"));
      setMSRP(convertedMSRP);
      handleBoxChange({product: val.product, productCode: matchedProducts[0]["code"], processor: onlyOption.processor[0]["key"],
      ram: onlyOption.ram[0]["key"], driveSize: onlyOption.driveSize[0]["key"],
      msrp: convertedMSRP, processorOptions: onlyOption.processor,
      ramOptions: onlyOption.ram, driveSizeOptions: onlyOption.driveSize,}, productId);

      return;
    }


    const uniqueProcessors = Array.from(new Set(matchedProducts.map((item) => item.processor)));
    const filteredProcessors = uniqueProcessors.map((i, index) => {
      return {
        key: `${index}-${i}`,
        text: i,
      }
    });

    setSelectedProductOption((state) => {
      return {
        ...state,
        name: productName,
      }
    });
    setProductOptions((state) => {
      return {
        ...state,
        processorOptions: filteredProcessors,
        ramOptions: [],
        driveSizeOptions: [],
      }
    });
    setDisableFields((state) => {
      return {
        ...state,
        processorDropdown: false,
        ramDropdown: true,
        driveSizeDropdown: true,
      }
    });
    setMSRP("");
    handleBoxChange({product: val.product, processor: "", ram: "", driveSize: "",
     msrp: "", processorOptions: filteredProcessors, ramOptions: [], driveSizeOptions: [], 
     productCode: 0}, productId);
  }

  const handleProcessorChange = (val, productId) => {

    const prefixIndex = val.processor.indexOf("-") + 1;
    const selectedProcessorName = val.processor.substring(prefixIndex);

    const matchedProcessors = config.productsMasterData.filter((i) => i.processor === selectedProcessorName && i.name === selectedProductOption.name);
    const uniqueRAM = Array.from(new Set(matchedProcessors.map((item) => item.ram)));
    const filteredRAM = uniqueRAM.map((i, index) => {
      return {
        key: `${index}-${i}`,
        text: i,
      }
    });

    setSelectedProductOption((state) => {
      return {
        ...state,
        processor: selectedProcessorName,
      }
    });
    setProductOptions((state) => {
      return {
        ...state,
        ramOptions: filteredRAM,
        driveSizeOptions: [],
      }
    });
    setDisableFields((state) => {
      return {
        ...state,
        ramDropdown: false,
        driveSizeDropdown: true,
      }
    })
    handleBoxChange({processor: val.processor, ram: "", driveSize: "", msrp: "", 
    ramOptions: filteredRAM, driveSizeOptions: [], productCode: 0}, productId);
  }

  const handleRAMChange = (val, productId) => {
    const prefixIndex = val.ram.indexOf("-") + 1;
    const selectedRAMOption = val.ram.substring(prefixIndex);

    const matchedRAM = config.productsMasterData.filter((i) => i.ram === selectedRAMOption && i.processor === selectedProductOption.processor && i.name === selectedProductOption.name);
    const uniqueDriveSizes = Array.from(new Set(matchedRAM.map((item) => item.driveSize)));
    const filteredDriveSizes = uniqueDriveSizes.map((i, index) => {
      return {
        key: `${index}-${i}`,
        text: i,
      }
    });

    setSelectedProductOption((state) => {
      return {
        ...state,
        ram: selectedRAMOption,
        driveSize: "",
      }
    });
    setProductOptions((state) => {
      return {
        ...state,
        driveSizeOptions: filteredDriveSizes,
      }
    });
    setDisableFields((state) => {
      return {
        ...state,
        driveSizeDropdown: false,
      }
    })
    handleBoxChange({ram: val.ram, driveSize: "", msrp: "", driveSizeOptions: filteredDriveSizes, productCode: 0}, productId);
  }

  const handleDriveSizeChange = (val, productId) => {
    const prefixIndex = val.driveSize.indexOf("-") + 1;
    const selectedDriveSizeOption = val.driveSize.substring(prefixIndex);
    const matchedDriveSize = config.productsMasterData.find((i) => i.driveSize === selectedDriveSizeOption && i.ram === selectedProductOption.ram && i.processor === selectedProductOption.processor && i.name === selectedProductOption.name);
    const matchedMSRP = matchedDriveSize["msrp"];
    const convertedMSRP = convertFromUSDToOther(matchedMSRP, sessionStorage.getItem("selectedCurrency"));

    setMSRP(convertedMSRP);
    // setSubmitDisabled(false);
    setSelectedProductOption((state) => {
      return {
        ...state,
        driveSize: selectedDriveSizeOption,
      }
    });
    handleBoxChange({driveSize: val.driveSize, msrp: convertedMSRP, productCode: matchedDriveSize["code"]}, productId);
  }

  const boxFields = useMemo(
    () => [
      {
        id: "product",
        type: "dropdown",
        placeholder: "Select Product",
        options: config.productNames,
        onChange: (event, val) => handleProductChange(val, productId),
        validation: [required],
        className: fieldStyles.deviceSelection,
        disabled: disableFields.productDropdown,
      },
      {
        id: "processor",
        type: "dropdown",
        placeholder: "Processor",
        options: productOptions.processorOptions,
        onChange: (event, val) => handleProcessorChange(val, productId),
        validation: [required],
        className: fieldStyles.processor,
        disabled: disableFields.processorDropdown,
      },
      {
        id: "ram",
        type: "dropdown",
        placeholder: "RAM",
        options: productOptions.ramOptions,
        selectedKey: null,
        onChange: (event, val) => handleRAMChange(val, productId),
        validation: [required],
        className: fieldStyles.ram,
        disabled: disableFields.ramDropdown,
      },
      {
        id: "driveSize",
        type: "dropdown",
        placeholder: "Drive",
        options: productOptions.driveSizeOptions,
        selectedKey: null,
        onChange: (event, val) => handleDriveSizeChange(val, productId),
        validation: [required],
        className: fieldStyles.ram,
        disabled: disableFields.driveSizeDropdown,
      },
    ],
    [config, productOptions, disableFields]
  );

  const boxFields2 = useMemo(
    () => [
      {
        id: "microsoft365Subscription",
        type: "dropdown",
        placeholder: "Select Subscription",
        options: config.m365Subscriptions,
        onChange: (event, val) => handleBoxChange(val, productId),
        validation: [required],
        className: `${fieldStyles.subscriptionInput} selection-box-subscription-input`,
        disabled: disableFields.productDropdown,
      },
      {
        id: "quantity",
        type: "positiveInteger",
        placeholder: "QTY",
        max: QUANTITY_LIMIT,
        onChange: (event, val) => handleBoxChange(val, productId),
        validation: [required],
        className: `${fieldStyles.quantityInput} selection-box-quantity-input`,
        disabled: disableFields.productDropdown,
      },
    ],
    [config, productOptions, disableFields]
  );

  const form = useForm(
    boxFields,
    data.find((i) => {
      return i.id === productId})
  );

  const form2 = useForm(
    boxFields2,
    data.find((i) => {
      return i.id === productId})
  );

  const enableInputEdit = () => {
    if(msrp){
      setIsMSRPDisabled(false)
    }
  }

  const validateMSRP = (event) => {
    const msrpValue = event.target.value;
    // if(!msrpValue){
    //   setSubmitDisabled(true);
    // } else {
    //   setSubmitDisabled(false);
    // }
    handleBoxChange({"msrp": Number(msrpValue)}, productId);
  }

  const productContainerStyles = mergeStyleSets({
    container: {
      width: "100%",
      "> div:first-child": {
        width: "100%",
        display: "flex",
        flexWrap: "wrap",
        justifyContent: 'space-between',
      },
    },
    innerContainer: {
      display: "flex",
      alignItems: 'center',
      ">div:nth-child(1)": {
        width: "66%",
        display: "flex",
        justifyContent: 'space-between',
      },
      ">div:nth-child(2)": {
        width: "2%",
      },
      ">div:nth-child(3)": {
        width: "32%",
      },

    },
    msrp: {
      alignSelf: "flex-start",
      height: "30px",
      display: "flex",
      justifyContent: "center",
      border: "1px solid #D6D6D6",
      ":hover": {
        border: "1px solid black",
      },
      // ":focus": {
      //   outline: "none",
      //   border: "2px solid #0078d4",
      // },
      alignItems: "center",
      "> input": {
        width: "70%",
        border: "none",
        ":hover": {
          border: "none",
        },
        ":focus": {
          outline: "none",
          border: "none",
        }
      },
      "> div:last-child": {
        width: "25%"
      }
    },
    editIcon: {
      fontSize: "1rem",
      cursor: "pointer",
    }
  });

  useEffect(() => {
    const notEmptyItem = data.filter(
      (i) => Object.values(i).filter((x) => x).length > 1
    );
    if (notEmptyItem.length === 0) {
      setValidationList((state) => {
        return {
          ...state,
          productBox: false,
        };
      });
    } else {
      if ((Object.keys(form.values).filter((i) => i !== "id").length > 0) &&
      (Object.keys(form2.values).filter((i) => i !== "id").length > 0)) {
        setValidationList((state) => {
          return {
            ...state,
            productBox: true,
            [productId]: form.validate() && form2.validate(),
          };
        });
      } else {
        setValidationList((state) => {
          return {
            ...state,
            productBox: true,
          };
        });
      }
    }

    let emptyProduct = data.filter((i) => Object.keys(i).length === 1);
    if(!emptyProduct[0]){
      setSubmitDisabled(false);
    } else {
      setSubmitDisabled(true);
    }

  }, [form.values, form2.values]);

  return <div className={`${productContainerStyles.container} product-container-wrapper`}>
    <CommonForm form={form} />
    <div className={`${productContainerStyles.innerContainer} product-box-inner-container-mobile`}>
      <CommonForm form={form2} />
      <div className="mobile-hide"></div>
      <div className={`${productContainerStyles.msrp}`}>
        {/* <input type="number" name="msrp" id="msrp" placeholder="MSRP" value={msrp && msrp.toFixed(2)} disabled={isMSRPDisabled} onChange={validateMSRP} /> */}
        {isMSRPDisabled?
            <input type="text" name="msrp" id="msrp" placeholder="MSRP" value={msrp && msrp.toFixed(2)} disabled />
          :
            <input type="number" name="msrp" id="msrp" placeholder="MSRP" onChange={validateMSRP} onBlur={() => setIsMSRPDisabled(true)} />
        }
        <FontIcon 
          aria-label="Edit" 
          iconName="Edit" 
          className={`${productContainerStyles.editIcon}`}
          onClick={enableInputEdit}
        />
      </div>
    </div>
  </div>
};

export default ProductBoxContent;
