const TwoColorCircle = (props) => {
  const { fill1, fill2 } = props;
  return (
    <svg
      width="21"
      height="22"
      viewBox="0 0 21 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      tabIndex={0}
    >
      {!fill2 ? (
        <circle cx="10.655" cy="10.5" r="10.155" fill={fill1} />
      ) : (
        <>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M0.535236 11C0.535236 10.9999 0.535236 10.9998 0.535236 10.9997C0.535236 5.39127 5.08178 0.844727 10.6902 0.844727C16.2987 0.844727 20.8452 5.39127 20.8452 10.9997C20.8452 10.9998 20.8452 10.9999 20.8452 11L0.535236 11Z"
            fill={fill1}
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M20.8451 11C20.8451 11.0001 20.8451 11.0002 20.8451 11.0003C20.8451 16.6087 16.2986 21.1553 10.6901 21.1553C5.0817 21.1553 0.535156 16.6087 0.535156 11.0003C0.535156 11.0002 0.535156 11.0001 0.535156 11L20.8451 11Z"
            fill={fill2}
          />
        </>
      )}
    </svg>
  );
};

export default TwoColorCircle;