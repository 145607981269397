import React, { useEffect, useRef, useState } from 'react';
import { ContextualMenu, TextField, mergeStyleSets } from '@fluentui/react';
import { useId, useBoolean } from '@fluentui/react-hooks';

import ChatbotBack from "assets/chatbot-back.svg";
import ChatbotOptionsIcon from "assets/chatbot-options.svg";
import ChatbotClose from "assets/chatbot-close.svg";
import ChatbotBroom from "assets/chatbot-broom.svg";
import ChatbotStatusIcon from "assets/chatbot-tick.svg";
import ChatbotStatusCompleted from "assets/chatbot-status-completed.svg";
import ChatbotStatusAbort from "assets/chatbot-status-abort.svg";
import ChatbotSend from "assets/chatbot-send.svg";
import ChatbotPower from "assets/chatbot-power.svg";
import ChatbotOptionsAbout from "assets/chatbot-options-about.svg";
import ChatbotOptionsPrivacy from "assets/chatbot-options-privacy.svg";
import ChatbotOptionsTerms from "assets/chatbot-options-terms.svg";
import ChatbotOptionsFeedback from "assets/chatbot-options-feedback.svg";
import ChatbotOptionsConversation from "assets/chatbot-options-conversation.svg";
import ChatbotStop from "assets/chatbot-stop.svg";
import ChatbotUpArrow from "assets/chatbot-up-arrow.png";
import ChatbotDownArrow from "assets/chatbot-down-arrow.png";
import _ from "lodash";

// import '../../tco/chatbot/Chatbot.scss';
import { AzureKeyCredential, OpenAIClient } from '@azure/openai';
import SEEChatbotLearnMore from './SEEChatbotLearnMore';
import { convertStringToNumber } from 'utils/helpers';

const SEEChatbot = (props) => {
    
    const {toggleChatbotWindow, reportData, productList, productRefOptions} = props;

    const [selectedProficiencyOption, setSelectedProficiencyOption] = useState(1);
    const [expandedInput, setExpandedInput] = useState(false);
    const [userInputText, setUserInputText] = useState("");
    const [isOptionsMenuVisible, setIsOptionsMenuVisible] = useState(false);
    const [optionsMenuTargetElement, setOptionsMenuTargetElement] = useState(null);
    const [generatingResponse, setGeneratingResponse] = useState(false);
    const [chatTokens, setChatTokens] = useState(0);
    const [totalTokens, setTotalTokens] = useState(0);
    const [generatedQuestions, setGeneratedQuestions] = useState([]);
    const [renderDetailedConversationPrompt, setRenderDetailedConversationPrompt] = useState(true);
    const [requireDetailedResponseCount, setRequireDetailedResponseCount] = useState(0);
    const [exemptedQuestions, setExemptedQuestions] = useState([]);

    const shouldCancelRef = useRef(false);
    const messagesStartRef = useRef(null);
    const messagesEndRef = useRef(null);
    const apiRequestRef = useRef(null);

    const starterChat = [
      {
        role: "custom",
        content: "",
        widget: "welcomeText",
      },
      {
        role: "custom",
        content: "",
        widget: "horizontalLine",
      },
      {
        role: "assistant",
        content: "Hello! Is there anything I can assist you with?"
      },
    ];

    const [messages, setMessages] = useState([
      {
        role: "custom",
        content: "",
        widget: "welcomeText",
      },
      {
        role: "custom",
        content: "",
        widget: "horizontalLine",
      },
      {
        role: "assistant",
        content: "Hello! Is there anything I can assist you with?"
      },
    ]);

    const optionsMenuItems = [
      // { key: 'item1', text: 'About', iconName: ChatbotOptionsAbout },
      { key: 'item2', text: 'Change Conversation Style', iconName: ChatbotOptionsConversation },
    ];

    useEffect(() => {
      messagesEndRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' });
    }, [messages]);

    useEffect(() => {
      document.addEventListener('click', handleDocumentClick);
      return () => {
        document.removeEventListener('click', handleDocumentClick);
      };
    }, []);

    useEffect(() => {
      if(!generatingResponse && (generatedQuestions.length > 0)){
        let newMessages = messages.filter(message => message.widget !== "suggestedQuestions")
        setMessages([...newMessages, {role: "custom", content: "", widget: "suggestedQuestions"}]);
      }
    }, [generatedQuestions]);

    const widgetStyles = mergeStyleSets({
      welcomeTextContainer: {
        width: window.innerWidth > 1440 ? "70%" : "90%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
        margin: "1.5rem auto 1rem",
        color: "#7F7D7B",
      },
      welcomeTextMainText: {
        fontSize: "1.5rem",
        fontWeight: 700,
      },
      previewText: {
        fontSize: "0.8rem",
        fontWeight: 400,
        padding: "0 0.5rem",
        border: "1px solid #0078d4",
        borderRadius: "0.3rem",
        // background: "#0078d4",
        // color: "white",
        color: "#0078d4",
        marginLeft: "0.3rem",
        position: "relative",
        top: "-0.3rem",
      },
      welcomeTextSubText: {
        width: "78%",
        fontSize: "0.89rem",
        fontWeight: 600,
        paddingTop: "0.7rem",
      },
      horizontalLine: {
        width: "80%",
        margin: "1.5rem auto",
        border: "0.5px solid #E3E3E3",
      },
      suggestedQuestionsWrapper: {
        display: "flex",
        flexWrap: "wrap",
        margin: "3rem 0 0 1rem",
        // maxWidth: "90%",
      },
      suggestedQuestion: {
        borderRadius: "5px",
        border: "1px solid #64BAF6",
        background: "#FFF",
        margin: "0 0.5rem 0.5rem 0",
        color: "#64BAF6",
        padding: "0.2rem 0.4rem",
        fontSize: "0.9rem",
        ":hover": {
          cursor: "pointer",
          background: "#0078D4",
          border: "1px solid #0078D4",
          color: "#FFF",
        }
      },
    });

    const SEEChatbotStyles = mergeStyleSets({
      container: {
        height: "99%",
        margin: "0.2rem",
        borderRadius: "0.43929rem",
        background: "linear-gradient(317deg, #F0F0F0 0%, #FFF 100%)",
        boxShadow: "0px 0px 1.5399999618530273px 0px rgba(0, 0, 0, 0.12), 0px 0.7699999809265137px 1.5399999618530273px 0px rgba(0, 0, 0, 0.14)",
      },
      header: {
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        background: "#F5F5F5",
        borderRadius: "6.15px 6.15px 0px 0px",
        // height: `${headerHeight ? headerHeight + "px" : "3.85rem"}`,
        // height: "4.07143rem",
        height: "7%",
        "> div": {
          margin: "1rem 2rem",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          "img:hover": {
            cursor: "pointer",
          },
        },
      },
      headerOptionsWrapper: {
        img: {
          marginLeft: "1rem",
          width: "1.5rem",
          height: "1.5rem",
        },
      },
      innerContainer: {
        padding: "0",
        height: "93%",
      },
      messagesContainer: {
        background: "linear-gradient(317deg, #F0F0F0 0%, #FFF 100%)",
        height: "83%",
        display: "flex",
        flexDirection: "column",
      },
      messagesContainerReduced: {
        background: "linear-gradient(317deg, #F0F0F0 0%, #FFF 100%)",
        height: "75%",
        display: "flex",
        flexDirection: "column",
      },
      chatMessage: {
        display: "flex",
        marginBottom: "1rem",
        width: "fit-content",
        maxWidth: "80%",
        fontSize: "1rem",
        selectors: {
          "&.user": {
            color: "#0078D4",
            alignSelf: "flex-end",
            borderRadius: "1rem 0 1rem 1rem",
            border: "1px solid #0078D4",
            background: "#CAEAFF",
            padding: "0.5rem 1rem",
            marginRight: "1rem",
          },
          "&.assistant": {
            flexDirection: "column",
            color: "#A7A7A7",
            alignSelf: "flex-start",
            borderRadius: "0 1rem 1rem 1rem",
            border: "1px solid #A7A7A7",
            background: "#FFF",
            padding: "0.5rem 1rem",
            marginLeft: "1rem",
            minWidth: "70%",
            // marginRight: "1rem",
          },
          "&.status": {
            color: "#A7A7A7",
            marginLeft: "1rem",
            // background: "yellow",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            img: {
              width: "1.3rem",
              height: "1.3rem",
              marginRight: "0.5rem",
            }
          },
        }
      },
      chatMessageContent: {
        whiteSpace: 'pre-line',
      },
      footer: {
        boxSizing: "border-box",
        height: "17%",
        padding: "0",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      },
      footerExpanded: {
        boxSizing: "border-box",
        height: "25%",
        padding: "0",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      },
      footerWrapper: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        width: "90%",
        margin: "0 auto",
      },
      footerWrapperExpanded: {
        width: "90%",
        margin: "0 auto",
      },
      broomImage: {
        background: "#0078D4",
        borderRadius: "0.4rem",
        padding: "0.5rem",
        width: "7%",
        height: "1.7rem",
        cursor: "pointer",
      },
      inputBox: {
        color: "#A7A7A7",
        // boxSizing: "border-box",
        width: "85%",
        // height: "2.7rem",
        // borderRadius: "5px",
        // border: "1px solid #A7A7A7",
        // background: "#FFF",
        // padding: "0.2rem 0.8rem",
        // fontSize: "1rem",
        // transition: "all 0.3s ease",
        selectors: {
          "&.expanded": {
            width: "100%",
            // transition: "all 0.3s ease",
          }
        },
      },
      inputBoxForReportAnswer: {
        color: "#A7A7A7",
        borderRadius: "5px",
        border: "1px solid #A7A7A7",
        background: "#FFF",
        padding: "2px",
        ".ms-TextField-suffix": {
          cursor: "pointer",
          background: "#FFFFFF",
        },
        ".ms-TextField-fieldGroup": {
          border: "none",
        },
        ".ms-TextField-fieldGroup::after": {
          border: "none",
        }
      },
      footerDisclaimer: {
        color: "#A7A7A7",
        fontSize: "0.6rem",
        marginBottom: "0.5rem",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      },
      horizontalLine: {
        width: "80%",
        margin: "0.8rem auto 0",
        border: "0.5px solid #E3E3E3",
      },
      fullWidthHorizontalLine: {
        width: "100%",
        borderBottom: "1px solid #E3E3E3",
        margin: "1rem auto",
      },
      stopButton: {
        fontSize: "0.8rem",
        background: "#0078D4",
        color: "#FFFFFF",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "max-content",
        padding: "0.5rem 0.7rem",
        margin: "0 auto",
        borderRadius: "5px",
        ":hover": {
          cursor: "pointer",
        }
      },
      sendButton: {
        width: "1.3rem",
        height: "1.3rem",
      },
      inputCharCount: {
        boxSizing: "border-box",
        width: "90%",
        margin: "0 auto",
        border: "1px solid #A7A7A7",
        borderRadius: "0 0 5px 5px",
        borderTop: "none",
        background: "#FFFFFF",
        padding: "5px 9px",
        color: "#A7A7A7",
      },
      optionsMenuItem: {
        boxSizing: "border-box",
        display: "flex",
        alignItems: "center",
        border: "1px solid transparent",
        borderRadius: "5px",
        background: "#F6F6F6",
        margin: "0.3rem auto",
        color: "#1D1D1C",
        fontWeight: 500,
        padding: "0.5rem",
        img: {
          width: "11%",
          marginRight: "0.5rem",
        },
        span: {
          width: "89%",
        },
        ":first-child": {
          marginTop: "0rem",
        },
        ":last-child": {
          marginBottom: "0rem",
        },
        ":hover": {
          cursor: "pointer",
          border: "1px solid #0078D4",
        },
      },
      optionsIconBackground: {
        background: "#CAEAFF",
        borderRadius: "5px",
      },
      promptSelection: {

      },
      promptSelectionWrapper: {
        marginTop: "0.8rem",
        display: "flex",
      },
      scollUpDownContainer: {
        display: "flex",
        // background: "yellow",
        position: "absolute",
        right: 40,
        bottom: 10,
        "img": {
          cursor: "pointer",
        }
      }
    });

    const welcomeText = () => {
      return (
        <div className={`${widgetStyles.welcomeTextContainer}`}>
          <div className={`${widgetStyles.welcomeTextMainText}`}>Welcome to Surface Emissions Estimator Chat <span className={`${widgetStyles.previewText}`}>Preview</span></div>
          <div className={`${widgetStyles.welcomeTextSubText}`}>Here you can use the power of AI to find answers to all of your questions.</div>
        </div>
      );
    }

    const horizontalLine = () => {
      return <div className={`${widgetStyles.horizontalLine}`}></div>
    }

    const suggestedQuestions = () => {
      if(!generatedQuestions){
        return;
      }
      return (
        <div className={`${widgetStyles.suggestedQuestionsWrapper}`}>
          {
            generatedQuestions.map((question, index) => {
              return (
                <div key={index} className={`${widgetStyles.suggestedQuestion}`} onClick={() => handleUserMessage(question)}>{question}</div>
              )
            })
          }
        </div>
      );
    }

    const renderWidget = (widgetName) => {
      switch (widgetName) {
        case "welcomeText": return welcomeText();
        case "horizontalLine": return horizontalLine();
        case "suggestedQuestions": return suggestedQuestions();
        default: break;
      }
    }

    const handleConversationStyleChange = (id) => {
      setRenderDetailedConversationPrompt(false);
      setSelectedProficiencyOption(id);
      const newMessage = {
        role: "assistant",
        content: `You have chosen to have more ${id === 1 ? "concise" : "detailed"} answers.`
      };

      let newMessages = messages.filter(message => !(message.role === "assistantPrompt"));

      let lastMessage = newMessages.pop();
      if(lastMessage.widget === "suggestedQuestions"){
        setMessages([...newMessages, newMessage, lastMessage]);
      } else {
        setMessages([...newMessages, lastMessage, newMessage]);
      }
    }

    const requireDetailedResponse = async (question) => {
      let matchedResponses = messages.filter(msg => msg.role === "assistantGeneratedResponse");
      if(matchedResponses.length === 4 && requireDetailedResponseCount === 0){
        handleUserMessage(question, true);
        setRequireDetailedResponseCount(currentCount => currentCount + 2);
        setExemptedQuestions(current => [...current, question]);
        return;
      }
      if((requireDetailedResponseCount < 1)){
        handleUserMessage(question, true);
        setRequireDetailedResponseCount(currentCount => currentCount + 1);
        setExemptedQuestions(current => [...current, question]);
        return;
      }
      setSelectedProficiencyOption(2);
      handleUserMessage(question, true);
      setRenderDetailedConversationPrompt(false);
    }

    const setRating = (messageObject, rating, isSubmitted) => {
      messageObject.rating = rating;
      let newMessage;
      if(isSubmitted){
        newMessage = {
          role: "assistant",
          content: `Thanks for the feedback`
        };
      } else {
        newMessage = {
          role: "assistant",
          content: `Error submitting feedback`
        };
      }
      setMessages((currentMessages) => {
        let latestMessage = currentMessages.pop();
        if(latestMessage.widget === "suggestedQuestions"){
          return [...currentMessages, newMessage, latestMessage];
        } else {
          return [...currentMessages, latestMessage, newMessage];
        }
      });
    }

    const findNearestUser = (currentIndex) => {
      for (let i = currentIndex - 1; i >= 0; i--) {
        if (messages[i].role === 'user') {
          return messages[i].content;
        }
      }
      return null;
    };

    const renderChatMessage = (messageObject, index) => {

      switch (messageObject.role) {
        case "user":
          return (
            <div key={index} className={`${SEEChatbotStyles.chatMessage} user`}>
                {messageObject.content}
            </div>
          );  
        case "assistant":
          return (
            <div key={index} className={`${SEEChatbotStyles.chatMessage} assistant`}>
                <div className={`${SEEChatbotStyles.chatMessageContent}`}>{messageObject.content}</div>
            </div>
          );
        case "assistantGeneratedResponse":
          let qaPair = {};
          qaPair.question = findNearestUser(index);
          qaPair.answer = messageObject.content;
          let firstFewAssistantGeneratedResponseObjects = [];
          let doNotRender = false;
          messages.forEach((msg, index) => {
            if(msg.role === "assistantGeneratedResponse"){
              firstFewAssistantGeneratedResponseObjects.push(index);
            }
          });

          firstFewAssistantGeneratedResponseObjects = firstFewAssistantGeneratedResponseObjects.slice(0, 4 + requireDetailedResponseCount);
          if(exemptedQuestions.includes(qaPair.question)){
            doNotRender = true;
          }
          return (
            <div key={index} className={`${SEEChatbotStyles.chatMessage} assistant`}>
              <div className={`${SEEChatbotStyles.chatMessageContent}`}>{messageObject.content}</div>
              <SEEChatbotLearnMore
                rating={messageObject.rating} 
                setRating={(rating, isSubmitted) => setRating(messageObject, rating, isSubmitted)} 
                questionAndAnswer={qaPair} 
                renderDetailedConversationPrompt={!doNotRender && renderDetailedConversationPrompt && firstFewAssistantGeneratedResponseObjects.includes(index) && (selectedProficiencyOption === 1)}
                handleConversationStyleChange={handleConversationStyleChange}
                requireDetailedResponse={requireDetailedResponse}
              />
            </div>
          );
        case "custom":
          return (
            <div key={index}>
              {renderWidget(messageObject.widget)}
            </div>
          );
        case "status":
          return (
            <div key={index} className={`${SEEChatbotStyles.chatMessage} status`}>
              {
                messageObject.content?.toLowerCase() === "completed" ?
                  <img src={ChatbotStatusCompleted} alt="status" />
                :
                  (messageObject.content?.toLowerCase() === "aborted") || (messageObject.content?.toLowerCase() === "error") ?
                    <img src={ChatbotStatusAbort} alt="status" />
                  :
                    <img src={ChatbotStatusIcon} alt="status" />
              }
                <div>{messageObject.content}</div>
            </div>
          );
        case "assistantPrompt":
          switch (messageObject.content) {
            case "changeConversation":
              return (
                <div key={index} className={`${SEEChatbotStyles.chatMessage} assistant`}>
                  <div className={`${SEEChatbotStyles.promptSelection}`}>
                    <div>Would you like your answers to be</div>
                    <div className={`${SEEChatbotStyles.promptSelectionWrapper}`}>
                      <div className={`${widgetStyles.suggestedQuestion}`} onClick={() => handleConversationStyleChange(1)}>Concise</div>
                      <div className={`${widgetStyles.suggestedQuestion}`} onClick={() => handleConversationStyleChange(2)}>Detailed</div>
                    </div>
                  </div>
                </div>
              );
            default:
              return (
                <div></div>
              );
          }
        default:
          break;
      }
    }

    const handleStopGenerating = () => {
      if (apiRequestRef && apiRequestRef.current) {
        apiRequestRef.current.abort();
      }
      setGeneratingResponse(false);
      shouldCancelRef.current = true;

      let chatMessages = messages.filter(messageObj => messageObj.role !== "status");

      setMessages([...chatMessages, 
        {role: "status", content: "Aborted", widget: null},
        {role: "assistant", content: "Stopped response generation", widget: null}]);
    };

    const generateSuggestedQuestions = async (node1, node2, lang) => {
      const questionsApiUrl = `${process.env.REACT_APP_SUGGESTED_QUESTIONS_API}?code=${process.env.REACT_APP_SUGGESTED_QUESTION_API_CODE}`;
      const fetchQuestionsAPIResponse = await fetch(questionsApiUrl, {
        method: "POST",
        body: JSON.stringify({
          'context': node1 + "\n" + node2,
          'language': lang,
        }),
        headers: {
          "Content-Type": "application/json",
        },
      });
      const fetchQuestionsAPIdata = await fetchQuestionsAPIResponse.json();
      let questionsArray = [
        fetchQuestionsAPIdata[0]["Question 1"],
        fetchQuestionsAPIdata[0]["Question 2"],
        fetchQuestionsAPIdata[0]["Question 3"],
      ];
      setGeneratedQuestions([...questionsArray]);
    }

    const logChatToDatabase = (data) => {
      const logApiUrl = `${process.env.REACT_APP_SEE_CHAT_LOG_API}?code=${process.env.REACT_APP_API_CODE}`;
      fetch(logApiUrl, {
        method: "POST",
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
        },
      }).then(response => response.json())
      .catch(error => console.log(error))
    }

    const fetchResponseFromGPTAsStreamUsingClient = async (chatMessages, systemContext, userQuestion, generateDetailedAnswer) => {

      try {

        let apiMessages = [];
        let responseMessage = "";
        let firstIteration = true;

        const newStatusMessage = {
          role: "status",
          content: "Generating answers for you...",
          widget: null,
        }
        setMessages(prevMessages => [...prevMessages, newStatusMessage]);

        const systemMessage = {
          role: "system",
          content: `You are an AI assistant that answers people's questions on the Microsoft Surface Emissions Estimator calculator website.
          - Provide a response to the question using the provided context, the conversation history and the data you were trained on.
          - Present the response in a structured format. Keep paragraphs VERY SHORT and make the response look attractive.
          The context is delimited by curly brackets.

          {
          Result - '${systemContext[0]}', 
          '${systemContext[1]}', 
          '${systemContext[2]}',
          }
          `
        };

        const sampleConversation = [
          {
            role: "user",
            content: "In-Context question"
          },
          {
            role: "assistant",
            content: "Assistant should answer"
          },
          {
            role: "user",
            content: "Out of context question"
          },
          {
            role: "assistant",
            content: "Assistant should not answer"
          },
        ];
    
        chatMessages.forEach((messageObj) => {
          if(messageObj.role === "user" || messageObj.role === "assistantGeneratedResponse"){
            apiMessages.push({
              role: messageObj.role === "user" ? "user" : "assistant",
              content: messageObj.content
            });
          }
        });

        apiMessages = [systemMessage, ...sampleConversation, ...apiMessages];

        let latestQuestion = apiMessages.pop();
        latestQuestion.content = `Question: "${latestQuestion.content} ${generateDetailedAnswer ? "(Add detail in the response)" : selectedProficiencyOption === 2 ? "(Add detail in the response)" : "(Please provide short, extremely concise and straightforward responses to keep the reader engaged)"}"`;
        apiMessages.push(latestQuestion);
      
        const client = new OpenAIClient(process.env.REACT_APP_AZURE_OPEN_AI_URL, new AzureKeyCredential(process.env.REACT_APP_AZURE_OPEN_AI_CODE));
        const deploymentId = "gpt-35-turbo-tco-chatbot-6-19-2023";

        if(shouldCancelRef.current){
          shouldCancelRef.current = false;
          return;
        }
    
       const response = await client.getChatCompletions(
        deploymentId,
        apiMessages,
        {
          temperature: 0.5,
          maxTokens: 512,
          topP: 0.95,
          frequencyPenalty: 0,
          presencePenalty: 0,
        }
      );

      const choices = response.choices || [];
      for (const choice of choices) {
        if (shouldCancelRef.current) {
          shouldCancelRef.current = false;
          return;
        }

        responseMessage += choice.message?.content
          ? choice.message?.content
          : "";

        if (responseMessage && firstIteration) {
          setMessages((currentChatMessages) => [
            ...currentChatMessages,
            {
              role: "assistantGeneratedResponse",
              content: responseMessage,
              widget: null,
            },
          ]);
          firstIteration = false;
        } else if (responseMessage) {
          setMessages((currentChatMessages) => [
            ...currentChatMessages.slice(0, -1),
            {
              role: "assistantGeneratedResponse",
              content: responseMessage,
              widget: null,
            },
          ]);
        }
      }

    
        setGeneratingResponse(false);
        shouldCancelRef.current = false;


        setMessages([...chatMessages.filter(message => message.widget !== "suggestedQuestions"), 
          {role: "status", content: "Completed", widget: null},
          {role: "assistantGeneratedResponse", content: responseMessage, widget: null},
          {role: "custom", content: "", widget: "suggestedQuestions"},
        ]);

        if(process.env.REACT_APP_BRANCH === "staging"){
          logChatToDatabase({question: userQuestion, answer: responseMessage});
        }
        // listChatCompletions doesn't return token count
        // setChatTokens(currentTokens => currentTokens + events.usage.completionTokens);
        // setTotalTokens(events.usage.totalTokens);
      } catch(error){
        setGeneratingResponse(false);
        shouldCancelRef.current = false;
        setMessages([...chatMessages.filter(message => message.widget !== "suggestedQuestions"), 
            {role: "status", content: "Error", widget: null},
            {role: "assistant", content: "Something went wrong. Please try again.", widget: null},
            {role: "custom", content: "", widget: "suggestedQuestions"},
        ]);
      }

    }

    const getRefProductName = (key) => {
      return productRefOptions.filter(item => item.key === key)[0].text;
    }

    const fetchCustomContextFromPythonAPI = async (latestMessage, chatMessages, generateDetailedAnswer) => {

      try {
        const abortController = new AbortController();
        apiRequestRef.current = abortController;


        const list = productList.filter((i) => i.product);
    // if (!list.length) return;
    const deviceMixPortfolio = list.map((item, index) => {
      return {
        seqNo: index + 1,
        productId: item.product.key,
        product: getRefProductName(item.product.key),
        countryCode: item.location.key,
        country: item.location.text,
        quantity: convertStringToNumber(item.quantity),
        years: item.years? Number(item.years.key) : 3,
      };
    });

        let requestBody = JSON.stringify({
          "response_string": {value: reportData},
          "request_string": {deviceMixPortfolio: deviceMixPortfolio},
          "query_text": latestMessage,
        });

        const contextApiUrl = `${process.env.REACT_APP_SEE_REPORT_API}?code=${process.env.REACT_APP_SEE_REPORT_API_CODE}`;

        const fetchContextAPIResponse = await fetch(contextApiUrl, {
          method: "POST",
          body: requestBody,
          signal: abortController.signal,
          headers: {
            "Content-Type": "application/json",
          },
        });

        const fetchContextAPIdata = await fetchContextAPIResponse.json();

        let combinedMessage = "";
        fetchContextAPIdata.forEach((item) => {
          combinedMessage += item.replace("\n", ". ");
        });
        apiRequestRef.current = null;
        if(fetchContextAPIdata.length < 1){
          setGeneratingResponse(false);
          setMessages([...chatMessages.filter(message => message.widget !== "suggestedQuestions"), 
            {role: "status", content: "Completed", widget: null},
            {role: "assistantGeneratedResponse", content: "As an AI assitant for this website, my knowledge base is limited to the SEE domain. Therefore, I can not answer this question. I can help you with questions about \n1. Report Values \n2. This website \n3. AI Assistant", widget: null},
          ]);
        } else {
          fetchResponseFromGPTAsStreamUsingClient(chatMessages, fetchContextAPIdata, latestMessage, generateDetailedAnswer);
          generateSuggestedQuestions(fetchContextAPIdata[1], fetchContextAPIdata[2], fetchContextAPIdata[5]);
        }
      } catch(error){
        setGeneratingResponse(false);
        shouldCancelRef.current = false;
        apiRequestRef.current = null;
        setMessages([...chatMessages.filter(message => message.widget !== "suggestedQuestions"), 
            {role: "status", content: "Error", widget: null},
            {role: "assistant", content: "Something went wrong. Please try again.", widget: null},
        ]);
      }
    }
    
    const handleUserMessage = async (message, generateDetailedAnswer) => {
      if(!message || (message && !message.trim())){
        return;
      }
      setGeneratedQuestions([]);
      let messagesWithoutStatus = messages.filter(messageObj => !(messageObj.role === "status" || messageObj.widget === "suggestedQuestions"));
      const newMessage = {
        role: "user",
        content: message,
        widget: null,
      };
      const newStatusMessage = {
        role: "status",
        content: `Searching for: ${message}`,
        widget: null,
      }
      const newMessages = [...messagesWithoutStatus, newMessage];
      setMessages([...messagesWithoutStatus, newMessage, newStatusMessage]);
      setGeneratingResponse(true);
      shouldCancelRef.current = false;
      fetchCustomContextFromPythonAPI(message, newMessages, generateDetailedAnswer);
    }

    const handleTextAreaClick = () => {
      setExpandedInput(true);
    };
  
    const handleDocumentClick = (event) => {
      const clickedInsideTextArea = event.target.closest('.ms-TextField-field');
      if (!clickedInsideTextArea) {
        setExpandedInput(false);
      }
    };
    
    const handleOptionsMenuItemClick = (key) => {
      switch (key) {
        case "item2":
          setMessages([
            ...messages.filter(message => message.widget !== "suggestedQuestions"), 
            {role: "assistantPrompt", content: "changeConversation"},
            {role: "custom", content: "", widget: "suggestedQuestions"},
          ]);
          break;
        default:
          break;
      }
      onDismissOptionsMenu();
    }

    const onOptionsButtonClick = (event) => {
      const currentState = isOptionsMenuVisible;
      setIsOptionsMenuVisible(prevState => !prevState);
      if(currentState){
        setOptionsMenuTargetElement(null);
      } else {
        setOptionsMenuTargetElement(event.currentTarget);
      }
    };

    const onDismissOptionsMenu = () => {
      setIsOptionsMenuVisible(false);
      setOptionsMenuTargetElement(null);
    };

    const onRenderOptionsMenuList = (menuListProps) => {
      return (
        <div>
          {menuListProps.items.map((item) => (
            <div key={item.key} className={SEEChatbotStyles.optionsMenuItem} onClick={() => handleOptionsMenuItemClick(item.key)}>
              <img src={item.iconName} alt="i" />
              <span>{item.text}</span>
            </div>
          ))}
        </div>
      );
    };

    const handleClearChat = () => {
      setMessages([...starterChat]);
      setGeneratedQuestions([]);
      setChatTokens(0);
      setTotalTokens(0);
      let matchesFound = messages.filter(message => message.role === "assistantGeneratedResponse");
      if(matchesFound.length >= 4){
        setRenderDetailedConversationPrompt(false);
      }
      setExemptedQuestions([]);
    }
    

  return (
    <div className={`${SEEChatbotStyles.container}`}>
      <div className={`${SEEChatbotStyles.header} chatbot-header`}>
        <div className={`chatbot-flex ${SEEChatbotStyles.headerOptionsWrapper}`}>
          <img src={ChatbotOptionsIcon} alt="options" onClick={onOptionsButtonClick} className={`${isOptionsMenuVisible ? SEEChatbotStyles.optionsIconBackground : ''}`} />
          <ContextualMenu
            items={optionsMenuItems}
            hidden={!isOptionsMenuVisible}
            target={optionsMenuTargetElement}
            onItemClick={onDismissOptionsMenu}
            onDismiss={onDismissOptionsMenu}
            onRenderMenuList={onRenderOptionsMenuList}
            directionalHint={2}
          />
          <img src={ChatbotClose} alt="close" onClick={toggleChatbotWindow} />
        </div>
      </div>
      <div className={`${SEEChatbotStyles.innerContainer}`}>
        <div className={`${expandedInput ? SEEChatbotStyles.messagesContainerReduced : SEEChatbotStyles.messagesContainer} ${expandedInput ? "chatbot-messages-reduced" : "chatbot-messages"}`}>
            {/* <div ref={messagesStartRef} /> */}
            {messages.map((message, index) => renderChatMessage(message, index))}
            {/* <div className={`${SEEChatbotStyles.scollUpDownContainer}`}>
              <img src={ChatbotUpArrow} alt="Up" onClick={() => messagesStartRef.current.scrollIntoView({behavior: "smooth"}) } />
              <img src={ChatbotDownArrow} alt="D" onClick={() => messagesEndRef.current.scrollIntoView({behavior: "smooth"}) }/>
            </div> */}
            <div ref={messagesEndRef} />
        </div>
        <div className={`${expandedInput ? SEEChatbotStyles.footerExpanded : SEEChatbotStyles.footer} ${expandedInput ? "footer-container" : "footer-container-reduced"}`}>
          {
            generatingResponse ?
              <div className={SEEChatbotStyles.stopButton} onClick={handleStopGenerating}> <img src={ChatbotStop} alt="stop" /> &nbsp; Stop Responding</div>
            :
              <div className={SEEChatbotStyles.horizontalLine}></div>
          }
          {/* DO NOT REMOVE BELOW DIV */}
          <div>
            <div className={`${expandedInput ? SEEChatbotStyles.footerWrapperExpanded : SEEChatbotStyles.footerWrapper} input-area ${expandedInput ? 'input-area-expanded' : ''}`}>
              {
                expandedInput ? 
                  <></> 
                : 
                  <img title='Clear Chat' src={ChatbotBroom} alt="clear chat" onClick={handleClearChat} className={SEEChatbotStyles.broomImage} />
              }
              <TextField 
                placeholder="Ask me anything..." 
                value={userInputText}
                // onBlur={() => setExpandedInput(false)} 
                onKeyDown={(event) => {
                  if (event.key === 'Enter') {
                    event.preventDefault();
                    handleUserMessage(event.target.value);
                    // event.target.value = '';
                    setUserInputText('');
                    // setExpandedInput(false);
                  }
                }}
                // onClick={() => setExpandedInput(true)}
                onClick={handleTextAreaClick}
                onChange={(event) => setUserInputText(event.target.value)}
                maxLength={1000}
                multiline={expandedInput ? true : false}
                rows={1} 
                resizable={false} 
                className={`${SEEChatbotStyles.inputBox} ${expandedInput ? 'expanded' : ''}`}
                suffix={<img src={ChatbotSend} alt="send" className={SEEChatbotStyles.sendButton} 
                onClick={() => {
                  handleUserMessage(userInputText);
                  setUserInputText('');
                }}/>}
              />
            </div>
            {expandedInput ? 
              <div className={`${SEEChatbotStyles.inputCharCount}`}>{userInputText.length}/1000</div> 
            : 
              <></>
            }
          </div>
          <div className={`${SEEChatbotStyles.footerDisclaimer} footer-disclaimer`}>Assistance &nbsp; <img src={ChatbotPower} alt="power" /> &nbsp; by GPT-3.5 Turbo &nbsp; AI generated content may be incomplete or factually incorrect.</div>
        </div>
      </div>
    </div>
  );
};

export default SEEChatbot;