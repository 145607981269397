import { FontIcon, mergeStyleSets } from "@fluentui/react";
import { useState, useEffect } from "react";

const Accordion = (props) => {
  const {
    title,
    children,
    className,
    headerClassName,
    isActive,
    handleChange,
    defaultOpen,
  } = props;
  const [_isOpen, setIsOpen] = useState(defaultOpen || false);

  useEffect(() => {
    if (isActive !== undefined) {
      setIsOpen(isActive);
    }
  }, [isActive]);

  const accordionStyles = mergeStyleSets({
    container: {
      marginBottom: "1.625rem",
    },
    headerWrapper: {
      display: "flex",
      alignItems: "center",
      cursor: "pointer",
      justifyContent: "space-between",
    },
    title: {
      color: "#0078D4",
      fontSize: "1.1rem",
      lineHeight: "1.25rem",
    },
    icon: {
      fontSize: "0.75rem",
      marginLeft: "0.5rem",
      color: "#0078D4",
    },
  });

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      setIsOpen(!_isOpen);
      if (handleChange) {
        handleChange(!_isOpen);
      }
    }
  };

  const handleClick = (val) => {
    setIsOpen(val);
    if (handleChange) {
      handleChange(val);
    }
  }

  return (
    <div
      className={`${accordionStyles.container} ${
        className ? className : ""
      } accordion-wrapper`}
    >
      <div
        className={`${accordionStyles.headerWrapper} ${
          headerClassName ? headerClassName : ""
        } accordion-header`}
        onClick={() => handleClick(!_isOpen)}
        tabIndex={0}
        onKeyDown={handleKeyDown}
      >
        <span className={accordionStyles.title}>{title}</span>
        <FontIcon
          className={accordionStyles.icon}
          iconName={_isOpen ? "ChevronUp" : "ChevronDown"}
        />
      </div>
      {_isOpen ? children : null}
    </div>
  );
};

export default Accordion;