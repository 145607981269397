import { v4 as uuid } from "uuid";
import { Stack, FontIcon, mergeStyleSets, DirectionalHint, FocusTrapCallout, Callout, Link, Text, FontWeights } from "@fluentui/react";
import { useBoolean, useId } from '@fluentui/react-hooks';
import { DefaultButton } from '@fluentui/react/lib/Button';
import laptopIcon from "assets/laptop.svg";
import "./SelectionBoxListTCO.scss";
import { useMemo, useState } from "react";
import { useForm } from "../../components/CommonForm/formHooks";
import CommonForm from "../../components/CommonForm/CommonForm";
import { useEffect } from "react";

const SelectionBoxListTCO = (props) => {
  const {
    title,
    selectionName,
    max = Number.MAX_SAFE_INTEGER,
    min = 0,
    list,
    updateList,
    boxContent,
    onAdd,
    onDelete,
    icon,
    message,
    config,
    selectedCurrency,
    setUserInput,
    currencyList,
    setCurrencyList,
    retainedRegionData, 
    setRetainedRegionData
  } = props;

  const [selectedRegionName, setSelectedRegionName] = useState("selected region");

  const [isCalloutVisible, { toggle: toggleIsCalloutVisible }] = useBoolean(false);
  const regionId = useId('callout-region');
  const labelId = useId('callout-label');
  const descriptionId = useId('callout-description');

  useEffect(() => {
    if((sessionStorage.getItem("shouldDoConversion")!==sessionStorage.getItem("selectedCurrency")) && selectedCurrency && list[0]["product"]){
      const matchedCurrentCurrency = config.currencies?.find((i) => i.key === selectedCurrency);
      const updatedList = list.map(i => {
        const msrpOfMatchedProduct = config.msrp.find((j) => j.key === i.productCode);
        if(msrpOfMatchedProduct){
          return {
            ...i,
            msrp: msrpOfMatchedProduct.text * matchedCurrentCurrency["fromUSD"],
          }
        } else {
          return i;
        }
      })
      updateList(updatedList);
    } else {
      sessionStorage.setItem("returningToLandingPage", false);
    }
    sessionStorage.setItem("shouldDoConversion", sessionStorage.getItem("selectedCurrency"));
  },[selectedCurrency]);

  const handleAdd = () => {
    let updatedList = [...list];
    updatedList.push({
      id: uuid(),
    });   
    if (onAdd) {
      if(onAdd(updatedList))
      {
        updateList(updatedList);
      }
    } else {
      updateList(updatedList);
    }
  };

  const handleDelete = (id) => {
    const updatedList = list.filter((i) => i.id !== id);
    updateList(updatedList);
    if (onDelete) {
      onDelete(updatedList, id);
    }
  };

  const ItemSelectionBoxStyles = mergeStyleSets({
    container: {
      width: "100%",
      display: "flex",
    },
    wrapper: {
      width: "97%",
      "> div": {
        display: "flex",
      },
    },
    titleWrapper: {
      justifyContent: "space-between",
      alignItems: "center",
      padding: "1.3281rem 1.1875rem",
      borderBottom: "0.0313rem solid #EDEBE9",
    },
    titleNameWrapper: {
      alignItems: "center",
    },
    itemTitleIcon: {
      marginRight: "0.625rem",
      alignSelf: "center",
      width: "1.25rem",
      height: "1.25rem",
    },
    title: {
      fontSize: "1rem",
      fontWeight: 600,
      lineHeight: "1rem",
      margin: 0,
    },
    deleteButton: {
      cursor: "pointer",
      color: "#212121",
      fontSize: "1rem",
      alignSelf: "center",
      margin: "auto",
    },
  });

  const ItemSelectionBox = (item, index) => (
    <div className={`${ItemSelectionBoxStyles.container}`} key={item.id}>
      {boxContent(item)}
    </div>
  );

  const selectionBoxListStyles = mergeStyleSets({
    selectionBoxListWrapper: {
      width: "100%",
      maxWidth: "60rem",
    },
    titleWrapper: {
      marginBottom: "1.375rem",
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
    },
    titleIcon: {
      marginRight: "1.0625rem",
      width: "1.25rem",
      height: "1.25rem",
    },
    title: {
      fontSize: "1.25rem",
      fontWeight: 600,
      lineHeight: "2.4375rem",
      color: "#201F1E",
      margin: 0,
    },
    maxMsg: {
      fontSize: "1.25rem",
      lineHeight: "1.5rem",
      marginBottom: "2rem",
    },
    contentWrapper: {
      width: "100%",
      //gap: "1.5rem",
      display: "block",
      //gridTemplateColumns: "repeat(2, minmax(0, 1fr))",
      boxShadow: "0px 3.2px 7.2px rgba(0, 0, 0, 0.132), 0px 0.6px 1.8px rgba(0, 0, 0, 0.108)",
      borderRadius: "6px",
      padding: "2rem 2.5rem 2rem",
      boxSizing: "border-box",
    },
    addBox: {
      border: "0.0625rem solid #E1DFDD",
      borderRadius: "0.1rem",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      padding: "5px 0px",
      cursor: "pointer",
      marginBottom: "0 !important",
      width: "97%"
    },
    addButton: {
      cursor: "pointer",
      fontSize: "2.5rem",
      lineHeight: "2rem",
      color: "#212121",
    },
    commonDropdown: {
      width: "100%",
      // ">div": {
      //   display: "flex",
      //   gap: '1rem',
      // }
    },
  });

  const fieldStyles = mergeStyleSets({
    regionSelection: {
      width: "100%",
      marginBottom: "1rem",
      ">div": {
        ">span:first-child": {
          borderBottom: "2px solid #75757A",
          fontWeight: 600,
        }
      }
    },
    currencySelection: {
      width: "100%",
      marginBottom: "1rem",
      ">div": {
        ">span:first-child": {
          borderBottom: "2px solid #75757A",
          fontWeight: 600,
        }
      }
    },
  });

  const handleRegionChange = (val) => {
    const region = val.region;
    if(region !== 'US'){
      toggleIsCalloutVisible();
    }

    const matchedCurrencyList = config.currencies?.filter((i) => i.countryCode === region);
    setCurrencyList(matchedCurrencyList);

    let countries = config.countries;
    countries = countries.filter(i => i.key === region);
    setSelectedRegionName(countries[0]["text"]);

    setRetainedRegionData((state) => {
      return {
        ...state,
        region: region,
      }
    });

    handleCurrencyChange({currency: matchedCurrencyList[0]["text"]});
  }

  const handleCurrencyChange = (val) => {
    const currency = val.currency;
    sessionStorage.setItem("previousCurrency", sessionStorage.getItem("selectedCurrency"));
    sessionStorage.setItem("selectedCurrency", currency);

    const matchedCurrency = config.currencies.find((i) => i.key === currency);

    setUserInput((state) => {
      return {
        ...state,
        currencyConversionMultipleToUSD: matchedCurrency["toUSD"],
        currencyConversionMultipleFromUSD: matchedCurrency["fromUSD"],
      };
    });

    setRetainedRegionData((state) => {
      return {
        ...state,
        currency: currency,
      }
    })

  }

  const boxFields = useMemo(
    () => [
      {
        id: "region",
        type: "dropdown",
        placeholder: "Select your Region",
        className: fieldStyles.regionSelection,
        options: config.countries,
        onChange: (event, val) => handleRegionChange(val),
      },
      {
        id: "currency",
        type: "dropdown",
        placeholder: "Currency",
        className: fieldStyles.currencySelection,
        options: currencyList,
        // selectedKey: null,
        onChange: (event, val) => handleCurrencyChange(val),
      },
    ],
    [config, currencyList]
  );

  const form = useForm(
    boxFields,
    retainedRegionData
  );

  const styles = mergeStyleSets({
    button: {
      width: 130,
    },
    callout: {
      // position: "absolute !important",
      width: 320,
      maxWidth: '90%',
      padding: '20px 24px',
      // top: "1rem",
      // left: "1rem"
    },
    title: {
      marginBottom: 12,
      color: "#201F1E",
      fontWeight: 400,
    },
    body: {
      color: "#201F1E",
      fontSize: "0.8rem",
      fontWeight: 400,
      lineHeight: "1.1rem",
    },
    footer: {
      color: "#201F1E",
      fontSize: "0.8rem",
      fontWeight: 400,
      lineHeight: "1.1rem",
      marginTop: "0.7rem",
    },
    link: {
      display: 'block',
      marginTop: 20,
      color: "#0078D4",
      cursor: "pointer",
      fontWeight: 400
    },
  });

  return (
    <div className={`${selectionBoxListStyles.selectionBoxListWrapper} selection-box-list-wrapper`}>
      <div
        className={`${selectionBoxListStyles.titleWrapper} selection-box-title-wrapper`}
      >
        <h2 className={selectionBoxListStyles.title} tabIndex={0}>
          {title}
        </h2>
      </div>
      {message ? (
        <div className={`${selectionBoxListStyles.maxMsg} maximum-message`}>
          {message}
        </div>
      ) : null}
      <div
        className={`${selectionBoxListStyles.contentWrapper} selection-box-content-wrapper`}
      >
        <div id={regionId} className={`${selectionBoxListStyles.commonDropdown} selection-box-common-dropdown`}>
          <CommonForm form={form}/>
          {isCalloutVisible && (
            <Callout
              className={styles.callout}
              ariaLabelledBy={labelId}
              ariaDescribedBy={descriptionId}
              role="dialog"
              gapSpace={0}
              target={`#${regionId}`}
              onDismiss={toggleIsCalloutVisible}
              // setInitialFocus
              directionalHint={DirectionalHint.rightCenter}
            >
              <Text as="h1" block variant="xLarge" className={styles.title} id={labelId}>
                Acknowledgement
              </Text>
              <Text block variant="small" className={styles.body} id={descriptionId}>
                {`Please note that the Manufacturer Suggested Retail Price (MSRP) for ${selectedRegionName} may vary from the MSRP listed in the calculator. For the most up to date pricing for Microsoft Surface for Business, please contact your authorized reseller or website and use the edit icon to input the up to date price for your device and/or accessory of interest.`}
              </Text>
              <Text block variant="small" className={styles.footer} id={descriptionId}>
                {`Available products may vary by market.`}
              </Text>
              <Text className={styles.link} onClick={toggleIsCalloutVisible} >
                Close
              </Text>
            </Callout>
          )}
        </div>
        {list.map((item, index) => (
          <div key={item.id}>{ItemSelectionBox(item, index)}</div>
        ))}
        {/* {list.length < max ? (
          <div
            className={`${selectionBoxListStyles.addBox}`}
            onClick={handleAdd}
            onKeyDown={(event) => {
              if (event.key === "Enter") {
                handleAdd();
              }
            }}
            tabIndex={0}
          >
            <div className={selectionBoxListStyles.addButton} tabIndex={0}>
              +
            </div>
          </div>
        ) : null} */}
      </div>
    </div>
  );
}

export default SelectionBoxListTCO;