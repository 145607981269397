import { BrowserRouter, Routes, Route } from "react-router-dom";
import SustainabilityCalculator from "./pages/sustainability/SustainabilityCalculator";
import TcoCalculator from "./pages/tco/TcoCalculator";
import { AlertBanner } from "./components/Alert";
import SustainabilityLanding from "./pages/sustainability/SustainabilityLanding";
import LookupLanding from "pages/lookup/LookupLanding";
import TcoHome from "pages/tco/TcoHome";
import TcoUnderstand from "pages/tco/TcoUnderstand";
import SustainabilityHome from "pages/sustainability/SustainabilityHome";
import SustainabilityUnderstand from "pages/sustainability/SustainabilityUnderstand";
import ReactGA from 'react-ga4';
import { useEffect } from "react";
import CookieConsent, { getCookieConsentValue, Cookies } from "react-cookie-consent";
import ChatDownload from "pages/ChatDownload";
 

function App() {

  const handleAcceptCookie = () => {
    ReactGA.initialize(process.env.REACT_APP_GA_TRACKING_ID);
  };

  const handleDeclineCookie = () => {
    //remove google analytics cookies
    Cookies.remove("_ga");
    // Cookies.remove("_gat");
    Cookies.remove("_gid");
  };

  useEffect(() => {
    const isConsent = getCookieConsentValue();
    if (isConsent === "true") {
      handleAcceptCookie();
    }
  }, []);

  const updateTracking = (path, title) => {
    ReactGA.send({ hitType: "pageview", page: path, title: title });
  }
  

  return (
    <div className="App">
      <AlertBanner />
      <div className="content-wrapper">
        <BrowserRouter>
          <Routes>
            {/* <Route path="/" element={<TcoCalculator />} /> */}
            <Route path="/" element={<TcoHome />} Component={updateTracking("/", "TCO Landing Page")} />
            <Route path="/idc" element={<TcoUnderstand />} Component={updateTracking("/idc", "TCO Understanding Page")} />
            <Route path="/calculator" element={<TcoCalculator />} Component={updateTracking("/calculator", "TCO Calculator Page")} />
            <Route path="/sustainability" element={<SustainabilityHome />} Component={updateTracking("/sustainability", "Sustainability Landing Page")} />
            <Route path="/sustainability/introduction" element={<SustainabilityUnderstand />} Component={updateTracking("/sustainability/introduction", "Sustainability Understanding Page")} />
            <Route path="/sustainability/calculator" element={<SustainabilityCalculator />} Component={updateTracking("/sustainability/calculator", "Sustainability Calculator Page")} />
            {process.env.REACT_APP_BRANCH === "dev" ? 
              <Route path="/download/chat" element={<ChatDownload />} />
            :
              null
            }
            {/* <Route path="/db" element={<LookupLanding />} /> */}
          </Routes>
        </BrowserRouter>
      </div>
      <CookieConsent
        enableDeclineButton
        flipButtons
        style={{background: "#e3e3e3", color: "#201F1E", }}
        buttonStyle={{ background: "#0078d4", color: "#fff", padding: "0.5rem 1.5rem", border: "1px solid #0078d4", fontWeight: "500" }}
        declineButtonStyle={{ background: "#fff", color: "#0078d4", border: "1px solid #0078d4", padding: "0.5rem 1.5rem", fontWeight: "600" }}
        onAccept={handleAcceptCookie}
        onDecline={handleDeclineCookie}
        buttonText="Accept"
        declineButtonText="Reject"
      >
        We use optional cookies to improve your experience on our websites, such as through social media connections and to display personalized advertising based on your online activity. This site does not use optional cookies for social media connections and personalized advertising purposes. If you reject optional cookies, only cookies necessary to provide you the services will be used.
      </CookieConsent>
    </div>
  );
}

export default App;
