import React from 'react';
import ReactDOM from 'react-dom/client';
import './styles.scss';
import App from './App';
import { initializeIcons } from "@fluentui/font-icons-mdl2";
import { AlertProvider } from "./components/Alert";
import { Cookies } from 'react-cookie';

const root = ReactDOM.createRoot(document.getElementById('root'));
const cookies = new Cookies();
cookies.set('StaticWebAppsBasicAuthCookie', "p0znftUQcY2aUksuYry+wvE4fc+H0e13xot5o+M8kiYEtiLJgyTkuvvfCVgbud5y7wA7qO+ui97eLkGLzLrWHdSfmw5BnBju1lTU/2L50KPady2nlF3h3LzPLB3fqgWZ59Np+NCANaeRMaypFsFnV27OF3Op5l66PhyLVTBHIgVOAtWF5cvulRCO8bW3qR3KjcNxAnjwazttQq1IxLPpfgovWVCCH7Qc+hIArKk4eH4u4SxFVOwOelreFeOfXrkq", {secure: true, sameSite: 'none'});
root.render(
  <React.StrictMode>
    <AlertProvider>
      <App />
    </AlertProvider>
  </React.StrictMode>
);

initializeIcons();
