import React, { useEffect, useRef, useState } from 'react';
import { Callout, ContextualMenu, Dialog, DialogType, DirectionalHint, FontIcon, Modal, PrimaryButton, Stack, Text, TextField, TooltipHost, mergeStyleSets } from '@fluentui/react';
import { useId, useBoolean } from '@fluentui/react-hooks';

import ChatbotBack from "assets/chatbot-back.svg";
import ChatbotOptionsIcon from "assets/chatbot-options.svg";
import ChatbotClose from "assets/chatbot-close.svg";
import ChatbotBroom from "assets/chatbot-broom.svg";
import ChatbotStatusIcon from "assets/chatbot-tick.svg";
import ChatbotStatusCompleted from "assets/chatbot-status-completed.svg";
import ChatbotStatusAbort from "assets/chatbot-status-abort.svg";
import ChatbotSend from "assets/chatbot-send.svg";
import ChatbotPower from "assets/chatbot-power.svg";
import ChatbotOptionsAbout from "assets/chatbot-options-about.svg";
import ChatbotOptionsPrivacy from "assets/chatbot-options-privacy.svg";
import ChatbotOptionsTerms from "assets/chatbot-options-terms.svg";
import ChatbotOptionsFeedback from "assets/chatbot-options-feedback.svg";
import ChatbotOptionsConversation from "assets/chatbot-options-conversation.svg";
import ChatbotStop from "assets/chatbot-stop.svg";
import ChatbotUpArrow from "assets/chatbot-up-arrow.png";
import ChatbotDownArrow from "assets/chatbot-down-arrow.png";
import CurrencySymbols from "assets/CurrencySymbols.json";
import _ from "lodash";

import './Chatbot.scss';
import { AzureKeyCredential, OpenAIClient } from '@azure/openai';
import ChatbotLearnMore from './ChatbotLearnMore';
import { convertStringToNumber, sendRequest, trackGAEvent } from 'utils/helpers';
import { CURRENCY } from 'constants/tco';

const Chatbot = (props) => {
    
    // const headerHeight = document.querySelector("header").offsetHeight;

    const {toggleChatbotWindow, userInput, activeViewForChatbot, currencySymbol, setOpenFullReport} = props;

    const conversationStyleCalloutId = useId('conversation-style-id');
    const reportCalloutId = useId('report-id');

    const [isReportCalloutVisible, { toggle: toggleIsReportCalloutVisible }] = useBoolean(false);
    const [isConversationCalloutVisible, { toggle: toggleIsConversationCalloutVisible }] = useBoolean(false);


    const [selectedProficiencyOption, setSelectedProficiencyOption] = useState(1);
    const [selectedQueryOption, setSelectedQueryOption] = useState(1);
    const [expandedInput, setExpandedInput] = useState(false);
    const [userInputText, setUserInputText] = useState("");
    const [isOptionsMenuVisible, setIsOptionsMenuVisible] = useState(false);
    const [optionsMenuTargetElement, setOptionsMenuTargetElement] = useState(null);
    const [generatingResponse, setGeneratingResponse] = useState(false);
    const [chatTokens, setChatTokens] = useState(0);
    const [totalTokens, setTotalTokens] = useState(0);
    const [generatedQuestions, setGeneratedQuestions] = useState([]);
    const [renderDetailedConversationPrompt, setRenderDetailedConversationPrompt] = useState(true);
    const [requireDetailedResponseCount, setRequireDetailedResponseCount] = useState(0);
    const [exemptedQuestions, setExemptedQuestions] = useState([]);

    const shouldCancelRef = useRef(false);
    const messagesStartRef = useRef(null);
    const messagesEndRef = useRef(null);
    const apiRequestRef = useRef(null);

    const starterChat = [
      {
        role: "custom",
        content: "",
        widget: "welcomeText",
      },
      // {
      //   role: "custom",
      //   content: "",
      //   widget: "proficiencyOptions",
      // },
      {
        role: "custom",
        content: "",
        widget: "queryOptions",
      },
      {
        role: "custom",
        content: "",
        widget: "horizontalLine",
      },
      {
        role: "assistant",
        content: `${selectedQueryOption === 1 ? "Hey, I can help you understand your results!" : "Hey, I can help you understand the findings of the IDC whitepaper!"}`
      },
    ];

    const [messages, setMessages] = useState([
      {
        role: "custom",
        content: "",
        widget: "welcomeText",
      },
      // {
      //   role: "custom",
      //   content: "",
      //   widget: "proficiencyOptions",
      // },
      {
        role: "custom",
        content: "",
        widget: "queryOptions",
      },
      {
        role: "custom",
        content: "",
        widget: "horizontalLine",
      },
      {
        role: "assistant",
        content: `${selectedQueryOption === 1 ? "Hey, I can help you understand your results!" : "Hey, I can help you understand the findings of the IDC whitepaper!"}`
      },
    ]);

    const optionsMenuItems = [
      // { key: 'item1', text: 'About', iconName: ChatbotOptionsAbout },
      { key: 'item2', text: 'Change Report', iconName: ChatbotOptionsConversation },
      { key: 'item3', text: 'Change Conversation Style', iconName: ChatbotOptionsConversation },
    ];

    useEffect(() => {
      messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }, [messages]);

    useEffect(() => {
      document.addEventListener('click', handleDocumentClick);
      return () => {
        document.removeEventListener('click', handleDocumentClick);
      };
    }, []);

    useEffect(() => {
      if(!generatingResponse && (generatedQuestions.length > 0)){
        let newMessages = messages.filter(message => message.widget !== "suggestedQuestions")
        setMessages([...newMessages, {role: "custom", content: "", widget: "suggestedQuestions"}]);
      }
    }, [generatedQuestions]);

    const widgetStyles = mergeStyleSets({
      welcomeTextContainer: {
        width: "90%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
        margin: "1.5rem auto 1rem",
        color: "#7F7D7B",
      },
      welcomeTextMainText: {
        fontSize: "1.5rem",
        fontWeight: 700,
      },
      previewText: {
        fontSize: "0.8rem",
        fontWeight: 400,
        padding: "0 0.5rem",
        border: "1px solid #0078d4",
        borderRadius: "0.3rem",
        // background: "#0078d4",
        // color: "white",
        color: "#0078d4",
        position: "relative",
        top: "-0.2rem",
      },
      welcomeTextSubText: {
        width: "78%",
        fontSize: "0.89rem",
        fontWeight: 600,
        paddingTop: "0.7rem",
      },
      proficiencyOptionsContainer: {
        width: "75%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        margin: "1rem auto 0",
        color: "#7F7D7B",
      },
      proficiencyOptionsText: {
        fontSize: "0.9rem",
        fontWeight: 600,
      },
      proficiencyOptionsBox: {
        width: "90%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        margin: "0.7rem 0 0",
        borderRadius: "5px",
        border: "1px solid #D0D0D0",
        background: "#FFF",
      },
      proficiencyOptionsBoxWrapper: {
        width: "100%",
        display: "flex",
        alignItems: "center",
        // justifyContent: "space-between",
      },
      proficiencyOption: {
        flex: 1,
        padding: "0.5rem 0.7rem",
        margin: "0.3rem",
        textAlign: 'center',
        borderRadius: "5px",
        cursor: 'pointer',
        selectors: {
          '&.active': {
            background: "#0078D4",
            color: 'white',
          },
        },
      },
      horizontalLine: {
        width: "80%",
        margin: "1.5rem auto",
        border: "0.5px solid #E3E3E3",
      },
      suggestedQuestionsWrapper: {
        display: "flex",
        flexWrap: "wrap",
        margin: "3rem 0 0 1rem",
        // maxWidth: "90%",
      },
      suggestedQuestion: {
        borderRadius: "5px",
        border: "1px solid #64BAF6",
        background: "#FFF",
        margin: "0 0.5rem 0.5rem 0",
        color: "#64BAF6",
        padding: "0.2rem 0.4rem",
        fontSize: "0.9rem",
        ":hover": {
          cursor: "pointer",
          background: "#0078D4",
          border: "1px solid #0078D4",
          color: "#FFF",
        }
      },
      reportChangeDisclaimer: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        textAlign: "center",
        flexDirection: "column",
        color: "#7F7D7B",
        width: "80%",
        margin: "0 auto 1rem",
        fontSize: "0.89rem",
        fontWeight: 600,
        borderTop: "3px solid #E3E3E3",
        paddingTop: "1rem",
        ">div:first-child": {
          fontSize: "1rem",
          marginBottom: "0.3rem",
        }
      },
      infoIcon: {
        cursor: "pointer",
        paddingLeft: "10px",
        paddingTop: "0.6rem",
      },
      calloutForReport: {
        padding: '1rem 1.5rem',
        width: 400,
        maxWidth: '60%',
        "span": {
          display: "block",
          lineHeight: "1.5rem",
        }
      }
    });

    const chatbotStyles = mergeStyleSets({
      container: {
        height: "99%",
        margin: "0.2rem",
        borderRadius: "0.43929rem",
        background: "linear-gradient(317deg, #F0F0F0 0%, #FFF 100%)",
        boxShadow: "0px 0px 1.5399999618530273px 0px rgba(0, 0, 0, 0.12), 0px 0.7699999809265137px 1.5399999618530273px 0px rgba(0, 0, 0, 0.14)",
      },
      header: {
        display: "flex",
        alignItems: "center",
        // justifyContent: "space-between",
        justifyContent: "flex-end",
        background: "#F5F5F5",
        borderRadius: "6.15px 6.15px 0px 0px",
        // height: `${headerHeight ? headerHeight + "px" : "3.85rem"}`,
        // height: "4.07143rem",
        height: "7%",
        "> div": {
          margin: "1rem 2rem",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          "img:hover": {
            cursor: "pointer",
          },
        },
      },
      headerBack: {
        img: {
          width: "1.1rem",
          height: "1.1rem",
        },
      },
      headerOptionsWrapper: {
        img: {
          marginLeft: "1rem",
          width: "1.5rem",
          height: "1.5rem",
        },
      },
      innerContainer: {
        padding: "0",
        height: "93%",
      },
      messagesContainer: {
        background: "linear-gradient(317deg, #F0F0F0 0%, #FFF 100%)",
        height: "83%",
        display: "flex",
        flexDirection: "column",
      },
      messagesContainerReduced: {
        background: "linear-gradient(317deg, #F0F0F0 0%, #FFF 100%)",
        height: "75%",
        display: "flex",
        flexDirection: "column",
      },
      chatMessage: {
        display: "flex",
        marginBottom: "1rem",
        width: "fit-content",
        maxWidth: "80%",
        fontSize: "1rem",
        selectors: {
          "&.user": {
            color: "#0078D4",
            alignSelf: "flex-end",
            borderRadius: "1rem 0 1rem 1rem",
            border: "1px solid #0078D4",
            background: "#CAEAFF",
            padding: "0.5rem 1rem",
            marginRight: "1rem",
          },
          "&.assistant": {
            flexDirection: "column",
            color: "#A7A7A7",
            alignSelf: "flex-start",
            borderRadius: "0 1rem 1rem 1rem",
            border: "1px solid #A7A7A7",
            background: "#FFF",
            padding: "0.5rem 1rem",
            marginLeft: "1rem",
            minWidth: "70%",
            // marginRight: "1rem",
          },
          "&.status": {
            color: "#A7A7A7",
            marginLeft: "1rem",
            // background: "yellow",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            img: {
              width: "1.3rem",
              height: "1.3rem",
              marginRight: "0.5rem",
            }
          },
        }
      },
      chatMessageContent: {
        whiteSpace: 'pre-line',
      },
      footer: {
        boxSizing: "border-box",
        height: "17%",
        padding: "0",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      },
      footerExpanded: {
        boxSizing: "border-box",
        height: "25%",
        padding: "0",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      },
      footerWrapper: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        width: "90%",
        margin: "0 auto",
      },
      footerWrapperExpanded: {
        width: "90%",
        margin: "0 auto",
      },
      broomImage: {
        background: "#0078D4",
        borderRadius: "0.4rem",
        padding: "0.5rem",
        width: "7%",
        height: "1.7rem",
        cursor: "pointer",
      },
      inputBox: {
        color: "#A7A7A7",
        // boxSizing: "border-box",
        width: "85%",
        // height: "2.7rem",
        // borderRadius: "5px",
        // border: "1px solid #A7A7A7",
        // background: "#FFF",
        // padding: "0.2rem 0.8rem",
        // fontSize: "1rem",
        // transition: "all 0.3s ease",
        selectors: {
          "&.expanded": {
            width: "100%",
            // transition: "all 0.3s ease",
          }
        },
      },
      inputBoxForReportAnswer: {
        color: "#A7A7A7",
        borderRadius: "5px",
        border: "1px solid #A7A7A7",
        background: "#FFF",
        padding: "2px",
        ".ms-TextField-suffix": {
          cursor: "pointer",
          background: "#FFFFFF",
        },
        ".ms-TextField-fieldGroup": {
          border: "none",
        },
        ".ms-TextField-fieldGroup::after": {
          border: "none",
        }
      },
      footerDisclaimer: {
        color: "#A7A7A7",
        fontSize: "0.6rem",
        marginBottom: "0.5rem",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      },
      horizontalLine: {
        width: "80%",
        margin: "0.8rem auto 0",
        border: "0.5px solid #E3E3E3",
      },
      fullWidthHorizontalLine: {
        width: "100%",
        borderBottom: "1px solid #E3E3E3",
        margin: "1rem auto",
      },
      stopButton: {
        fontSize: "0.8rem",
        background: "#0078D4",
        color: "#FFFFFF",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "max-content",
        padding: "0.5rem 0.7rem",
        margin: "0 auto",
        borderRadius: "5px",
        ":hover": {
          cursor: "pointer",
        }
      },
      sendButton: {
        width: "1.3rem",
        height: "1.3rem",
      },
      inputCharCount: {
        boxSizing: "border-box",
        width: "90%",
        margin: "0 auto",
        border: "1px solid #A7A7A7",
        borderRadius: "0 0 5px 5px",
        borderTop: "none",
        background: "#FFFFFF",
        padding: "5px 9px",
        color: "#A7A7A7",
      },
      optionsMenuItem: {
        boxSizing: "border-box",
        display: "flex",
        alignItems: "center",
        border: "1px solid transparent",
        borderRadius: "5px",
        background: "#F6F6F6",
        margin: "0.3rem auto",
        color: "#1D1D1C",
        fontWeight: 500,
        padding: "0.5rem",
        img: {
          width: "11%",
          marginRight: "0.5rem",
        },
        span: {
          width: "89%",
        },
        ":first-child": {
          marginTop: "0rem",
        },
        ":last-child": {
          marginBottom: "0rem",
        },
        ":hover": {
          cursor: "pointer",
          border: "1px solid #0078D4",
        },
      },
      optionsIconBackground: {
        background: "#CAEAFF",
        borderRadius: "5px",
      },
      learnMoreContainer: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        padding: "1rem 0 0rem",
        "> div:first-child": {
          flex: 1,
        },
        "> div:last-child": {
          width: "max-content",
        },
      },
      sourceNodesContainer: {
        display: "flex",
        alignItems: "center",
        paddingBottom: "6px",
        // justifyContent: "space-around",
        "> div": {
          padding: "0.2rem 1.5rem",
          border: "1px solid #A7A7A7",
          borderRadius: "51px",
          background: "#F6F6F6",
          marginLeft: "0.5rem",
          color: "#0078D4",
          fontWeight: 500,
        },
        "> div:first-child": {
          margin: "0",
        }
      },
      responseFeedbackContainer: {
        display: "flex",
        justifyContent: "space-between",
        "img": {
          cursor: "pointer",
          paddingBottom: "3px",
          borderBottom: "3px solid transparent",
        },
        "img:first-child": {
          marginRight: "5px",
        }
      },
      reportAnswerCallout: {
        border: "1px solid #A7A7A7",
        padding: "1px",
        borderRadius: "5px",
        background: "linear-gradient(218deg, #FFF 0%, #F1F0EF 100%)",
        boxShadow: "0px 0px 1.5399999618530273px 0px rgba(0, 0, 0, 0.12), 0px 0.7699999809265137px 1.5399999618530273px 0px rgba(0, 0, 0, 0.14)",
        ".ms-Callout-main": {
          padding: "0.8rem 0.6rem 0.7rem",
        },
        ".report-answer-title": {
          color: "#1D1D1C",
          fontWeight: 600,
          marginBottom: "0.8rem",
        }
      },
      reportAnswerOptionsCheckbox: {
        marginBottom: "0.7rem",
        span: {
          color: "#1D1D1C",
          // fontWeight: 600,
        }
      },
      likeDislikeActive: {
        borderBottom: "3px solid #0078D4 !important",
        borderRadius: "2px",
      },
      promptSelection: {

      },
      promptSelectionWrapper: {
        marginTop: "0.8rem",
        display: "flex",
      },
      scollUpDownContainer: {
        display: "flex",
        // background: "yellow",
        position: "absolute",
        right: 40,
        bottom: 10,
        "img": {
          cursor: "pointer",
        }
      }
    });

    const welcomeText = () => {
      return (
        <div className={`${widgetStyles.welcomeTextContainer}`}>
          <div className={`${widgetStyles.welcomeTextMainText}`}>Welcome to TCO Chat <sup className={`${widgetStyles.previewText}`}>Preview</sup></div>
          <div className={`${widgetStyles.welcomeTextSubText}`}>Here you can use the power of AI to find answers to all of your questions.</div>
        </div>
      );
    }

    const proficiencyOptions = () => {
      return (
        <div className={`${widgetStyles.proficiencyOptionsContainer}`}>
          <div className={`${widgetStyles.proficiencyOptionsText}`}>Choose conversation style</div>
          <div className={`${widgetStyles.proficiencyOptionsBoxWrapper}`}>
            <div className={`${widgetStyles.proficiencyOptionsBox}`}>
              <div
                className={`${widgetStyles.proficiencyOption} ${selectedProficiencyOption === 1 ? 'active' : ''}`}
                onClick={() => handleConversationStyleChange(1)}
              >
                Concise
              </div>
              <div
                className={`${widgetStyles.proficiencyOption} ${selectedProficiencyOption === 2 ? 'active' : ''}`}
                onClick={() => handleConversationStyleChange(2)}
              >
                Detailed
              </div>
            </div>
            <FontIcon
                id={conversationStyleCalloutId}
                iconName="Info"
                tabIndex={0}
                className={`${widgetStyles.infoIcon}`}
                onClick={toggleIsConversationCalloutVisible}
                directionalHint={DirectionalHint.rightCenter}
              />
              {isConversationCalloutVisible && (
                <Callout 
                  className={widgetStyles.calloutForReport} 
                  target={`#${conversationStyleCalloutId}`} 
                  onDismiss={toggleIsConversationCalloutVisible} 
                  role="alert"
                  gapSpace={0}
                >
                  <Text>
                    Lorem ipsum dolor sit amet consectetur adipisicing elit. Similique, animi recusandae iusto a ab tempore, totam et minus, alias deserunt expedita ducimus numquam maxime? Nobis quam debitis corrupti est dolores.
                  </Text>
                </Callout>
              )}
          </div>
        </div>
      );
    };

    const queryOptions = () => {
      return (
        <div className={`${widgetStyles.proficiencyOptionsContainer}`}>
          <div className={`${widgetStyles.proficiencyOptionsText}`}>What would you like help with?</div>
          <div className={`${widgetStyles.proficiencyOptionsBoxWrapper}`}>
            <div className={`${widgetStyles.proficiencyOptionsBox}`}>
              <div
                className={`${widgetStyles.proficiencyOption} ${selectedQueryOption === 1 ? 'active' : ''}`}
                onClick={() => handleReportSelection(1)}
              >
                My Results
              </div>
              <div
                className={`${widgetStyles.proficiencyOption} ${selectedQueryOption === 2 ? 'active' : ''}`}
                onClick={() => handleReportSelection(2)}
              >
                IDC Report
              </div>
              {/* <div
                className={`${widgetStyles.proficiencyOption} ${selectedQueryOption === 3 ? 'active' : ''}`}
                onClick={() => setSelectedQueryOption(3)}
              >
                FAQs
              </div> */}
            </div>
            <FontIcon
              id={reportCalloutId}
              iconName="Info"
              tabIndex={0}
              className={`${widgetStyles.infoIcon}`}
              onClick={toggleIsReportCalloutVisible}
              directionalHint={DirectionalHint.rightCenter}
            />
            {isReportCalloutVisible && (
              <Callout 
                className={widgetStyles.calloutForReport} 
                target={`#${reportCalloutId}`} 
                onDismiss={toggleIsReportCalloutVisible} 
                role="alert"
                gapSpace={0}
              >
                <Text>
                  "My Results" allows you to query your individual results, providing you with a personalized overview.
                </Text>
                <Text>
                  "IDC Report" on the other hand, enables you to access the IDC research whitepaper that servers as the foundation for this TCO calculator.
                </Text>
              </Callout>
            )}
          </div>
        </div>
      );
    };

    const horizontalLine = () => {
      return <div className={`${widgetStyles.horizontalLine}`}></div>
    }

    const handleSuggestedQuestionClick = (question) => {
      handleUserMessage(question);
    }

    const suggestedQuestions = () => {
      if(!generatedQuestions){
        return;
      }
      return (
        <div className={`${widgetStyles.suggestedQuestionsWrapper}`}>
          {
            generatedQuestions.map((question, index) => {
              return (
                <div key={index} className={`${widgetStyles.suggestedQuestion}`} onClick={() => handleSuggestedQuestionClick(question)}>{question}</div>
              )
            })
          }
        </div>
      );
    }

    const reportChangeDisclaimer = () => {
      return (
        <div className={`${widgetStyles.reportChangeDisclaimer}`}>
          <div>New chat session started</div>
          <div>The report has been changed. Previous messages won't be used as context for new queries.</div>
        </div>
      )
    }

    const renderWidget = (widgetName) => {
      switch (widgetName) {
        case "welcomeText": return welcomeText();
        case "proficiencyOptions": return proficiencyOptions();
        case "horizontalLine": return horizontalLine();
        case "queryOptions": return queryOptions();
        case "suggestedQuestions": return suggestedQuestions();
        case "reportChangeDisclaimer": return reportChangeDisclaimer();
        default: break;
      }
    }

    const keywordsForReportChangeToIDC = [
      "idc",
    ];

    const keywordsForReportChangeToCustom = [
      "custom",
      "my inputs",
      "my input",
      "my values",
      "my",
      "my results",
    ];

    const handleReportSelection = (reportId) => {
      setSelectedQueryOption(reportId);
      if(reportId === 1){
        trackGAEvent("Chatbot", "Query My Results", "My results option in Chatbot");
      } else {
        trackGAEvent("Chatbot", "Query IDC Report", "IDC report option in Chatbot");
      }
      const newMessage = {
        role: "assistant",
        content: `${reportId === 1 ? "You have selected to query 'My Results'. I can help you understand your results!" : "You have selected to query the IDC report. I can help you understand the results of the IDC report!"}`
      };
      const reportChangeWidget = {
        role: "custom",
        content: "",
        widget: "reportChangeDisclaimer",
      }

      let newMessages = messages.filter(message => !(message.widget === "reportChangeDisclaimer" || message.role === "assistantPrompt"));

      let notEmptyConversation = newMessages.find(msg => msg.role === "user");
      if(!notEmptyConversation){
        let updatedIntroMessages = newMessages.map(message => {
          if(message.content === "Hey, I can help you understand your results!" || message.content === "Hey, I can help you understand the findings of the IDC whitepaper!"){
            return {
              ...message,
              content: `${reportId === 1 ? "Hey, I can help you understand your results!" : "Hey, I can help you understand the findings of the IDC whitepaper!"}`,
            }
          } else {
            return message;
          }
        });
        setMessages([...updatedIntroMessages]);
        return;
      }

      let lastMessage = newMessages.pop();
      if(lastMessage.widget === "suggestedQuestions"){
        setMessages([...newMessages, reportChangeWidget, newMessage, lastMessage]);
      } else {
        setMessages([...newMessages, lastMessage, reportChangeWidget, newMessage]);
      }
    }

    const handleConversationStyleChange = (id) => {
      setRenderDetailedConversationPrompt(false);
      setSelectedProficiencyOption(id);
      const newMessage = {
        role: "assistant",
        content: `You have chosen to have more ${id === 1 ? "concise" : "detailed"} answers.`
      };

      let newMessages = messages.filter(message => !(message.role === "assistantPrompt"));

      let lastMessage = newMessages.pop();
      if(lastMessage.widget === "suggestedQuestions"){
        setMessages([...newMessages, newMessage, lastMessage]);
      } else {
        setMessages([...newMessages, lastMessage, newMessage]);
      }
    }

    const requireDetailedResponse = async (question) => {
      let matchedResponses = messages.filter(msg => msg.role === "assistantGeneratedResponse");
      if(matchedResponses.length === 4 && requireDetailedResponseCount === 0){
        handleUserMessage(question, true);
        setRequireDetailedResponseCount(currentCount => currentCount + 2);
        setExemptedQuestions(current => [...current, question]);
        return;
      }
      if((requireDetailedResponseCount < 1)){
        handleUserMessage(question, true);
        setRequireDetailedResponseCount(currentCount => currentCount + 1);
        setExemptedQuestions(current => [...current, question]);
        return;
      }
      setSelectedProficiencyOption(2);
      handleUserMessage(question, true);
      setRenderDetailedConversationPrompt(false);
    }

    const switchReport = (shouldSwitch, reportId) => {
      if(shouldSwitch){
        setSelectedQueryOption(reportId);
        if(reportId === 1){
          trackGAEvent("Chatbot", "Query My Results", "My results option in Chatbot");
        } else {
          trackGAEvent("Chatbot", "Query IDC Report", "IDC report option in Chatbot");
        }
        const newMessage = {
          role: "assistant",
          content: `${reportId === 1 ? "You have selected to query 'My Results'. I can help you understand your results!" : "You have selected to query the IDC report. I can help you understand the results of the IDC report!"}`
        };
        const reportChangeWidget = {
          role: "custom",
          content: "",
          widget: "reportChangeDisclaimer",
        };
        let newMessages = messages.filter(message => !(message.widget === "reportChangeDisclaimer" || message.role === "assistantPrompt"));
        
        let notEmptyConversation = newMessages.find(msg => msg.role === "user");
        if(!notEmptyConversation){
          let updatedIntroMessages = newMessages.map(message => {
            if(message.content === "Hey, I can help you understand your results!" || message.content === "Hey, I can help you understand the findings of the IDC whitepaper!"){
              return {
                ...message,
                content: `${reportId === 1 ? "Hey, I can help you understand your results!" : "Hey, I can help you understand the findings of the IDC whitepaper!"}`,
              }
            } else {
              return message;
            }
          });
          setMessages([...updatedIntroMessages]);
          return;
        }

        let lastMessage = newMessages.pop();
        if(lastMessage.widget === "suggestedQuestions"){
          setMessages([...newMessages, reportChangeWidget, newMessage, lastMessage]);
        } else {
          setMessages([...newMessages, lastMessage, reportChangeWidget, newMessage]);
        }
      } else {
        const newMessage = {
          role: "assistant",
          content: `Ok. You've chosen to continue with the ${selectedQueryOption === 1 ? "custom" : "IDC"} report`
        };
        let newMessages = messages.filter(message => !(message.role === "assistantPrompt"));
        let lastMessage = newMessages.pop();
        if(lastMessage.widget === "suggestedQuestions"){
          setMessages([...newMessages, newMessage, lastMessage]);
        } else {
          setMessages([...newMessages, lastMessage, newMessage]);
        }
      }
    }

    const setRating = (messageObject, rating, isSubmitted) => {
      messageObject.rating = rating;
      let newMessage;
      if(isSubmitted){
        newMessage = {
          role: "assistant",
          content: `Thanks for the feedback`
        };
      } else {
        newMessage = {
          role: "assistant",
          content: `Error submitting feedback`
        };
      }
      setMessages((currentMessages) => {
        let latestMessage = currentMessages.pop();
        if(latestMessage.widget === "suggestedQuestions"){
          return [...currentMessages, newMessage, latestMessage];
        } else {
          return [...currentMessages, latestMessage, newMessage];
        }
      });
    }

    const findNearestUser = (currentIndex) => {
      for (let i = currentIndex - 1; i >= 0; i--) {
        if (messages[i].role === 'user') {
          return messages[i].content;
        }
      }
      return null;
    };

    const renderChatMessage = (messageObject, index) => {

      switch (messageObject.role) {
        case "user":
          return (
            <div key={index} className={`${chatbotStyles.chatMessage} user`}>
                {messageObject.content}
            </div>
          );  
        case "assistant":
          return (
            <div key={index} className={`${chatbotStyles.chatMessage} assistant`}>
                <div className={`${chatbotStyles.chatMessageContent}`}>{messageObject.content}</div>
            </div>
          );
        case "assistantGeneratedResponse":
          let qaPair = {};
          qaPair.question = findNearestUser(index);
          qaPair.answer = messageObject.content;
          let firstFewAssistantGeneratedResponseObjects = [];
          let doNotRender = false;
          messages.forEach((msg, index) => {
            if(msg.role === "assistantGeneratedResponse"){
              firstFewAssistantGeneratedResponseObjects.push(index);
            }
          });

          firstFewAssistantGeneratedResponseObjects = firstFewAssistantGeneratedResponseObjects.slice(0, 4 + requireDetailedResponseCount);
          if(exemptedQuestions.includes(qaPair.question)){
            doNotRender = true;
          }
          return (
            <div key={index} className={`${chatbotStyles.chatMessage} assistant`}>
              <div className={`${chatbotStyles.chatMessageContent}`}>{messageObject.content}</div>
              <ChatbotLearnMore 
                rating={messageObject.rating} 
                setRating={(rating, isSubmitted) => setRating(messageObject, rating, isSubmitted)} 
                questionAndAnswer={qaPair} 
                renderDetailedConversationPrompt={!doNotRender && renderDetailedConversationPrompt && firstFewAssistantGeneratedResponseObjects.includes(index) && (selectedProficiencyOption === 1)}
                handleConversationStyleChange={handleConversationStyleChange}
                requireDetailedResponse={requireDetailedResponse}
                setOpenFullReport={setOpenFullReport}
                selectedQueryOption={selectedQueryOption}
              />
            </div>
          );
        case "custom":
          return (
            <div key={index}>
              {renderWidget(messageObject.widget)}
            </div>
          );
        case "status":
          return (
            <div key={index} className={`${chatbotStyles.chatMessage} status`}>
              {
                messageObject.content?.toLowerCase() === "completed" ?
                  <img src={ChatbotStatusCompleted} alt="status" />
                :
                  (messageObject.content?.toLowerCase() === "aborted") || (messageObject.content?.toLowerCase() === "error") ?
                    <img src={ChatbotStatusAbort} alt="status" />
                  :
                    <img src={ChatbotStatusIcon} alt="status" />
              }
                <div>{messageObject.content}</div>
            </div>
          );
        case "assistantPrompt":
          switch (messageObject.content) {
            case "changeReport":
              return (
                <div key={index} className={`${chatbotStyles.chatMessage} assistant`}>
                  <div className={`${chatbotStyles.promptSelection}`}>
                    <div>Select the report you like to query:</div>
                    <div className={`${chatbotStyles.promptSelectionWrapper}`}>
                      <div className={`${widgetStyles.suggestedQuestion}`} onClick={() => handleReportSelection(2)}>IDC Report</div>
                      <div className={`${widgetStyles.suggestedQuestion}`} onClick={() => handleReportSelection(1)}>My Results</div>
                    </div>
                  </div>
                </div>
              );
            case "changeConversation":
              return (
                <div key={index} className={`${chatbotStyles.chatMessage} assistant`}>
                  <div className={`${chatbotStyles.promptSelection}`}>
                    <div>Would you like your answers to be</div>
                    <div className={`${chatbotStyles.promptSelectionWrapper}`}>
                      <div className={`${widgetStyles.suggestedQuestion}`} onClick={() => handleConversationStyleChange(1)}>Concise</div>
                      <div className={`${widgetStyles.suggestedQuestion}`} onClick={() => handleConversationStyleChange(2)}>Detailed</div>
                    </div>
                  </div>
                </div>
              );
            case "changeReportToIDC":
              return (
                <div key={index} className={`${chatbotStyles.chatMessage} assistant`}>
                  <div className={`${chatbotStyles.promptSelection}`}>
                    <div>Looks like you are trying to query the IDC report. Would you like me to switch the report to IDC?</div>
                    <div className={`${chatbotStyles.promptSelectionWrapper}`}>
                      <div className={`${widgetStyles.suggestedQuestion}`} onClick={() => switchReport(true, 2)}>Yes</div>
                      <div className={`${widgetStyles.suggestedQuestion}`} onClick={() => switchReport(false)}>No</div>
                    </div>
                  </div>
                </div>
              );
            case "changeReportToCustom":
              return (
                <div key={index} className={`${chatbotStyles.chatMessage} assistant`}>
                  <div className={`${chatbotStyles.promptSelection}`}>
                    <div>Looks like you are trying to query your results. Would you like me to switch to 'My Results' selection?</div>
                    <div className={`${chatbotStyles.promptSelectionWrapper}`}>
                      <div className={`${widgetStyles.suggestedQuestion}`} onClick={() => switchReport(true, 1)}>Yes</div>
                      <div className={`${widgetStyles.suggestedQuestion}`} onClick={() => switchReport(false)}>No</div>
                    </div>
                  </div>
                </div>
              );
            default:
              return (
                <div></div>
              );
          }
        default:
          break;
      }
    }

    const handleStopGenerating = () => {
      if (apiRequestRef && apiRequestRef.current) {
        apiRequestRef.current.abort();
      }
      setGeneratingResponse(false);
      shouldCancelRef.current = true;

      let chatMessages = messages.filter(messageObj => messageObj.role !== "status");

      setMessages([...chatMessages, 
        {role: "status", content: "Aborted", widget: null},
        {role: "assistant", content: "Stopped response generation", widget: null}]);
    };

    const generateSuggestedQuestions = async (node1, node2, lang) => {
      const questionsApiUrl = `${process.env.REACT_APP_SUGGESTED_QUESTIONS_API}?code=${process.env.REACT_APP_SUGGESTED_QUESTION_API_CODE}`;
      const fetchQuestionsAPIResponse = await fetch(questionsApiUrl, {
        method: "POST",
        body: JSON.stringify({
          'context': node1 + "\n" + node2,
          'language': lang,
        }),
        headers: {
          "Content-Type": "application/json",
        },
      });
      const fetchQuestionsAPIdata = await fetchQuestionsAPIResponse.json();
      let questionsArray = [
        fetchQuestionsAPIdata[0]["Question 1"],
        fetchQuestionsAPIdata[0]["Question 2"],
        fetchQuestionsAPIdata[0]["Question 3"],
      ];
      setGeneratedQuestions([...questionsArray]);

    }

    const logChatToDatabase = (data) => {
      const logApiUrl = `${process.env.REACT_APP_TCO_CHAT_LOG_API}?code=${process.env.REACT_APP_API_CODE}`;
      fetch(logApiUrl, {
        method: "POST",
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
        },
      }).then(response => response.json())
      .catch(error => console.log(error))
    }

    const fetchResponseFromGPTAsStreamUsingClient = async (chatMessages, systemContext, userQuestion, generateDetailedAnswer) => {

      try {

        let apiMessages = [];
        let responseMessage = "";
        let firstIteration = true;

        const newStatusMessage = {
          role: "status",
          content: "Generating answers for you...",
          widget: null,
        }
        setMessages(prevMessages => [...prevMessages, newStatusMessage]);

        const systemMessage = selectedQueryOption === 2 ? {
          role: "system",
          content: `You are a friendly AI assistant on a Microsoft website. The Microsoft website is designed to calculate the Total Cost of Ownership of Microsoft Surface devices and compare those costs to the Total Cost of Ownership of Other PCs, which are made by other manufacturers (not Microsoft). 
          I will give you a question and some context. Given below are the instructions you have to follow.
          - Answer the question using the provided context, the conversation history and the data you were trained on.
          - ${selectedProficiencyOption === 2 ? 
            "Add detail in the response" 
          : 
            "Make the answer concise and easy to understand."}
          - Present the answers in a structured format. Keep paragraphs VERY SHORT (NOT MORE THAN 400 characters) and use bullet points/numbered points to make the answers look attractive.
          - You are a friendly AI assistant. Make sure the answers sound friendly.
          The context is delimited by curly brackets.

          {
          '${systemContext[0]}', 
          '${systemContext[1]}', 
          '${systemContext[2]}',
          '${systemContext[3]}'
          }
          `
        } :
        {
          role: "system",
          content: `You are a friendly AI assistant on a Microsoft website. The Microsoft website is designed to calculate the Total Cost of Ownership of Microsoft Surface devices and compare those costs to the Total Cost of Ownership of Other PCs, which are made by other manufacturers (not Microsoft). 
          I will give you a question and some context. Given below are the instructions you have to follow.
          - Answer the question using the provided context, the conversation history and the data you were trained on.
          - All the information in the context is 100% correct.
          - Savings values are calculated with respect to surface devices, so they can be negative.
          - If a cost value is negative, then that means the organization actually saves that amount of money.
          - <sup>1</sup> means that the value was manually changed by the user.
          - <sup>2</sup> means that the value is 0 because the user decided not to include that value in the final results. 
          - ${selectedProficiencyOption === 2 ? 
            "Add detail in the response" 
          : 
            "Please provide short, extremely concise and straightforward responses to keep the reader engaged."}
          - Present the answers in a structured format. Keep paragraphs VERY SHORT (NOT MORE THAN 400 characters) and use bullet points/numbered points to make the answers look attractive.
          - You are a friendly AI assistant. Make sure the answers sound friendly.
          The context is delimited by curly brackets.

          {
          Results -> '${systemContext[0]}', 
          '${systemContext[1]}', 
          }
          `
        }

        const sampleConversation = [
          {
            role: "user",
            content: "In-Context question"
          },
          {
            role: "assistant",
            content: "Assistant should answer"
          },
          {
            role: "user",
            content: "Out of context question"
          },
          {
            role: "assistant",
            content: "Assistant should not answer"
          },
        ];
    
        //When report is changed and a new chat session is started, ignore the previous conversation.
        const widgetIndex = chatMessages.findIndex(obj => obj.widget === "reportChangeDisclaimer");
        const messagesAfterWidget = widgetIndex !== -1 ? chatMessages.slice(widgetIndex + 1) : chatMessages;
        messagesAfterWidget.forEach((messageObj) => {
          if(messageObj.role === "user" || messageObj.role === "assistantGeneratedResponse"){
            apiMessages.push({
              role: messageObj.role === "user" ? "user" : "assistant",
              content: messageObj.content
            });
          }
        });

        apiMessages = [systemMessage, ...sampleConversation, ...apiMessages];

        let latestQuestion = apiMessages.pop();
        latestQuestion.content = `Question: "${latestQuestion.content} ${generateDetailedAnswer ? "(Add detail in the response)" : selectedProficiencyOption === 2 ? "(Add detail in the response)" : "(Please provide short, extremely concise and straightforward responses to keep the reader engaged)"}"`;
        apiMessages.push(latestQuestion);
      
        const client = new OpenAIClient(process.env.REACT_APP_AZURE_OPEN_AI_URL, new AzureKeyCredential(process.env.REACT_APP_AZURE_OPEN_AI_CODE));
        const deploymentId = "gpt-35-turbo-tco-chatbot-6-19-2023";

        if(shouldCancelRef.current){
          shouldCancelRef.current = false;
          return;
        }
    
        const response = await client.getChatCompletions(
        deploymentId,
        apiMessages,
        {
          temperature: 0.5,
          maxTokens: 512,
          topP: 0.95,
          frequencyPenalty: 0,
          presencePenalty: 0,
        }
      );

      const choices = response.choices || [];
      for (const choice of choices) {
        if (shouldCancelRef.current) {
          shouldCancelRef.current = false;
          return;
        }

        responseMessage += choice.message?.content
          ? choice.message?.content
          : "";

        if (responseMessage && firstIteration) {
          setMessages((currentChatMessages) => [
            ...currentChatMessages,
            {
              role: "assistantGeneratedResponse",
              content: responseMessage,
              widget: null,
            },
          ]);
          firstIteration = false;
        } else if (responseMessage) {
          setMessages((currentChatMessages) => [
            ...currentChatMessages.slice(0, -1),
            {
              role: "assistantGeneratedResponse",
              content: responseMessage,
              widget: null,
            },
          ]);
        }
      }

    
        setGeneratingResponse(false);
        shouldCancelRef.current = false;

        if(selectedQueryOption === 1 && keywordsForReportChangeToIDC.some(keyword => userQuestion.toLowerCase().includes(keyword))){
          setMessages([...chatMessages.filter(message => message.widget !== "suggestedQuestions"), 
            {role: "status", content: "Completed", widget: null},
            {role: "assistantGeneratedResponse", content: responseMessage, widget: null},
            {role: "assistantPrompt", content: "changeReportToIDC"},
            {role: "custom", content: "", widget: "suggestedQuestions"},
          ]);
        } else if(selectedQueryOption === 2 && keywordsForReportChangeToCustom.some(keyword => userQuestion.toLowerCase().includes(keyword))){
          setMessages([...chatMessages.filter(message => message.widget !== "suggestedQuestions"), 
            {role: "status", content: "Completed", widget: null},
            {role: "assistantGeneratedResponse", content: responseMessage, widget: null},
            {role: "assistantPrompt", content: "changeReportToCustom"},
            {role: "custom", content: "", widget: "suggestedQuestions"},
          ]);
        } else {
          setMessages([...chatMessages.filter(message => message.widget !== "suggestedQuestions"), 
            {role: "status", content: "Completed", widget: null},
            {role: "assistantGeneratedResponse", content: responseMessage, widget: null},
            {role: "custom", content: "", widget: "suggestedQuestions"},
          ]);
        }
        if(process.env.REACT_APP_BRANCH === "staging"){
          logChatToDatabase({question: userQuestion, answer: responseMessage});
        }
        // listChatCompletions doesn't return token count
        // setChatTokens(currentTokens => currentTokens + events.usage.completionTokens);
        // setTotalTokens(events.usage.totalTokens);
      } catch(error){
        setGeneratingResponse(false);
        shouldCancelRef.current = false;
        setMessages([...chatMessages.filter(message => message.widget !== "suggestedQuestions"), 
            {role: "status", content: "Error", widget: null},
            {role: "assistant", content: "Something went wrong. Please try again.", widget: null},
            {role: "custom", content: "", widget: "suggestedQuestions"},
        ]);
      }

    }

    const fetchIDCContextFromPythonAPI = async (latestMessage, chatMessages, generateDetailedAnswer) => {

      try {
        const abortController = new AbortController();
        apiRequestRef.current = abortController;
        const contextApiUrl =
                `${process.env.REACT_APP_CHATBOT_API}?code=${process.env.REACT_APP_IDC_CONTEXT_API_CODE}`;
        const fetchContextAPIResponse = await fetch(contextApiUrl, {
          method: "POST",
          body: JSON.stringify({
            'query_text': latestMessage,
          }),
          signal: abortController.signal,
          headers: {
            "Content-Type": "application/json",
          },
        });

        //Not entering this block?
        // if (!apiRequestRef.current) {
        //   setGeneratingResponse(false);
        //   shouldCancelRef.current = false;
        //   return; // If the reference is already cleared, exit early.
        // }

        const fetchContextAPIdata = await fetchContextAPIResponse.json();

        let combinedMessage = "";
        fetchContextAPIdata.forEach((item) => {
          combinedMessage += item.replace("\n", ". ");
        });
        apiRequestRef.current = null;
        if(fetchContextAPIdata.length < 1){
          setGeneratingResponse(false);
          setMessages([...chatMessages.filter(message => message.widget !== "suggestedQuestions"), 
            {role: "status", content: "Completed", widget: null},
            {role: "assistantGeneratedResponse", content: "As an AI assitant for this website, my knowledge base is limited to the TCO domain. Therefore, I can not answer this question. I can help you with questions about \n1. IDC Report \n2. My Results/Your Calculations \n3. This website \n4. AI Assistant (me) :)", widget: null},
          ]);
        } else {
          fetchResponseFromGPTAsStreamUsingClient(chatMessages, fetchContextAPIdata, latestMessage, generateDetailedAnswer);
          generateSuggestedQuestions(fetchContextAPIdata[1], fetchContextAPIdata[2], fetchContextAPIdata[5]);
        }
      } catch(error){
        setGeneratingResponse(false);
        shouldCancelRef.current = false;
        apiRequestRef.current = null;
        setMessages([...chatMessages.filter(message => message.widget !== "suggestedQuestions"), 
            {role: "status", content: "Error", widget: null},
            {role: "assistant", content: "Something went wrong. Please try again.", widget: null},
        ]);
      }
    }

    const getReportPageData = async (values) => {   
      let requestData = _.cloneDeep(values);
      const currencyConversionMultipleToUSD = requestData.currencyConversionMultipleToUSD;
      const currencyConversionMultipleFromUSD = requestData.currencyConversionMultipleFromUSD;
      requestData.deviceMixPortfolio = requestData.deviceMixPortfolio
        .filter((i) => Object.keys(i).length > 1)
        .map((item, index) => {
          return {
            seqNo: index + 1,
            product: item.product,
            quantity: sessionStorage.getItem("showPerDevice") === "true" ? 1 : convertStringToNumber(item.quantity),
            microsoft365Subscription: item.microsoft365Subscription,
            msrp: (item.msrp * currencyConversionMultipleToUSD).toFixed(2),
          };
        });
  
      let assumptionsWithCurrency = ["annualEmployeeSalary", "annualITStaffSalary", "averageCostToHire", "averagePCCost", "cameraCost", "dockCost", "extraPowerSupplyCost", "headsetCost", "lightsCost", "pcWarrantyAndMaintenanceCost", "penCost", "typeCoverCost", "PCCameraCost", "PCDockCost", "PCHeadsetCost", "PCLightsCost", "PCPenCost", "PCPowerSupplyCost", "PCTypeCoverCost", "SurfaceCameraCost", "SurfaceDockCost", "SurfaceHeadsetCost", "SurfaceLightsCost", "SurfacePenCost",  "SurfacePowerSupplyCost", "SurfaceTypeCoverCost"];
      let updatedAssumptions = {};
      Object.keys(requestData.assumptions).forEach((key) => {
        if (key === "warrantyAndMaintenanceCost" || key === "pcWarrantyAndMaintenanceCost") {
          if(sessionStorage.getItem("activeView") === "Advanced"){
            updatedAssumptions[key] = (convertStringToNumber(requestData.assumptions[key]) * currencyConversionMultipleToUSD / sessionStorage.getItem("years")).toFixed(2);
          } else {
            updatedAssumptions[key] = (convertStringToNumber(requestData.assumptions[key]) * currencyConversionMultipleToUSD / 3).toFixed(2);
          }
        } else if(assumptionsWithCurrency.includes(key)) {
          updatedAssumptions[key] = (convertStringToNumber(requestData.assumptions[key]) * currencyConversionMultipleToUSD).toFixed(2);
        } else {
          updatedAssumptions[key] = convertStringToNumber(requestData.assumptions[key]);
        }
      });
      requestData.assumptions = updatedAssumptions;
  
      if(requestData.advancedInput){
        let updatedAdvancedInput = {};
        Object.keys(requestData.advancedInput).forEach((key) => {
          const currentValueObject = requestData.advancedInput[key];
          updatedAdvancedInput[key] = {
            ...currentValueObject,
            value: (currentValueObject["value"] * currencyConversionMultipleToUSD).toFixed(2),
          };
        });
        requestData.advancedInput = updatedAdvancedInput;
      }
  
      const rootURL = process.env.REACT_APP_API_URL;
      const url = "api/tco-report-complete";
      const code = process.env.REACT_APP_API_CODE;

      const completeReportAPIResponse = await fetch(`${rootURL}${url}?code=${code}`, {
        method: "POST",
        body: JSON.stringify({
          country: "US",
          industry: "default",
          currency: CURRENCY.unit,
          years: activeViewForChatbot === "Advanced" && sessionStorage.getItem("years") ? Number(sessionStorage.getItem("years")) : 3,
          ...requestData,
        }),
        // signal: abortController.signal,
        // headers: {
        //   "Content-Type": "application/json",
        // },
      });

      const completeReportAPIData = await completeReportAPIResponse.json();
  
      if (completeReportAPIData.value) {
        let apiData = completeReportAPIData.value;

        const convertRowsArray = (rowsArray) => {
          return rowsArray.map((i) => {
            return {
              ...i,
              pcValue: i.pcValue * currencyConversionMultipleFromUSD,
              savings: i.savings * currencyConversionMultipleFromUSD,
              surfaceValue: i.surfaceValue * currencyConversionMultipleFromUSD,
            }
          });
        }

        const convertCategoryObject = (category) => {
          return {
            ...category, 
            pcValue: category.pcValue * currencyConversionMultipleFromUSD,
            surfaceValue: category.surfaceValue * currencyConversionMultipleFromUSD,
            savings: category.savings * currencyConversionMultipleFromUSD,
            rows: convertRowsArray(category.rows),
          }
        }

        apiData = {
          ...apiData,
          costs: apiData.costs * currencyConversionMultipleFromUSD,
          savings: apiData.savings * currencyConversionMultipleFromUSD,
          employeeEmpowerment: convertCategoryObject(apiData.employeeEmpowerment),
          itSimplification: convertCategoryObject(apiData.itSimplification),
          totalCosts: convertCategoryObject(apiData.totalCosts),
          totalSavings: convertCategoryObject(apiData.totalSavings),
        }

        return [apiData, {
          country: "US",
          industry: "default",
          currency: sessionStorage.getItem("selectedCurrency"),
          years: activeViewForChatbot === "Advanced" && sessionStorage.getItem("years") ? Number(sessionStorage.getItem("years")) : 3,
          ...requestData,
        }];
      } else {
        console.log("error", completeReportAPIData.error.message);
        return {};
      }
    };

    const fetchCustomContextFromPythonAPI = async (latestMessage, chatMessages, generateDetailedAnswer) => {

      try {
        const contextApiUrl = `${process.env.REACT_APP_CUSTOM_REPORT_API}?code=${process.env.REACT_APP_CUSTOM_REPORT_API_CODE}`;
        const abortController = new AbortController();
        apiRequestRef.current = abortController;

        const completeReportData = await getReportPageData(userInput);

        let requestBody = JSON.stringify({
          "response_string": {value: completeReportData[0]},
          "request_string": completeReportData[1],
          "query_text": latestMessage,
        });

        const fetchContextAPIResponse = await fetch(contextApiUrl, {
          method: "POST",
          body: requestBody,
          signal: abortController.signal,
          headers: {
            "Content-Type": "application/json",
          },
        });

        //Not entering this block?
        // if (!apiRequestRef.current) {
        //   setGeneratingResponse(false);
        //   shouldCancelRef.current = false;
        //   return; // If the reference is already cleared, exit early.
        // }

        const fetchContextAPIdata = await fetchContextAPIResponse.json();

        let combinedMessage = "";
        fetchContextAPIdata.forEach((item) => {
          combinedMessage += item.replace("\n", ". ");
        });
        apiRequestRef.current = null;
        if(fetchContextAPIdata.length < 1){
          setGeneratingResponse(false);
          setMessages([...chatMessages.filter(message => message.widget !== "suggestedQuestions"), 
            {role: "status", content: "Completed", widget: null},
            {role: "assistantGeneratedResponse", content: "As an AI assitant for this website, my knowledge base is limited to the TCO domain. Therefore, I can not answer this question. I can help you with questions about \n1. IDC Report \n2. My Results/Your Calculations \n3. This website \n4. AI Assistant (me) :)", widget: null},
          ]);
        } else {
          fetchResponseFromGPTAsStreamUsingClient(chatMessages, fetchContextAPIdata, latestMessage, generateDetailedAnswer);
          generateSuggestedQuestions(fetchContextAPIdata[0], fetchContextAPIdata[1], fetchContextAPIdata[5]);
        }
      } catch(error){
        setGeneratingResponse(false);
        shouldCancelRef.current = false;
        apiRequestRef.current = null;
        setMessages([...chatMessages.filter(message => message.widget !== "suggestedQuestions"), 
            {role: "status", content: "Error", widget: null},
            {role: "assistant", content: "Something went wrong. Please try again.", widget: null},
        ]);
      }
    }
    

    const handleUserMessage = async (message, generateDetailedAnswer) => {
      if(!message || (message && !message.trim())){
        return;
      }
      setGeneratedQuestions([]);
      let messagesWithoutStatus = messages.filter(messageObj => !(messageObj.role === "status" || messageObj.widget === "suggestedQuestions"));
      const newMessage = {
        role: "user",
        content: message,
        widget: null,
      };
      const newStatusMessage = {
        role: "status",
        content: `Searching for: ${message}`,
        widget: null,
      }
      const newMessages = [...messagesWithoutStatus, newMessage];
      setMessages([...messagesWithoutStatus, newMessage, newStatusMessage]);
      // await getContextFromPythonAPI(message, newMessages);
      setGeneratingResponse(true);
      shouldCancelRef.current = false;
      if(selectedQueryOption === 1){
        fetchCustomContextFromPythonAPI(message, newMessages, generateDetailedAnswer);
      } else {
        fetchIDCContextFromPythonAPI(message, newMessages, generateDetailedAnswer);
      }
    }

    const handleTextAreaClick = () => {
      setExpandedInput(true);
    };
  
    const handleDocumentClick = (event) => {
      const clickedInsideTextArea = event.target.closest('.ms-TextField-field');
      if (!clickedInsideTextArea) {
        setExpandedInput(false);
      }
    };
    
    const handleOptionsMenuItemClick = (key) => {
      switch (key) {
        case "item2":
            setMessages([
              ...messages.filter(message => message.widget !== "suggestedQuestions"), 
              {role: "assistantPrompt", content: "changeReport"},
              {role: "custom", content: "", widget: "suggestedQuestions"},
            ]);
          break;
        case "item3":
          setMessages([
            ...messages.filter(message => message.widget !== "suggestedQuestions"), 
            {role: "assistantPrompt", content: "changeConversation"},
            {role: "custom", content: "", widget: "suggestedQuestions"},
          ]);
          break;
        default:
          break;
      }
      onDismissOptionsMenu();
    }

    const onOptionsButtonClick = (event) => {
      const currentState = isOptionsMenuVisible;
      setIsOptionsMenuVisible(prevState => !prevState);
      if(currentState){
        setOptionsMenuTargetElement(null);
      } else {
        setOptionsMenuTargetElement(event.currentTarget);
      }
    };

    const onDismissOptionsMenu = () => {
      setIsOptionsMenuVisible(false);
      setOptionsMenuTargetElement(null);
    };

    const onRenderOptionsMenuList = (menuListProps) => {
      return (
        <div>
          {menuListProps.items.map((item) => (
            <div key={item.key} className={chatbotStyles.optionsMenuItem} onClick={() => handleOptionsMenuItemClick(item.key)}>
              <img src={item.iconName} alt="i" />
              <span>{item.text}</span>
            </div>
          ))}
        </div>
      );
    };

    const handleClearChat = () => {
      setMessages([...starterChat]);
      setGeneratedQuestions([]);
      setChatTokens(0);
      setTotalTokens(0);
      let matchesFound = messages.filter(message => message.role === "assistantGeneratedResponse");
      if(matchesFound.length >= 4){
        setRenderDetailedConversationPrompt(false);
      }
      setExemptedQuestions([]);
    }
    

  return (
    <>
      <div className={`${chatbotStyles.container}`}>
        <div className={`${chatbotStyles.header} chatbot-header`}>
          {/* <div className={`${chatbotStyles.headerBack}`}>
            <img src={ChatbotBack} alt="back" onClick={toggleChatbotWindow} />
          </div> */}
          <div className={`chatbot-flex ${chatbotStyles.headerOptionsWrapper}`}>
            <img src={ChatbotOptionsIcon} alt="options" onClick={onOptionsButtonClick} className={`${isOptionsMenuVisible ? chatbotStyles.optionsIconBackground : ''}`} />
            <ContextualMenu
              items={optionsMenuItems}
              hidden={!isOptionsMenuVisible}
              target={optionsMenuTargetElement}
              onItemClick={onDismissOptionsMenu}
              onDismiss={onDismissOptionsMenu}
              onRenderMenuList={onRenderOptionsMenuList}
              directionalHint={2}
            />
            <img src={ChatbotClose} alt="close" onClick={toggleChatbotWindow} />
          </div>
        </div>
        <div className={`${chatbotStyles.innerContainer}`}>
          <div className={`${expandedInput ? chatbotStyles.messagesContainerReduced : chatbotStyles.messagesContainer} ${expandedInput ? "chatbot-messages-reduced" : "chatbot-messages"}`}>
              {/* <div ref={messagesStartRef} /> */}
              {messages.map((message, index) => renderChatMessage(message, index))}
              {/* <div className={`${chatbotStyles.scollUpDownContainer}`}>
                <img src={ChatbotUpArrow} alt="Up" onClick={() => messagesStartRef.current.scrollIntoView({behavior: "smooth"}) } />
                <img src={ChatbotDownArrow} alt="D" onClick={() => messagesEndRef.current.scrollIntoView({behavior: "smooth"}) }/>
              </div> */}
              <div ref={messagesEndRef} />
          </div>
          <div className={`${expandedInput ? chatbotStyles.footerExpanded : chatbotStyles.footer} ${expandedInput ? "footer-container" : "footer-container-reduced"}`}>
            {
              generatingResponse ?
                <div className={chatbotStyles.stopButton} onClick={handleStopGenerating}> <img src={ChatbotStop} alt="stop" /> &nbsp; Stop Responding</div>
              :
                <div className={chatbotStyles.horizontalLine}></div>
            }
            {/* DO NOT REMOVE BELOW DIV */}
            <div>
              <div className={`${expandedInput ? chatbotStyles.footerWrapperExpanded : chatbotStyles.footerWrapper} input-area ${expandedInput ? 'input-area-expanded' : ''}`}>
                {
                  expandedInput ? 
                    <></> 
                  : 
                  // <>
                    // {/* <TooltipHost content="Clear Chat" delay={0} > */}
                      <img title='Clear Chat' src={ChatbotBroom} alt="clear chat" onClick={handleClearChat} className={chatbotStyles.broomImage} />
                    // {/* </TooltipHost> */}
                  // </>
                }
                <TextField 
                  placeholder="Ask me anything..." 
                  value={userInputText}
                  // onBlur={() => setExpandedInput(false)} 
                  onKeyDown={(event) => {
                    if (event.key === 'Enter') {
                      event.preventDefault();
                      handleUserMessage(event.target.value);
                      // event.target.value = '';
                      setUserInputText('');
                      // setExpandedInput(false);
                    }
                  }}
                  // onClick={() => setExpandedInput(true)}
                  onClick={handleTextAreaClick}
                  onChange={(event) => setUserInputText(event.target.value)}
                  maxLength={1000}
                  multiline={expandedInput ? true : false}
                  rows={1} 
                  resizable={false} 
                  className={`${chatbotStyles.inputBox} ${expandedInput ? 'expanded' : ''}`}
                  suffix={<img src={ChatbotSend} alt="send" className={chatbotStyles.sendButton} 
                  onClick={() => {
                    handleUserMessage(userInputText);
                    setUserInputText('');
                  }}/>}
                />
              </div>
              {expandedInput ? 
                <div className={`${chatbotStyles.inputCharCount}`}>{userInputText.length}/1000</div> 
              : 
                <></>
              }
            </div>
            <div className={`${chatbotStyles.footerDisclaimer} footer-disclaimer`}>Assistance &nbsp; <img src={ChatbotPower} alt="power" /> &nbsp; by GPT-3.5 Turbo &nbsp; AI generated content may be incomplete or factually incorrect.</div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Chatbot;