import { Stack, mergeStyleSets } from "@fluentui/react";
import { TOOLTIP_CONTENT, SCOPE_DESCRIPTION } from "constants/sustainability";
import Accordion from "components/Accordion";
import "./MobileTooltipAccordion.scss";

const MobileTooltipAccordion = (props) => {
  const accordionStyles = mergeStyleSets({
    container: {
      alignSelf: "flex-start",
      width: "100%",
    },
    accordionTitle: {
      backgroundColor: "#ffffff",
      padding: "1.3rem 1rem",
    },
    scopeTitleWrapper: {
      gap: "0.6rem",
    },
    titleLabel: {
      fontWeight: "400 !important",
    },
    titleValue: {
      fontWeight: "600 !important",
    },
    accordionContent: {
      backgroundColor: "#ffffff",
      padding: " 0 1.6rem 1.6rem",
      fontSize: "1.4rem",
      lineHeight: "2.1rem",
    },
    introText: {
      fontSize: "1.6rem",
      lineHeight: "2.2rem",
      marginLeft: "1.5rem",
      marginBottom: "3rem",
    },
  });

  return (
    <div
      className={`${accordionStyles.container} mobile-display-only mobile-tooltip-accordion-wrapper`}
    >
      <div className={accordionStyles.introText}>
        Expand for Additional Details
      </div>
      {Object.keys(SCOPE_DESCRIPTION).map((key, index) => {
        const title = (
          <Stack horizontal className={accordionStyles.scopeTitleWrapper}>
            <span className={accordionStyles.titleLabel}>
              {SCOPE_DESCRIPTION[key].label}
            </span>
            <span className={accordionStyles.titleValue}>
              {props[key] ? `- ${props[key]}` : null}
            </span>
          </Stack>
        );
        return (
          <Accordion
            key={index}
            title={title}
            headerClassName={`${accordionStyles.accordionTitle} mobile-tooltip-accordion-title`}
          >
            <div
              className={accordionStyles.accordionContent}
              dangerouslySetInnerHTML={{
                __html: SCOPE_DESCRIPTION[key].content,
              }}
            />
          </Accordion>
        );
      })}
      {TOOLTIP_CONTENT.map((item) => (
        <Accordion
          key={item.id}
          title={item.id}
          headerClassName={`${accordionStyles.accordionTitle} mobile-tooltip-accordion-title`}
        >
          <div
            className={accordionStyles.accordionContent}
            dangerouslySetInnerHTML={{
              __html: item.text,
            }}
          />
        </Accordion>
      ))}
    </div>
  );
};

export default MobileTooltipAccordion;
