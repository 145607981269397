import { useState } from "react";
import { mergeStyleSets } from "@fluentui/react";
import { isSmallWidthScreen } from "utils/helpers";

const ReadMore = (props) => {
  const { className } = props;
  const text = props.children;
  const { count = isSmallWidthScreen() ? 12 : 25 } = props;
  const [isFullContent, setIsFullContent] = useState(false);
  const handleToggle = () => {
    setIsFullContent(!isFullContent);
  };
  const readMoreStyles = mergeStyleSets({
    container: {
      marginBottom: "2.3125rem",
    },
    text: {
      fontSize: "1rem",
      fontWeight: 400,
      color: "#201F1E",
      lineHeight: "1.375rem",
      "p": {
        marginTop: 0,
      },
    },
    toggleButton: {
      cursor: "pointer",
      fontSize: "1rem",
      fontWeight: 600,
      color: "#0078D4",
      lineHeight: "1.375rem",
    },
  });

  const getStringContent = (content) => {
    let result = content;
    if (Array.isArray(content)) {
      result = content.reduce((acc, curr) => {
        if (typeof curr === "string") {
          return acc + " " + curr;
        }
        return acc + " " + getStringContent(curr.props.children);
      }, "");
    }
    return result;
  }

  const cutText = (text) => {
    let content = text;
    content = getStringContent(content);
    content = content.split(" ").slice(0, count).join(" ");
    return `${content} ...`;
  }

  return (
    <div
      className={`${readMoreStyles.container} ${
        className ? className : ""
      } read-more-wrapper`}
    >
      <span
        className={readMoreStyles.text}
        tabIndex={0}
        onClick={() => {
          if (isSmallWidthScreen()) {
            handleToggle();
          }
        }}
      >
        {isFullContent ? text : cutText(text)}
      </span>
      <span
        onClick={handleToggle}
        className={readMoreStyles.toggleButton}
        tabIndex={0}
        onKeyDown={(event) => {
          if (event.key === "Enter") {
            handleToggle();
          }
        }}
      >
        {isFullContent ? " Read Less" : " Read More"}
      </span>
    </div>
  );
};

export default ReadMore;