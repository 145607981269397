import React, { useState } from 'react';
import { Checkbox, mergeStyleSets } from '@fluentui/react';
import { useBoolean } from '@fluentui/react-hooks';

import tcoBuildingImage from "assets/tco-building.svg";
import tcoGlobeImage from "assets/tco-globe.svg";
import tcoPanelImage from "assets/tco-panel.svg";
import deviceCostImage from "assets/tco-device-cost.svg";
import { useNavigate } from 'react-router-dom';
import directSavingsImage from "assets/tco-direct-savings.svg";
import itEfficiencyImage from "assets/tco-it-efficiency.svg";
import employeeExperienceImage from "assets/tco-employee-experience.svg";
import "./TcoUnderstand.scss";
import { convertNumberToString, trackGAEvent } from 'utils/helpers';

const TcoUnderstand = () => {

    const navigate = useNavigate();

    const [activeItem, setActiveItem] = useState(1);
    const [totalValues, setTotalValues] = useState({
        deviceCosts: 3238,
        directSavings: 293,
        itEfficiency: 5635,
        employeeExperience: 3108,
    });

    const groupsAndItems = {
        deviceCosts: ["deviceCosts", "m365Costs", "accessoriesCosts", "maintenanceCosts",],
        directSavings: ["increasedResidualValue", "reducedSupport", "accessoriesSavings",],
        itEfficiency: ["deviceConsolidation", "helpdeskSupport", "ongoingMaintenance", "securityIncidentResponse", "reducedITDeploymentCosts", "ongoingSecurity", "itTraining",],
        employeeExperience: ["productivityGains", "employeeTimeLosttoHelpdesk", "meetingTime", "employeeTimeLostToSecurityIncidents", "surfaceDeployment", "employeeRetention", "bootupTime",],
    };

    const itemsAndCosts = {
        deviceCosts: 1355,
        m365Costs: 1296,
        accessoriesCosts: 344,
        maintenanceCosts: 243,
        increasedResidualValue: 115,
        reducedSupport: 58,
        accessoriesSavings: 120,
        deviceConsolidation: 3756,
        helpdeskSupport: 885,
        ongoingMaintenance: 810,
        securityIncidentResponse: 126,
        reducedITDeploymentCosts: 42,
        ongoingSecurity: 18,
        itTraining: -2,
        productivityGains: 1290,
        employeeTimeLosttoHelpdesk: 619,
        meetingTime: 864,
        employeeTimeLostToSecurityIncidents: 89,
        surfaceDeployment: 60,
        employeeRetention: 105,
        bootupTime: 81,
    }

    const itemThreeTableData = [
        {
            label: "Device Cost",
            values: [
                {
                    id: "deviceCosts",
                    value: "$1,355",
                    description: "Average Surface Device",
                },
                {
                    id: "m365Costs",
                    value: "$1,296",
                    description: "M365 E3 subscription",
                },
                {
                    id: "accessoriesCosts",
                    value: "$344",
                    description: "Accessories",
                },
                {
                    id: "maintenanceCosts",
                    value: "$243",
                    description: "Extended warranty and maintenance",
                },
            ],
            total: {
                value: totalValues.deviceCosts,
                description: "Total 3-year cost per Surface",
            }
        },
        {
            label: "Direct Savings",
            values: [
                {
                    id: "increasedResidualValue",
                    value: "$115",
                    description: "Increased residual value, 31% higher than other PC* devices",
                },
                {
                    id: "reducedSupport",
                    value: "$58",
                    description: "Reduction in IT costs from lower 3rd-party support and security expenses",
                },
                {
                    id: "accessoriesSavings",
                    value: "$120",
                    description: "Accessory Savings",
                },
            ],
            total: {
                value: totalValues.directSavings,
                description: "Device savings per Surface Device",
            }
        },
        {
            label: "IT Efficiency",
            values: [
                {
                    id: "deviceConsolidation",
                    value: "$3,756",
                    description: "Device Consolidation",
                },
                {
                    id: "helpdeskSupport",
                    value: "$885",
                    description: "Reduced Helpdesk",
                },
                {
                    id: "ongoingMaintenance",
                    value: "$810",
                    description: "Reduced Maintenance",
                },
                {
                    id: "securityIncidentResponse",
                    value: "$126",
                    description: "Reduced Security Incident Response",
                },
                {
                    id: "reducedITDeploymentCosts",
                    value: "$42",
                    description: "Reduced Deployment Cost",
                },
                {
                    id: "ongoingSecurity",
                    value: "$18",
                    description: "Reduced IT Staff Time for Security",
                },
                {
                    id: "itTraining",
                    value: "-$2",
                    description: "Higher IT Training Cost",
                },
            ],
            total: {
                value: totalValues.itEfficiency,
                description: "Total IT Benefit per Surface Device",
            }
        },
        {
            label: "Employee Retention",
            values: [
                {
                    id: "productivityGains",
                    value: "$1,290",
                    description: "Productivity Gains from Increased Mobility",
                },
                {
                    id: "employeeTimeLosttoHelpdesk",
                    value: "$619",
                    description: "Reduced Employee Time Lost to Helpdesk",
                },
                {
                    id: "meetingTime",
                    value: "$864",
                    description: "Meeting Time Saved",
                },
                {
                    id: "employeeTimeLostToSecurityIncidents",
                    value: "$89",
                    description: "Reduced Employee Time Lost to Security Incidents",
                },
                {
                    id: "surfaceDeployment",
                    value: "$60",
                    description: "Faster Deployment",
                },
                {
                    id: "employeeRetention",
                    value: "$105",
                    description: "Higher Employee Retention",
                },
                {
                    id: "bootupTime",
                    value: "$81",
                    description: "Faster Bootup Time",
                },
            ],
            total: {
                value: totalValues.employeeExperience,
                description: "Total Employee Benefit per Surface Device",
            }
        },
    ];


    const understandTcoStyles = mergeStyleSets({
        rootContainer: {
            display: "flex",
            flexDirection: "column",
        },
        container: {
            display: "flex",
            justifyContent: "space-between",
            minHeight: "80vh",
        },
        left: {
            width: "25%",
            // width: "40%",
            display: "flex",
            flexDirection: "column",
        },
        right: {
            width: "73%",
            // width: "57%",
        },
        leftElement: {
            flex: 1,
            borderRight: "1px solid #bfbfbf",
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            color: "#bfbfbf",
            // fontSize: "3.1rem",
            fontSize: "2.4rem",
            fontWeight: 340,
            textAlign: "right",
            paddingLeft: "2rem",
            paddingRight: "3rem",
            "p": {
                cursor: "pointer",
                strong: {
                    fontWeight: 500,
                }
            },
            "&.active": {
                color: "#4E95D9",
                borderRight: "1px solid #0070C0",
            },
            position: "relative",
        },
        emptyElement: {
            padding: "0.5rem 0",
        },
        triangle: {
            content: '""',
            position: "absolute",
            top: "50%",
            right: "-2rem",
            transform: "translateY(-50%)",
            borderLeft: "1rem solid #0070C0",
            borderTop: "1rem solid transparent",
            borderBottom: "1rem solid transparent",
            borderRight: "1rem solid transparent",
        },
        topRightButton: {
            marginRight: window.innerWidth > 1640 ? "4.6rem" : "4rem",
            border: "1px solid #4E95D9",
            color: "#4E95D9",
            borderRadius: "0.5rem",
            alignSelf: "flex-end",
            padding: "0.4rem 1.5rem",
            fontWeight: 500,
            ":hover": {
                background: "#4E95D9",
                color: "#ffffff",
                cursor: "pointer",
            }
        }
    });

    const itemOneStyles = mergeStyleSets({
        container: {
            padding: "0 6rem 0 2rem",
            // border: "1px solid red",
        },
        textContainer: {
            // border: "1px solid green",
            marginTop: "4rem",//font-size-revert
            fontSize: "1.1rem",//font-size-revert
            lineHeight: "1.5rem",
            span: {
                color: "#0070C0",
            },
            a: {
                color: "#201F1E",
                fontStyle: "italic",
            },
            p: {//font-size-revert
                padding: "0.4rem 0",
            }
        },
        togglesContainer: {
            // border: "1px solid blue",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "16rem",
        },
        toggleElement: {
            flex: 1,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
        },
        flipCardFront: {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
            img: {
                width: "4rem",
                paddingTop: "1rem",
            },
            p: {
                fontSize: "1.2rem",
                color: "#575757",
                fontWeight: 500,
            },
        },
        flipCardBack: {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            p: {
                padding: "0 1rem 1rem",
            }
        },
        bold: {
            color: "#0070C0",
            fontWeight: 500,
        },
    });

    const itemTwoStyles = mergeStyleSets({
        container: {
            padding: "0 6rem 0 2rem",
            // border: "1px solid red",
        },
        iconsContainer: {
            display: "flex",
            alignItems: "center",
            justifyContent: "space-around",
            height: "14rem",
            marginTop: "2rem",
        },
        iconElement: {
            // flex: 1,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
            width: "16rem",
            img: {
                width: "4.5rem",
            },
            p: {
                textAlign: "center",
                color: "#0070C0",
                fontSize: "1.1rem",
            }
        },
        textContainer: {
            fontSize: "1.1rem",
            ol: {
                li: {
                    paddingLeft: "1rem",
                    marginTop: "1rem",
                    span: {
                        color: "#0070C0",
                        fontWeight: 500,
                    }
                },
                "li::marker": {
                    color: "#0070C0",
                    fontWeight: 500,
                }
            }
        }
    });

    const itemTwoContent = (
        <div className={`${itemOneStyles.container} mobile-item-one-container`}>
            <div className={`${itemOneStyles.textContainer} mobile-item-one-text-container`}>
                <p>
                    When it comes to evaluating a device purchase for your organization, the cost of the device is only one part of the overall equation.
                </p>
                <p>
                    <span>Total Cost of Ownership (TCO)</span> factors in the cost of the device plus the operating costs of ownership over a period of time (e.g. 3 years); to provide a complete and holistic assessment of the value of your investment.
                </p>
                <p>
                    Based on a Microsoft commissioned IDC study, <a href="https://aka.ms/SurfaceIDCWhitepaper" rel="noreferrer" target="_blank">Evaluating the Business Case of Microsoft Surface (2022)</a>, <span className={`${itemOneStyles.bold}`}>Microsoft Surface benefits are worth almost three times the investment cost over 3 years</span> based on: 
                </p>
            </div>
            <div className={`${itemOneStyles.togglesContainer} mobile-toggles-container`}>
                <div className={`${itemOneStyles.toggleElement} mobile-toggle-element`}>
                    <div className="flip-card">
                        <div className="flip-card-inner">
                            <div className={`${itemOneStyles.flipCardFront} flip-card-front`}>
                                <img src={directSavingsImage} alt="direct-savings" />
                                <p>Direct Savings</p>
                            </div>
                            <div className={`${itemOneStyles.flipCardBack} flip-card-back`}>
                                <p>
                                    Consolidate hardware with versatile devices, reduce spending on peripherals, and decrease operational costs.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={`${itemOneStyles.toggleElement} mobile-toggle-element`}>
                    <div className="flip-card">
                        <div className="flip-card-inner">
                            <div className={`${itemOneStyles.flipCardFront} flip-card-front`}>
                                <img className='exception' src={itEfficiencyImage} alt="it-efficiency" />
                                <p>IT Efficiency</p>
                            </div>
                            <div className={`${itemOneStyles.flipCardBack} flip-card-back`}>
                                <p>
                                    Devices optimized for Microsoft experiences require less IT staff time to deploy, manage, support, and secure
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={`${itemOneStyles.toggleElement} mobile-toggle-element`}>
                    <div className="flip-card">
                        <div className="flip-card-inner">
                            <div className={`${itemOneStyles.flipCardFront} flip-card-front`}>
                                <img src={employeeExperienceImage} alt="emp-experience" />
                                <p>Employee Experience</p>
                            </div>
                            <div className={`${itemOneStyles.flipCardBack} flip-card-back`}>
                                <p>
                                    Productive devices make employees feel valued and engaged so they can achieve their best.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );

    const itemThreeContent = (
        <div className={`${itemTwoStyles.container} mobile-item-two-container`}>
            
            <div className={`${itemTwoStyles.iconsContainer}`}>
                <div className={`${itemTwoStyles.iconElement} mobile-icon-element`}>
                    <img src={tcoBuildingImage} alt="building" />
                    <p>700 Surface and 100 non-Surface customers surveyed</p>
                </div>
                <div className={`${itemTwoStyles.iconElement} mobile-icon-element`}>
                    <img src={tcoGlobeImage} alt="globe" />
                    <p>Across 8 countries, 20+ industries</p>
                </div>
                <div className={`${itemTwoStyles.iconElement} mobile-icon-element`}>
                    <img src={tcoPanelImage} alt="panel" />
                    <p>17 x IT Decision Maker Panel Interviews</p>
                </div>
            </div>
            <div className={`${itemTwoStyles.textContainer} mobile-item-two-text-container`}>
                <p>
                    Based on interviews, IDC performed a three-step process to calculate net benefits and ROI:
                </p>
                <ol>
                    <li><span>Gathered quantitative data</span> during in-depth interviews using before-and-after assessments of the impact of using Microsoft Surface devices compared with other devices used by study participants.</li>
                    <li><span>Created complete investment profile</span> (3-year total cost analysis) based on interviews. Included investments beyond the costs of deploying and using Microsoft Surface devices with the option of including additional costs related to migrations, planning, consulting, and staff/user training.</li>
                    <li><span>Calculated the ROI.</span> IDC conducted a depreciated cash flow analysis of the benefits and investments for the organizations' use of Microsoft Surface devices over a 3-year period. ROI is the ratio of the net present value (NPV) and the discounted investment.</li>
                </ol>
            </div>
        </div>
    );

    const itemThreeStyles = mergeStyleSets({
        container: {
            marginTop: window.innerWidth > 1740 ? "0rem" : "0",
            paddingTop: "1rem",
        },
        firstElement: {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
        },
        secondElement: {
            marginTop: window.innerWidth > 1740 ? "3rem" : "2.5rem",
            marginRight: "1rem",
        },
        firstElementLeft: {
            width: "50%",
            paddingRight: "2rem",
            ">p:first-child": {
                fontSize: "2rem",
                marginTop: "0rem",
                marginBottom: "1rem",
                fontWeight: 370,
            },
            ">p:last-child": {
                fontSize: "1.1rem",
                marginTop: "0rem",
                span: {
                    fontWeight: 500,
                }
            },
        },
        firstElementRight: {
            width: "50%",
            paddingLeft: "3rem",
            borderLeft: "1px solid #0070C0",
        },
        description: {
            margin: "0",
            fontSize: "1.1rem",
            span: {
                fontWeight: 500,
            },
            "&.mb": {
                marginBottom: "0.7rem",
            }
        },
        value: {
            margin: "0",
            color: "#0070C0",
            fontSize: "2.2rem",
        },
        tableContainer: {
            display: "flex",
            alignItems: "stretch",
            // justifyContent: "center",
            ">div:nth-child(1)": {
                width: "21.85%",
                borderRight: "1px solid rgb(191, 191, 191)",
            },
            ">div:nth-child(2)": {
                width: "21.85%",
                borderRight: "1px solid #fff",
            },
            ">div:nth-child(3)": {
                width: "25.65%",
                borderRight: "1px solid #fff",
            },
            ">div:nth-child(4)": {
                width: "25.65%",
            },
        },
        tableHead: {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            background: "rgba(191, 191, 191, 0.4)",
            img: {
                width: "2.2rem",
                marginRight: "0.7rem",
            },
            p: {
                fontSize: "1rem",
                fontWeight: 500,
                marginTop: "0.8rem",
                marginBottom: "0.8rem",
            }
        },
        tableBody: {
            paddingRight: "1rem",
            ul: {
                paddingLeft: "2rem",
            }
        },
        tableListItem: {
            marginBottom: "0.7rem",
        },
        tableListItemBold: {
            fontWeight: 500,
        },
        tableTotalContainer: {
            display: "flex",
            alignItems: "stretch",
            // justifyContent: "center",
            ">div:nth-child(1)": {
                width: "21.95%",
                borderRight: "1px solid rgb(191, 191, 191)",
            },
            ">div:nth-child(2)": {
                width: "21.85%",
                borderRight: "1px solid #fff",
            },
            ">div:nth-child(3)": {
                width: "25.65%",
                borderRight: "1px solid #fff",
            },
            ">div:nth-child(4)": {
                width: "25.65%",
                borderRight: "1px solid #fff",
            },
        },
        totalListItem: {
            boxSizing: "border-box",
            // width: "25%",
            padding: "0.5rem 0.5rem 0.5rem",
            textAlign: "center",
            color: "#0070C0",
            fontSize: "1rem",
            marginBottom: "0.5rem",
            borderTop: "1px solid #0070C0",
            span: {
                fontWeight: 500,
            }
        },
        exceptionImage: {
            width: "1.8rem !important",
        },
    });

    const handleCheckboxClick = (isChecked, id) => {
        let group;
        if(groupsAndItems.deviceCosts.includes(id)){
            group = "deviceCosts";
        } else if(groupsAndItems.directSavings.includes(id)){
            group = "directSavings";
        } else if(groupsAndItems.itEfficiency.includes(id)){
            group = "itEfficiency";
        } else if(groupsAndItems.employeeExperience.includes(id)){
            group = "employeeExperience";
        } else {
            group = "NA";
        }

        setTotalValues((state) => {
            return {
                ...state,
                [group]: isChecked ? state[group] + itemsAndCosts[id] : state[group] - itemsAndCosts[id]
            }
        });

    }

    const tableColumnContent = (item, index) => {

        let imageSource;
        switch (item.label) {
            case "Device Cost":
                imageSource = deviceCostImage;
                break;
            case "Direct Savings":
                imageSource = directSavingsImage;
                break;
            case "IT Efficiency":
                imageSource = itEfficiencyImage;
                break;
            case "Employee Retention":
                imageSource = employeeExperienceImage;
                break;
            default:
                imageSource = itEfficiencyImage;
                break;
        }

        return (
            <div key={index}>
                <div className={`${itemThreeStyles.tableHead}`}>
                    <img className={item.label === "IT Efficiency" ? `${itemThreeStyles.exceptionImage}` : ""} src={imageSource} alt={item.label} />
                    <p>{item.label}</p>
                </div>
                <div className={`${itemThreeStyles.tableBody} mobile-table-body`}>
                    <ul>
                        {item.values.map((listItem, i) => {
                            return (
                                <>
                                    <Checkbox defaultChecked className={`${itemThreeStyles.tableListItem} custom-checkbox`} label={<><span className={`${itemThreeStyles.tableListItemBold}`}>{listItem.value}</span>: {listItem.description}</>} onChange={(eve, isChecked) => handleCheckboxClick(isChecked, listItem.id)} />
                                </>
                            );
                        })}
                    </ul>
                </div>
            </div>
        );
    }

    const itemOneContent = (
        <div className={`${itemThreeStyles.container}`}>
            <div className={`${itemThreeStyles.firstElement}`}>
                <div className={`${itemThreeStyles.firstElementLeft} mobile-first-element-left`}>
                    <p>Surface unlocks savings</p>
                    <p><span>Over a three-year period</span>, Surface simplifies your hardware lineup and reduces IT costs.</p>
                </div>
                <div className={`${itemThreeStyles.firstElementRight} mobile-first-element-right`}>
                    <p className={`${itemThreeStyles.description} mobile-first-element-description`}><span>Up to</span></p>
                    <p className={`${itemThreeStyles.value} mobile-first-element-value`}>$9,036</p>
                    <p className={`${itemThreeStyles.description} mb mobile-first-element-description`}>In total 3-year savings and benefits</p>
                    <p className={`${itemThreeStyles.value} mobile-first-element-value`}>2.8x</p>
                    <p className={`${itemThreeStyles.description} mobile-first-element-description`}>3-year return on investment</p>
                </div>
            </div>
            <div className={`${itemThreeStyles.secondElement}`}>
                <div className={`${itemThreeStyles.tableContainer} mobile-table-container`}>
                    { itemThreeTableData.map((item, index) => tableColumnContent(item, index)) }
                </div>
                <div className={`${itemThreeStyles.tableTotalContainer} mobile-table-total-container`}>
                    { 
                        itemThreeTableData.map((item, index) => {
                            return (
                                <div className={`${itemThreeStyles.totalListItem} mobile-table-total-list-item`}><span>{convertNumberToString(item.total.value, "$")}</span>: {item.total.description}</div>
                            );
                        })
                    }
                </div>
            </div>
        </div>
    );

  return (
    <div className={`${understandTcoStyles.rootContainer}`}>
        <div className={`${understandTcoStyles.topRightButton}`} onClick={() => {navigate("/calculator"); trackGAEvent("Navigation", "TCO calculator button click on IDC page", "TCO Calculator button in Understand TCO page")}}>TCO Calculator</div>
        <div className={`${understandTcoStyles.container} mobile-understand-tco-container`}>
            <div className={`${understandTcoStyles.left}`}>
                <div className={`${understandTcoStyles.emptyElement}`}></div>
                <div 
                    className={`${understandTcoStyles.leftElement} ${activeItem === 1 ? "active" : ""} mobile-left-element`}
                    onClick={() => setActiveItem(1)}
                >
                    {
                        activeItem === 1 ?
                        <>
                            <p><strong>Why</strong> Surface?</p>
                            <span className={`${understandTcoStyles.triangle}`} ></span>
                        </>
                :
                        <p>Why Surface?</p>
                    }
                </div>
                <div 
                    className={`${understandTcoStyles.leftElement} ${activeItem === 2 ? "active" : ""} mobile-left-element`}
                    onClick={() => setActiveItem(2)}
                >
                    {
                        activeItem === 2 ?
                            <>
                                <p><strong>What</strong> is the Surface TCO?</p>
                                <span className={`${understandTcoStyles.triangle}`} ></span>
                            </>
                        :
                            <p>What is the Surface TCO?</p>
                    }
                </div>
                <div 
                    className={`${understandTcoStyles.leftElement} ${activeItem === 3 ? "active" : ""} mobile-left-element`}
                    onClick={() => setActiveItem(3)}
                >
                    {
                        activeItem === 3 ?
                            <>
                                <p><strong>How</strong> was it calculated?</p>
                                <span className={`${understandTcoStyles.triangle}`} ></span>
                            </>
                        :
                            <p>How was it calculated?</p>
                    }
                </div>
                <div className={`${understandTcoStyles.emptyElement}`}></div>
            </div>
            <div className={`${understandTcoStyles.right}`}>
                {
                    activeItem === 3 ? 
                        itemThreeContent 
                    :
                    activeItem === 2 ?
                        itemTwoContent
                    :
                        itemOneContent
                }
            </div>
        </div>
    </div>
  )
}

export default TcoUnderstand;