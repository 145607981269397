import { useState, useContext, createContext } from "react";
import { MessageBar, MessageBarType } from "@fluentui/react";

export const AlertContext = createContext({
  type: "",
  content: "",
  setAlert: () => {},
});

export const AlertProvider = ({ children }) => {
  const [content, setContent] = useState("");
  const [type, setType] = useState("");

  const setAlert = (type, content, timer) => {
    setType(type);
    setContent(content);

    if (timer) {
      setTimeout(() => {
        setType("");
        setContent("");
      }, timer);
    }
  };

  return (
    <AlertContext.Provider
      value={{
        type,
        content,
        setAlert,
      }}
    >
      {children}
    </AlertContext.Provider>
  );
};

export const AlertBanner = () => {
  const { type, content, setAlert } = useContext(AlertContext);
  const messageBarStyles = {
    root: {
      position: "sticky",
      top: 0,
      zIndex: 1,
    },
  };

  if (!type || !content) {
    return <></>;
  }

  return (
    <MessageBar
      messageBarType={MessageBarType[type]}
      styles={messageBarStyles}
      onDismiss={() => setAlert("", "")}
    >
      {content}
    </MessageBar>
  );
};